export default function CardItem({ image, style, length = 4, className, children }) {
  return (
    <div className={className}>
      <div className="card flex-sm-col flex-md-row overflow-hidden" style={style}>
        {image ? <img className="card-img-top" src={image} alt="" /> : <></>}
        <div className="card-body d-flex justify-content-between align-items-center ">{children}</div>
      </div>
    </div>
  )
}

export function CardLongItem({ image, children }) {
  return (
    //col-sm-12 -> flex-sm-col
    <div className="flex-sm-col">
      {/* <div className='card flex-sm-col flex-md-row overflow-hidden'> */}
      <div className="card flex-md-row overflow-hidden">
        {image ? <img className="card-img-top" src={image} alt="" /> : <></>}
        <div className="card-body">{children}</div>
      </div>
    </div>
  )
}
