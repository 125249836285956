import React, { useState } from 'react'
import { Row, Col, Form, InputGroup, Card } from 'react-bootstrap'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { IoStar } from 'react-icons/io5'
import Slider from '@mui/material/Slider'
import { useSpecialityListQuery } from 'v2/src/lib/services/provider/providerApi'
import { useTranslation } from 'react-i18next'
import { FaCheck } from 'react-icons/fa6'

const filterDropdownStyle = {
  backgroundColor: '#f1f5fa',
  color: '#000',
  border: 'none',
  borderRadius: '50px',
  padding: '8px 25px',
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  alignItems: 'center',
  gap: '24px',
}

const filterDropdownMenuStyle = {
  position: 'absolute',
  zIndex: '1000',
  backgroundColor: 'white',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
  padding: '0px',
  borderRadius: '12px',
  top: '100%',
  left: '0',
  marginTop: 8,
  width: '250px',
}

const filterSelectionBG = {
  backgroundColor: '#fde0e4',
  color: '#f04d4a',
  border: 'none',
  borderRadius: '50px',
  padding: '2px 10px',
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  alignItems: 'center',
  gap: '14px',
  fontSize: 13,
}

const removeXStyle = {
  background: 'transparent',
  border: 'none',
  color: 'rgba(255, 0, 0, 0.7)',
  cursor: 'pointer',
}

const SearchSection = ({
  priceRange,
  setPriceRange,
  selectedRatings,
  setSelectedRatings,
  handleRatingSelect,
  handlePriceRangeChange,
  setSpecializations,
  setSearchTriggered,
  visitType,
  setVisitType,
  searchText,
  setSearchText,
}) => {
  const { t: patient_marketplace } = useTranslation('patient_marketplace')

  const [selectedSpecializations, setSelectedSpecializations] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isPriceRangeOpen, setIsPriceRangeOpen] = useState(false)
  const [isRatingsOpen, setIsRatingsOpen] = useState(false)

  const { data: SpecialityListData } = useSpecialityListQuery()

  const handleSpecializationSelect = (spec, id) => {
    setSpecializations((prev) => (prev.includes(id) ? prev.filter((s) => s !== id) : [...prev, id]))
    setSelectedSpecializations(
      (prev) => (prev.includes(spec) ? prev.filter((s) => s !== spec) : [...prev, spec]) // Toggle selection
    )
  }

  const ratingOptions = [
    { value: 5, label: '5' },
    { value: 4, label: '4' },
    { value: 3, label: '3' },
    { value: 2, label: '2' },
    { value: 1, label: '1' },
    { value: 0, label: 'Unrated' },
  ]

  return (
    <div>
      <Row
        className="align-items-center"
        style={{
          border: '1px solid #1b181830',
          borderRadius: '50px',
          padding: '2px',
          margin: 0,
          gap: 8,
        }}
      >
        {/* Visit Type Toggle Buttons inside search bar */}
        <span style={{ backgroundColor: '#f1f5fa' }} className="rounded-pill">
          <button
            style={{
              backgroundColor: visitType === 'clinic' ? '#198754' : 'transparent',
              color: visitType === 'clinic' ? '#fff' : 'black',
              border: '0px solid #198754',
              borderRadius: '50px',
              padding: '8px 15px',
              // marginRight: '8px',
            }}
            onClick={() => setVisitType('clinic')}
          >
            {patient_marketplace('in_clinic_visit')}
          </button>
          <button
            style={{
              backgroundColor: visitType === 'telehealth' ? '#198754' : 'transparent',
              color: visitType === 'telehealth' ? '#fff' : 'black',
              border: '0px solid #198754',
              borderRadius: '50px',
              padding: '8px 15px',
            }}
            onClick={() => setVisitType('telehealth')}
          >
            {patient_marketplace('virtual_visit')}
          </button>
        </span>

        {/* Specialization Input with Dropdown */}
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <div
            style={{
              backgroundColor: '#f1f5fa',
              color: '#000',
              borderRadius: '50px',
              marginLeft: '6px',
              minWidth: '180px',
              padding: '8px 16px',
              display: 'flex',
              justifyContent: 'space-between',
              cursor: 'pointer',
              alignItems: 'center',
            }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <span> {patient_marketplace('specialization')}</span>
            <span>{isOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
          </div>

          {isOpen && (
            <ul style={filterDropdownMenuStyle}>
              {SpecialityListData?.data?.map((spec, index) => (
                <li
                  key={index}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px',
                    cursor: 'pointer',
                    marginBottom: '5px',
                    width: '100%',
                    backgroundColor: selectedSpecializations.includes(spec?.name) ? '#b3c0e314' : '',
                  }}
                  onClick={() => handleSpecializationSelect(spec?.name, spec?.id)}
                >
                  {spec?.name}
                  {selectedSpecializations.includes(spec?.name) && (
                    <span className="text-success">
                      <FaCheck style={{ color: '#008100' }} />
                    </span>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Specializations Displayed in Input */}
        <Col md className="p-0">
          <InputGroup>
            <Form.Control
              type="text"
              placeholder={patient_marketplace('type_your_search')}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value) // Allow typing
              }}
              onFocus={() => setIsOpen(false)} // Close dropdown when typing
              style={{
                border: '0px solid black',
                borderRadius: '50px',
                padding: '10px 15px',
              }}
            />
          </InputGroup>
        </Col>

        {/* Search Button */}
        <span md="auto">
          <button
            style={{
              backgroundColor: '#008100',
              border: 'none',
              color: '#fff',
              borderRadius: '50px',
              padding: '8px 20px',
              width: 125,
            }}
            onClick={() => setSearchTriggered((prev) => !prev)}
          >
            {patient_marketplace('search')}
          </button>
        </span>
      </Row>

      {/* Filters */}
      <div className="mt-3 d-flex flex-column" style={{ gap: 8 }}>
        <div className="d-flex align-items-center" style={{ gap: 12 }}>
          <span auto>
            <span style={{ fontWeight: 600, fontSize: 15 }}>{patient_marketplace('filter_by')}:</span>
          </span>

          {/* Price ranges filters */}
          <span md="auto" style={{ position: 'relative' }}>
            <button
              style={filterDropdownStyle}
              onClick={() => setIsPriceRangeOpen(!isPriceRangeOpen)} // Toggle slider visibility
            >
              <span> {patient_marketplace('price_range')}</span>
              {/* Toggle arrow */}
              <span
                style={{
                  transform: isPriceRangeOpen ? 'rotate(180deg)' : 'rotate(0deg)', // Rotate arrow based on open state
                  transition: 'transform 0.3s ease-in-out', // Smooth transition for rotation
                }}
              >
                {isPriceRangeOpen ? <FaChevronUp /> : <FaChevronDown />}
              </span>
            </button>

            {isPriceRangeOpen && (
              <Card style={{ ...filterDropdownMenuStyle, width: '320px' }}>
                <Card.Body style={{ paddingBottom: 5 }}>
                  <Slider
                    value={priceRange}
                    onChange={handlePriceRangeChange}
                    min={0}
                    max={500}
                    step={1}
                    marks={[
                      { value: priceRange[0], label: `$${priceRange[0]}` }, // Selected Min
                      { value: priceRange[1], label: `$${priceRange[1]}` }, // Selected Max
                    ]}
                    sx={{
                      width: '100%',
                      color: 'green',
                      m: 0,
                      mt: 2,
                      '& .MuiSlider-track': { height: 10 },
                      '& .MuiSlider-markLabel': { fontWeight: 500, top: -20 },
                      '& .MuiSlider-thumb': { width: 24, height: 24, backgroundColor: 'green', outline: '2px solid #fff !important' },
                      '& .MuiSlider-rail': { height: 10, backgroundColor: '#ddd' },
                    }}
                  />
                </Card.Body>
              </Card>
            )}
          </span>

          {/* Ratings filter */}
          <span md="auto" style={{ position: 'relative' }}>
            <button
              style={filterDropdownStyle}
              onClick={() => setIsRatingsOpen(!isRatingsOpen)} // Toggle dropdown
            >
              <span>{patient_marketplace('ratings')}</span>
              <FaChevronDown style={{ transform: isRatingsOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out' }} />
            </button>

            {/* Ratings Dropdown - Custom Card */}
            {isRatingsOpen && (
              <Card style={filterDropdownMenuStyle} className="rounded-0">
                <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                  {ratingOptions.map((option) => (
                    <li
                      key={option.value}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '12px',
                        cursor: 'pointer',
                        backgroundColor: '#FAFBFF',
                        transition: 'background-color 0.3s ease',
                      }}
                      onClick={() => handleRatingSelect(option.value)}
                    >
                      <div>
                        <span style={{ marginRight: '10px', fontWeight: 500, fontSize: 15 }}>{option.label}</span>
                        {[...Array(5)].map((_, i) => (
                          <IoStar key={i} color={i < option.value ? '#FFCA22' : '#D9D9D9'} style={{ marginRight: '4px' }} size={20} />
                        ))}
                      </div>
                      {selectedRatings.includes(option.value) && (
                        <span className="text-success">
                          <FaCheck style={{ color: '#008100' }} />
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </Card>
            )}
          </span>
        </div>

        {/* Filters list */}
        <div className="d-flex flex-wrap align-items-center" style={{ justifyContent: 'space-between', gap: 12 }}>
          <div className="d-flex" style={{ gap: 16, paddingLeft: 74 }}>
            {priceRange[0] !== 0 || priceRange[1] !== 500 ? (
              <span style={filterSelectionBG}>
                {`${patient_marketplace('price_range')}: $${priceRange[0]}-$${priceRange[1]}`}
                <button onClick={() => setPriceRange([0, 500])} style={removeXStyle}>
                  ✖
                </button>
              </span>
            ) : null}

            {selectedRatings.length > 0 && (
              <span style={filterSelectionBG}>
                {`${patient_marketplace('ratings')}: ${selectedRatings.map((rating) => (rating === 0 ? patient_marketplace('unrated') : `${rating} ${patient_marketplace('stars')}`)).join(', ')}`}
                <button onClick={() => setSelectedRatings([])} style={removeXStyle}>
                  ✖
                </button>
              </span>
            )}
          </div>

          {(selectedRatings?.length > 0 || priceRange[0] !== 0 || priceRange[1] !== 500) && (
            <span
              style={{
                backgroundColor: '#f04d4a',
                color: '#fff',
                border: 'none',
                borderRadius: '50px',
                padding: '2px 6px',
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <button
                onClick={() => {
                  setSelectedRatings([])
                  setSelectedSpecializations([])
                  setSpecializations([])
                  setPriceRange([0, 500])
                  setSearchTriggered((prev) => !prev)
                }}
                style={{
                  background: 'transparent',
                  border: 'none',
                  color: '#fff',
                  fontSize: '13px',
                  cursor: 'pointer',
                }}
              >
                ✖ {patient_marketplace('clear_all')}
              </button>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default SearchSection
