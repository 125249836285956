import moment from 'moment'
import notificationSound1 from './assets/notifications/01.mp3'
import Cookies from 'js-cookie'
import { NIU_SOCIAL_FE_URL } from './constants'
//---------------------------------------------------------

const audio = new Audio(notificationSound1)

export const getSubscriptionStatus = (userSubscriptionDetailsApiResp) => {
  let status = userSubscriptionDetailsApiResp?.Status
  let data = userSubscriptionDetailsApiResp?.Data

  let currentDate = moment()
  let subscriptionEndDate = moment(data[0]?.subscription_end)

  let subscriptionStatus = 'not_subscribed'

  if (Object.keys(data).length) {
    if (subscriptionEndDate > currentDate) {
      subscriptionStatus = 'subscribed'
    } else if (subscriptionEndDate < currentDate) {
      subscriptionStatus = 'expired'
    }
  }

  return subscriptionStatus
}

export const sortInsuranceDocuments = (insuranceDocumentsApiResp) => {
  const { Buckets } = insuranceDocumentsApiResp.Data
  const today = moment()
  const validDocuments = Buckets.filter((bucket) => {
    const startDate = moment(bucket.start_date)
    const endDate = moment(bucket.end_date)
    return startDate.isSameOrBefore(today, 'day') && endDate.isSameOrAfter(today, 'day')
  })
  const invalidDocuments = Buckets.filter((bucket) => {
    const startDate = moment(bucket.start_date)
    const endDate = moment(bucket.end_date)
    return !(startDate.isSameOrBefore(today, 'day') && endDate.isSameOrAfter(today, 'day')) && bucket.status === '1'
  })
  return [validDocuments, invalidDocuments]
}

export const filterInsuranceDocuments = (validIncDocs) => {
  const pendingValidation = []
  const approved = []
  const rejected = []

  validIncDocs.forEach((bucket) => {
    if (bucket.status === '0') {
      pendingValidation.push(bucket)
    } else if (bucket.status === '1') {
      approved.push(bucket)
    } else if (bucket.status === '2') {
      rejected.push(bucket)
    }
  })

  return [pendingValidation, approved, rejected]
}

export const getInsuranceStatus = (insuranceDocumentsApiResp) => {
  let documents = insuranceDocumentsApiResp?.Data?.Buckets

  let insuranceStatus = 'not_uploaded'

  if (documents) {
    const [validIncDocs, invalidIncDocs] = sortInsuranceDocuments(insuranceDocumentsApiResp)
    if (validIncDocs.length) {
      const [pendingValidation, approved, rejected] = filterInsuranceDocuments(validIncDocs)
      if (approved.length) {
        return 'approved'
      } else if (pendingValidation.length) {
        return 'pending_validation'
      } else if (rejected.length) {
        return 'rejected'
      }
    } else if (invalidIncDocs.length) {
      return 'expired'
    }
  }

  return insuranceStatus
}

export const dateFormat = (date) => {
  let formatdate = Date.parse(date)
  const d = new Date(formatdate)
  return d.toDateString()
}

export const getUserSubscriptionDetailsStatusList = (userActiveSubscription) => {
  const today = moment()
  const insDocId = parseInt(userActiveSubscription?.BucketId)
  const insDocStatus = parseInt(userActiveSubscription?.status)
  const subscriptionId = parseInt(userActiveSubscription?.subscription_id)
  const isUserSubscribed = parseInt(userActiveSubscription?.is_user_subscribed)

  const incStartDateMinusOne = moment(userActiveSubscription?.start_date).subtract(1, 'days')
  const incStartDate = moment(userActiveSubscription?.start_date)
  const incEndDate = moment(userActiveSubscription?.end_date)
  const incEndDatePlusOne = moment(userActiveSubscription?.end_date).add(1, 'days')

  const subscriptionStartMinusOne = moment(userActiveSubscription?.start_date).subtract(1, 'days')
  const subscriptionStart = moment(userActiveSubscription?.subscription_start)
  const subscriptionEnd = moment(userActiveSubscription?.subscription_end)
  const subscriptionEndPlusOne = moment(userActiveSubscription?.end_date).add(1, 'days')

  let showSubscriptionPlans = false
  let subscriptionIsActive = false
  let showCancelBtn = false
  let showWaitingMessage = false

  if (userActiveSubscription) {
    showSubscriptionPlans = true
  }

  if (userActiveSubscription?.renewal_date && userActiveSubscription?.subscription_status === "active") {
    subscriptionIsActive = true
  }

  if (insDocId == 0 && isUserSubscribed == 0) {
    /*Insurance document does not exists && user subscription does not exists*/
    showSubscriptionPlans = true
  }
  if ((insDocId == 0 || (insDocId > 0 && insDocStatus == 0)) && isUserSubscribed > 0 && subscriptionEndPlusOne.isBefore(today)) {
    /*Insurance document does not exists && user is subscribed && user subscription is expired*/
    ;[showSubscriptionPlans, subscriptionIsActive] = [true, true]
  }
  if (insDocId > 0 && insDocStatus == 1 && incEndDatePlusOne.isBefore(today)) {
    /*Insurance document is approved && user subscription is expired*/
    ;[showSubscriptionPlans, subscriptionIsActive] = [true, true]
  }
  if (insDocId > 0 && insDocStatus == 0) {
    /*Insurance document does not exists*/
    showWaitingMessage = true
  }
  if (insDocId > 0 && insDocStatus == 1 && subscriptionStartMinusOne.isBefore(today) && subscriptionEndPlusOne.isAfter(today)) {
    /*Insurance document is approved and subscription is active*/
    subscriptionIsActive = true
  }

  if (userActiveSubscription && userActiveSubscription.subscription_status === "active" && userActiveSubscription.renewal_date && !userActiveSubscription.end_date) {
    showCancelBtn = true;
  }

  if (userActiveSubscription && subscriptionStartMinusOne.isBefore(today) && subscriptionEndPlusOne.isAfter(today)) {
    /*User is subscribed and user subscription is active*/
    ;[subscriptionIsActive, showCancelBtn] = [true, true]
  }
  if (insDocId > 0) {
    /*Insurance document exists then hide the subscription plans*/
    showSubscriptionPlans = false
  }
  if (isUserSubscribed == 0 && insDocId > 0 && insDocStatus == 2) {
    /*If the user is not subscribed and the insurance document exists but rejected */
    showSubscriptionPlans = true
  }

  return [showSubscriptionPlans, subscriptionIsActive, showCancelBtn, showWaitingMessage]
}

export const isSubsUnderGracePeriod = (data, gracePeriod) => {
  const startDate = new Date(data?.start_date)
  const currentDate = new Date()
  const timeDifference = currentDate.getTime() - startDate.getTime()
  const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24))
  if (data?.subscription_status && daysDifference < gracePeriod) {
    return true
  } else {
    return false
  }
}

export const getProvinceFromCountryId = (data, countryId) => {
  const filteredData = data.filter((item) => item.country_id === countryId)
  return filteredData
}

export const emailStartsWithAdminFunc = (email) => {
  if (email.toLowerCase().startsWith('admin')) {
    return true
  } else {
    return false
  }
}

export const playNotificationSound = () => {
  if (audio && typeof audio.play === 'function') {
    audio.play().catch((error) => {
      console.error('Audio play failed:', error)
    })
  } else {
    console.warn('Audio object or play method does not exist')
  }
}

export const generateMarketplaceFilterTags = (searchString, selectedCategories, priceRangeMin, priceRangeMax, starFilter) => {
  let search = ''
  let categories = ''
  let priceRange = ''
  let ratings = ''
  let anyFilterApplied = false

  const defaultPriceMin = 0
  const defaultPriceMax = 2000
  const defaultRatings = [5, 4, 3, 2, 1, 0]

  if (searchString) {
    search = searchString
  }

  if (Object.keys(selectedCategories).length) {
    categories = Object.values(selectedCategories)
      .map((category) => category.name)
      .join(', ')
  }

  if ((priceRangeMin && priceRangeMin != defaultPriceMin) || (priceRangeMax && priceRangeMax != defaultPriceMax)) {
    priceRange = (parseInt(priceRangeMin) || 'Any') + ' - ' + (parseInt(priceRangeMax) || 'Any')
  }

  if (!isArraysEqual(starFilter, defaultRatings)) {
    ratings = starFilter
      .filter((rating) => rating !== false)
      .map((rating) => (rating === 0 ? 'Unrated' : rating))
      .join(', ')
  }

  anyFilterApplied = search || categories || priceRange || ratings ? true : false

  return { search, categories, priceRange, ratings, anyFilterApplied }
}

export const isArraysEqual = (arr1, arr2) => {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false
  }

  // Check if each element in both arrays is equal
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }

  // If we haven't returned by now, the arrays are equal
  return true
}

export const redirectToNiuSocial = async () => {
  /*Redirect to NIU Social platform with token in the header*/
  const accessToken = Cookies.get('accessToken') || ''
  const email = Cookies.get('email') || ''
  const userId = Cookies.get('userId') || ''
  window.location.href = NIU_SOCIAL_FE_URL + '?niuhcauth=' + accessToken + '&email=' + email + '&userId=' + userId
}

export const getCurrentTimezone = () => {
  const timezone = moment.tz.guess()
  return timezone === 'Asia/Calcutta' ? 'Asia/Kolkata' : timezone
}

export const isApiSuccess = (apiResp) => {
  return apiResp?.Status
}

export const checkPassCondition = (Condition) => {
  if (Condition === true) {
    return '✓'
  } else if (Condition === false) {
    return '✖'
  }
}

// Function to reverse a string
export const reverseString = (str) => {
  return str.split('').reverse().join('')
}

export const base64Decode = (str) => {
  return atob(str)
}

export const fGetArrTimeZone = (filterCountryCode = null) => {
  let resp;
  let timezonecountries = moment.tz.countries();
  let timezoneoptions = [];
  if (filterCountryCode && filterCountryCode.length) {
    let pushobject = moment.tz.zonesForCountry(filterCountryCode, true);
    pushobject.push({
      "name": "Pacific/Rarotonga",
      "offset": 600
    });
    timezoneoptions = [...timezoneoptions, ...pushobject]
  } else {
    for (var i in timezonecountries) {
      let pushobject = moment.tz.zonesForCountry(timezonecountries[i], true)[0]
      timezoneoptions.push(pushobject)
    }
  }
  let sorted_timezoneoptions = timezoneoptions.sort((a, b) => (moment(a.offset) > moment(b.offset) ? 1 : -1))

  let tzOptions = []
  for (var i in sorted_timezoneoptions) {
    let alreadyinlist = false
    for (var j in sorted_timezoneoptions) {
      if (sorted_timezoneoptions[i] === sorted_timezoneoptions[j]) {
        alreadyinlist = true
      }
    }
    if (!alreadyinlist) {
      tzOptions.push(sorted_timezoneoptions[i])
    }
  }

  // Find the "Pacific/Rarotonga" timezone option and replace it with "Hawaii"
  resp = sorted_timezoneoptions.map((timezone) => {
    if (timezone.name === 'Pacific/Rarotonga') {
      return {
        ...timezone,
        name: 'Hawaii',
      }
    }
    return timezone
  })

  return resp;
};

export const fGetArrTimeZone2 = (countryId = null, filterCountryCode = null) => {
  let resp = countryId === 232 ?//let resp = filterCountryCode === 'US' ?
    [
      //{tzId: 1, utcOffsetMinute: -720, tzCode: 'AoE', title: 'Anywhere on Earth'},
      { tzId: 2, utcOffsetMinute: -240, tzCode: 'AST', title: 'Atlantic Standard Time' },
      { tzId: 3, utcOffsetMinute: -300, tzCode: 'EST', title: 'Eastern Standard Time' },
      { tzId: 4, utcOffsetMinute: -360, tzCode: 'CST', title: 'Central Standard Time' },
      { tzId: 5, utcOffsetMinute: -420, tzCode: 'MST', title: 'Mountain Standard Time' },
      { tzId: 6, utcOffsetMinute: -480, tzCode: 'PST', title: 'Pacific Standard Time' },
      { tzId: 7, utcOffsetMinute: -540, tzCode: 'AKST', title: 'Alaska Standard Time' },
      { tzId: 8, utcOffsetMinute: -600, tzCode: 'HST', title: 'Hawaii-Aleutian Standard Time' },
      { tzId: 9, utcOffsetMinute: -660, tzCode: 'SST', title: 'Samoa Standard Time' },
      { tzId: 10, utcOffsetMinute: 600, tzCode: 'ChST', title: 'Chamorro Standard Time' },
      { tzId: 11, utcOffsetMinute: 720, tzCode: 'WIST', title: 'Wake Island Time Zone' },
    ] : fGetArrTimeZone(filterCountryCode);

  if (countryId === 232) {
    //resp.forEach(tzInf => { return tzInf['countryId'] = countryId; });
    //resp = resp.map(tzInf => { return tzInf['countryId'] = countryId; });
    //resp.map(tzInf => { return {...tzInf, countryId: countryId} });
    for (const objTz of resp) { objTz['countryId'] = countryId; }
  }

  return resp;
};

//export const fGetProvinceTimezoneMap = () => {};
export const fGetCountryProvince = (countryId) => {
  return countryId === 232 ?
    [
      { province_id: 2, province: 'Alabama' },
      { province_id: 3, province: 'Alaska' },
      { province_id: 4, province: 'Arizona' },
      { province_id: 5, province: 'Arkansas' },
      { province_id: 6, province: 'California' },
      { province_id: 7, province: 'Colorado' },
      { province_id: 8, province: 'Connecticut' },
      { province_id: 9, province: 'Delaware' },
      { province_id: 10, province: 'Florida' },
      { province_id: 11, province: 'Georgia' },
      { province_id: 12, province: 'Hawaii' },
      { province_id: 13, province: 'Idaho' },
      { province_id: 14, province: 'Illinois' },
      { province_id: 15, province: 'Indiana' },
      { province_id: 16, province: 'Iowa' },
      { province_id: 17, province: 'Kansas' },
      { province_id: 18, province: 'Kentucky' },
      { province_id: 19, province: 'Louisiana' },
      { province_id: 20, province: 'Maine' },
      { province_id: 21, province: 'Maryland' },
      { province_id: 22, province: 'Massachusetts' },
      { province_id: 23, province: 'Michigan' },
      { province_id: 24, province: 'Minnesota' },
      { province_id: 25, province: 'Mississippi' },
      { province_id: 26, province: 'Missouri' },
      { province_id: 27, province: 'Montana' },
      { province_id: 28, province: 'Nebraska' },
      { province_id: 29, province: 'Nevada' },
      { province_id: 30, province: 'New Hampshire' },
      { province_id: 31, province: 'New Jersey' },
      { province_id: 32, province: 'New Mexico' },
      { province_id: 33, province: 'New York' },
      { province_id: 34, province: 'North Carolina' },
      { province_id: 35, province: 'North Dakota' },
      { province_id: 36, province: 'Ohio' },
      { province_id: 37, province: 'Oklahoma' },
      { province_id: 38, province: 'Oregon' },
      { province_id: 39, province: 'Pennsylvania' },
      { province_id: 40, province: 'Rhode Island' },
      { province_id: 41, province: 'South Carolina' },
      { province_id: 42, province: 'South Dakota' },
      { province_id: 43, province: 'Tennessee' },
      { province_id: 44, province: 'Texas' },
      { province_id: 45, province: 'Utah' },
      { province_id: 46, province: 'Vermont' },
      { province_id: 47, province: 'Virginia' },
      { province_id: 48, province: 'Washington' },
      { province_id: 49, province: 'West Virginia' },
      { province_id: 50, province: 'Wisconsin' },
      { province_id: 51, province: 'Wyoming' },
      //{province_id: 52,	province: 'Washington, D.C.'},
    ] : [];
};
export const fGetCountryProvince2 = (countryId) => {
  return countryId === 232 ?
    [//US has SummerTime DST
      {
        provinceId: 2, code: 'AL', province: 'Alabama', primaryTz: 'CST',
        secondaryTz: null, hasDst: false
      },
      {
        provinceId: 3, code: 'AK', province: 'Alaska', primaryTz: 'AKST',
        secondaryTz: 'HST', hasDst: false
      },
      {
        provinceId: 4, code: 'AZ', province: 'Arizona', primaryTz: 'MST',
        secondaryTz: null, hasDst: 'mostlyNo'
      },//*
      {
        provinceId: 5, code: 'AR', province: 'Arkansas', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 6, code: 'CA', province: 'California', primaryTz: 'PST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 7, code: 'CO', province: 'Colorado', primaryTz: 'MST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 8, code: 'CT', province: 'Connecticut', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 9, code: 'DE', province: 'Delaware', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 10, code: 'FL', province: 'Florida', primaryTz: 'EST',
        secondaryTz: 'CST', hasDst: true
      },
      {
        provinceId: 11, code: 'GA', province: 'Georgia', primaryTz: 'EST',
        secondaryTz: null, hasDst: false
      },
      {
        provinceId: 12, code: 'HI', province: 'Hawaii', primaryTz: 'HST',
        secondaryTz: null, hasDst: false
      },
      {
        provinceId: 13, code: 'ID', province: 'Idaho', primaryTz: 'MST',
        secondaryTz: 'PST', hasDst: true
      },
      {
        provinceId: 14, code: 'IL', province: 'Illinois', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 15, code: 'IN', province: 'Indiana', primaryTz: 'EST',
        secondaryTz: 'CST', hasDst: true
      },
      {
        provinceId: 16, code: 'IA', province: 'Iowa', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 17, code: 'KS', province: 'Kansas', primaryTz: 'CST',
        secondaryTz: 'MST', hasDst: true
      },
      {
        provinceId: 18, code: 'KY', province: 'Kentucky', primaryTz: 'CST',
        secondaryTz: 'EST', hasDst: true
      },
      {
        provinceId: 19, code: 'LA', province: 'Louisiana', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 20, code: 'ME', province: 'Maine', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 21, code: 'MD', province: 'Maryland', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 22, code: 'MA', province: 'Massachusetts', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 23, code: 'MI', province: 'Michigan', primaryTz: 'EST',
        secondaryTz: 'CST', hasDst: true
      },
      {
        provinceId: 24, code: 'MN', province: 'Minnesota', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 25, code: 'MS', province: 'Mississippi', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 26, code: 'MO', province: 'Missouri', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 27, code: 'MT', province: 'Montana', primaryTz: 'MST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 28, code: 'NE', province: 'Nebraska', primaryTz: 'CST',
        secondaryTz: 'MST', hasDst: true
      },
      {
        provinceId: 29, code: 'NV', province: 'Nevada', primaryTz: 'PST',
        secondaryTz: 'MST', hasDst: true
      },
      {
        provinceId: 30, code: 'NH', province: 'New Hampshire', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 31, code: 'NJ', province: 'New Jersey', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 32, code: 'NM', province: 'New Mexico', primaryTz: 'MST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 33, code: 'NY', province: 'New York', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 34, code: 'NC', province: 'North Carolina', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 35, code: 'ND', province: 'North Dakota', primaryTz: 'CST',
        secondaryTz: 'MST', hasDst: true
      },
      {
        provinceId: 36, code: 'OH', province: 'Ohio', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 37, code: 'OK', province: 'Oklahoma', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 38, code: 'OR', province: 'Oregon', primaryTz: 'PST',
        secondaryTz: 'MST', hasDst: true
      },
      {
        provinceId: 39, code: 'PA', province: 'Pennsylvania', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 40, code: 'RI', province: 'Rhode Island', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 41, code: 'SC', province: 'South Carolina', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 42, code: 'SD', province: 'South Dakota', primaryTz: 'CST',
        secondaryTz: 'MST', hasDst: true
      },
      {
        provinceId: 43, code: 'TN', province: 'Tennessee', primaryTz: 'CST',
        secondaryTz: 'EST', hasDst: true
      },
      {
        provinceId: 44, code: 'TX', province: 'Texas', primaryTz: 'CST',
        secondaryTz: 'MST', hasDst: true
      },
      {
        provinceId: 45, code: 'UT', province: 'Utah', primaryTz: 'MST	',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 46, code: 'VT', province: 'Vermont', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 47, code: 'VA', province: 'Virginia', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 48, code: 'WA', province: 'Washington', primaryTz: 'PST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 49, code: 'WV', province: 'West Virginia', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 50, code: 'WI', province: 'Wisconsin', primaryTz: 'CST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 51, code: 'WY', province: 'Wyoming', primaryTz: 'MST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 52, code: 'DC', province: 'Washington, D.C.', primaryTz: 'EST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 53, code: 'AS', province: 'American Samoa', primaryTz: 'SST',
        secondaryTz: null, hasDst: false
      },
      {
        provinceId: 54, code: 'GU', province: 'Guam', primaryTz: 'ChST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 55, code: 'MP', province: 'Northern Mariana Islands', primaryTz: 'ChST',
        secondaryTz: null, hasDst: true
      },
      {
        provinceId: 56, code: 'PR', province: 'Puerto Rico', primaryTz: 'AST',
        secondaryTz: null, hasDst: false
      },
      {
        provinceId: 57, code: 'USVI', province: 'United States Virgin Islands',
        primaryTz: 'AST', secondaryTz: null, hasDst: false
      }
    ] : [];
};

export const fGetHMFormat2 = (minutes) => {
  let offsetoperator = '';
  if (minutes > 0) { offsetoperator = '+'; }
  else if (minutes < 0) {
    offsetoperator = '-';
    minutes = minutes * -1;
  }
  const hours = Math.floor(minutes / 60);
  // Getting the minutes.
  const min = minutes % 60;
  let dig = '';
  if (min < 10) { dig = '0'; }
  return offsetoperator + hours + ':' + dig + min;
};

export const fGetHourFormat2 = (minutes) => {
  return fGetHMFormat2(minutes);
};

export const fParseGglPlaces = (pl, apiKey = null, langCode = null) => {
  let resp, tzApiUrl;

  if (pl?.address_components && pl.address_components?.length) {
    resp = {};
    resp['selAddr'] = pl?.formatted_address;

    pl.address_components.forEach((pC) => {
      if (pC?.types?.[0]) {
        //City may not exist but postal_town or locality
        switch (pC.types[0]) {
          case 'street_number': resp['streetNum'] = pC; break;
          case 'subpremise': resp['complex'] = pC; break;
          case 'route': resp['route'] = pC; break;
          case 'neighborhood': resp['neighborhood'] = pC; break;
          case 'sublocality_level_1': resp['sublocality'] = pC; break;
          case 'sublocality': resp['sublocality'] = pC; break;
          //case 'locality': resp['locality'] = pC; break;
          case 'locality': resp['city'] = pC; break;
          case 'postal_town': resp['township'] = pC; break;
          case 'administrative_area_level_3': resp['township'] = pC; break;
          case 'administrative_area_level_2': resp['district'] = pC; break;
          case 'administrative_area_level_1': resp['province'] = pC; break;
          case 'country': resp['country'] = pC; break;
          case 'postal_code': resp['zip'] = pC; break;
        }
      }
    });
    resp['placeId'] = pl?.place_id;
    resp['utcOffSet'] = pl?.utc_offset_minutes;//utc_offset
    const plLoc = pl?.geometry?.location;
    if (plLoc) {
      tzApiUrl = 'https://maps.googleapis.com/maps/api/timezone/json' +
        '?location=' + encodeURIComponent((plLoc.lat()) + ',' + (plLoc.lng()));
      if (apiKey) { tzApiUrl += '&key=' + apiKey; }
      if (langCode) { tzApiUrl += '&language=' + langCode; }
      tzApiUrl += '&timestamp=' + (Date.now());//1331161200';
    }
    resp['tzApiUrl'] = tzApiUrl;
  }
  return resp;
};
export const fFormatGglAddressComps = (addrCmp) => {
  let resp;
  if (addrCmp?.province?.long_name?.length) {
    resp = { nAddr1: [], nAddr2: [] };
    //streetNum, route, neighborhood
    if (addrCmp?.streetNum?.long_name) {
      resp.nAddr1.push(addrCmp.streetNum.long_name);
    }
    if (addrCmp?.complex?.long_name) {
      resp.nAddr1.push(addrCmp.complex.long_name);
    }
    if (addrCmp?.route?.long_name) {
      resp.nAddr1.push(addrCmp.route.long_name);
    }
    resp.nAddr1 = resp.nAddr1.join(', ').trim();

    //sublocality || township, locality
    if (addrCmp?.neighborhood?.long_name) {
      resp.nAddr2.push(addrCmp.neighborhood.long_name);
    }
    if (addrCmp?.sublocality?.long_name) {
      resp.nAddr2.push(addrCmp.sublocality.long_name);
    } else if (addrCmp?.township?.long_name) {
      resp.nAddr2.push(addrCmp.township.long_name);
    }
    //if(addrCmp?.locality?.long_name) {
    //  resp.nAddr2.push(addrCmp.locality.long_name);
    //}
    resp.nAddr2 = resp.nAddr2.join(', ').trim();

    if (!resp.nAddr1.length) {
      resp.nAddr1 = resp.nAddr2; resp.nAddr2 = '';
    }

    //resp['nCity'] = addrCmp?.city?.long_name || addrCmp?.township?.long_name || '';
    resp['nCity'] = addrCmp?.city?.long_name || '';
    resp['nProvince'] = addrCmp?.province?.long_name || '';
    resp['nCountry'] = addrCmp?.country?.long_name;
    resp['nZip'] = addrCmp?.zip?.long_name || '';
    resp['nTZ'] = addrCmp?.utcOffSet;

    if (resp.nCity.length) {
      resp.nCity = resp.nCity.replace(' County', '').replace(' county', '');
    }
  }
  return resp;
};
export const fGetTrimmedText = (txt = '', maxLen = 30) => {
  return txt?.length > maxLen ? txt.substr(0, maxLen - 2) + '..' : txt
};
export const fGetUrlFileName = (url) => {
  //new URL(url).pathname.split('/').pop();
  return url.split('#')[0].split('?')[0].split('/').pop();
};
export const fGetFileExt = (fName) => {
  return fName?.length ? fName.split('.').pop() : null;
};