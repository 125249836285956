import { nHApi } from 'v2/src/lib/services/public/nHApi'

export const nHServiceApi = nHApi.injectEndpoints({
  endpoints: (builder) => ({
    postService: builder.mutation({
      query: ({ formData }) => {
        return {
          url: '/service',
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: ['services'],
    }),
    updateService: builder.mutation({
      query: ({ formData, id }) => {
        return {
          url: `/service/${id}`,
          method: 'POST',
          body: formData,
        }
      },
    }),

    getServiceList: builder.query({
      query: ({ page, filters }) => {
        const params = new URLSearchParams()

        params.append('per_page', 10)
        params.append('page', page)
        params.append('is_active', 1)

        if (filters?.booking_availability?.length) {
          params.append('booking_availability', filters?.booking_availability)
        }

        if (!(filters?.price_min === 0 && filters?.price_max === 500)) {
          if (filters?.price_min >= 0) params.append('price_min', filters?.price_min)
          if (filters?.price_max) params.append('price_max', filters?.price_max)
        }

        if (filters?.ratings?.length) {
          filters.ratings.forEach((rating) => params.append('ratings[]', rating))
        }

        if (filters?.speciality_ids?.length) {
          filters.speciality_ids.forEach((spec) => params.append('speciality_ids[]', spec))
        }
        if (filters?.search) {
          params.append('search', filters?.search)
        }
        return {
          url: `/services?${params.toString()}`,
        }
      },
      providesTags: ['services'],
    }),

    getSingleService: builder.query({
      query: ({ id }) => {
        const relations = ['practice']
        const relationsParam = relations.map((relation) => `relations[]=${encodeURIComponent(relation)}`).join('&')
        return {
          url: `/service/${id}?${relationsParam}`,
        }
      },
    }),
  }),
})

export const { usePostServiceMutation, useUpdateServiceMutation, useLazyGetServiceListQuery, useLazyGetSingleServiceQuery } = nHServiceApi
