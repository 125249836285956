//import { createSlice, current } from "@reduxjs/toolkit";
import { nSApi } from 'v2/src/lib/services/public/nSApi';
//---------------------------------------------------------

export const nSUtilApi = nSApi.injectEndpoints({
  endpoints: builder => ({
    setNSUser: builder.mutation({
      query: ({ userId, inParams }) => {
        return {
          url: '/user/' + userId,
          method: 'POST',
          body: inParams,
        };
      },
    }),
  }),
});

export const {
  useSetNSUserMutation,
} = nSUtilApi;
//---------------------------------------------------------

