import * as yup from 'yup'

const validateImage = (file) => {
  if (!file) {
    return yup.mixed().notRequired()
  }

  return yup.mixed().required('required_error')
}

export const InsuranceSchema = (error_messsage) => {
  return yup
    .object({
      BucketName: yup.string().required(error_messsage('required_error')).min(1, error_messsage('name_min_length_error')).max(255, error_messsage('name_max_length_error')),
      images: yup.number().required(error_messsage('upload_front_back_image')).min(2, error_messsage('upload_two_images')).max(2, error_messsage('upload_only_two_images')),
    })
    .required()
}

export default InsuranceSchema
