import { useState, useEffect, useRef, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import { FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { fGetAUserInf } from "v2/src/lib/appUtils";
import {
  fGetTrimmedText,
  fGetUrlFileName,
  fGetFileExt,
} from "v2/src/lib/strUtils";
import {
  fCallNHApi,
  fCalcProfileIdDocsInf,
  InsuranceStatusText,
} from "./appProfileFx";
import { LottieSpinner } from "v2/src/core";
import CardItem from "components/cards/Card";
import {
  useNHDocumentTypesQuery,
  useUserDocumentListQuery,
  useAddUserDocumentMutation,
  useDeleteUserDocumentMutation,
} from "v2/src/lib/services/nHDocumentApi";
import VeriffIntegration from "components/Veriff/VeriffIntegration";

export default function IdDocumentForm({
  has_approved_identification_document,
}) {
  let aUData = fGetAUserInf();
  const { t: comp_profile } = useTranslation("comp_profile");
  const { t: patient_insurance } = useTranslation("patient_insurance");

  const [visible, setVisible] = useState(true);
  const [stPgMsg, setStPgMsg] = useState("");
  const [stProcessing, setStProcessing] = useState(false);
  const [stPgHasErr, setStPgHasErr] = useState(false);
  const [stPIdDocsInf, setStPIdDocsInf] = useState([]);
  const [stIViewInf, setStIViewInf] = useState({
    isOpen: false,
    iUrls: [],
    curr: null,
  });
  const flgEditableForm = true;
  const gArrIdDocFileTypes = [
    "pdf",
    "webp",
    "png",
    "jpeg",
    "jpg",
    "jiff",
    "jfif",
  ];
  const gMaxPIdDoc = 3;
  const gDocType = "identification";
  const aUserProfileId = aUData?.details?.profileId;
  const arrValidDocTypeId = [];

  const [fAddUserDocument, { reset: fResetUDoc }] =
    useAddUserDocumentMutation();
  const [fDelUserDocument] = useDeleteUserDocumentMutation();

  let objNHDocTypesData = [];
  const {
    data: nHDocTypesApiData,
    isLoading: nHDocTypesWait,
    isFetching: nHDocTypesWait2,
    isError: nHDocTypesErr,
  } = useNHDocumentTypesQuery(
    { userType: aUData?.userType, category: gDocType }
  );
  if (nHDocTypesApiData?.data?.length) {
    objNHDocTypesData = nHDocTypesApiData?.data || [];
    objNHDocTypesData.forEach((dTI) => {
      dTI?.category === gDocType && arrValidDocTypeId.push(dTI.id);
    });
  }

  let objUserIdDocsData = [];
  const {
    data: userIdDocsApiData,
    refetch: fRefetchUserIdDocs,
    isLoading: userIdDocsWait,
    isFetching: userIdDocsWait2,
    isError: userIdDocsErr,
    error: userIdDocsErrData,
    isSuccess: userIdDocsOK,
  } = useUserDocumentListQuery(
    { profileId: aUserProfileId, category: gDocType },
    { skip: aUserProfileId === null, refetchOnMountOrArgChange: true }
  );
  if (
    userIdDocsErrData &&
    userIdDocsErrData?.status === false &&
    userIdDocsErrData?.message?.toLowerCase() === "no documents found"
  ) {
    objUserIdDocsData = [];
  } else if (userIdDocsApiData?.data?.length) {
    objUserIdDocsData = userIdDocsApiData?.data.filter((uD) =>
      arrValidDocTypeId.includes(uD.document_type_id)
    );
  }

  useEffect(() => {
    const idDocsInf = fCalcProfileIdDocsInf(gMaxPIdDoc, objUserIdDocsData);
    setStPIdDocsInf(idDocsInf);
  }, [userIdDocsApiData, userIdDocsErrData]);

  const fInitVerrifInContextFlow2 = () => {
    setVisible(false);
    document.getElementById("ctnVeriffDoc1").style.visibility = "visible";
  };

  const isPageLoading =
    nHDocTypesWait || nHDocTypesWait2 || userIdDocsWait || userIdDocsWait2;

  const fResetProcessing = (flgClearPgMsg = false) => {
    setStProcessing(false);
    setStPgMsg("");
    setStPgHasErr(false);
  };

  const fOpenImgViewer = (cPDoc, uDocsInf = []) => {
    if (uDocsInf?.length) {
      let currIdx,
        i = 0,
        arrIUrls = [];
      uDocsInf.forEach((uD) => {
        if (uD.id === cPDoc.id) {
          currIdx = i;
        }
        i++;
        uD?.docApiData?.file_url && arrIUrls.push(uD.docApiData.file_url);
        uD?.hasView &&
          uD?.docFormData?.docFile &&
          arrIUrls.push(URL.createObjectURL(uD.docFormData.docFile));
      });
      arrIUrls?.length &&
        setStIViewInf({
          isOpen: true,
          iUrls: arrIUrls,
          curr: currIdx || 0,
        });
    }
  };
  const fCloseImgViewer = () => {
    setStIViewInf({ isOpen: false, iUrls: [], curr: null });
  };
  const fAddNewIdDocBox = () => {
    if (stPIdDocsInf?.shownIdDocCount < gMaxPIdDoc) {
      setStPIdDocsInf((prevSt) => {
        const newSt = structuredClone(prevSt);
        for (let i = 0; i < newSt?.arrIdDocs?.length; i++) {
          const pIdDInf = newSt?.arrIdDocs[i];
          if (!pIdDInf?.hasData && !pIdDInf?.hasView) {
            newSt.arrIdDocs[i]["hasView"] = true;
            newSt.shownIdDocCount++;
            break;
          }
        }
        return newSt;
      });
    }
  };
  const fRemoveIdDocBox = (pDoc) => {
    const arrIdDocs = structuredClone(stPIdDocsInf?.arrIdDocs);
    let fFound = false;
    arrIdDocs.forEach((pDN, idx) => {
      if (pDN.id === pDoc.id) {
        fFound = true;
        arrIdDocs[idx]["hasView"] = false;
        arrIdDocs[idx]["hasData"] = false;
        arrIdDocs[idx]["errCode"] = null;
        arrIdDocs[idx]["arrErrMsg"] = [];
        arrIdDocs[idx]["docFormData"]["docTypeId"] = null;
        arrIdDocs[idx]["docFormData"]["docFile"] = null;
      }
    });
    if (fFound && stPIdDocsInf.shownIdDocCount > 1) {
      setStPIdDocsInf((prevSt) => {
        return {
          ...prevSt,
          shownIdDocCount: prevSt.shownIdDocCount - 1,
          arrIdDocs: arrIdDocs,
        };
      });
    }
  };

  const fValidateIdDocUploads = (arrFrmVals, upIdDocStat) => {
    let resp = true,
      upFileSize = 0,
      upFileSizeErrCode = null,
      arrErrMsg = [];
    if (upIdDocStat?.length) {
      upIdDocStat.forEach((eIdObj, idx) => {
        if (eIdObj?.hasView && eIdObj?.docFormData) {
          let hasUpFile = false,
            hasSelDocType = false,
            fExt = null;
          eIdObj.errCode = null;
          eIdObj.hasErr = false;
          eIdObj.arrErrMsg = [];

          hasUpFile = eIdObj?.docFormData?.docFile;
          if (hasUpFile?.name) {
            fExt = fGetFileExt(hasUpFile.name);
            upFileSize += parseInt(hasUpFile?.size);
            if (fExt && gArrIdDocFileTypes.includes(fExt)) {
            } else {
              resp = false;
              eIdObj.hasErr = true;
              eIdObj.errCode = "e_3";
              eIdObj.arrErrMsg.push("Only JPEG / PNG / PDF files are allowed");
            }
          } else {
            resp = false;
            eIdObj.hasErr = true;
            eIdObj.errCode = "e_2";
            eIdObj.arrErrMsg.push("Document file is not selected");
          }

          hasSelDocType = parseInt(eIdObj?.docFormData?.docTypeId);
          if (hasSelDocType !== NaN && hasSelDocType > 0) {
          } else {
            resp = false;
            eIdObj.hasErr = true;
            eIdObj.errCode = "e_1";
            eIdObj.arrErrMsg.push("Document type is not selected");
          }
        }
      });

      if (upFileSize && parseInt(upFileSize / 1048576) > 10) {
        resp = false;
        upFileSizeErrCode = "e_4";
      }

      if (!resp) {
        resp = { result: false, upIdDocStat, upFileSize, upFileSizeErrCode };
      } else {
        resp = { result: true };
      }
    }
    return resp;
  };


  const fHandleDelIdDocSubmit = async (id) => {
    let idDocDelResp;
    if (id) {
      setStProcessing(true);
      idDocDelResp = await fDelUserDocument({ docId: id });
      if (idDocDelResp?.data?.status) {
        fRefetchUserIdDocs();
        setStPgMsg(idDocDelResp?.data?.message);
      }
    } else {
      idDocDelResp = false;
    }
    setTimeout(fResetProcessing, 3000);
  };
  //---------------------------------------------------------

  const errIdDocCss = { border: "2px dashed #f14b4b" };
  const btnClassName =
    "btn btn-danger btn-round waves-effect waves-light image-close-icon";
  const fUploadCss = {
    width: "100%",
    textOverflow: "clip",
    textSizeAdjust: "90%",
    fontSize: "83%",
  };

  const fGetSavedIdDocBoxJsx = (pDoc) => {
    const savedPIdDocId = pDoc?.id;
    const fName = pDoc?.docApiData?.file_url;
    let docTypeData = objNHDocTypesData?.filter(
      (dT) => dT.id === pDoc?.docApiData?.document_type_id
    );
    docTypeData = docTypeData?.length ? docTypeData[0] : {};
    let fImgTitle = docTypeData?.name;
    if (!fImgTitle && fName) {
      fImgTitle = fGetUrlFileName(fName);
    }
    fImgTitle = fGetTrimmedText(fImgTitle, 20);

    return (
      <div
        key={savedPIdDocId}
        id={"sIdDoc_" + savedPIdDocId}
        className="id-container card col-md-3"
      >
        <div className="document-type-dropdown">
          <label>Document Type : </label>
          <label className="image-close-icon float-right">
            <button
              type="button"
              className={btnClassName}
              onClick={() => {
                fHandleDelIdDocSubmit(savedPIdDocId?.replace("uIdDoc_", ""));
              }}
            >
              <FaTimes />
            </button>
          </label>
          <br />
          <div className="mb-2">{docTypeData?.name}</div>
        </div>
        <div>
          <div
            className="image-preview mb-2"
            onClick={() => fOpenImgViewer(pDoc, stPIdDocsInf.arrIdDocs)}
          >
            <img className="lnkPt id-image" src={fName} alt={fImgTitle} />
          </div>
        </div>
      </div>
    );
  };

  const fGetNewIdDocBoxJsx = (pDoc, shownDocCount) => {
    const newPIdDocId = pDoc?.id;
    const hasErr = pDoc?.hasOwnProperty("hasErr") && pDoc["hasErr"];
    const elDErr = hasErr && pDoc["arrErrMsg"]?.length ? pDoc["arrErrMsg"] : [];
    const errJsx = [];
    if (elDErr?.length) {
      elDErr.reverse();
      elDErr.forEach((errS, idx) => {
        errJsx.push(
          <p className="m-1" key={"pIdDocErr_" + newPIdDocId + "_" + idx}>
            {errS}
          </p>
        );
      });
    }

    return (
      <div
        key={"kNewPIdDoc_" + newPIdDocId}
        id={"ctnNewPIdDoc_" + newPIdDocId}
        className="id-container card col-md-3"
      >
        <div className="padS" {...(hasErr && { style: errIdDocCss })}>
          <div className="document-type-dropdown">
            <label>Document Type : </label>
            {shownDocCount > 1 && (
              <label className="image-close-icon float-right">
                <button
                  type="button"
                  className={btnClassName}
                  onClick={() => fRemoveIdDocBox(pDoc)}
                >
                  <FaTimes />
                </button>
              </label>
            )}
            {
              <select
                id=""
                name=""
                className="form-control mb-2"
                value={pDoc?.docFormData?.docTypeId || ""}
                onChange={(e) => {
                  setStPIdDocsInf((prevSt) => {
                    const newSt = structuredClone(prevSt);
                    for (let i = 0; i < newSt?.arrIdDocs?.length; i++) {
                      const pIdDInf = newSt?.arrIdDocs[i];
                      if (pIdDInf.id === newPIdDocId) {
                        newSt.arrIdDocs[i]["docFormData"]["docTypeId"] =
                          e.target.value;
                        break;
                      }
                    }
                    return newSt;
                  });
                }}
              >
                <option value="">{comp_profile("select_document_type")}</option>
                {objNHDocTypesData.map((nHDType) => (
                  <option key={nHDType.id} value={nHDType.id}>
                    {nHDType.name}
                  </option>
                ))}
              </select>
            }
          </div>

          <div className="xtBdr2" style={{ minHeight: "7.5rem" }}>
            <div
              className="image-preview mb-2"
              onClick={() => fOpenImgViewer(pDoc, stPIdDocsInf.arrIdDocs)}
            >
              {pDoc?.docFormData?.docFile && (
                <img
                  className="id-image lnkPt"
                  alt={"IdDocImage-" + newPIdDocId}
                  src={URL.createObjectURL(pDoc.docFormData.docFile)}
                />
              )}
            </div>
            <div>
              <input
                type="file"
                className="mb-2"
                style={fUploadCss}
                name={"idDocImg_" + newPIdDocId}
                id={"idDocImg_" + newPIdDocId}
                accept="image/*,.pdf"
                onChange={(e) => {
                  const [selFile] = e.target.files;
                  setStPgMsg("");
                  setStPIdDocsInf((prevSt) => {
                    const newSt = structuredClone(prevSt);
                    for (let i = 0; i < newSt?.arrIdDocs?.length; i++) {
                      const pIdDInf = newSt?.arrIdDocs[i];
                      if (pIdDInf.id === newPIdDocId) {
                        newSt.arrIdDocs[i]["docFormData"]["docFile"] = selFile;
                        break;
                      }
                    }
                    return newSt;
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="text-danger xtBdr2">{hasErr && errJsx}</div>
      </div>
    );
  };

  const loaderJsx = (
    <div className="d-flex justify-content-center">
      <LottieSpinner size={200} />
    </div>
  );

  let idDocsJsx = [],
    flgDisableSubmit = false;

  if (stPIdDocsInf?.arrIdDocs?.length) {
    stPIdDocsInf?.arrIdDocs.forEach((pIdDocInf, idx) => {
      if (pIdDocInf?.hasData && pIdDocInf?.docApiData) {
        idDocsJsx.push(fGetSavedIdDocBoxJsx(pIdDocInf));
      } else if (pIdDocInf?.hasView && pIdDocInf?.hasData === false) {
      }
    });
  }

  return isPageLoading && !stProcessing ? (
    loaderJsx
  ) : (
    <div id="ctnProfIdDoc">
      <form>
        <div className="file-box-content">
          <CardItem>
            {comp_profile("upload_id_doc_msg2")}
            <button
              type="button"
              disabled={
                !flgEditableForm ||
                has_approved_identification_document ||
                stPIdDocsInf?.shownIdDocCount >= gMaxPIdDoc
              }
              className="btn btn-success btn-round waves-effect waves-light mb-2"
              onClick={fInitVerrifInContextFlow2}
              style={{
                cursor:
                  flgEditableForm &&
                    !has_approved_identification_document &&
                    stPIdDocsInf?.shownIdDocCount < gMaxPIdDoc
                    ? "pointer"
                    : "not-allowed",
              }}
            >
              {comp_profile("add_id_document_button")}
            </button>
          </CardItem>
        </div>

        <div className="row" style={{ minHeight: "100px" }}>
          {visible && userIdDocsApiData?.data && (
            <div
              style={{
                padding: "10px 30px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <span>
                {" "}
                <b>{patient_insurance("approval_status")}:</b>{" "}
                <InsuranceStatusText
                  status={userIdDocsApiData?.data[0]?.status}
                />
              </span>
              <span>
                {" "}
                <b>{patient_insurance("reason")}: </b>
                {userIdDocsApiData?.data[0]?.status_reason || "--"}
              </span>
            </div>
          )}
          <div
            className="form-group row wA"
            style={{
              display: visible ? "none" : "flex",
              justifyContent: "center",
              minHeight: "168px",
            }}
          >
            <div
              id="ctnVeriffDoc1"
              style={{ visibility: "hidden", marginTop: "20px" }}
            >
              <VeriffIntegration profileId={aUserProfileId} />
            </div>
            {stIViewInf.isOpen && (
              <ImageViewer
                src={stIViewInf.iUrls}
                currentIndex={stIViewInf.curr}
                onClose={fCloseImgViewer}
              />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col text-left xtBdr2">
            {stPgMsg && <div className="text-danger">{stPgMsg}</div>}
          </div>
        </div>
      </form>
    </div>
  );
}
//---------------------------------------------------------
