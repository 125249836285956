//
//---------------------------------------------------------

export const fIsEmpty = (val)=> {
  return val === null || typeof(val) === 'undefined';
};

export const fDescendingComparator = (a, b, orderBy) => {
  if(b[orderBy] < a[orderBy]) { return -1; }
  if(b[orderBy] > a[orderBy]) { return 1; }
  return 0;
};
export const fGetComparator = (orderBy, order) => {
  return order === 'desc'
    ? (a, b) => fDescendingComparator(a, b, orderBy)
    : (a, b) => -fDescendingComparator(a, b, orderBy);
};
export const fStableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if(order !== 0) { return order; }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const fSearchArrayRowByKey = (arr, k, val) => {
  let result  = arr.filter((o) => {return  o[k] === val; } );
  return result? result[0] : null; // or undefined
};

export const fGetUniqueArrayRowByKey = (arr=[], k='id') => {
  if (!k) { return arr; }
  return [...new Map(arr.map(elR => [elR[k], elR])).values()];
  //return [...new Map(arr.map(elR => [elR[k], elR]).reverse()).values()];
  //Or
  //let setObj = new Set(arr.map(JSON.stringify));
  //let output = Array.from(setObj).map(JSON.parse);
  //Or
  /*return [...new Map(
      arr.filter(Boolean).map((elR) => [elR[k], item]),
    ).values(),];
  }*/
  //Or
  /*const map = {}, resp = [];
  for(const elR of arr) {
    if(!map[elR[k]]) {
      resp.push(elR);
      map[elR[k]] = true;
    }
  }
  return resp;*/
};

