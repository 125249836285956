import { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { MdOutlineEmail, MdPhone } from 'react-icons/md'
import useDebounce from 'hooks/useDebounce'
import CardLongItem from 'components/cards/Card'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import { useNavigate } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import { playNotificationSound } from 'utils'
import { useLazyGetAppointmentsQuery } from '../lib/services/nHAppointmentApi'
import { capitalizeFirstLetter } from '../lib/strUtils'
import { useTranslation } from 'react-i18next'

export function PaginatedList({ limit, pagenum, list = [] }) {
  const actionX = useMemo(() => ({ approve: 'approve', cancel: 'cancel', meet: 'meet' }), []);
  const navigate = useNavigate();
  const { t: provider_patient_list } = useTranslation('provider_patient_list')
  function handleActionClick(action, selectedItem) {
    if (action === actionX.meet) {
      navigate('/virtualvisit/room', {
        state: {
          MeetingID: selectedItem?.zoom_meeting_id,
          Password: selectedItem?.zoom_meeting_password,
          Symptom: selectedItem?.symptoms,
          SelectedItem: selectedItem,
          MeetingStatus: selectedItem?.status,
          AppointmentID: selectedItem?.id,
          startTime: selectedItem?.start_time,
        },
      });
    }
  }

  return list.map((item, index) => {
    return (
      <tr key={item?.id || index} style={{ backgroundColor: item.color_flag }}>
        <td className="text-dark">
          <Link to={'#'} className="text-dark">
            <div className="d-flex align-items-center">
              <img
                src={item?.patient?.profile?.profile_pic_url}
                alt=""
                className="thumb-sm rounded-circle mr-2"
                style={{ width: 50, height: 50 }}
              />
              <div>
                {item?.patient?.profile?.user?.first_name + ' ' + item?.patient?.profile?.user?.last_name}
              </div>
            </div>
          </Link>
        </td>
        <td className="text-dark">
          <a href={`mailto:${item?.patient?.profile?.user?.email}`} className="text-dark">
            <MdOutlineEmail /> {item?.patient?.profile?.user?.email}
          </a>
        </td>
        <td className="text-dark">
          <a href={`tel:${item?.patient?.profile?.user?.phone}`} className="text-dark">
            <MdPhone /> {item?.patient?.profile?.user?.phone}
          </a>
        </td>
        <td className="text-dark">{item?.symptoms}</td>
        <td className="text-dark">
          {moment(item?.start_time).format('MMM DD, YYYY')}
          <br />
          {moment(item?.start_time).format('hh:mm A')}
        </td>
        <td className="text-dark">{capitalizeFirstLetter(item?.status)}</td>
        <td className="text-dark">
          <div className="d-flex align-items-center">

            {item?.zoom_meeting_id && item.enable_meet_button ? (
              <button
                type="button"
                className="btn waves-effect waves-light"
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  border: '1px solid black',
                }}
                onClick={() => handleActionClick(actionX.meet, item)}
              >
                {provider_patient_list('meet')}
              </button>
            ) : (
              <OverlayTrigger
                placement="top"
                delay={{ show: 100, hide: 100 }}
                overlay={<Tooltip id="tooltip-disabled">{provider_patient_list('meet_button_not_available')}</Tooltip>}
              >
                <span className="d-inline-block">
                  <button
                    type="button"
                    className="btn waves-effect waves-light"
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                      border: '1px solid black',
                    }}
                    disabled
                  >
                    {provider_patient_list('meet')}
                  </button>
                </span>
              </OverlayTrigger>
            )}
          </div>
        </td>
      </tr>
    );
  });
}
function PatientQueue({ limit, search, stopPolling = false }) {
  const [list, setList] = useState([])
  const debouncedSearch = useDebounce(search, 100)
  const [hasInteracted, setHasInteracted] = useState(false)
  const { t: provider_patient_list } = useTranslation('provider_patient_list')
  const [errMsg, setErrMsg] = useState(null)

  const [isLoading, setIsLoading] = useState(true)

  const [pageNum, setPageNum] = useState(1)
  const [delay, setDelay] = useState('5000')
  const [getAppointments, { data: appointmentListData, isError: appointmentListDataError }] = useLazyGetAppointmentsQuery()
  /*
    For Status:
    Confined -  badge-soft-purple
    Deceased - badge-soft-danger
    Follow-up Checkup - badge-soft-success
    */

  const handleUserInteraction = () => {
    setHasInteracted(true)
  }

  useEffect(() => {
    getProviderVirtualVisitQue()
    window.addEventListener('click', handleUserInteraction)
    window.addEventListener('keydown', handleUserInteraction)
    return () => {
      window.removeEventListener('click', handleUserInteraction)
      window.removeEventListener('keydown', handleUserInteraction)
    }
  }, [])

  async function getProviderVirtualVisitQue() {
    const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
    const params = {
      current_page: pageNum,
      start_date: today,
      end_date: today,
      status: ['scheduled', 'rescheduled', 'started', 'in_progress'],
      sort_order: 'asc',
    };

    await getAppointments(params);
  }

  useEffect(() => {
    getProviderVirtualVisitQue();
    if (appointmentListData) {
      setIsLoading(false)
      setList(appointmentListData?.data)
      if (appointmentListData.status) {
        if (hasInteracted) {
          playNotificationSound()
        }
      }
    }
    if (appointmentListDataError) {
      setErrMsg(appointmentListDataError)
    }
  }, [appointmentListData, pageNum])

  // useInterval(getProviderVirtualVisitQue, delay)

  // useEffect(() => {
  //   const controller = new AbortController()
  //   setIsLoading(true)

  //   return () => {
  //     let isMounted = false
  //     controller.abort()
  //   }
  // }, [debouncedSearch,])

  return (
    // list.length===0?null:

    <>
      {list?.length === 0 ? (
        <CardLongItem>
          <h4>{isLoading ? '...' : provider_patient_list('no_virtual_visits')}</h4>
        </CardLongItem>
      ) : (
        <table className="table">

          <thead className="thead-light">
            <tr>
              <th>{provider_patient_list('patient')}</th>
              <th>{provider_patient_list('email')}</th>
              <th>{provider_patient_list('contact_info')} </th>
              <th>{provider_patient_list('symptoms')}</th>
              <th>{provider_patient_list('date_time')}</th>
              <th>{provider_patient_list('status')}</th>
              <th>{provider_patient_list('action')}</th>
            </tr>
          </thead>

          <thead>
            <PaginatedList list={list} limit={limit} pagenum={pageNum}></PaginatedList>
          </thead>
        </table>
      )}
      {list.length > limit ? (
        <div className="justify-content-center d-flex" style={{ alignItems: 'center', flexDirection: 'column' }}>
          <Pagination
            activePage={pageNum}
            itemsCountPerPage={appointmentListData?.meta?.per_page}
            // totalItemsCount={list.length || []}
            totalItemsCount={appointmentListData?.meta?.total}
            pageRangeDisplayed={appointmentListData?.meta?.last_page}
            // onPageChange={}
            itemclassName="page-item"
            linkClass="page-link float-center"
            onChange={(e) => {
              setPageNum(e)
            }}
          />
          <div className="row-lg-12 ">Page {pageNum}</div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
function dateGenerator(date, time) {
  return moment(date).add(time, 'hours').format('MMM DD, YYYY h:mm a')
}

export default PatientQueue
