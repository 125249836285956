import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Footer from "components/Footer";
import RingLoading from "components/lottie/RingLoading";
import { APP_URL } from "constants";
import useAuth from "hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useUserProfileDetailQuery } from "v2/src/lib/services/profileApi";
import { fGetAUserInf } from "v2/src/lib/appUtils";
import { useUserDocumentListQuery } from "v2/src/lib/services/nHDocumentApi";
import { useGetUserSubscriptionHistoryQuery } from "v2/src/lib/services/nHSubscriptionApi";

const gDocType = "insurance";
const gDocType_indentification = "identification";

function PatientIndexPage() {
  let aUData = fGetAUserInf();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const { t: patient_virtual_visit } = useTranslation("patient_virtual_visit");
  const aUserProfileId = aUData?.details?.profileId;
  const [allowVirtualVisit, setAllowVirtualVisit] = useState(false);

  const { data: userIndentiDocsApiData } = useUserDocumentListQuery(
    { profileId: aUserProfileId, category: gDocType_indentification },
    { skip: aUserProfileId === null, refetchOnMountOrArgChange: true }
  );

  const { data: userSubscriptionHistoryData } =
    useGetUserSubscriptionHistoryQuery("status=active");
  const { data: patientDetails, loading: patientDetailsLoading } =
    useUserProfileDetailQuery({ profileId: aUserProfileId });
  useEffect(() => {
    if (patientDetails?.status) {
      setAuth({ ...auth, ...patientDetails.data });
      if (
        !patientDetails.data?.personal_details_completed ||
        !patientDetails.data?.has_approved_identification_document
      ) {
        Swal.fire({
          html: patient_virtual_visit("complete_registration"),
          confirmButtonText: 'Start Verification'
        }).then(() => {
          navigate("/patient/profile", { replace: true });
        });
      } else if (
        patientDetails.data?.has_active_subscription ||
        patientDetails.data?.has_approved_insurance_document
      ) {
        setAllowVirtualVisit(true);
      } else {
        Swal.fire({
          html:
            `${patient_virtual_visit("please_upload_msg")} <a href='` +
            APP_URL +
            `/patient/insurance'>${patient_virtual_visit(
              "insurance"
            )}</a> ${patient_virtual_visit("or")} <a href='` +
            APP_URL +
            `/patient/subscription'>${patient_virtual_visit("subscribe")}.</a>`,
          confirmButtonText: 'Start Verification'
        }
        ).then(() => {
          navigate("/patient/profile", { replace: true });
        });

      }
    }
  }, [patientDetails, userSubscriptionHistoryData, userIndentiDocsApiData]);

  const handleVirtualVisitButtonClick = () => {
    if (!auth || !patientDetails?.data?.personal_details_completed) {
      Swal.fire({
        html: patient_virtual_visit("complete_registration"),
      }).then(() => {
        navigate("/patient/profile", { replace: true });
      });
    } else if (allowVirtualVisit) {
      navigate("/virtualvisit");
    } else {
      Swal.fire({
        html:
          `${patient_virtual_visit("access_virtual_visit_msg")} <a href='` +
          APP_URL +
          `/patient/insurance'>${patient_virtual_visit(
            "insurance"
          )}</a> ${patient_virtual_visit("or")} <a href='` +
          APP_URL +
          `/patient/subscription'>${patient_virtual_visit("subscribe")}.</a>`,
      }).then(() => {
        navigate("/patient/profile", { replace: true });
      });
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row figmaFirstBox">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h2>{patient_virtual_visit("welcome")}</h2>
                  <h4 style={{ marginTop: "40px" }}>
                    {patient_virtual_visit("reminder_text")}
                  </h4>
                  <ol className="telehealth">
                    <li>{patient_virtual_visit("only_allowed_in_hawai")}</li>
                    <li>
                      {patient_virtual_visit(
                        "offer_consultation_no_prescription"
                      )}
                    </li>
                    <li>
                      {patient_virtual_visit(
                        "offer_consultation_with_prescription"
                      )}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row figmaFirstBox">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h2>{patient_virtual_visit("book_your_appointment")}</h2>
                  <h4 style={{ marginTop: "40px" }}>
                    {patient_virtual_visit("how_telehealth_works")}
                  </h4>
                  <ol className="telehealth">
                    <li>{patient_virtual_visit("connected_to_internet")}</li>
                    <li>{patient_virtual_visit("click_below_button")}</li>
                    <li>{patient_virtual_visit("reason")}</li>
                    <li>{patient_virtual_visit("enjoy_video")}</li>
                  </ol>

                  <p style={{ marginTop: "40px" }}>
                    <button
                      disabled={patientDetailsLoading}
                      type="button"
                      className={`btn ${allowVirtualVisit
                        ? "btn-success"
                        : "btn-outline-success"
                        }  btn-round waves-effect waves-light figmaBigButton`}
                      onClick={handleVirtualVisitButtonClick}
                    >
                      {patientDetailsLoading ? (
                        <div className="row d-flex align-items-center m-0">
                          <RingLoading size={30} />
                          <b>{patient_virtual_visit("loading")}</b>
                        </div>
                      ) : (
                        patient_virtual_visit("book_your_appointment")
                      )}
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default PatientIndexPage;
