import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CardItem from 'components/cards/Card'
import TableCard from 'components/table/Tables'
import useAuth from 'hooks/useAuth'
import useAxiosPrivate from 'hooks/useAxiosPrivate'
import Calendar from './calender/Calendar'

import { useForm } from 'react-hook-form'
import { useRef } from 'react'

import moment from 'moment'
import styled from '@emotion/styled'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { AppointmentStatus, StatusTextVisit } from 'v2/src/components/status/Status'
import { useLazyGetAppointmentsQuery } from 'v2/src/lib/services/nHAppointmentApi'
import Pagination from 'react-js-pagination'

import { MdOutlineEmail, MdPhone } from 'react-icons/md'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import ReactDOMServer from 'react-dom/server'; // Import this if you're using React

export const StyleWrapper = styled.div`
  .optionListContainer {
    position: sticky;
  }
`
const StatusText = ({ status }) => {
  const statusColor = {
    0: 'badge-soft-purple',
    1: 'badge-soft-success',
    2: 'badge-soft-danger',
    3: 'badge-soft-danger',
    4: 'badge-soft-success',
  }
  const statusText = {
    0: 'For Approval',
    1: 'Completed',
    2: 'Cancelled By Patient',
    3: 'Cancelled By Provider',
    4: 'Approved',
  }
  return <span className={`virtualvisitbadge badge badge-md ${statusColor[status]}`}>{statusText[status]}</span>
}

function hourformat(hourstr) {
  const hour = parseInt(hourstr)
  if (hour > 12) {
    return (hour - 12 < 10 ? '0' : '') + (hour - 12) + ':00 PM'
  } else if (hour === 12) {
    return 12 + ':00 PM'
  } else if (hour === 0) {
    return 12 + ':00 AM'
  } else {
    return (hour < 10 ? '0' : '') + hour + ':00 AM'
  }
}
function Visits() {
  const navigate = useNavigate()
  const timenow = moment()
  const { t: provider_visits } = useTranslation('provider_visits')
  const [isLoading, setIsLoading] = useState(true)
  const [appointmentList, setAppointmentList] = useState([])
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const [patientList, setPatientList] = useState([])
  const [clinicList, setClinicList] = useState([])
  const [clinicIDList, setClinicIDList] = useState([])
  const [listOriginal, setListOriginal] = useState([])
  const myModal = useRef()
  const [updateVisit, setUpdateVisit] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const appointmentsPerPage = 10
  const [upcomingAppointments, setUpcomingAppointments] = useState([])
  const [pastAppointments, setPastAppointments] = useState([])
  const [upcomingCurrentPage, setUpcomingCurrentPage] = useState(1)
  const [pastCurrentPage, setPastCurrentPage] = useState(1)
  const [upcomingTotalPages, setUpcomingTotalPages] = useState(0)
  const [pastTotalPages, setPastTotalPages] = useState(0)
  const [innerActiveTab, setInnerActiveTab] = useState('upcoming')

  const [getUpcomingAppointments, { data: upcomingAppointmentListData, isError: upcomingAppointmentListDataError }] = useLazyGetAppointmentsQuery()
  const [getPastAppointments, { data: pastAppointmentListData, isError: pastAppointmentListDataError }] = useLazyGetAppointmentsQuery()

  useEffect(() => {
    getUpcomingAppointments({
      current_page: upcomingCurrentPage,
      sort_order: 'asc',
      status: ['scheduled', 'rescheduled', 'started', 'in_progress'],
    })
  }, [upcomingCurrentPage])

  useEffect(() => {
    if (upcomingAppointmentListData) {
      setIsLoading(false)
      setUpcomingAppointments(upcomingAppointmentListData.data)
      setUpcomingTotalPages(upcomingAppointmentListData?.meta?.total)
    }
    if (upcomingAppointmentListDataError) {
      console.error('upcomingAppointmentListDataError', upcomingAppointmentListDataError)
      setIsLoading(false)
    }
  }, [upcomingAppointmentListData, upcomingAppointmentListDataError])

  useEffect(() => {
    getPastAppointments({
      current_page: pastCurrentPage,
      sort_order: 'desc',
      status: ['completed', 'missed', 'cancelled'],
    })
  }, [pastCurrentPage])
  useEffect(() => {
    if (pastAppointmentListData) {
      setIsLoading(false)
      setPastAppointments(pastAppointmentListData.data)
      setPastTotalPages(pastAppointmentListData?.meta?.total)
    }
    if (pastAppointmentListDataError) {
      console.error('pastAppointmentListDataError', pastAppointmentListDataError)
      setIsLoading(false)
    }
  }, [pastAppointmentListData, pastAppointmentListDataError])

  function handleActionClick(action, selectedItem) {
    if (action === 'meet') {
      navigate('/virtualvisit/room', {
        state: {
          MeetingID: selectedItem?.zoom_meeting_id,
          Password: selectedItem?.zoom_meeting_password,
          Symptom: selectedItem?.symptoms,
          SelectedItem: selectedItem,
          MeetingStatus: selectedItem?.status,
          AppointmentID: selectedItem?.id,
          startTime: selectedItem?.start_time,
        },
      })
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm()

  const [getAppointments, { data: appointmentListData, isError: appointmentListDataError }] = useLazyGetAppointmentsQuery()

  function formatLongtxt(string = '') {
    if (string?.length > 20) {
      return string.substring(0, 20) + '...'
    }
    return string
  }
  async function createInPersonVisit(data) {
    const controller = new AbortController()

    await axiosPrivate
      .post(
        'createInPersonVisit',
        { ...data, Email: auth?.email || Cookies.get('email') },
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        const { Status, Message } = res.data
        if (Status) {
          document.getElementById('create-appointment').reset()

          Swal.fire({
            title: provider_visits('in_person_appointment_created'),
            html: ``,
          })
          setUpdateVisit(!updateVisit)
          $('#myModal').hide()
          $('.modal-backdrop').hide()
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }
  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    function getSched() {
      if (appointmentListDataError) {
        setIsLoading(false)
        console.error('appointmentListDataError', appointmentListDataError)
      }
      if (appointmentListData) {
        const sorted_data = appointmentListData.data
        isMounted && setAppointmentList(sorted_data)
        isMounted && setListOriginal(sorted_data)
        setIsLoading(false)
      }
    }
    // async function getPatientList() {
    //   const controller = new AbortController()

    //   await axiosPrivate
    //     .post(
    //       'getPatients',
    //       { Email: auth?.email || Cookies.get('email') },
    //       {
    //         signal: controller.signal,
    //       }
    //     )
    //     .then((res) => {
    //       const { Data = [] } = res.data
    //       setPatientList(Data.Patients)
    //     })
    //     .catch((err) => {
    //       console.error(err)
    //     })
    // }
    // async function getClinicList() {
    //   const controller = new AbortController()

    //   await axiosPrivate
    //     .post(
    //       'getClinics',
    //       { Email: auth.email },
    //       {
    //         signal: controller.signal,
    //       }
    //     )
    //     .then((res) => {
    //       const { Status, Data: data = [], Message } = res.data

    //       if (Status) {
    //         setClinicList(data)
    //         var clinics = []
    //         setClinicIDList(
    //           data.map((item) => {
    //             if (clinics.includes(item.clinic_id)) {
    //               pass
    //             } else {
    //               clinics.push(item.clinic_id)

    //               return { name: item.clinic_name, id: item.clinic_id }
    //             }
    //           })
    //         )
    //       } else {
    //         throw new Error(Message)
    //       }
    //     })
    //     .catch((err) => {
    //       console.error(err)
    //     })
    // }
    // getPatientList()
    // getClinicList()
    getSched()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [updateVisit, appointmentListData])
  let morning_options = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7]

  useEffect(() => {
    const fetchAppointments = async () => {
      await getAppointments({ current_page: currentPage })
      setTotalPages(appointmentListData?.meta?.total)
    }

    fetchAppointments()
  }, [currentPage, appointmentListData])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <h4 className="page-title">{provider_visits('appointments')}</h4>
          </div>
        </div>
      </div>

      <div className="col-12">
        <CardItem>
          <h5 className="mt-1">{provider_visits('view')}</h5>

          <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" id="calendar_view_tab" data-toggle="pill" href="#calendar_view" role="tab">
                {provider_visits('calendar')}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="list_view_tab" data-toggle="pill" href="#list_view" role="tab">
                {provider_visits('list')}
              </a>
            </li>
          </ul>
        </CardItem>
      </div>

      <div className="tab-content detail-list col-12" style={{ marginTop: -20, width: '100%' }} id="pills-tabContent">
        <div className="row tab-pane fade show active" id="calendar_view" role="tabpanel" aria-labelledby="calendar_view_tab">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <Calendar />
                <div style={{ clear: 'both' }}></div>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-pane fade" id="list_view" role="tabpanel" aria-labelledby="list_view_tab">
          <div className="card mb-1">
            <div className="card-body">
              <ul className="nav nav-pills mb-0" id="appointmentTabs" role="tablist" style={{ display: 'flex' }}>
                <li className="nav-item" style={{ flex: '1' }}>
                  <a
                    href="#!"
                    className={`nav-link text-center ${innerActiveTab === 'past' ? 'active' : ''}`}
                    onClick={(e) => {
                      e.preventDefault()
                      setInnerActiveTab('past')
                    }}
                    style={{
                      width: '100%',
                      backgroundColor: innerActiveTab === 'past' ? '#28a745' : '#f6f6f6',
                      color: innerActiveTab === 'past' ? 'white' : 'black',
                      borderRadius: '0',
                      cursor: 'pointer',
                    }}
                  >
                    {provider_visits('past_appointments')}
                  </a>
                </li>
                <li className="nav-item" style={{ flex: '1' }}>
                  <a
                    href="#!"
                    className={`nav-link text-center ${innerActiveTab === 'upcoming' ? 'active' : ''}`}
                    onClick={(e) => {
                      e.preventDefault()
                      setInnerActiveTab('upcoming')
                    }}
                    style={{
                      width: '100%',
                      backgroundColor: innerActiveTab === 'upcoming' ? '#28a745' : '#f6f6f6',
                      color: innerActiveTab === 'upcoming' ? 'white' : 'black',
                      borderRadius: '0',
                      cursor: 'pointer',
                    }}
                  >
                    {provider_visits('upcoming_appointments')}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="tab-content" id="appointmentTabsContent">
            {innerActiveTab === 'upcoming' && (
              <div className="row tab-pane fade show active" id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
                {upcomingAppointments.length === 0 ? (
                  <CardItem className={'col-lg-12'}>{isLoading ? provider_visits('loading') : provider_visits('no_upcoming_appointments')}</CardItem>
                ) : (
                  <>
                    <TableCard headers={['Patient', 'Email', 'Contact Info', 'Symptoms', 'Date & Time', 'Status', 'Action']}>
                      {upcomingAppointments.map((item, index) => {
                        const appointmentStartTime = moment(item?.start_time)
                        const appointmentEndTime = moment(item?.end_time)
                        const currentTime = moment()
                        const withinAppointmentPeriod = currentTime.isBetween(appointmentStartTime, appointmentEndTime)
                        const enableMeetButton = item?.zoom_meeting_id && item?.enable_meet_button
                        return (
                          <tr key={index} style={{ backgroundColor: item.color_flag }}>
                            <td className="text-dark">
                              <div className="d-flex align-items-center">
                                <img src={item?.patient?.profile?.profile_pic_url} alt="" className="thumb-sm rounded-circle mr-2" style={{ width: 50, height: 50 }} />
                                <div>{`${item?.patient?.profile?.user?.first_name} ${item?.patient?.profile?.user?.last_name}`}</div>
                              </div>
                            </td>
                            <td className="text-dark">
                              <a href={`mailto:${item?.patient?.profile?.user?.email}`} className="text-dark">
                                <MdOutlineEmail /> {item?.patient?.profile?.user?.email}
                              </a>
                            </td>
                            <td className="text-dark">
                              <a href={`tel:${item?.patient?.profile?.user?.phone}`} className="text-dark">
                                <MdPhone /> {item?.patient?.profile?.user?.phone}
                              </a>
                            </td>
                            <td className="text-dark">{item?.symptoms}</td>
                            <td className="text-dark">
                              {moment(item?.start_time).format('MMM DD, YYYY')}
                              <br />
                              {moment(item?.start_time).format('hh:mm A')}
                            </td>
                            <td className="text-dark">
                              <StatusTextVisit status={item.status} />
                            </td>
                            <td className="text-dark">
                              <div className="d-flex align-items-center">
                                {enableMeetButton ? (
                                  <button
                                    type="button"
                                    className="btn waves-effect waves-light"
                                    style={{
                                      backgroundColor: 'white',
                                      color: 'black',
                                      border: '1px solid black',
                                    }}
                                    onClick={() => handleActionClick('meet', item)}
                                  >
                                    {provider_visits('meet')}
                                  </button>
                                ) : (
                                  <OverlayTrigger placement="top" delay={{ show: 100, hide: 100 }} overlay={<Tooltip id="tooltip-disabled">{provider_visits('meet_button_unavailable')}</Tooltip>}>
                                    <span className="d-inline-block">
                                      <button
                                        type="button"
                                        className="btn waves-effect waves-light"
                                        style={{
                                          backgroundColor: 'white',
                                          color: 'black',
                                          border: '1px solid black',
                                        }}
                                        disabled
                                      >
                                        {provider_visits('meet')}
                                      </button>
                                    </span>
                                  </OverlayTrigger>
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </TableCard>

                    {upcomingTotalPages > appointmentsPerPage && (
                      <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                        <Pagination
                          activePage={upcomingCurrentPage}
                          itemsCountPerPage={appointmentsPerPage}
                          totalItemsCount={upcomingTotalPages}
                          pageRangeDisplayed={5}
                          onChange={(pageNumber) => {
                            setUpcomingCurrentPage(pageNumber)
                          }}
                          itemClass="page-item"
                          linkClass="page-link float-center"
                        />
                        <div className="row-lg-12 text-center mt-2">
                          {provider_visits('page')} {upcomingCurrentPage}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}

            {innerActiveTab === 'past' && (
              <div className="row tab-pane fade show active" id="past" role="tabpanel" aria-labelledby="past-tab">
                {pastAppointments.length === 0 ? (
                  <CardItem className={'col-lg-12'}>{isLoading ? provider_visits('loading') : provider_visits('no_past_appointments')}</CardItem>
                ) : (
                  <>
                    <TableCard headers={['Patient', 'Email', 'Contact Info', 'Symptoms', 'Date & Time', 'Status', 'Action']}>
                      {pastAppointments.map((item, index) => (
                        <tr key={index} style={{ backgroundColor: item.color_flag }}>
                          <td className="text-dark">
                            <div className="d-flex align-items-center">
                              <img src={item?.patient?.profile?.profile_pic_url} alt="" className="thumb-sm rounded-circle mr-2" style={{ width: 50, height: 50 }} />
                              <div>{`${item?.patient?.profile?.user?.first_name} ${item?.patient?.profile?.user?.last_name}`}</div>
                            </div>
                          </td>
                          <td className="text-dark">
                            <a href={`mailto:${item?.patient?.profile?.user?.email}`} className="text-dark">
                              <MdOutlineEmail /> {item?.patient?.profile?.user?.email}
                            </a>
                          </td>
                          <td className="text-dark">
                            <a href={`tel:${item?.patient?.profile?.user?.phone}`} className="text-dark">
                              <MdPhone /> {item?.patient?.profile?.user?.phone}
                            </a>
                          </td>
                          <td className="text-dark">{item?.symptoms}</td>
                          <td className="text-dark">
                            {moment(item?.start_time).format('MMM DD, YYYY')}
                            <br />
                            {moment(item?.start_time).format('hh:mm A')}
                          </td>
                          <td className="text-dark align-center">
                            <AppointmentStatus status={item?.status} labelBgColor={item?.label_background_color} labelColor={item?.label_text_color} />
                          </td>
                          <td className="text-dark">
                            <button
                              type="button"
                              style={{
                                backgroundColor: 'white',
                                color: 'black',
                                border: '1px solid #42323B',
                                borderRadius: '64px',
                                padding: '8px 20px',
                                fontSize: '13.5px',
                                display: 'flex',
                                flexWrap: 'nowrap',
                                minWidth: '113px',
                                justifyContent: 'center',
                              }}
                              onClick={() => {
                                const visitData = {
                                  date: moment(item?.start_time).format('MMM DD, YYYY, hh:mm A'),
                                  name: item?.patient?.profile?.user?.first_name + ' ' + item?.patient?.profile?.user?.last_name,
                                  email: item?.patient?.profile?.user?.email,
                                  phone: item?.patient?.profile?.user?.phone,
                                  symptoms: item?.symptoms,
                                  ratings: item?.feedback?.rating || 0,
                                  feedback: item?.feedback?.feedback || provider_visits('no_feedback_available'),
                                };
                                const stars = +visitData.ratings > 0
                                  ? new Array(+visitData.ratings).fill(null).map(() =>
                                    `<svg class="star-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style="fill: gold; width: 24px; height: 24px;">
                                        <path d="M12 17.75l-6.18 3.24 1.64-7.03-5.56-4.86 7.09-.61L12 2.25l2.97 6.33 7.09.61-5.56 4.86 1.64 7.03z"/>
                                      </svg>`).join('')
                                  : provider_visits('no_rating_available');
                                Swal.fire({
                                  html: `
                                        <div class='d-flex justify-content-center flex-column' style="gap:24px">
                                          <div style="font-size:28px; font-weight:500">${provider_visits('appointment_details')}</div>
                                          <div class='d-flex flex-column'> 
                                            ${Object.keys(visitData)
                                      .map((key) => {
                                        if (key !== 'ratings' && key !== 'feedback') {
                                          return `
                                                    <div class='d-flex flex-column flex-sm-row mb-2'>
                                                      <label style="font-size:14px; color:#42323B; min-width:130px; margin-bottom:0px; text-align:start">${provider_visits(key)}:</label>
                                                      <label style="font-size:14px; color:#42323B; text-align:start">${visitData[key]}</label>
                                                    </div>
                                                  `;
                                        }
                                        return '';
                                      })
                                      .join('')}
                                            <div class='d-flex flex-column flex-sm-row mb-2'>
                                              <label style="font-size:14px; color:#42323B; min-width:130px; margin-bottom:0px; text-align:start">${provider_visits('ratings')}: </label>
                                              <label style="font-size:14px; color:#42323B; text-align:start">${stars}</label>
                                            </div>
                                            <div class='d-flex flex-column flex-sm-row'>
                                              <label style="font-size:14px; color:#42323B; min-width:130px; margin-bottom:0px; text-align:start">${provider_visits('feedback')}: </label>
                                              <label style="font-size:14px; color:#42323B; text-align:start">${visitData.feedback}</label>
                                            </div>
                                          </div>
                                        </div>
                                      `,
                                  confirmButtonText: provider_visits('ok'),
                                  width: '600px',
                                  height: '440px',
                                  padding: '16px 24px',
                                  customClass: {
                                    confirmButton: 'custom-confirm-button'
                                  }
                                });
                              }}
                            >
                              {provider_visits('view_visit')}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </TableCard>

                    {pastTotalPages > appointmentsPerPage && (
                      <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                        <Pagination
                          activePage={pastCurrentPage}
                          itemsCountPerPage={appointmentsPerPage}
                          totalItemsCount={pastTotalPages}
                          pageRangeDisplayed={5}
                          onChange={(pageNumber) => {
                            setPastCurrentPage(pageNumber)
                          }}
                          itemClass="page-item"
                          linkClass="page-link float-center"
                        />
                        <div className="row-lg-12 text-center mt-2">
                          {provider_visits('page')} {pastCurrentPage}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Visits
