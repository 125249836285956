import * as yup from 'yup'
import { isValidPhoneNumber } from 'react-phone-number-input'

export const ProviderProfileSchema = (error_message) => {
  return yup
    .object({
      first_name: yup
        .string()
        .required(error_message('first_name_is_required'))
        .min(2, error_message('first_name_min'))
        .max(50, error_message('first_name_max'))
        .matches(/^[A-Za-z\s]+$/, error_message('first_name_match')),

      last_name: yup
        .string()
        .required(error_message('last_name_is_required'))
        .min(2, error_message('last_name_min'))
        .max(50, error_message('last_name_max'))
        .matches(/^[A-Za-z\s]+$/, error_message('last_name_match')),

      provider_description: yup.string().nullable().max(500, error_message('provider_description_max')),

      contactNum: yup
        .string()
        .required(error_message('contact_num_is_required'))
        .test('is-valid-phone-number', error_message('contact_num_invalid'), (value) => {
          if (!value) return false // if no value, fail validation
          return isValidPhoneNumber(value) // validate phone number using react-phone-number-input
        }),
    })
    .required()
}

export default ProviderProfileSchema
