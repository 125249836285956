import React, { useEffect, useState } from 'react'
import { Rating } from 'react-simple-star-rating'
import { CardLongItem } from 'components/cards/Card'
import Footer from 'components/Footer'
import Pagination from 'react-js-pagination'
import styled from '@emotion/styled'
import { Link, useNavigate } from 'react-router-dom'
import RingLoading from 'components/lottie/RingLoading'
import { useTranslation } from 'react-i18next'
import { useLazyGetServiceListQuery } from 'v2/src/lib/services/nHServiceApi'
import SearchSection from 'components/search'
import { useUserProfileDetailQuery } from 'v2/src/lib/services/profileApi'
import { fGetAUserInf } from 'v2/src/lib/appUtils'

export const StyleWrapper = styled.div`
  .optionListContainer {
    position: sticky;
  }
`
export default function Marketplace() {
  const { t: patient_marketplace } = useTranslation('patient_marketplace')
  const { t: patient_virtual_visit } = useTranslation('patient_virtual_visit')
  /////////////////////////(useState)
  const [pageNumber, setPageNumber] = useState(1)
  const [practiceList, setPracticeList] = useState([])
  const [perPageItem, setRowPerPageItem] = useState(0)
  const [totalItemInPage, setTotalItemPerInPage] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [priceRange, setPriceRange] = useState([0, 500])
  const [selectedRatings, setSelectedRatings] = useState([])
  const [specialization, setSpecializations] = useState([])
  const [searchTriggered, setSearchTriggered] = useState(false)
  const [visitType, setVisitType] = useState('clinic')
  const [searchText, setSearchText] = useState('')
  const [trigger, { data: servicesData, isLoading }] = useLazyGetServiceListQuery()

  const navigate = useNavigate()
  let aUData = fGetAUserInf()
  const aUserProfileId = aUData?.details?.profileId
  const { data: patientDetails, loading: patientDetailsLoading } = useUserProfileDetailQuery({ profileId: aUserProfileId })
  const getService = async (page, filters) => {
    trigger({ page, filters }).unwrap()
  }

  useEffect(() => {
    if (servicesData) {
      setPracticeList(servicesData?.data)
      setRowPerPageItem(servicesData?.meta?.per_page)
      setTotalItemPerInPage(servicesData?.meta?.total)
      setTotalPage(servicesData?.meta?.last_page)
    }
  }, [servicesData])

  const getImageHeight = () => {
    if (windowWidth <= 576) {
      return 180
    } else if (windowWidth < 768) {
      return 290
    } else if (windowWidth <= 1000) {
      return 160
    } else if (windowWidth <= 1200) {
      return 240
    } else {
      return 280
    }
  }

  useEffect(() => {
    const filters = {
      booking_availability: visitType,
      price_min: priceRange[0],
      price_max: priceRange[1],
      ratings: selectedRatings,
      speciality_ids: specialization,
      search: searchText,
    }

    getService(pageNumber, filters)
  }, [pageNumber, searchTriggered])

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (patientDetails?.status) {
      if (!patientDetails.data?.personal_details_completed || !patientDetails.data?.has_approved_identification_document) {
        Swal.fire({
          html: patient_virtual_visit('complete_registration'),
          confirmButtonText: patient_virtual_visit('start_verification'),
        }).then(() => {
          navigate('/patient/profile', { replace: true })
        })
      }
    }
  }, [patientDetails])

  const handleRatingSelect = (rating) => {
    setSelectedRatings(
      (prev) => (prev.includes(rating) ? prev.filter((r) => r !== rating) : [...prev, rating]) // Toggle selection
    )
  }
  const handlePriceRangeChange = (event, newValue) => {
    setPriceRange(newValue)
  }

  return (
    <div className="figma mt-5">
      <div className="page-wrapper">
        <div className="page-content">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="page-title">{patient_marketplace('services')}</h4>
                </div>
              </div>
            </div>
            <div className="row-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="col-lg-12 p-0">
                    <h5 className="mt-1" style={{ fontSize: 18, fontWeight: 600 }}>
                      {patient_marketplace('search')}
                    </h5>
                    <SearchSection
                      priceRange={priceRange}
                      setPriceRange={setPriceRange}
                      selectedRatings={selectedRatings}
                      setSelectedRatings={setSelectedRatings}
                      handleRatingSelect={handleRatingSelect}
                      handlePriceRangeChange={handlePriceRangeChange}
                      setSpecializations={setSpecializations}
                      setSearchTriggered={setSearchTriggered}
                      visitType={visitType}
                      setVisitType={setVisitType}
                      searchText={searchText}
                      setSearchText={setSearchText}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  {isLoading ? (
                    <CardLongItem>
                      <div className="d-flex justify-content-center">
                        <RingLoading size={200} />
                      </div>
                    </CardLongItem>
                  ) : (
                    <>
                      <div className="bg-white py-2 rounded" style={{ width: '100%' }}>
                        <h5 className="px-3" style={{ fontSize: '15px', fontWeight: 600, marginLeft: '4px' }}>
                          {patient_marketplace('top_doctors_across_specialties')}{' '}
                        </h5>
                        {/* <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4"> */}
                        <div class="row g-4 m-0 py-3 px-2" style={{ rowGap: 24 }}>
                          {practiceList && practiceList.length ? (
                            practiceList.map((item) => (
                              <div className="col-12 col-sm-6 col-md-3" key={item?.id}>
                                <div class="card w-100 h-100 p-3 px-sm-2 p-md-3" style={{ boxShadow: '0 3px 7px -1px rgba(0,0,0,0.4)' }}>
                                  <img
                                    className="card-img-top mb-3"
                                    id="wallet-card-media"
                                    src={item?.provider?.profile?.profile_pic_url}
                                    alt="Card image cap"
                                    style={{
                                      width: '100%',
                                      height: getImageHeight(),
                                      // height: 200,
                                      borderRadius: 10,
                                    }}
                                  />
                                  <div className="card-body text-left p-0">
                                    <div className="d-flex flex-column justify-content-between">
                                      <h4 className="" style={{ fontSize: '20px', fontWeight: 600 }}>
                                        {`${item?.provider?.profile?.user?.first_name} ${item?.provider?.profile?.user?.last_name}`}
                                      </h4>
                                      <div className="mb-3">
                                        <span>
                                          {`${item?.total_consultations} ${patient_marketplace(item?.total_consultations === 1 ? 'consultation' : 'consultations')}`}
                                          <span className="rounded-circle d-inline-block mx-2" style={{ width: 3, height: 3, backgroundColor: '#D9D9D9', padding: 4 }} />
                                        </span>
                                        <span>${item?.amount}</span>
                                      </div>
                                      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                        <div>
                                          <span style={{ width: 110, display: 'inline-block' }}>{patient_marketplace('specialization')}:</span>
                                          <span style={{ fontSize: '15px', fontWeight: 600 }}>{item?.provider?.specialities?.map((spec) => spec.name)}</span>
                                        </div>
                                        <div>
                                          <span style={{ width: 110, display: 'inline-block' }}>{patient_marketplace('experience')}:</span>
                                          <span style={{ fontSize: '15px', fontWeight: 600 }}>{item?.total_experience}</span>
                                        </div>
                                        <div className="d-flex align-items-center flex-wrap">
                                          <span style={{ width: 110, display: 'inline-block' }}>{patient_marketplace('ratings')}:</span>
                                          <span className="d-flex align-items-center" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                            <span className="mr-1">{Math.round(item?.provider?.average_rating)}</span>
                                            <Rating
                                              fillColor="#ffb822"
                                              emptyColor="white"
                                              SVGstrokeColor="#f1a545"
                                              SVGstorkeWidth={1}
                                              size={17}
                                              allowFraction={true}
                                              initialValue={item?.provider?.average_rating}
                                              readonly={true}
                                              style={{ marginBottom: 3 }}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="card-footer bg-transparent p-0 mt-2 text-center">
                                    <Link to={'/patient/marketplace/booking/' + item?.id} state={{ practiceId: item?.id }}>
                                      <button className="btn btn-success rounded-pill" style={{ width: 'auto', maxWidth: '200px', padding: '6px 24px' }}>
                                        {patient_marketplace('consult_now')}
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="text-center w-100">{patient_marketplace('no_data_found')}</div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <div className="row d-flex justify-content-center">
                  {practiceList?.length > 0 && (
                    <Pagination
                      activePage={pageNumber}
                      itemsCountPerPage={perPageItem}
                      totalItemsCount={totalItemInPage}
                      pageRangeDisplayed={totalPage}
                      itemClass="page-item"
                      linkClass="page-link float-center"
                      onChange={setPageNumber}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}
