import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Spinner, Alert } from 'react-bootstrap';
import { usePostSubscriptionMutation } from 'v2/src/lib/services/nHSubscriptionApi';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CheckoutForm = ({ handleClose, selPlanId, fCheckoutHandler }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const { t: patient_subscription } = useTranslation('patient_subscription');

  const [postSubscription, { data: postSubscriptionData, error: postSubscriptionError }] = usePostSubscriptionMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    try {
      const response = await postSubscription({
        subscription_plan_id: selPlanId,
        payment_method: paymentMethod?.id,
      }).unwrap();
      if (response && response.message) {
        toast.success(response.message);
      }
      setProcessing(false);
      handleClose();
    } catch (err) {
      setProcessing(false);
      const errorMessage = postSubscriptionError?.data?.message || patient_subscription('subscription_error');
      toast.error(errorMessage);
    }
  };

  return (
    <form onSubmit={handleSubmit} className='stripe-checkout-form mt-4'>
      <CardElement className="stripe-card-element" />

      <button
        type="button"
        className="btn btn-success btn-round waves-effect waves-light"
        style={{
          fontWeight: '700', letterSpacing: '0.32px',
          textTransform: 'uppercase',
          width: "160px",
          margin: "0 auto",
          borderRadius: "30px",
          padding: "14px 10px"
        }}
        disabled={!stripe || processing}
        onClick={(e) => {
          fCheckoutHandler();
          handleSubmit(e);
        }}
      >
        {patient_subscription('subscribe_now')}
      </button>
    </form>
  );
};

export default CheckoutForm;
