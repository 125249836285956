//import LinearProgress from "@mui/material/LinearProgress";
import AppLogo from 'v2/src/core/AppLogo';
//---------------------------------------------------------

const pgLoaderCss = {
  position: 'fixed', top: 0, left: 0,
  zIndex: 1301, width: '100%',
};

export default function PageLoader () {
  return (
    <div style={pgLoaderCss}>
      <AppLogo />
      {/*<LinearProgress color="primary" />*/}
    </div>
  );
};
