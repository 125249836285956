import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Rating } from 'react-simple-star-rating'
import CardItem from '../../../components/cards/Card'
import useAuth from '../../../hooks/useAuth'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import Multiselect from 'multiselect-react-dropdown'
import styled from '@emotion/styled'
import { emailStartsWithAdminFunc } from '../../../utils'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

export const StyleWrapper = styled.div`
  .optionListContainer {
    position: sticky;
  }
`
function Services({ limit }) {
  const navigate = useNavigate()
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const [errMsg, setErrMsg] = useState(null)
  const [list, setList] = useState([])
  const [search, setSearch] = useState('')
  const { t: provider_services } = useTranslation('provider_services')
  const [showFilterWindow, setShowFilterWindow] = useState(false)

  const [starFilter, setStarFilter] = useState([5, 4, 3, 2, 1, 0])
  const priceRangeRef = useRef()
  const [listOriginal, setListOriginal] = useState([])
  const [filterlist, setFilterList] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [priceRangeMin, setPriceRangeMin] = useState(0)
  const [priceRangeMax, setPriceRangeMax] = useState(2000)
  const [activeFilter, setActiveFilter] = useState('')
  const [searchString, setSearchString] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [emailStartsWithAdmin, setEmailStartsWithAdmin] = useState(true)

  async function getList() {
    const controller = new AbortController()
    await axiosPrivate
      .post(
        search ? 'providerSearchService' : 'getServices',
        {
          Email: auth.email || Cookies.get('email'),
          Search: search,
        },
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        const { Status, Data: data = [], Message } = res.data

        if (Status) {
          setIsLoading(false)
          const serviceList = res.data.Data
          setList(data.slice(0, limit))

          setListOriginal(serviceList)
          const serviceCategories = serviceList?.map((item, index) => {
            return item.category
          })

          setCategoryOptions(serviceCategories)
        } else {
          throw new Error(Message)
        }
      })
      .catch((err) => {
        console.error(err)
        setErrMsg(err.message)
      })
  }

  async function handleSubmit(event) {
    event.preventDefault()

    if (search.length < 3) {
      getList()
      return
    }
    setSearchString(search)
    getList()
  }

  useEffect(() => {
    getList()
  }, [searchString])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    if (isMounted) {
      if (emailStartsWithAdminFunc(auth.email)) {
        setEmailStartsWithAdmin(true)
      }
    }

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const servBoxCss = {
    minWidth: '13.5rem',
    height: '100%',//'590px',
    //position: 'relative',
    //textAlign: 'center',
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <div className="float-right">
              <ol className="breadcrumb">
                {emailStartsWithAdmin ? (
                  <Link to="manage/new">
                    <button type="button" className="btn btn-success waves-effect waves-light">
                      {provider_services('new_service')}
                    </button>
                  </Link>
                ) : (
                  <div className={`d-flex flex-column flex-md-row float-right m-4 ${!emailStartsWithAdmin ? 'disabled' : ''}`}>
                    <OverlayTrigger placement="top" delay={{ show: 100, hide: 100 }} overlay={<Tooltip id="tooltip-disabled">{provider_services('admin_new_service')}</Tooltip>}>
                      <span className="d-inline-block">
                        <button type="button" className="btn btn-success waves-effect waves-light" disabled={!emailStartsWithAdmin} style={{ pointerEvents: 'none' }}>
                          {provider_services('new_service')}
                        </button>
                      </span>
                    </OverlayTrigger>
                  </div>
                )}
              </ol>
            </div>
            <h4 className="page-title">{provider_services('services')}</h4>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="col-lg-12">
            <h5 className="mt-1 ">{provider_services('filters')}</h5>

            <ul className="nav nav-pills m-2" id="pills-tab" role="tablist">
              <li className="nav-item col-xl-5 m-2">
                <div className="row">
                  <div className="col-lg-10">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={provider_services('search_service')}
                            aria-label={provider_services('search_service')}
                            // value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <span className="input-group-append">
                            <button className="btn btn-success" type="submit">
                              {provider_services('go')}
                            </button>
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </li>
              <li className="nav-item m-2">
                <a
                  className="nav-link"
                  id="service_category_tab"
                  data-toggle="pill"
                  href="#service_category"
                  onClick={(e) => {
                    // setShowFilterWindow(!showFilterWindow)

                    setActiveFilter('service_category')
                    if (activeFilter === 'service_category') {
                      setShowFilterWindow(!showFilterWindow)
                    } else if (activeFilter !== 'service_category') {
                      setShowFilterWindow(true)
                    }
                    // else {
                    //   setShowFilterWindow(false)

                    // }
                  }}
                >
                  {provider_services('service_category')}
                </a>
                <div className="tab-content detail-list position-absolute" id="pills-tabContent">
                  <div className="tab-pane position-absolute" style={{ width: 420, zIndex: 4 }} id="service_category">
                    {showFilterWindow && activeFilter === 'service_category' ? (
                      <CardItem>
                        <div className="p-3">
                          <h6 className=" mt-0">{provider_services('service_categories')}</h6>
                          <div className="">
                            <StyleWrapper>
                              <Multiselect
                                style={{ zIndex: 3 }}
                                options={categoryOptions} // Options to display in the dropdown
                                selectedValues={filterlist} // Preselected value to persist in dropdown
                                onSelect={(selectedList, selectedItem) => {
                                  setFilterList(selectedList)
                                  if (selectedList.length > 0) {
                                    setList(
                                      listOriginal.filter((item) => {
                                        return selectedList.includes(item.category)
                                      })
                                    )
                                  } else {
                                    setList(listOriginal)
                                  }
                                }} // Function will trigger on select event
                                onRemove={(selectedList, selectedItem) => {
                                  setFilterList(selectedList)
                                  if (selectedList.length > 0) {
                                    setList(
                                      listOriginal.filter((item) => {
                                        return selectedList.includes(item.category)
                                      })
                                    )
                                  } else {
                                    setList(listOriginal)
                                  }
                                }} // Function will trigger on remove event
                                isObject={false}
                                showCheckbox={true}
                                displayValue="name" // Property name to display in the dropdown options
                              />
                            </StyleWrapper>
                          </div>
                        </div>
                      </CardItem>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </li>
              <li className="nav-item m-2">
                <a
                  className="nav-link"
                  id="price_range_tab"
                  data-toggle="pill"
                  href="#price_range"
                  onClick={(e) => {
                    // setShowFilterWindow(!showFilterWindow)
                    setActiveFilter('price_range')
                    if (activeFilter === 'price_range') {
                      setShowFilterWindow(!showFilterWindow)
                    } else if (activeFilter !== 'price_range') {
                      setShowFilterWindow(true)
                    }
                    // else {
                    //   setShowFilterWindow(false)

                    // }
                  }}
                >
                  {provider_services('price_range')}
                </a>
                <div className="tab-content detail-list position-absolute" id="pills-tabContent">
                  <div className="tab-pane position-absolute" style={{ zIndex: 3 }} id="price_range">
                    {showFilterWindow && activeFilter === 'price_range' ? (
                      <CardItem>
                        <div className="p-3">
                          {/* <h6 className=" mt-0">{provider_services('price_range')}</h6> */}
                          <div className="m-1">
                            <h6 className=" mb-0">{provider_services('minimum')}</h6>
                            <input
                              ref={priceRangeRef}
                              type="number"
                              step={10}
                              value={priceRangeMin}
                              // id="range_doctors_rate"
                              onChange={(e) => {
                                setPriceRangeMin(e.target.value)
                                if (priceRangeMax - e.target.value >= 0) {
                                  setList(
                                    listOriginal.filter((item) => {
                                      const price = parseFloat(item.cost_price)
                                      return e.target.value <= price && priceRangeMax >= price
                                    })
                                  )
                                }
                              }}
                            />
                          </div>
                          <div className="m-1">
                            <h6 className=" mb-0">{provider_services('maximum')}</h6>

                            <input
                              ref={priceRangeRef}
                              type="number"
                              step={10}
                              // id="range_doctors_rate"
                              value={priceRangeMax}
                              onChange={(e) => {
                                setPriceRangeMax(e.target.value)
                                if (e.target.value - priceRangeMin >= 0) {
                                  setList(
                                    listOriginal.filter((item) => {
                                      const price = parseFloat(item.cost_price)
                                      return priceRangeMin <= price && e.target.value >= price
                                    })
                                  )
                                }
                              }}
                            />
                          </div>
                        </div>
                      </CardItem>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </li>
              <li className="nav-item m-2">
                <a
                  className="nav-link"
                  id="payment_history_detail_tab"
                  data-toggle="pill"
                  href="#ratings"
                  onClick={(e) => {
                    // setShowFilterWindow(!showFilterWindow)
                    setActiveFilter('ratings')
                    if (activeFilter === 'ratings') {
                      setShowFilterWindow(!showFilterWindow)
                    } else if (activeFilter !== 'ratings') {
                      setShowFilterWindow(true)
                    }
                    // else {
                    //   setShowFilterWindow(false)

                    // }
                  }}
                >
                  {provider_services('ratings')}
                </a>
                <div className="tab-content detail-list position-absolute" id="pills-tabContent">
                  <div className="tab-pane position-absolute " style={{ zIndex: 3 }} id="ratings">
                    {showFilterWindow && activeFilter === 'ratings' ? (
                      <CardItem>
                        <div className="p-3">
                          <h6 className="mt-0 ">{provider_services('ratings')}</h6>

                          {[5, 4, 3, 2, 1, 0].map((val, index) => (
                            <div key={val} className="checkbox checkbox-success" style={{ width: 140 }}>
                              <input
                                id={`checkboxa${val}`}
                                type="checkbox"
                                defaultChecked={starFilter[index] === val}
                                onChange={(e) => {
                                  var newstarfilter = starFilter
                                  var checked = false
                                  if (newstarfilter[index] === val) {
                                    newstarfilter[index] = false
                                  } else {
                                    newstarfilter[index] = parseInt(val)
                                    checked = true
                                  }
                                  setStarFilter(newstarfilter)
                                  setList(
                                    listOriginal.filter((item) => {
                                      return newstarfilter.includes(parseInt(item.average_ratings))
                                    })
                                  )
                                }}
                              />
                              <label htmlFor={`checkboxa${val}`}>
                                {val === 0 ? provider_services('unrated') : val}
                                {Array.apply(null, { length: val }).map((e, i) => (
                                  <i key={i} className="mdi mdi-star text-warning"></i>
                                ))}
                              </label>
                            </div>
                          ))}
                        </div>
                      </CardItem>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="row m-1">
            {isLoading ? (
              <CardItem>{provider_services('loading')}</CardItem>
            ) : searchString.length === 0 && list.length === 0 ? (
              <CardItem>{provider_services('no_created_services')}</CardItem>
            ) : searchString.length > 0 && list.length === 0 ? (
              <CardItem>{provider_services('no_results')}</CardItem>
            ) : (
              <div className='grdCtn wA'>
                {list?.map((item, index) => (
                  <div key={item?.recno || index} className="grdItm bShade cardBG"
                    style={servBoxCss}
                  >
                    <div className='txAC pt-3 xpb-3'>
                      {item?.email === auth.email ? (
                        <Link to={'manage/update/' + item.service_id} state={{ selectedService: item }}>
                          <img style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                            src={item?.default_image} alt="" className="img-fluid"
                          //width="100%" height="100%" title="Niu Health"
                          />
                        </Link>
                      ) : (
                        <Link to="#" state={{ selectedService: item }}>
                          <img style={{ width: '200px', height: '200px', objectFit: 'cover' }} src={item?.default_image} alt="" className="img-fluid" />
                        </Link>
                      )}
                    </div>
                    <div className="txAL p-3">
                      {item?.email === auth.email ? (
                        <Link to={'manage/update/' + item.service_id} state={{ selectedService: item }} className="cardHd">
                          {item.service_name}
                        </Link>
                      ) : (
                        <span className="cardHd">{item.service_name}</span>
                      )}
                    </div>
                    <div className='p-3' style={{ marginBottom: '5rem', paddingTop: '10px' }}>
                      <div className="xmt-1">
                        <b>{provider_services('description')}: </b><br />
                        {item.service_description}
                      </div>
                      <div className=" mt-2">
                        <b>{provider_services('category')} :</b><br />
                        {item.category}
                      </div>
                      <br />
                      <div className="mt-2">
                        <p>{item.description}</p>
                      </div>
                    </div>
                    <div className='dBtm'>
                      <div className="xd-flex xjustify-content-between my-2">
                        {item?.cost_price > 1 ? (
                          <p className="dAmt m-2">${item.cost_price}</p>
                        ) : item?.provider_id === '62gv3150511lx' ? (
                          <p className="dAmt m-2">{provider_services('free_for_members')}</p>
                        ) : (
                          <p className="dAmt m-2">{provider_services('contact_for_pricing')}</p>
                        )}
                      </div>
                      <div className='my-2'>
                        <div className="mb-0 product-review align-self-center">
                          <div className="xcol-md-10 m-3">
                            {item.average_ratings === 0 ? (
                              <>
                                {/* {provider_services('unrated')} */}
                              </>
                            ) : (
                              <Rating
                                fillColor="#ffb822"
                                emptyColor="white"
                                SVGstrokeColor="#f1a545"
                                SVGstorkeWidth={1}
                                size={17}
                                allowFraction={true}
                                initialValue={item.average_ratings}
                                readonly={true}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='my-2'>
                        {item?.email === auth.email ? (
                          <Link to={'manage/update/' + item.service_id} state={{ selectedService: item }}>
                            <button type="button" className="btn btn-gradient-success waves-effect waves-light">
                              {provider_services('manage')}
                            </button>
                          </Link>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
