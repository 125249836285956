import { nHApi } from 'v2/src/lib/services/public/nHApi'

export const nHSubscriptionApi = nHApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptions: builder.query({
      query: () => {
        return {
          url: '/subscription-plans',
          method: 'GET',
        }
      },
      providesTags: ['Subscriptions'],
    }),
    getUserSubscriptionHistory: builder.query({
      query: (queryString = '') => {
        return {
          url: `/subscription/history?${queryString}`,
          method: 'GET',
        }
      },
      providesTags: ['UserSubscriptionHistory'],
    }),
    cancelSubscription: builder.mutation({
      query: (apiParams) => {
        return {
          url: '/subscription/cancel',
          method: 'POST',
          body: apiParams,
        }
      },
    }),
    postSubscription: builder.mutation({
      query: (apiParams) => {
        return {
          url: '/subscription',
          method: 'POST',
          body: apiParams,
        }
      },
      invalidatesTags: ['UserSubscriptionHistory', 'userProfileDetail'],
    }),
  }),
})

export const { useGetSubscriptionsQuery, useGetUserSubscriptionHistoryQuery, useCancelSubscriptionMutation, usePostSubscriptionMutation } = nHSubscriptionApi
