import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import serviceSchema from 'v2/src/schema/serviceSchema';
import { useGetListOfClinicsQuery } from 'v2/src/lib/services/nHPracticesApi';
import { useLazyGetSingleServiceQuery, usePostServiceMutation, useUpdateServiceMutation } from 'v2/src/lib/services/nHServiceApi';
import { toast } from 'react-toastify';

export const StyleWrapper = styled.div`
  .optionListContainer {
    position: sticky;
  }
`;

const clinicList = [{ id: 1, name: 'telehealth' }, { id: 2, name: 'clinic' }];

function ManageServicesN() {
  const { action, id: serviceId } = useParams();
  const { t: provider_services } = useTranslation('provider_services');
  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);

  // RTK Query hooks
  const provider_id = localStorage.getItem("providerId");
  const { data: listOfClinics, refetch, isLoading } = useGetListOfClinicsQuery({
    page: 1,
    per_page: 100,
    provider_id,
    is_current: 1,
  }, { refetchOnMount: true });
  const [postService] = usePostServiceMutation();
  const [updateService] = useUpdateServiceMutation();
  const [getSingleService] = useLazyGetSingleServiceQuery();

  useEffect(() => {
    if (listOfClinics) setList(listOfClinics?.data)
    refetch();
  }, [listOfClinics, refetch]);

  useEffect(() => {
    if (serviceId) {
      getSingleServiceData(serviceId);
    }
  }, [serviceId]);

  // Form handling using react-hook-form
  const { register, handleSubmit, setValue, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(serviceSchema),
  });

  const getSingleServiceData = async (id) => {
    try {
      const { data } = await getSingleService({ id });
      if (data) {
        const service = data?.data;
        setValue('serviceName', service?.name);
        setValue('costPrice', service?.amount);
        setValue('practiceName', service?.practice_id);
        setValue('clinicAvailability', service?.booking_availability);
        setValue('serviceDescription', service?.description);
      }
    } catch (error) {
      toast.error('Failed to fetch service data');
    }
  };

  const handleFormSubmit = async (data) => {
    const formData = new FormData();
    formData.append('description', data.serviceDescription);
    formData.append('name', data.serviceName);
    formData.append('provider_id', provider_id);
    formData.append('practice_id', data.practiceName);
    formData.append('amount', data.costPrice);
    formData.append('booking_availability', data.clinicAvailability);

    try {
      let result;
      if (action === 'new') {
        result = await postService({ formData }).unwrap();
        toast.success(result?.message);
        navigate('/provider/services');
      } else if (action === 'update') {
        await updateService({ formData, id: serviceId }).unwrap();
        toast.success('Service updated successfully');
        navigate('/provider/services');
      }
    } catch (error) {
      toast.error(`Error ${action === 'new' ? 'creating' : 'updating'} service`);
      console.error(error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <div className="float-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">{provider_services('niu_health')}</Link></li>
                <li className="breadcrumb-item"><Link to="/provider/services">{provider_services('services')}</Link></li>
                <li className="breadcrumb-item active">
                  {provider_services(action === 'new' ? 'create_service' : 'update_service')}
                </li>
              </ol>
            </div>
            <h4 className="page-title">{provider_services('services')}</h4>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="row">
              {/* Service Name */}
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="serviceName" className="col-form-label text-right required">{provider_services('service_name')}</label>
                  <input
                    {...register('serviceName')}
                    type="text"
                    className={`form-control ${errors.serviceName ? 'is-invalid' : ''}`}
                    disabled={action === 'update' && !isEditMode}
                    id="serviceName"
                  />
                  {errors.serviceName && <div className="invalid-feedback">{errors.serviceName.message}</div>}
                </div>
              </div>

              {/* Price Rate */}
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="costPrice" className="col-form-label text-right required">{provider_services('price_rate')}</label>
                  <input
                    {...register('costPrice', { required: provider_services('price_rate_is_required') })}
                    type="number"
                    step="0.01"
                    className={`form-control ${errors.costPrice ? 'is-invalid' : ''}`}
                    disabled={action === 'update' && !isEditMode}
                    id="costPrice"
                  />
                  {errors.costPrice && <div className="invalid-feedback">{errors.costPrice.message}</div>}
                </div>
              </div>
            </div>

            {/* Practice Name */}
            <div className="row mb-3">
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="practiceName" className="col-form-label text-right required">{provider_services('practice_name')}</label>
                  <select
                    {...register('practiceName')}
                    className={`form-control ${errors.practiceName ? 'is-invalid' : ''}`}
                    disabled={action === 'update' && !isEditMode}
                    id="practiceName"
                  >
                    <option value="">Select a category</option>
                    {list?.map((obj) => (
                      <option key={obj.id} value={obj?.id}>
                        {obj?.institution?.name}
                      </option>
                    ))}
                  </select>
                  {errors.practiceName && <div className="invalid-feedback">{errors.practiceName.message}</div>}
                </div>
              </div>

              {/* Clinic Availability */}
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="clinicAvailability" className="col-form-label text-right required">{provider_services('clinic_availability')}</label>
                  <select
                    {...register('clinicAvailability', { required: provider_services('clinic_ids_is_required') })}
                    className={`form-control ${errors.clinicAvailability ? 'is-invalid' : ''}`}
                    disabled={action === 'update' && !isEditMode}
                    id="clinicAvailability"
                  >
                    <option value="">Select a clinic</option>
                    {clinicList?.map((clinic) => (
                      <option key={clinic.id} value={clinic.name}>{clinic.name}</option>
                    ))}
                  </select>
                  {errors.clinicAvailability && <div className="invalid-feedback">{errors.clinicAvailability.message}</div>}
                </div>
              </div>
            </div>

            {/* Service Description */}
            <div className="form-group">
              <label htmlFor="serviceDescription" className="col-form-label text-right">{provider_services('description')}</label>
              <textarea
                {...register('serviceDescription')}
                rows="5"
                className={`form-control ${errors.serviceDescription ? 'is-invalid' : ''}`}
                id="serviceDescription"
                disabled={action === 'update' && !isEditMode}
              />
              {errors.serviceDescription && <div className="invalid-feedback">{errors.serviceDescription.message}</div>}
            </div>

            {/* Submit and Cancel Buttons */}
            <div className="row mt-4">
              {(isEditMode || action === 'new') && (
                <div className="col-lg-12">
                  <button type="submit" className="btn btn-gradient-success waves-effect waves-light" disabled={isSubmitting}>
                    {isSubmitting ? provider_services('please_wait') : provider_services('save')}
                  </button>
                  <button
                    type="button"
                    className="btn btn-gradient-info waves-effect waves-light"
                    onClick={() => window.history.back()}
                    style={{ marginLeft: '10px' }}
                  >
                    {provider_services('cancel')}
                  </button>
                </div>
              )}
            </div>

            {/* Edit Button (for Update mode) */}
            {action === 'update' && !isEditMode && (
              <div className="col-lg-12">
                <button
                  type="button"
                  className="btn btn-gradient-success waves-effect waves-light"
                  onClick={() => setIsEditMode(true)}
                >
                  {provider_services('edit')}
                </button>
                <button
                  type="button"
                  className="btn btn-gradient-info waves-effect waves-light"
                  onClick={() => window.history.back()}
                  style={{ marginLeft: '10px' }}
                >
                  {provider_services('cancel')}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ManageServicesN;
