import * as yup from 'yup';

export const RegisterSchema = (error_message) => {
  return yup
    .object({
      FirstName: yup
        .string()
        .required(error_message('required_error'))
        .min(2, error_message('name_min_length_error'))
        .max(50, error_message('name_max_length_error'))
        .matches(/^[A-Za-z\s]+$/, error_message('name_format_error')),
      LastName: yup
        .string()
        .required(error_message('required_error'))
        .min(2, error_message('name_min_length_error'))
        .max(50, error_message('name_max_length_error'))
        .matches(/^[A-Za-z\s]+$/, error_message('name_format_error')),
      Email: yup.string().email(error_message('email_error')).required(error_message('required_error')).max(255, error_message('email_max_length_error')).min(6, error_message('email_min_length_error')),
      Password: yup
        .string()
        .required(error_message('required_error'))
        .min(8, error_message('password_min_length_error'))
        .max(50, error_message('password_max_length_error'))
        .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+-={}|;:'\",.<>?]).{8,}$/, error_message('password_format_error')),
      ConfirmPassword: yup
        .string()
        .oneOf([yup.ref('Password')], error_message('confirm_password_error'))
        .required(error_message('required_error')),
      Terms: yup.boolean().oneOf([true], error_message('terms_error')).required(error_message('terms_error')),
    })
    .required();
}
export default RegisterSchema;
