import { createSlice } from "@reduxjs/toolkit";

const lngSlice = createSlice({
  name: 'lang',
  initialState: {
    defaultLocalLang: {
      "id": 1,
      "value": "en",
      "label": "English",
      "flagIcon": "https://apia.niuhealth.com/storage/flag_images/en_flag.png"
    }
  },
  reducers: {
    updatedefaultLocalLang: (state, action) => {
      state.defaultLocalLang = action.payload
    }
  }
});

export default lngSlice.reducer;

export const {
  updatedefaultLocalLang
} = lngSlice.actions;