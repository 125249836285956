import React, { useEffect } from 'react';
import { loadScript } from 'v2/src/lib/appUtils';

const VeriffIntegration = ({ profileId }) => {
  useEffect(() => {
    const initializeVeriff = async () => {
      try {
        // Load Veriff SDK
        await loadScript('https://cdn.veriff.me/sdk/js/1.5/veriff.min.js', 'veriff-sdk');
        await loadScript('https://cdn.veriff.me/incontext/js/v1/veriff.js', 'veriff-incontext');

        const veriff = window.Veriff({
          host: 'https://stationapi.veriff.com',
          apiKey: 'c634f759-f86b-4eec-9705-26ba9cefc8d7',
          parentId: 'veriff-root',
          onSession: function (err, response) {
            if (err) {
              console.error('Veriff session error', err);
            } else {
              window.veriffSDK.createVeriffFrame({
                url: response.verification.url,
              });
            }
          },
        });

        // Set parameters for the session
        veriff.setParams({
          vendorData: `{'profile_id': ${profileId}, 'mode': 'veriff'}`,
        });

        // Mount Veriff session
        veriff.mount();
      } catch (error) {
        console.error('Error loading Veriff SDK', error);
      }
    };

    initializeVeriff();
  }, []);

  return <div id="veriff-root" style={{ width: '100%', height: '100%' }} />;
};

export default VeriffIntegration;
