import { Link, /*useNavigate, useParams*/ } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { AppLogo, } from 'v2/src/core';
import ForgotPasswordSchema from 'v2/src/schema/forgotPassword';
import { BtnOKCls } from 'v2/src/config/uiConfig';
import { useForgotPasswordMutation } from 'v2/src/lib/services/auth/authApi';
import { toast, ToastContainer } from "react-toastify";
//---------------------------------------------------------

export default function ForgotPassword() {
  const { t: error_message } = useTranslation('error_message');
  const { t: common } = useTranslation('common');
  const { t: authentication } = useTranslation('authentication');

  const [fForgotPassword, { isLoading: pWaitFP }] = useForgotPasswordMutation();

  const {
    register: ForgotPassRegister, handleSubmit,//: ForgotPassHandleSubmit,
    formState: { errors: ForgotPassErrors, isSubmitting: ForgotPassIsSubmitting },
  } = useForm({ resolver: yupResolver(ForgotPasswordSchema(error_message)), });

  const fFPwdHandler = async (data) => {
    let fPResp, axCode, strMsg = '';
    const inParams = {
      data: {
        email: data.Email,
        //medium: 'niuHAppLink', //'niuHAppOtp'
      },
    }
    try {
      fPResp = await fForgotPassword(inParams);
    } catch (excp) { }
    if (fPResp?.error) { fPResp = fPResp.error; }
    else if (fPResp?.data) { fPResp = fPResp.data; }
    if (fPResp?.status === true) {
      toast.success(fPResp?.message)
      return;
    } else {
      if (!fPResp?.data?.status) {
        if (fPResp?.data?.errors) {
          Object.keys(fPResp?.data?.errors).forEach(item => {
            fPResp?.data?.errors[item]?.forEach(msg => {
              toast.error(msg);
            })
          })
          return;
        }
        else {
          toast.error(fPResp?.message);
          return;
        }
      }
    }
  };
  //---------------------------------------------------------

  return (
    <div className="account-body accountbg">
      <div className="container">
        <div className="row vh-100 ">
          <div className="col-12 align-self-center">
            <div className="auth-page">
              <div className="card auth-card shadow-lg">
                <div className="card-body">
                  <div className="px-3">
                    <div style={{ textAlign: 'center' }}>
                      {/*<AppLogo style={{ maxHeight: '100px', height: 'auto', width: '100%', maxWidth: '170px' }} />*/}
                      <AppLogo style={{ maxHeight: '100px', height: '80px', width: 'auto', maxWidth: '100px' }} />
                    </div>

                    <div className="text-center auth-logo-text">
                      <h4 className="mt-0 mb-3 mt-5">{authentication('forgot_password')}</h4>
                      <p className="text-muted mb-0">{authentication('enter_email_for_instruction')}</p>
                    </div>

                    <form className="form-horizontal auth-form my-4" onSubmit={handleSubmit(fFPwdHandler)} noValidate>
                      <div className="form-group">
                        <label htmlFor="useremail">{common('email')}</label>
                        <div className="input-group">
                          <span className="auth-form-icon">
                            <i className="dripicons-mail"></i>
                          </span>
                          <input type="email" placeholder={common('enter_email')} {...ForgotPassRegister('Email')} className={`form-control ${Boolean(ForgotPassErrors.Email) ? 'is-invalid' : ''}`} />
                        </div>
                        {ForgotPassErrors.Email && <label className="text-danger">{error_message(ForgotPassErrors.Email.message)}</label>}
                      </div>
                      <div className="form-group mb-0 row">
                        <div className="col-12 mt-2">
                          <button type="submit"
                            className={BtnOKCls + (pWaitFP ? ' disabled' : '')}
                          >
                            {common('submit')} <i className="fas fa-sign-in-alt ml-1"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="m-3 text-center text-muted">
                    <p className="">
                      {common('remember_it')}{' '}
                      <Link to="/login" className="text-primary ml-2">
                        {common('sigin_here')}
                      </Link>
                    </p>
                  </div>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

