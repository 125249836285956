//import { createSlice, current } from "@reduxjs/toolkit";
import { nHApi } from 'v2/src/lib/services/public/nHApi';
import { USER_TYPES } from 'v2/src/config/appConfig';
//---------------------------------------------------------

export const nHDocumentApi = nHApi.injectEndpoints({
  endpoints: builder => ({
    nHDocumentTypes: builder.query({
      query: (params) => {
        const inParams = {
          user_role: params?.userType || USER_TYPES.patient,
          category: params?.category, //|| 'identification'
        };

        return { url: '/document-types', params: inParams };
      },
      keepUnusedDataFor: 1200,
    }),
    userDocumentList: builder.query({
      query: (params) => {
        /*if(params?.profileId) {
          userRole = params?.profileId;
        }*/
        let aUrl = '/documents?profile_id=' + params?.profileId + '&document_type_category=' + params?.category;

        return { url: aUrl, };
      },
    }),
    userDocumentDetail: builder.query({
      query: (params) => {
        if (params?.profileId) {
          userRole = params?.profileId;
        }
        const docId = params.docId;
        let aUrl = '/documents/' + docId;

        return { url: aUrl, };
      },
    }),
    addUserDocument: builder.mutation({
      query: ({ inParams }) => {
        return {
          //profile_id, document_type_id, file
          url: '/document',
          method: 'POST',
          body: inParams,
        }
      },
    }),
    getUserDocument: builder.query({
      query: (params) => {
        return {
          url: '/document/' + params.docId,
          method: 'GET',
        }
      },
    }),
    setUserDocument: builder.mutation({
      query: (params) => {
        return {
          //document_type_id,status, file, veridfied_at, expires_at
          url: '/document/' + params.docId,
          method: 'POST',
          body: params,
        }
      },
    }),
    deleteUserDocument: builder.mutation({
      query: (params) => {
        return {
          url: '/document/' + params.docId,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const { useNHDocumentTypesQuery,
  useUserDocumentListQuery,
  useUserDocumentDetailQuery,
  useAddUserDocumentMutation,
  useLazyGetUserDocumentQuery,
  useSetUserDocumentMutation,
  useDeleteUserDocumentMutation
} = nHDocumentApi;
//---------------------------------------------------------

