import { useRef } from 'react'
import { useEffect, useState } from 'react'
import { ContainerFluid, PageWrapper, TableTitle } from '../../components/table/Tables'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import TimeZoneSelect from '../time/Timezone'
import ScheduleSelect from '../time/Hours'
import './icon.css'
import useAuth from '../../hooks/useAuth'
import { AWS_BUCKET_DOCUMENTS, AWS_BUCKET_PROFILES, AWS_BUCKET_SERVICES, USERTYPE } from '../../constants'
import Swal from 'sweetalert2'
import moment from 'moment'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import PatientProfileSchema from '../../validation/patientProfile'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { getProvinceFromCountryId } from '../../utils'
import ProviderProfileSchema from '../../validation/providerProfile'
import ImageViewer from 'react-simple-image-viewer'
import { FaTimes } from 'react-icons/fa'
import useGetIdentificationDocumentList from '../../hooks/api/useGetIdentificationDocumentList'
import useDeleteDocument from '../../hooks/api/useDeleteDocument'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'
import LanguageDropdown from '../LanguageDropdown'

function ProfileEdit() {
  const { auth, setAuth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const { t: error_message } = useTranslation('error_message')
  const { t: comp_profile } = useTranslation('comp_profile')

  // const effectRun = useRef(false);
  const [profile, setProfile] = useState({
    ...auth,
    hours_sun_end: '20',
    hours_sun_start: '8',
    hours_mon_end: '20',
    hours_mon_start: '8',
    hours_tue_end: '20',
    hours_tue_start: '8',
    hours_wed_end: '20',
    hours_wed_start: '8',
    hours_thu_end: '20',
    hours_thu_start: '8',
    hours_fri_end: '20',
    hours_fri_start: '8',
    hours_sat_end: '20',
    hours_sat_start: '8',
    picture: (auth.userType === USERTYPE.patient ? '' : '') + 'Default.jpg',
    contact_info: '',
    practice: '',
    provider_description: '',
    provider_name: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    local_time_zone: '',
    address_line_1: '',
    address_line_2: '',
    zip_code: '',
    country_city_id: '',
    city: '',
    province: '',
    language: '',
    has_insurance: '',
    country_id: '0',
    date_of_birth: '2000-01-01',
    picturefile: '',
    facebook: '',
    twitter: '',
    instagram: '',
    othersocial: '',
    email: auth.email,
  })
  const [oldProfile, setOldProfile] = useState({})
  const [disableForm, setDisableForm] = useState(true)

  const [isLoading, setIsLoading] = useState(true)
  const [timeZone, setTimeZone] = useState('+00:00')
  const [countries, setCountries] = useState([])
  const [apiProvinceList, setApiProvinceList] = useState([])
  const [countryProvinceList, setCountryProvinceList] = useState([])
  const [cities, setCities] = useState([])
  const [cityActive, setCityActive] = useState(false)
  const [imagepreview, setImagePreview] = useState(false)
  const imgRef = useRef()

  const { data: identificationDocuments, getIdentificationDocumentList } = useGetIdentificationDocumentList()
  const { data: deleteDocumentData, deleteDocument } = useDeleteDocument()

  const [images, setImages] = useState([])
  const [documentTypes, setDocumentTypes] = useState([])
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [idUploaded, setIdUploaded] = useState(null)
  const [documentTypeOptions, setDocumentTypeOptions] = useState([])
  const [profileDocuments, setProfileDocuments] = useState([])

  const [hours, setHours] = useState({
    HoursMonStart: 8,
    HoursMonEnd: 20,
    HoursTueStart: 8,
    HoursTueEnd: 20,
    HoursWedStart: 8,
    HoursWedEnd: 20,
    HoursThuStart: 8,
    HoursThuEnd: 20,
    HoursFriStart: 8,
    HoursFriEnd: 20,
    HoursSatStart: 8,
    HoursSatEnd: 20,
    HoursSunStart: 8,
    HoursSunEnd: 20,
  })
  const [oldHours, setOldHours] = useState({
    HoursMonStart: 8,
    HoursMonEnd: 20,
    HoursTueStart: 8,
    HoursTueEnd: 20,
    HoursWedStart: 8,
    HoursWedEnd: 20,
    HoursThuStart: 8,
    HoursThuEnd: 20,
    HoursFriStart: 8,
    HoursFriEnd: 20,
    HoursSatStart: 8,
    HoursSatEnd: 20,
    HoursSunStart: 8,
    HoursSunEnd: 20,
  })
  function dateFormat(date) {
    return moment(date).format('YYYY-MM-DD') // moment can initialize passing empty params
  }

  const {
    register: PatientRegister,
    trigger: PatientProfileTrigger,
    setValue: PatientProfileSetValue,
    getValues: PatientProfileGetValues,
    control: PatientControl,
    formState: { errors: PatientProfileError, isValid: PatientProfileIsValid },
  } = useForm({
    resolver: yupResolver(PatientProfileSchema),
  })

  const {
    trigger: ProviderProfileTrigger,
    setValue: ProviderProfileSetValue,
    getValues: ProviderProfileGetValues,
    formState: { errors: ProviderProfileError, isValid: ProviderProfileIsValid },
  } = useForm({
    resolver: yupResolver(ProviderProfileSchema),
  })

  const handleLanguageChange = (selectedOption) => {
    PatientProfileSetValue('Language', selectedOption.value)
    PatientProfileTrigger('Language')
    setProfile((prev) => ({ ...prev, language: selectedOption.value }))
  }

  async function handleSubmit() {
    const formData = new FormData()

    if (auth.userType === USERTYPE.patient) {
      /*patient validation*/
      let isPatientProfileValid = false

      const first_name = profile.first_name
      const middle_name = profile.middle_name
      const last_name = profile.last_name
      const contact_info = profile.contact_info
      const address_line_1 = profile.address_line_1
      const address_line_2 = profile.address_line_2
      const zip_code = profile.zip_code
      const city = profile.city
      const country_city_id = profile.country_id
      const province = profile.province
      const language = profile.language
      const date_of_birth = profile.date_of_birth
      const local_time_zone = profile.local_time_zone

      /* Check the user type and trigger the validation */
      PatientProfileSetValue('FirstName', first_name)
      PatientProfileSetValue('MiddleName', middle_name)
      PatientProfileSetValue('LastName', last_name)
      PatientProfileSetValue('ContactInfo', contact_info)
      PatientProfileSetValue('Address1', address_line_1)
      PatientProfileSetValue('Address2', address_line_2)
      PatientProfileSetValue('ZIPCode', zip_code)
      PatientProfileSetValue('Province', province)
      PatientProfileSetValue('Language', language)
      PatientProfileSetValue('City', city)
      PatientProfileSetValue('CountryID', country_city_id)
      PatientProfileSetValue('DateOfBirth', date_of_birth)
      PatientProfileSetValue('LocalTimeZone', local_time_zone)

      const result = await PatientProfileTrigger()
      isPatientProfileValid = result && Object.keys(result).length === 0

      if (!isPatientProfileValid) {
        return false
      }

      formData.append('Email', auth.email)
      formData.append('FirstName', profile.first_name || '')
      formData.append('MiddleName', profile.middle_name || '')
      formData.append('LastName', profile.last_name || '')
      formData.append('ContactInfo', profile.contact_info || '')
      formData.append('Address1', profile.address_line_1 || '')
      formData.append('Address2', profile.address_line_2 || '')
      formData.append('ZIPCode', profile.zip_code || '')
      formData.append('Province', profile.province || '')
      formData.append('Language', profile.language || '')
      formData.append('CountryID', profile.country_id || '')
      formData.append('City', profile.city || '')
      formData.append('DateOfBirth', moment(profile.date_of_birth).format('YYYY-MM-DD') || '')
      formData.append('LocalTimeZone', profile.local_time_zone || '')

      if (idUploaded == false) {
        return false
      }

      images?.forEach((image, index) => {
        formData.append('documentImages[' + index + ']', image)
      })

      PatientProfileGetValues('documentTypes')?.forEach((documentType, index) => {
        formData.append('documentTypes[' + index + ']', documentType)
      })
    } else {
      /*Provider validation*/
      let isProviderProfileValid = false

      const provider_name = profile.provider_name
      const provider_description = profile.provider_description
      const practice = profile.practice
      const contact_info = profile.contact_info

      /* Check the user type and trigger the validation */
      ProviderProfileSetValue('provider_name', provider_name)
      ProviderProfileSetValue('ProviderDescription', provider_description)
      ProviderProfileSetValue('Practice', practice)
      ProviderProfileSetValue('ContactInfo', contact_info)

      const result = await ProviderProfileTrigger()
      isProviderProfileValid = result && Object.keys(result).length === 0

      if (!isProviderProfileValid) {
        return false
      }

      formData.append('Email', auth.email)
      formData.append('Name', profile.provider_name)
      formData.append('ProviderDescription', profile.provider_description)
      formData.append('Practice', profile.practice)
      formData.append('ContactInfo', profile.contact_info)
      for (let key in hours) {
        if (hours.hasOwnProperty(key)) {
          formData.append(key, hours[key])
        }
      }
    }

    if (typeof profile.picturefile === 'object') {
      formData.append('Image', profile.picturefile, 'profile_pic')
    }
    let profile_endpoint2 = auth.userType === USERTYPE.provider ? 'providerUpdateDetails' : auth.userType === USERTYPE.patient ? 'updatePatientDetails' : 'none'

    await axiosPrivate
      .post(profile_endpoint2, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: function (ProgressEvent) {
        },
      })
      .then((res) => {
        const { Status, Data: data = [], Message } = res.data
        if (Status) {
          setAuth((prev) => ({ ...prev, ...profile }))
          setOldProfile(profile)
          Swal.fire({
            // icon: 'success', 
            text: comp_profile('profile_update_successfull')
          })
          setDisableForm(!disableForm)
          if (auth.userType === USERTYPE.patient) {
            setImages([])
            getProfileDetails()
            setProfileDocuments([])
          } else {
            let updateHours = {}
            for (let key in hours) {
              if (hours.hasOwnProperty(key)) {
                updateHours[key] = hours[key]
              }
            }
            setOldHours(updateHours)
          }
        } else {
          Swal.fire({
            // icon: 'error',
            text: Message
          })
          throw new Error(Message)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    if (images.length > 0) {
      setIdUploaded(true)
    } else {
      setIdUploaded(false)
    }
  }, [images])

  function triggerFileInput() {
    if (imgRef.current) {
      imgRef.current.click()
    }
  }

  function handleCancelEdit() {
    setImagePreview(false)
    setDisableForm((prev) => !prev)
    setProfile(oldProfile)
    setHours(oldHours)
  }

  function handleInputChange(e) {
    const name = e.target.name
    const value = e.target.value
    setProfile((prev) => ({ ...prev, [name]: value }))
  }

  function FormTextField({ label, name, value, onChange }) {
    return (
      <div className="form-group row">
        <label htmlFor="example-text-input" className="col-sm-2 col-form-label text-right">
          {label}
        </label>
        <div className="col-sm-10">
          <input disabled={disableForm} className="form-control" type="text" name={name} value={value} onChange={onChange.bind(this)} required />
        </div>
      </div>
    )
  }

  const handleImageInputChange = (e) => {
    const [file] = e.target.files
    setProfile({
      ...profile,
      picturefile: file,
    })
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    let fileReader,
      isCancel = false
    if (profile.picturefile) {
      fileReader = new FileReader()
      fileReader.onload = (e) => {
        const { result } = e.target
        if (result && !isCancel) {
          // setFileDataURL(result)

          setProfile({
            ...profile,
            picture: result,
          })

          setImagePreview(true)
        }
      }
      fileReader.readAsDataURL(profile.picturefile)
    }
    if (profile?.documents && profile?.documents.length) {
      setProfileDocuments(profile.documents)
      setIdUploaded(true)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [profile])

  // async function getCities() {
  //   const result = await axiosPrivate
  //     .post('getCities', {
  //       CountryID: profile.country_id,
  //       Email: auth.email,
  //     })
  //     .then((res) => {
  //       const { Status, Data: data = [], Message } = res.data

  //       if (Status) {
  //         setCities(res.data.Data)
  //       }
  //     })

  //   return result || []
  // }
  // Get Profile details
  // use our useAuth hooks to update user information, it should be centralized to this context hook

  const controller = new AbortController()
  let profile_endpoint = auth.userType === USERTYPE.patient ? 'getPatientDetails' : auth.userType === USERTYPE.provider ? 'getProviderDetails' : null
  async function getProfileDetails() {
    await axiosPrivate
      .post(
        profile_endpoint,
        { Email: auth.email },
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        const { Status, Data: data = [], Message } = res.data
        const details = data[0]

        if (Status) {
          setProfile(details)
          // setProfile({...profile, date_of_birth:(moment(details.date_of_birth).format('yyyy-MM-dd'))})
          setHours({
            HoursSunStart: details.hours_sun_start,
            HoursSunEnd: details.hours_sun_end,
            HoursMonStart: details.hours_mon_start,
            HoursMonEnd: details.hours_mon_end,
            HoursTueStart: details.hours_tue_start,
            HoursTueEnd: details.hours_tue_end,
            HoursWedStart: details.hours_wed_start,
            HoursWedEnd: details.hours_wed_end,
            HoursThuStart: details.hours_thu_start,
            HoursThuEnd: details.hours_thu_end,
            HoursFriStart: details.hours_fri_start,
            HoursFriEnd: details.hours_fri_end,
            HoursSatStart: details.hours_sat_start,
            HoursSatEnd: details.hours_sat_end,
          })
          setOldHours({
            HoursSunStart: details.hours_sun_start,
            HoursSunEnd: details.hours_sun_end,
            HoursMonStart: details.hours_mon_start,
            HoursMonEnd: details.hours_mon_end,
            HoursTueStart: details.hours_tue_start,
            HoursTueEnd: details.hours_tue_end,
            HoursWedStart: details.hours_wed_start,
            HoursWedEnd: details.hours_wed_end,
            HoursThuStart: details.hours_thu_start,
            HoursThuEnd: details.hours_thu_end,
            HoursFriStart: details.hours_fri_start,
            HoursFriEnd: details.hours_fri_end,
            HoursSatStart: details.hours_sat_start,
            HoursSatEnd: details.hours_sat_end,
          })

          setOldProfile(details)
          setAuth((prev) => ({ ...prev, ...details }))
          setTimeZone(details?.local_time_zone)

          /*If the Language is not empty then take the value and set change the language for i18next*/
          if (details['language']) {
            i18n.changeLanguage(details['language'])
          }
        } else {
          throw new Error(Message)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }
  async function getCountries() {
    await axiosPrivate
      .post(
        'getCountries',
        { Email: auth.email },
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        const { Status, Data: data = [], Message } = res.data

        if (Status) {
          setCountries(data)
          setCityActive(true)
        } else {
          throw new Error(Message)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }
  async function getProvince() {
    await axiosPrivate
      .post(
        'getProvince',
        { Email: auth.email },
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        const { Status, Data: data = [], Message } = res.data

        if (Status) {
          setApiProvinceList(data)
        } else {
          throw new Error(Message)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    let isMounted = true

    setAuth((prev) => ({ ...prev, profile, name: profile.first_name }))
    // if (effectRun.current){
    getProfileDetails()

    if (auth.userType === USERTYPE.patient) {
      getCountries()
      getProvince()
      getIdentificationDocumentList({ Email: auth.email })
    }
    // }
    return () => {
      isMounted = false
      controller.abort()
      // effectRun.current = true;
    }
  }, [])

  useEffect(() => {
    if (profile.country_id != '0' && apiProvinceList.length > 0) {
      const countryProvince = getProvinceFromCountryId(apiProvinceList, profile.country_id)
      setCountryProvinceList(countryProvince)
      if (countryProvince.length == 0 && profile.province == '') {
        PatientProfileSetValue('Province', '')
        setProfile((prev) => ({
          ...prev,
          province: '',
        }))
      }
    }
  }, [profile.country_id, apiProvinceList])

  // useEffect(() => {

  //   if (!profile?.country_id || profile?.country_id === 'undefined'|| profile?.country_id === '') return
  //   // getCities()
  // }, [profile.country_id])

  const removeImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index)
    const updatedDocumentTypes = documentTypes.filter((_, i) => i !== index)
    setImages(updatedImages)
    setDocumentTypes(updatedDocumentTypes)
  }

  const openImageViewer = (index) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  const addImageBox = () => {
    setImages([...images, undefined])
    setDocumentTypes([...documentTypes, '']) // Reset the dropdown to an empty string
  }

  useEffect(() => {
    if (identificationDocuments) {
      let documentTypeOptions = []
      if (identificationDocuments.Status) {
        identificationDocuments.Data.map((documentData) => {
          documentTypeOptions.push({ label: documentData.name, value: documentData.id })
        })
      }
      setDocumentTypeOptions(documentTypeOptions)
    }
  }, [identificationDocuments])

  const deleteDocumentHandler = async (id) => {
    const deletedApiRes = await deleteDocument({ Id: id, Email: auth.email })
    if (deletedApiRes?.Status) {
      const newProfileDocuments = profileDocuments.filter((item) => item.Id !== id)
      setProfileDocuments(newProfileDocuments)
    }
  }

  useEffect(() => {
    if (countries) {
    }
  }, [countries])

  return (
    <form>
      <PageWrapper>
        <ContainerFluid>
          <TableTitle title="My Profile" />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2">
                      {/* <div class="uploadPicContainer">
                        <input type="file" id="input-file-now-custom-1" class="dropify" data-default-file="../assets/images/users/user-4.jpg"/>
                    </div>    */}
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <input
                          hidden
                          type="file"
                          id="input-file-now-custom-1"
                          accept="image/*"
                          // capture="user"
                          // capture="filesystem"
                          name="Image"
                          ref={imgRef}
                          onChange={handleImageInputChange}
                        />

                        {/* {auth.userType} */}
                        <img
                          alt=""
                          style={{ objectFit: 'cover', width: 100, height: 100 }}
                          onClick={() => {
                            Swal.fire({
                              title: comp_profile('profile_picture'),
                              html: `<img style="width: 100%" height="300px" src="${profile.picture}"></img>`,
                            })
                          }}
                          src={profile.picture}
                          className="m-4 rounded-circle profile-pic"
                        />

                        {disableForm ? null : (
                          <button type="button" className="btn btn-success btn-round waves-effect waves-light mt-2 m-2" onClick={triggerFileInput}>
                            {comp_profile('change')}
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="col">
                      {auth.userType === USERTYPE.provider ? (
                        <div className="form-group row">
                          <label htmlFor="example-text-input" className="col-sm-2 col-form-label text-right">
                            {comp_profile('full_name')} <small className="text-danger font-13"> *</small>
                          </label>
                          <div className="col-sm-10">
                            <input
                              disabled={disableForm}
                              className={`form-control ${Boolean(ProviderProfileError.provider_name) ? 'is-invalid' : ''}`}
                              type="text"
                              name="provider_name"
                              value={profile.provider_name}
                              onChange={(e) => {
                                ProviderProfileSetValue('provider_name', e.target.value)
                                ProviderProfileTrigger('provider_name')
                                handleInputChange(e)
                              }}
                            />
                            {ProviderProfileError.provider_name && <label className="text-danger">{error_message(ProviderProfileError.provider_name.message)}</label>}
                          </div>
                        </div>
                      ) : auth.userType === USERTYPE.patient ? (
                        <>
                          <div className="form-group row">
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label text-right">
                              {comp_profile('first_name')}
                            </label>
                            <div className="col-sm-10">
                              <input
                                disabled={disableForm}
                                type="text"
                                name="first_name"
                                value={profile.first_name}
                                className={`form-control ${Boolean(PatientProfileError.FirstName) ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  PatientProfileSetValue('FirstName', e.target.value)
                                  PatientProfileTrigger('FirstName')
                                  handleInputChange(e)
                                }}
                              />
                              {PatientProfileError.FirstName && <label className="text-danger">{error_message(PatientProfileError.FirstName.message)}</label>}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label text-right">
                              {comp_profile('middle_name')}
                            </label>
                            <div className="col-sm-10">
                              <input
                                disabled={disableForm}
                                type="text"
                                name="middle_name"
                                value={profile.middle_name}
                                className={`form-control ${Boolean(PatientProfileError.MiddleName) ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  PatientProfileSetValue('MiddleName', e.target.value)
                                  PatientProfileTrigger('MiddleName')
                                  handleInputChange(e)
                                }}
                              />
                              {PatientProfileError.MiddleName && <label className="text-danger">{error_message(PatientProfileError.MiddleName.message)}</label>}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label text-right">
                              {comp_profile('last_name')}
                            </label>
                            <div className="col-sm-10">
                              <input
                                disabled={disableForm}
                                type="text"
                                name="last_name"
                                value={profile.last_name}
                                className={`form-control ${Boolean(PatientProfileError.LastName) ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  PatientProfileSetValue('LastName', e.target.value)
                                  PatientProfileTrigger('LastName')
                                  handleInputChange(e)
                                }}
                              />
                              {PatientProfileError.LastName && <label className="text-danger">{error_message(PatientProfileError.LastName.message)}</label>}
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="form-group row">
                        <label htmlFor="email-input" className="col-sm-2 col-form-label">
                          {comp_profile('email')}
                        </label>
                        <div className="col-sm-10">
                          <input
                            disabled={true}
                            className="form-control"
                            type="email"
                            name="email"
                            value={auth.email}
                          // onChange={handleInputChange.bind(this)}
                          />
                        </div>
                      </div>
                      {/* <div className="form-group row">
                        <label
                          htmlFor="example-tel-input"
                          className="col-sm-2 col-form-label text-right"
                        >
                          Facebook
                        </label>
                        <div className="col-sm-10">
                          <input
                            disabled={disableForm}
                            className="form-control"
                            type="tel"
                            name="facebook"
                            value={profile.facebook}
                            onChange={handleInputChange.bind(this)}
                          />
                        </div>
                      </div> */}
                      {auth.userType === USERTYPE.provider ? (
                        <>
                          <div className="form-group row">
                            <label htmlFor="example-tel-input" className="col-sm-2 col-form-label">
                              {comp_profile('about_me')}
                            </label>
                            <div className="col-sm-10">
                              <textarea
                                disabled={disableForm}
                                className="form-control"
                                type="tel"
                                rows="4"
                                name="provider_description"
                                value={profile.provider_description}
                                onChange={(e) => {
                                  ProviderProfileSetValue('provider_description', e.target.value)
                                  ProviderProfileTrigger('provider_description')
                                  handleInputChange(e)
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label htmlFor="example-tel-input" className="col-sm-2 col-form-label">
                              {comp_profile('specialization')}
                            </label>
                            <div className="col-sm-10">
                              <input
                                disabled={disableForm}
                                className="form-control"
                                type="tel"
                                name="practice"
                                value={profile.practice}
                                onChange={(e) => {
                                  ProviderProfileSetValue('practice', e.target.value)
                                  ProviderProfileTrigger('practice')
                                  handleInputChange(e)
                                }}
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="form-group row">
                        <label htmlFor="example-tel-input" className="col-sm-2 col-form-label">
                          {comp_profile('contact_info')}
                        </label>
                        <div className="col-sm-10">
                          <PhoneInput
                            disabled={disableForm}
                            name="contact_info"
                            required
                            value={profile.contact_info}
                            defaultCountry="US"
                            className={`form-control ${Boolean(PatientProfileError.ContactInfo) ? 'is-invalid' : ''}`}
                            onChange={(value) => {
                              setProfile((prev) => ({ ...prev, contact_info: value }))
                              PatientProfileSetValue('ContactInfo', value)
                              PatientProfileTrigger('ContactInfo')
                              ProviderProfileSetValue('ContactInfo', value)
                              ProviderProfileTrigger('ContactInfo')
                            }}
                          />

                          {PatientProfileError.ContactInfo && <label className="text-danger">{error_message(PatientProfileError.ContactInfo.message)}</label>}
                        </div>
                      </div>

                      {auth.userType === USERTYPE.patient ? (
                        <>
                          <div className="form-group row">
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                              {comp_profile('address_line_1')}
                            </label>
                            <div className="col-sm-10">
                              <input
                                disabled={disableForm}
                                type="text"
                                name="address_line_1"
                                value={profile.address_line_1}
                                className={`form-control ${Boolean(PatientProfileError.Address1) ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  PatientProfileSetValue('Address1', e.target.value)
                                  PatientProfileTrigger('Address1')
                                  handleInputChange(e)
                                }}
                              />
                              {PatientProfileError.Address1 && <label className="text-danger">{error_message(PatientProfileError.Address1.message)}</label>}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                              {comp_profile('address_line_2')}
                            </label>
                            <div className="col-sm-10">
                              <input
                                disabled={disableForm}
                                type="text"
                                name="address_line_2"
                                value={profile.address_line_2}
                                className={`form-control ${Boolean(PatientProfileError.Address2) ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  PatientProfileSetValue('Address2', e.target.value)
                                  PatientProfileTrigger('Address2')
                                  handleInputChange(e)
                                }}
                              />
                              {PatientProfileError.Address2 && <label className="text-danger">{error_message(PatientProfileError.Address2.message)}</label>}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label ">{comp_profile('country')}</label>
                            <div className="col-sm-4">
                              <select
                                disabled={disableForm}
                                name="country_id"
                                value={profile.country_id}
                                className={`form-control ${Boolean(PatientProfileError.CountryID) ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  PatientProfileSetValue('CountryID', e.target.value)
                                  PatientProfileTrigger('CountryID')
                                  handleInputChange(e)
                                }}
                              >
                                <option value={''}>Select</option>
                                {countries.map((country, index) => (
                                  <option value={country.country_id} key={index}>
                                    {country.description}
                                  </option>
                                ))}
                              </select>
                              {PatientProfileError.CountryID && <label className="text-danger">{error_message(PatientProfileError.CountryID.message)}</label>}
                            </div>
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                              {comp_profile('state_province')}
                            </label>
                            <div className="col-sm-4">
                              {countryProvinceList.length > 0 ? (
                                <select
                                  disabled={disableForm}
                                  name="province"
                                  value={profile.province}
                                  className={`form-control ${Boolean(PatientProfileError.Province) ? 'is-invalid' : ''}`}
                                  onChange={(e) => {
                                    PatientProfileSetValue('Province', e.target.value)
                                    PatientProfileTrigger('Province')
                                    handleInputChange(e)
                                  }}
                                >
                                  <option value={''}>Select</option>
                                  {countryProvinceList.map((province, index) => (
                                    <option value={province.province} key={index}>
                                      {province.province}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <input
                                  disabled={disableForm}
                                  type="text"
                                  name="province"
                                  value={profile.province}
                                  className={`form-control ${Boolean(PatientProfileError.Province) ? 'is-invalid' : ''}`}
                                  onChange={(e) => {
                                    PatientProfileSetValue('Province', e.target.value)
                                    PatientProfileTrigger('Province')
                                    handleInputChange(e)
                                  }}
                                />
                              )}
                              {PatientProfileError.Province && <label className="text-danger">{error_message(PatientProfileError.Province.message)}</label>}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">{comp_profile('city')}</label>
                            <div className="col-sm-4">
                              <input
                                disabled={disableForm}
                                type="text"
                                name="city"
                                value={profile.city}
                                className={`form-control ${Boolean(PatientProfileError.City) ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  PatientProfileSetValue('City', e.target.value)
                                  PatientProfileTrigger('City')
                                  handleInputChange(e)
                                }}
                              />
                              {PatientProfileError.City && <label className="text-danger">{error_message(PatientProfileError.City.message)}</label>}
                            </div>
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                              {comp_profile('zip_code')}
                            </label>
                            <div className="col-sm-4">
                              <input
                                disabled={disableForm}
                                type="text"
                                name="zip_code"
                                value={profile.zip_code}
                                className={`form-control ${Boolean(PatientProfileError.ZIPCode) ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  PatientProfileSetValue('ZIPCode', e.target.value)
                                  PatientProfileTrigger('ZIPCode')
                                  handleInputChange(e)
                                }}
                              />
                              {PatientProfileError.ZIPCode && <label className="text-danger">{error_message(PatientProfileError.ZIPCode.message)}</label>}
                            </div>
                          </div>
                        </>
                      ) : null}
                      {auth.userType === USERTYPE.patient ? (
                        <>
                          <div className="form-group row">
                            <label htmlFor="example-date-input" className="col-sm-2 col-form-label text-right">
                              {comp_profile('local_timezone')}
                            </label>
                            <div className="col-sm-4">
                              <TimeZoneSelect
                                required={true}
                                value={profile.local_time_zone}
                                className={`form-control ${Boolean(PatientProfileError.LocalTimeZone) ? 'is-invalid' : ''}`}
                                setTimeZone={(selected) => {
                                  setProfile((prev) => ({
                                    ...prev,
                                    local_time_zone: selected || '',
                                  }))
                                  PatientProfileSetValue('LocalTimeZone', selected)
                                  PatientProfileTrigger('LocalTimeZone')
                                }}
                                disabled={disableForm}
                              />
                              {PatientProfileError.LocalTimeZone && <label className="text-danger">{error_message(PatientProfileError.LocalTimeZone.message)}</label>}
                            </div>

                            <label htmlFor="example-date-input" className="col-sm-2 col-form-label text-right">
                              {comp_profile('date_of_birth')}
                            </label>
                            <div className="col-sm-4">
                              <input
                                disabled={disableForm}
                                type="date"
                                required={true}
                                placeholder={'mm-dd-yyyy'}
                                name="date_of_birth"
                                // defaultValue={dateFormat(profile.date_of_birth)}
                                value={dateFormat(profile?.date_of_birth)}
                                className={`form-control ${Boolean(PatientProfileError.DateOfBirth) ? 'is-invalid' : ''}`}
                                onChangeCapture={(e) => {
                                  PatientProfileSetValue('DateOfBirth', e.target.value)
                                  PatientProfileTrigger('DateOfBirth')
                                  handleInputChange(e)
                                }}
                              />
                              {PatientProfileError.DateOfBirth && <label className="text-danger">{error_message(PatientProfileError.DateOfBirth.message)}</label>}
                            </div>
                          </div>

                          {/* <div className="form-group row">
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                              {comp_profile('language')}
                            </label>
                            <div className="col-sm-4">
                              <select
                                disabled={disableForm}
                                name="language"
                                value={profile.language}
                                className={`form-control ${Boolean(PatientProfileError.Language) ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  PatientProfileSetValue('Language', e.target.value)
                                  PatientProfileTrigger('Language')
                                  handleInputChange(e)
                                }}
                              >
                                {languageOptions.map((option) => (
                                  <option value={option.value} key={option.value}>
                                    <img src={option.flagIcon} alt={option.label} className="flag-icon" /> {option.label}
                                  </option>
                                ))}
                              </select>
                              {PatientProfileError.Language && <label className="text-danger">{error_message(PatientProfileError.Language.message)}</label>}
                            </div>
                          </div> */}
                          <div className="form-group row">
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                              {comp_profile('language')}
                            </label>
                            <div className="col-sm-4">
                              <LanguageDropdown
                                isDisabled={disableForm}
                                value={profile.language}
                                onChange={handleLanguageChange}
                                error={Boolean(PatientProfileError.Language)} // Replace with your error logic
                              />
                              {PatientProfileError.Language && <label className="text-danger">{error_message(PatientProfileError.Language.message)}</label>}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="col-lg-12">
                          <div className="form-group row">
                            {/* <div className='row'> */}
                            <div className="col-md-3">
                              <h5>{comp_profile('sunday')}</h5>
                              <ScheduleSelect hours={hours} setHours={setHours} weekday="Sun" disabled={disableForm} oldHours={oldHours} />
                            </div>
                            <div className="col-md-3">
                              <h5>{comp_profile('monday')}</h5>
                              <ScheduleSelect hours={hours} setHours={setHours} weekday="Mon" disabled={disableForm} oldHours={oldHours} />
                            </div>
                            <div className="col-md-3">
                              <h5>{comp_profile('tuesday')}</h5>
                              <ScheduleSelect hours={hours} setHours={setHours} weekday="Tue" disabled={disableForm} oldHours={oldHours} />
                            </div>
                            <div className="col-md-3">
                              <h5>{comp_profile('wednesday')}</h5>
                              <ScheduleSelect hours={hours} setHours={setHours} weekday="Wed" disabled={disableForm} oldHours={oldHours} />
                            </div>

                            {/* <div className='row'> */}

                            <div className="col-md-3">
                              <h5>{comp_profile('thursday')}</h5>
                              <ScheduleSelect hours={hours} setHours={setHours} weekday="Thu" disabled={disableForm} oldHours={oldHours} />
                            </div>
                            <div className="col-md-3">
                              <h5>{comp_profile('friday')}</h5>
                              <ScheduleSelect hours={hours} setHours={setHours} weekday="Fri" disabled={disableForm} oldHours={oldHours} />
                            </div>
                            <div className="col-md-3">
                              <h5>{comp_profile('saturday')}</h5>
                              <ScheduleSelect hours={hours} setHours={setHours} weekday="Sat" disabled={disableForm} oldHours={oldHours} />
                            </div>
                            <div>{/* </div> */}</div>
                            {/* </div> */}
                          </div>
                        </div>
                      )}

                      {auth.userType === USERTYPE.patient && (
                        <div className="form-group row">
                          <div className="col-sm-12 text-center mb-3">
                            <button type="button" onClick={addImageBox} className="btn btn-success btn-round waves-effect waves-light mb-2" disabled={disableForm}>
                              {comp_profile('add_id_document_button')}
                            </button>
                            {!disableForm && idUploaded == false && <p className="text-danger">Please upload at least one id document</p>}
                          </div>

                          {profileDocuments &&
                            profileDocuments.map((document, index) => (
                              <div key={index} className="id-container card col-md-3">
                                <div className="document-type-dropdown">
                                  <label>Document Type : </label>
                                  <label className="image-close-icon float-right">
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-round waves-effect waves-light image-close-icon"
                                      onClick={() => {
                                        deleteDocumentHandler(document.Id)
                                      }}
                                    >
                                      <FaTimes />
                                    </button>
                                  </label>
                                  <br />
                                  <div className="mb-2">{document.DocumentTypeName}</div>
                                </div>

                                <div className="">
                                  <div className="image-preview mb-2">
                                    <img className="id-image" src={`${document.FileName}`} alt={`${document.FileName}`} />
                                  </div>
                                </div>
                              </div>
                            ))}

                          {images.map((_, index) => (
                            <div key={index} className="id-container card col-md-3">
                              <div className="document-type-dropdown">
                                <label>Document Type : </label>
                                <label className="image-close-icon float-right">
                                  <button type="button" onClick={() => removeImage(index)} className="btn btn-danger btn-round waves-effect waves-light image-close-icon">
                                    <FaTimes />
                                  </button>
                                </label>
                                <Controller
                                  name={`documentTypes[${index}]`}
                                  control={PatientControl}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <select {...field} className="form-control mb-2">
                                      <option value="" disabled>
                                        {comp_profile('select_document_type')}
                                      </option>
                                      {documentTypeOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                />
                              </div>

                              <div className="">
                                <div className="image-preview mb-2" onClick={() => openImageViewer(index)}>
                                  {images[index] && typeof images[index] !== 'string' && <img className="id-image" src={URL.createObjectURL(images[index])} alt={`Image ${index}`} />}
                                </div>
                                <input
                                  type="file"
                                  onChange={(e) => {
                                    const selectedFile = e.target.files[0]
                                    setImages((prevImages) => {
                                      const updatedImages = [...prevImages]
                                      updatedImages[index] = selectedFile
                                      PatientProfileSetValue('images', updatedImages.length)
                                      PatientProfileTrigger('images')
                                      return updatedImages
                                    })
                                  }}
                                  className="mb-2"
                                />
                              </div>
                            </div>
                          ))}
                          {isViewerOpen && <ImageViewer src={images.map((img) => URL.createObjectURL(img))} currentIndex={currentImage} onClose={closeImageViewer} />}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col text-right">
                      {!disableForm ? (
                        <button
                          style={{ marginRight: '10px' }}
                          onClick={handleSubmit}
                          type="button"
                          className="btn btn-success btn-round waves-effect waves-light"
                          disabled={profile === oldProfile && hours === oldHours}
                        >
                          {comp_profile('submit_button')}
                        </button>
                      ) : null}

                      {disableForm ? (
                        <button style={{ marginRight: '10px' }} onClick={() => setDisableForm((prev) => !prev)} type="button" className="btn btn-success btn-round waves-effect waves-light">
                          {comp_profile('edit_profile_button')}
                        </button>
                      ) : null}

                      {!disableForm ? (
                        <button type="button" className="btn btn-danger btn-round waves-effect waves-light" onClick={handleCancelEdit}>
                          {comp_profile('cancel_button')}
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContainerFluid>
      </PageWrapper>
    </form>
  )
}

export default ProfileEdit
