import Footer from 'components/Footer'
import React, { useEffect, useState } from 'react'

import moment from 'moment'
import CardItem, { CardLongItem } from 'components/cards/Card'
import RingLoading from 'components/lottie/RingLoading'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import 'animate.css'
import { useTranslation } from 'react-i18next'
import { useLazyGetAppointmentsQuery } from 'v2/src/lib/services/nHAppointmentApi'
import Pagination from 'react-js-pagination'
import useAuth from 'hooks/useAuth'
import { capitalizeFirstLetter } from 'v2/src/lib/strUtils'
import { useDeleteAppointmentMutation } from 'v2/src/lib/services/nHAppointmentApi'
import StarSVGIcon from 'v2/src/components/icons/StarSVGIcon'

function dateTimeFormat(date) {
  return moment(date).format('MMM DD, YYYY, hh:mm:ss A UTC Z')
}
const timenow = moment()

function formatLongtxt(string = '') {
  if (string?.length > 40) {
    return string.substring(0, 40) + '...'
  }
  return string
}

const CancelButton = ({ visit_id, refreshList, setRefreshList }) => {
  const { t: patient_appointment } = useTranslation('patient_appointment')
  const [deleteAppointment] = useDeleteAppointmentMutation()

  return (
    <div className="list-inline mb-0 align-self-center">
      <button
        type="button"
        className="btn btn-outline-danger btn-round waves-effect waves-light"
        onClick={() => {
          Swal.fire({
            showCancelButton: true,
            confirmButtonText: patient_appointment('yes'),
            cancelButtonText: patient_appointment('no'),
            showClass: {
              popup: 'animate__animated animate__fadeIn animate__faster',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOut  animate__faster',
            },
            html: `
              <div class='col'>
              ${patient_appointment('cancel_visit_confirm')}
                </div>
              `,
          }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
              try {
                await deleteAppointment({ appointmentId: visit_id }).unwrap()
                Swal.fire(patient_appointment('appointment_cancelled_success')).then(() => {
                  setRefreshList(!refreshList)
                })
              } catch (err) {
                console.error(err)
                Swal.fire('Error cancelling appointment')
              }
            }
          })
        }}
      >
        {patient_appointment('cancel_visit')}
      </button>
    </div>
  )
}

function StartButton({ joinAppointment, appointmentDetail }) {
  const { t: patient_appointment } = useTranslation('patient_appointment')
  return (
    <div className="list-inline mb-0 align-self-center">
      <button type="button" className="btn btn-gradient-success btn-round waves-effect waves-light" onClick={() => joinAppointment(appointmentDetail)}>
        {patient_appointment('join_virtual_visit')}
      </button>
    </div>
  )
}

const StatusIcon = ({ icontype }) => {
  const StatusColor = {
    0: 'text-purple',
    2: 'text-danger',
    3: 'text-danger',
    1: 'text-success',
    4: 'text-success',
    5: 'text-success',
    6: 'text-success',
  }
  return (
    <div className="task-priority-icon">
      <i className={`fas fa-circle ${StatusColor[icontype]}`}></i>
    </div>
  )
}

const StatusText = ({ status, labelBgColor, labelColor }) => {
  const { t: patient_appointment } = useTranslation('patient_appointment')
  return (
    <span className="virtualvisitbadge badge badge-md ml-0" style={{ color: labelColor, backgroundColor: labelBgColor }}>
      {patient_appointment(status)}
    </span>
  )
}

function HMFormat(minutes) {
  var hours = Math.floor(minutes / 60)
  var days = Math.floor(hours / 24)

  var min = parseInt(minutes % 60)

  let dig = ''
  if (min < 10) {
    dig = '0'
  }
  if (hours > 24) {
    hours = hours - days * 24
    return days + ' days'
  } else if (min > 60) {
    return hours + ' hrs and ' + dig + min + ' mins'
  } else {
    return days + ' days, ' + hours + ' hrs and ' + dig + min + ' mins'
  }
}

const AppointmentAction = ({ status, visit_id, appointmentTime, image, provider_name, provider_description, joinAppointment, refreshList, setRefreshList, appointmentDetail, innerActiveTab }) => {
  const { t: patient_appointment } = useTranslation('patient_appointment')
  const appointmentPeriod = [moment(appointmentTime), moment(appointmentTime).add(1, 'hours')]

  const appointmentETA = HMFormat(moment(appointmentTime).diff(moment(), 'minutes', true))

  if (innerActiveTab === 'upcoming') {
    if (appointmentDetail?.enable_meet_button) {
      return <StartButton joinAppointment={joinAppointment} appointmentDetail={appointmentDetail} />
    } else {
      return (
        <div className="col-md-12 ">
          <CancelButton visit_id={visit_id} refreshList={refreshList} setRefreshList={setRefreshList} />
        </div>
      )
    }
  } else if (innerActiveTab === 'past') {
    return <></>
  } else {
    return null
  }
}

function AppointmentItem({
  provider_description,
  provider_name,
  provider_id,
  image,
  service_description,
  service_name,
  service_id,
  trans_start,
  trans_date_time,
  visit_id,
  visit_type,
  status,
  joinAppointment,
  refreshList,
  setRefreshList,
  appointmentDetail,
  innerActiveTab,
  labelBgColor,
  labelColor,
}) {
  const { t: patient_appointment } = useTranslation('patient_appointment')
  const dateTime = trans_start
  const date = moment(trans_start).format('MMM DD, YYYY')
  const time = moment(trans_start).format('hh:mm A')

  return (
    <div className="card" id={service_id}>
      <div className="card-body">
        <div className="task-box row" key={visit_id}>
          <div className="task-priority-icon">
            <StatusIcon icontype={status} />
          </div>
          <div className="media col-lg-12">
            <div className="media col-md-8">
              <img src={image} alt="user" className="rounded-circle thumb-md" />
              <div className="media-body align-self-center ml-3">
                {patient_appointment('appointment_with')}:<p className="font-16 font-weight-bold mb-0 ">{provider_name}</p>
                <StatusText status={status} labelBgColor={labelBgColor} labelColor={labelColor} />
                <p className="mb-0 font-12 text-muted">{patient_appointment('provider')}</p>
              </div>
            </div>
            <div className="col-lg-4 text-right w-100">
              <div className="text-muted">
                <b>{date}</b>
                <br />
                <i className="far fa-fw fa-clock"></i>
                {time}
              </div>
            </div>
          </div>
          <div className="row m-2 bg-light w-100">
            <div className="col-md-5 m-3">
              <div className="d-flex mt-0 mb-0 virtDesc">
                <span style={{ minWidth: '100px' }}>{patient_appointment('service_title')} :</span>
                <span>
                  <strong>{service_name === '' ? patient_appointment('untitled') : service_name}</strong>
                </span>
              </div>
              <div className="d-flex mt-0 mb-0 virtDesc">
                <span style={{ minWidth: '100px' }}>{patient_appointment('symptoms')} :</span>
                <span>
                  <strong>{formatLongtxt(appointmentDetail?.symptoms)}</strong>
                </span>
              </div>
              <div className="d-flex mt-0 mb-0 virtDesc">
                <span style={{ minWidth: '100px' }}>{patient_appointment('visit_type')} :</span>
                <span>
                  <strong>{patient_appointment(visit_type === 'telehealth' ? 'telehealth' : 'clinic')}</strong>
                </span>
              </div>
            </div>
            {innerActiveTab === 'past' && (
              <div className="col-md-6 m-3">
                <div className="d-flex mt-0 mb-0 virtDesc">
                  <span style={{ minWidth: '90px' }}> {patient_appointment('ratings')} :</span>
                  <span>
                    <strong>
                      {+appointmentDetail?.feedback?.rating > 0
                        ? Array.from({ length: 5 }, (_, index) => (index < +appointmentDetail?.feedback?.rating ? <StarSVGIcon key={index} /> : null))
                        : patient_appointment('no_rating_available')}
                    </strong>
                  </span>
                </div>
                <div className="d-flex mt-0 mb-0 virtDesc">
                  <span style={{ minWidth: '90px' }}>{patient_appointment('feedback')} :</span>
                  <span>
                    <strong>{formatLongtxt(appointmentDetail?.feedback?.feedback) || patient_appointment('no_feedback_available')}</strong>
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-12 text-right m-2">
            <AppointmentAction
              status={status}
              visit_id={visit_id}
              appointmentTime={dateTime}
              image={image}
              provider_name={provider_name}
              provider_description={provider_description}
              joinAppointment={joinAppointment}
              refreshList={refreshList}
              setRefreshList={setRefreshList}
              appointmentDetail={appointmentDetail}
              innerActiveTab={innerActiveTab}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function Appointment() {
  const { t: patient_appointment } = useTranslation('patient_appointment')
  const [upcomingAppointments, setUpcomingAppointments] = useState([])
  const [pastAppointments, setPastAppointments] = useState([])
  const [upcomingCurrentPage, setUpcomingCurrentPage] = useState(1)
  const [pastCurrentPage, setPastCurrentPage] = useState(1)
  const [upcomingTotalPages, setUpcomingTotalPages] = useState(0)
  const [pastTotalPages, setPastTotalPages] = useState(0)
  const [innerActiveTab, setInnerActiveTab] = useState('upcoming')
  const [isLoading, setIsLoading] = useState(false)
  const [stIsPageLoading, setStIsPageLoading] = useState(true)
  const navigate = useNavigate()
  const [refreshList, setRefreshList] = useState(false)

  const [getUpcomingAppointments, { data: upcomingAppointmentListData, isError: upcomingAppointmentListDataError }] = useLazyGetAppointmentsQuery()
  const [getPastAppointments, { data: pastAppointmentListData, isError: pastAppointmentListDataError }] = useLazyGetAppointmentsQuery()

  const getAppointment = () => {
    getUpcomingAppointments({
      current_page: upcomingCurrentPage,
      sort_order: 'asc',
      status: ['scheduled', 'rescheduled', 'started', 'in_progress'],
    })
  }

  const getPastAppointment = () => {
    getPastAppointments({
      current_page: pastCurrentPage,
      sort_order: 'desc',
      status: ['completed', 'missed', 'cancelled'],
    })
  }
  useEffect(() => {
    getAppointment()
    getPastAppointment()
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      getAppointment()
    }, 5000)
    return () => clearInterval(intervalId)
  }, [upcomingCurrentPage, refreshList])

  useEffect(() => {
    if (upcomingAppointmentListData) {
      setStIsPageLoading(false)
      setUpcomingAppointments(upcomingAppointmentListData.data)
      setUpcomingTotalPages(upcomingAppointmentListData.meta.last_page)
    }
    if (upcomingAppointmentListDataError) {
      console.error('upcomingAppointmentListDataError', upcomingAppointmentListDataError)
      setStIsPageLoading(false)
    }
  }, [upcomingAppointmentListData, upcomingAppointmentListDataError])

  useEffect(() => {
    const intarvalId = setInterval(() => {
      getPastAppointment()
    }, 5000)
    return () => clearInterval(intarvalId)
  }, [pastCurrentPage, refreshList])

  useEffect(() => {
    if (pastAppointmentListData) {
      setStIsPageLoading(false)
      setPastAppointments(pastAppointmentListData.data)
      setPastTotalPages(pastAppointmentListData.meta.last_page)
    }
    if (pastAppointmentListDataError) {
      console.error('pastAppointmentListDataError', pastAppointmentListDataError)
      setStIsPageLoading(false)
    }
  }, [pastAppointmentListData, pastAppointmentListDataError])

  async function joinAppointment(appointment) {
    setIsLoading(false)
    navigate('/virtualvisit/waitingroom', {
      state: {
        MeetingID: appointment?.zoom_meeting_id,
        Password: appointment?.zoom_meeting_password,
        AppointmentID: appointment?.id,
        startTime: appointment?.start_time,
      },
    })
  }

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <h4 className="page-title">{patient_appointment('my_appointments')}</h4>
              </div>
            </div>
          </div>

          <div className="card mb-1">
            <div className="card-body">
              <ul className="nav nav-pills mb-0" id="appointmentTabs" role="tablist" style={{ display: 'flex' }}>
                <li className="nav-item" style={{ flex: '1' }}>
                  <a
                    href="#!"
                    className={`nav-link text-center ${innerActiveTab === 'past' ? 'active' : ''}`}
                    onClick={(e) => {
                      e.preventDefault()
                      setInnerActiveTab('past')
                    }}
                    style={{
                      width: '100%',
                      backgroundColor: innerActiveTab === 'past' ? '#28a745' : '#f6f6f6',
                      color: innerActiveTab === 'past' ? 'white' : 'black',
                      borderRadius: '0',
                      cursor: 'pointer',
                    }}
                  >
                    {patient_appointment('past_appointments')}
                  </a>
                </li>
                <li className="nav-item" style={{ flex: '1' }}>
                  <a
                    href="#!"
                    className={`nav-link text-center ${innerActiveTab === 'upcoming' ? 'active' : ''}`}
                    onClick={(e) => {
                      e.preventDefault()
                      setInnerActiveTab('upcoming')
                    }}
                    style={{
                      width: '100%',
                      backgroundColor: innerActiveTab === 'upcoming' ? '#28a745' : '#f6f6f6',
                      color: innerActiveTab === 'upcoming' ? 'white' : 'black',
                      borderRadius: '0',
                      cursor: 'pointer',
                    }}
                  >
                    {patient_appointment('upcoming_appointments')}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="tab-content" id="appointmentTabsContent">
            {stIsPageLoading ? (
              <RingLoading size={100} />
            ) : innerActiveTab === 'upcoming' ? (
              upcomingAppointments.length > 0 ? (
                <div className="row">
                  <div className="col-lg-12">
                    {upcomingAppointments.map((appointment, index) => (
                      <AppointmentItem
                        provider_description={appointment?.provider?.about_me}
                        provider_name={`${appointment?.provider?.profile?.user?.first_name} ${appointment?.provider?.profile?.user?.last_name}`}
                        provider_id={appointment?.provider_id}
                        image={appointment?.provider?.profile?.profile_pic_url}
                        service_description={appointment?.service?.description}
                        service_name={appointment?.service?.name}
                        service_id={appointment?.service_id}
                        trans_start={appointment?.start_time}
                        trans_date_time={appointment?.end_time}
                        visit_id={appointment?.id}
                        visit_type={appointment?.service?.booking_availability}
                        status={appointment?.status}
                        joinAppointment={joinAppointment}
                        key={index}
                        refreshList={refreshList}
                        setRefreshList={setRefreshList}
                        appointmentDetail={appointment}
                        innerActiveTab={innerActiveTab}
                        labelBgColor={appointment?.label_background_color}
                        labelColor={appointment?.label_text_color}
                      />
                    ))}
                    <div className="justify-content-center d-flex" style={{ alignItems: 'center', flexDirection: 'column' }}>
                      <Pagination
                        activePage={upcomingCurrentPage}
                        itemsCountPerPage={10}
                        totalItemsCount={upcomingTotalPages * 10}
                        pageRangeDisplayed={5}
                        onChange={(pageNumber) => {
                          setUpcomingCurrentPage(pageNumber)
                        }}
                        itemClass="page-item"
                        linkClass="page-link float-center"
                      />
                      <div className="row-lg-12 ">Page {upcomingCurrentPage}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <CardLongItem>{patient_appointment('no_upcoming_appointments')}</CardLongItem>
              )
            ) : innerActiveTab === 'past' ? (
              pastAppointments.length > 0 ? (
                <div className="row">
                  <div className="col-lg-12">
                    {pastAppointments.map((appointment, index) => (
                      <AppointmentItem
                        provider_description={appointment?.provider?.about_me}
                        provider_name={`${appointment?.provider?.profile?.user?.first_name} ${appointment?.provider?.profile?.user?.last_name}`}
                        provider_id={appointment?.provider_id}
                        image={appointment?.provider?.profile?.profile_pic_url}
                        service_description={appointment?.service?.description}
                        service_name={appointment?.service?.name}
                        service_id={appointment?.service_id}
                        trans_start={appointment?.start_time}
                        trans_date_time={appointment?.end_time}
                        visit_id={appointment?.id}
                        visit_type={appointment?.service?.booking_availability}
                        status={appointment?.status}
                        joinAppointment={joinAppointment}
                        key={index}
                        refreshList={refreshList}
                        setRefreshList={setRefreshList}
                        appointmentDetail={appointment}
                        innerActiveTab={innerActiveTab}
                        labelBgColor={appointment?.label_background_color}
                        labelColor={appointment?.label_text_color}
                      />
                    ))}
                    <div className="justify-content-center d-flex" style={{ alignItems: 'center', flexDirection: 'column' }}>
                      <Pagination
                        activePage={pastCurrentPage}
                        itemsCountPerPage={10}
                        totalItemsCount={pastTotalPages * 10}
                        pageRangeDisplayed={5}
                        onChange={(pageNumber) => {
                          setPastCurrentPage(pageNumber)
                        }}
                        itemClass="page-item"
                        linkClass="page-link float-center"
                      />
                      <div className="row-lg-12 ">Page {pastCurrentPage}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <CardLongItem>{patient_appointment('no_past_appointments')}</CardLongItem>
              )
            ) : null}
          </div>
        </div>

        <Footer />
      </div>
    </div>
  )
}

export default Appointment
