import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CharOK, CharErr } from 'v2/src/config/uiConfig';
import { fCheckPassCondition } from 'v2/src/lib/appUtils';
//---------------------------------------------------------

export default function PasswordValidationList({ password, errors }) {
  const { t: authentication } = useTranslation('authentication');
  const [hasLowercase, setHasLowercase] = useState(CharErr);
  const [hasUppercase, setHasUppercase] = useState(CharErr);
  const [hasNumber, setHasNumber] = useState(CharErr);
  const [has8chars, setHas8chars] = useState(CharErr);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(CharErr);
  const [hasSpaceOnEnd, setHasSpaceOnEnd] = useState(CharErr);

  useEffect(() => {
    setHasLowercase(fCheckPassCondition(password?.match(/[a-z]/)?.length > 0))
    setHasUppercase(fCheckPassCondition(password?.match(/[A-Z]/)?.length > 0))
    setHasNumber(fCheckPassCondition(password?.match(/[0-9]/)?.length > 0))
    setHas8chars(fCheckPassCondition(password?.length >= 8))
    setHasSpecialCharacter(fCheckPassCondition(password?.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/)?.length > 0))
    setHasSpaceOnEnd(fCheckPassCondition(!(password?.charAt(0) === ' ' || password?.charAt(password?.length - 1) === ' ')))
  }, [password]);

  return (
    password?.length > 0 && (
      (() => {
        switch (true) {
          case (hasLowercase !== CharOK):
            return (
              <div className="text-danger">
                {authentication('password_must_contain_lowercase')}
              </div>
            );

          case (hasUppercase !== CharOK):
            return (
              <div className="text-danger">
                {authentication('password_must_contain_uppercase')}
              </div>
            );

          case (hasNumber !== CharOK):
            return (
              <div className="text-danger">
                {authentication('password_must_contain_number')}
              </div>
            );

          case (hasSpecialCharacter !== CharOK):
            return (
              <div className="text-danger">
                {authentication('password_must_contain_special_character')}
              </div>
            );

          case (hasSpaceOnEnd !== CharOK):
            return (
              <div className="text-danger">
                {authentication('password_must_not_contain_space_at_end')}
              </div>
            );

          case (has8chars !== CharOK):
            return (
              <div className="text-danger">
                {authentication('password_must_contain_8_characters')}
              </div>
            );

          default:
            return null;
        }
      })()
    )
  )
};