//dateTimeUtils
import moment from "moment";
//---------------------------------------------------------

export const fGetCurrentTimezone = () => {
  const timezone = moment.tz.guess();
  return timezone === 'Asia/Calcutta' ? 'Asia/Kolkata' : timezone;
};

export const fGetArrTimeZonesMoment = (filterCountryCode = null) => {
  let resp;
  let timezonecountries = moment.tz.countries();
  let timezoneoptions = [];
  if (filterCountryCode && filterCountryCode.length) {
    let pushobject = moment.tz.zonesForCountry(filterCountryCode, true);
    pushobject.push({
      "name": "Pacific/Rarotonga",
      "offset": 600
    });
    timezoneoptions = [...timezoneoptions, ...pushobject]
  } else {
    for (var i in timezonecountries) {
      let pushobject = moment.tz.zonesForCountry(timezonecountries[i], true)[0]
      timezoneoptions.push(pushobject)
    }
  }
  let sorted_timezoneoptions = timezoneoptions.sort((a, b) => (moment(a.offset) > moment(b.offset) ? 1 : -1))

  let tzOptions = []
  for (var i in sorted_timezoneoptions) {
    let alreadyinlist = false
    for (var j in sorted_timezoneoptions) {
      if (sorted_timezoneoptions[i] === sorted_timezoneoptions[j]) {
        alreadyinlist = true
      }
    }
    if (!alreadyinlist) {
      tzOptions.push(sorted_timezoneoptions[i])
    }
  }

  // Find the "Pacific/Rarotonga" timezone option and replace it with "Hawaii"
  resp = sorted_timezoneoptions.map((timezone) => {
    if (timezone.name === 'Pacific/Rarotonga') {
      return {
        ...timezone,
        name: 'Hawaii',
      }
    }
    return timezone
  })

  return resp;
};

export const fGetHMFormat2 = (minutes) => {
  let offsetoperator = '';
  if (minutes > 0) { offsetoperator = '+'; }
  else if (minutes < 0) {
    offsetoperator = '-';
    minutes = minutes * -1;
  }
  const hours = Math.floor(minutes / 60);
  // Getting the minutes.
  const min = minutes % 60;
  let dig = '';
  if (min < 10) { dig = '0'; }
  return offsetoperator + hours + ':' + dig + min;
};

export const fFormateDate = (strDate) => {
  //return moment(strDate).format('YYYY-MM-DD')
  //const objDate = new Date(strDate);
  strDate = (new Date(strDate)).toISOString();
  //strDate += 'T01:10:00.000Z';
  //return moment(strDate).format('MMM-DD-YYYY');
  return moment(strDate).format('MMM DD, YYYY');
};


