import * as yup from 'yup';

const dummyDOB = '1920-01-01';

export const ProfileFormSchema = (error_message) => {
  return yup.object({
    FirstName: yup
      .string()
      .required(error_message('required_error'))
      .min(2, error_message('name_min_length_error'))
      .max(50, error_message('name_max_length_error'))
      .matches(/^[A-Za-z\s]+$/, error_message('name_format_error')),

    LastName: yup
      .string()
      .required(error_message('required_error'))
      .min(2, error_message('name_min_length_error'))
      .max(50, error_message('name_max_length_error'))
      .matches(/^[A-Za-z\s]+$/, error_message('name_format_error')),

    contactNum: yup.string()
      .required(error_message('required_error'))
      .test('isValidPhoneNumber', error_message('mobile_error'), value => {
        const cleanedValue = value.replace(/[^\d+]/g, '');
        const regex = /^\+1\d{1,11}$/;
        return regex.test(cleanedValue);
      }),

    Address1: yup.string().required(error_message('required_error')).max(255, error_message('address_max_length_error')),
    CountryID: yup.string().required(error_message('required_error')),
    Province: yup.string().required(error_message('required_error')),
    City: yup.string().required(error_message('required_error')),
    ZIPCode: yup
      .string()
      .required(error_message('required_error'))
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, error_message('zipcode_error')),

    LocalTimeZone: yup.string().required(error_message('required_error')),

    DateOfBirth: yup
      .string()
      .required(error_message('required_error'))
      .test('is-not-dummy-date', error_message('required_error'), value => {
        return value !== dummyDOB;
      })
      .test('is-not-in-future', error_message('no_future_dob'), function (value) {
        const selectedDate = new Date(value);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        selectedDate.setHours(0, 0, 0, 0);
        return selectedDate <= currentDate;
      })
      .test('is-at-least-18-years-old', error_message('min_18_years_old'), function (value) {
        const selectedDate = new Date(value);
        const currentDate = new Date();
        let age = currentDate.getFullYear() - selectedDate.getFullYear();
        const m = currentDate.getMonth() - selectedDate.getMonth();
        if (m < 0 || (m === 0 && currentDate.getDate() < selectedDate.getDate())) {
          age--;
        }
        return age >= 18;
      }),

  })
    .required();
}

export default ProfileFormSchema;

