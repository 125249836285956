import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Rating } from 'react-simple-star-rating'
import CardItem, { CardLongItem } from '../../../components/cards/Card'
import Footer from '../../../components/Footer'
import { AWS_BUCKET_SERVICES } from '../../../constants'
import Multiselect from 'multiselect-react-dropdown'
import Pagination from 'react-js-pagination'
import styled from '@emotion/styled'
import RingLoading from '../../../components/lottie/RingLoading'
import useGetServiceCategories from '../../../hooks/api/useGetServiceCategories'
import useGetServices from '../../../hooks/api/useGetServicesList'
import { generateMarketplaceFilterTags } from '../../../utils'
import FilterTag from '../../../components/filterTag'
import { useTranslation } from 'react-i18next'

export const StyleWrapper = styled.div`
  .optionListContainer {
    position: sticky;
  }
`
export default function Marketplace(props) {
  const navigate = useNavigate()
  const { t: patient_marketplace } = useTranslation('patient_marketplace')

  const defPage = 1
  const defSearchString = ''
  const defSelectedCategories = {}
  const defSelectedCategoriesOptions = []
  const defPriceRangeMin = 0
  const defPriceRangeMax = 2000
  const defStarFilter = [5, 4, 3, 2, 1, 0]
  const showComingSoon = true

  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([])

  const [searchString, setSearchString] = useState('')

  const [showFilterWindow, setShowFilterWindow] = useState(false)
  const [activeFilter, setActiveFilter] = useState('')
  const [categoryOptions, setCategoryOptions] = useState([])
  const [selectedCategoriesOptions, setSelectedCategoriesOptions] = useState([])
  const [selectedCategories, setSelectedCategories] = useState({})

  const priceRangeRef = useRef()
  const [priceRangeMin, setPriceRangeMin] = useState(defPriceRangeMin)
  const [priceRangeMax, setPriceRangeMax] = useState(defPriceRangeMax)

  const [starFilter, setStarFilter] = useState(defStarFilter)

  const [filterTags, setFilterTags] = useState()

  /*Get the list of categories and show it in the dropdown*/
  const { data: serviceCategories, getServiceCategories } = useGetServiceCategories()

  //console.log('mm', activeFilter);
  useEffect(() => {
    ; (async function () {
      getServiceCategories()
    })();

    if (typeof window !== 'undefined') {
      //console.log('DDD');
      //const arrCtnPopupIds = ['pills-tabContent1', 'pills-tabContent3', 'pills-tabContent3',
      //'service_category_tab', 'price_range_tab', 'payment_history_detail_tab'
      //];
      //const arrXclude = ['service_category_tab', 'price_range_tab', 'payment_history_detail_tab'];
      //const arrXclude = ['service_category', 'price_range', 'payment_history_detail'];
      //const arrXclude = ['service_category', 'price_range', 'ratings'];
      document.onclick = (e) => {
        //activeFilter
        const fEl1 = document.getElementById('service_category');
        const fEl2 = document.getElementById('price_range');
        const fEl3 = document.getElementById('ratings');
        const fEl11 = document.getElementById('service_category_tab');
        const fEl21 = document.getElementById('price_range_tab');
        const fEl31 = document.getElementById('payment_history_detail_tab');
        if (e?.target && fEl1 && fEl11 && fEl2 && fEl21 && fEl3 && fEl31 &&
          e.target !== fEl1 && !fEl1.contains(e.target) && e.target !== fEl11 &&
          e.target !== fEl2 && !fEl2.contains(e.target) && e.target !== fEl21 &&
          e.target !== fEl3 && !fEl3.contains(e.target) && e.target !== fEl31
        ) {//console.log('Hell0', {activeFilter, showFilterWindow});
          //showFilterWindow && setShowFilterWindow(false);
          //activeFilter && setActiveFilter('');
          setActiveFilter('');
          setShowFilterWindow(false);
          fEl11.classList.remove('active');
          fEl21.classList.remove('active');
          fEl31.classList.remove('active');
        }
        /*arrXclude.forEach((elPUpId) => {
          const pNode = document.getElementById(elPUpId);
          if(pNode) {
            if(e.target !== pNode && !pNode.contains(e.target)) {
            //if(arrXclude.includes()e.target !== pNode && !pNode.contains(e.target)) {
              //console.log('Damn', pNode);
              showFilterWindow && setShowFilterWindow(false);
            }
          }
        });*/
      };
      /*window.onload = () => {
        //document.onclick = (e) => { console.log('SSS', e?.target?.id);}

        arrCtnPopupIds.forEach((elPUpId)=> {
          const elPUp = document.getElementById(elPUpId); console.log('EE', elPUp);
          if(elPUp) {//console.log('AA', elPUp.target.id);
            document.onclick = (e) => {
              if(e.target.id !== elPUpId) {
                //setShowFilterWindow(false);
              }
            };
          }
        });
      };*/
      /*document.addEventListener('mouseup', function(e) {
          const elPUp = document.getElementById(elPUpID);
          if(!elPUp.contains(e.target)) {
              //elPUp.style.display = 'none';
          }
      }); */
    }
  }, []);

  useEffect(() => {
    if (serviceCategories && serviceCategories.Data) {
      let categoryOptions = []
      serviceCategories.Data.map((item, index) => {
        categoryOptions.push({
          id: item.id,
          name: item.name,
        })
      })
      setCategoryOptions(categoryOptions)
    }
  }, [serviceCategories])

  /*Get the list of services and show it in the list below the filter*/
  const [page, setPage] = useState(defPage)
  let defPaginationData = {
    defaultPage: 0,
    activePage: 0,
    totalRecords: 0,
    recordsPerPage: 0,
    totalPages: 0,
  }
  const [servicePaginationData, setServicePaginationData] = useState(defPaginationData)
  const { data: serviceList, getServiceList } = useGetServices()

  useEffect(() => {
    if (page) {
      getServiceList(page, searchString, selectedCategories, priceRangeMin, priceRangeMax, starFilter)
    }
  }, [page])

  useEffect(() => {
    if (serviceList && serviceList.Status) {
      const serviceListData = serviceList.Data
      if (serviceListData) {
        setList(serviceListData)

        const servicePagination = serviceList.Pagination
        setServicePaginationData({
          defaultPage: 1,
          activePage: parseInt(page),
          totalRecords: parseInt(servicePagination.total_records),
          recordsPerPage: parseInt(servicePagination.records_per_page),
          totalPages: parseInt(servicePagination.total_pages),
        })
      } else {
        setList([])
        setServicePaginationData(defPaginationData)
      }
      setIsLoading(false)
    }
  }, [serviceList])

  const controller = new AbortController()
  function formatLongtxt(string = '', limit = 50) {
    if (string?.length > limit) {
      return string.substring(0, limit) + '...'
    }
    return string
  }

  useEffect(() => {
    if (priceRangeRef.current) {
      window.$('#range_doctors_rate').ionRangeSlider({
        type: 'double',
        skin: 'modern',
        grid: true,
        min: 0,
        max: 1000,
        from: 20,
        to: 200,
      })
    }

    // getList()
    return () => {
      controller.abort()
    }
  }, [])
  async function handleSubmit(event) {
    setIsLoading(true)
    event.preventDefault()
    setPage(defPage)

    getServiceList(defPage, searchString, selectedCategories, priceRangeMin, priceRangeMax, starFilter)
    setFilterTags(generateMarketplaceFilterTags(searchString, selectedCategories, priceRangeMin, priceRangeMax, starFilter))
  }

  const clearFilter = () => {
    setIsLoading(true)
    setSearchString(defSearchString)
    setSelectedCategories(defSelectedCategories)
    setSelectedCategoriesOptions(defSelectedCategoriesOptions)
    setPriceRangeMin(defPriceRangeMin)
    setPriceRangeMax(defPriceRangeMax)
    setStarFilter(defStarFilter)
    getServiceList(page, defSearchString, defSelectedCategories, defPriceRangeMin, defPriceRangeMax, defStarFilter)
    setFilterTags(generateMarketplaceFilterTags('', {}, '', '', []))
  }

  function resetSearch() {
    setSearchString(defSearchString)
    getServiceList(defPage, defSearchString, selectedCategories, priceRangeMin, priceRangeMax, starFilter)
    setFilterTags(generateMarketplaceFilterTags(defSearchString, selectedCategories, priceRangeMin, priceRangeMax, starFilter))
  }

  function resetCategories() {
    setSelectedCategories(defSelectedCategories)
    getServiceList(defPage, searchString, defSelectedCategories, priceRangeMin, priceRangeMax, starFilter)
    setFilterTags(generateMarketplaceFilterTags(searchString, defSelectedCategories, priceRangeMin, priceRangeMax, starFilter))
  }

  function resetPriceRange() {
    setPriceRangeMin(defPriceRangeMin)
    setPriceRangeMax(defPriceRangeMax)
    getServiceList(defPage, searchString, selectedCategories, defPriceRangeMin, defPriceRangeMax, starFilter)
    setFilterTags(generateMarketplaceFilterTags(searchString, selectedCategories, defPriceRangeMin, defPriceRangeMax, starFilter))
  }

  function resetRatings() {
    setStarFilter(defStarFilter)
    getServiceList(defPage, searchString, selectedCategories, priceRangeMin, priceRangeMax, defStarFilter)
    setFilterTags(generateMarketplaceFilterTags(searchString, selectedCategories, priceRangeMin, priceRangeMax, defStarFilter))
  }
  const servBoxCss = {
    minWidth: '200px',
    height: '550px',
    height: '550px',
    height: '550px',
  };
  //---------------------------------------------------------

  return (
    <div className="figma mt-5">
      <div className="page-wrapper">
        <div className="page-content">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="page-title">{patient_marketplace('marketplace')}</h4>
                </div>
              </div>
            </div>

            <div className="row-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="col-lg-12">
                    <h5 className="mt-1 ">{patient_marketplace('filters')}</h5>
                    <form onSubmit={handleSubmit}>
                      <ul className="nav nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item col-xl-5 mt-2 mb-0" style={{ paddingRight: '2px' }}>
                          <div className="row-xl-12" style={{ width: '95%' }}>
                            <div className="form-group">
                              <div className="input-group">
                                <input type="text" size="10"
                                  className="form-control"
                                  placeholder={patient_marketplace('search_service')}
                                  aria-label={patient_marketplace('search_service')}
                                  onChange={(e) => setSearchString(e.target.value)}
                                  value={searchString || ''}
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item m-1 mt-2">
                          {true ? (
                            <span className="input-group-append">
                              <button className="btn btn-success" type="submit">
                                <i className="fas fa-search"></i>
                              </button>
                            </span>
                          ) : (
                            <span className="input-group-append">
                              <button className="btn btn-success" style={{ zIndex: 0 }} type="submit">
                                {isLoading && searchString >= 3 ? patient_marketplace('searching') : patient_marketplace('search')}
                              </button>
                            </span>
                          )}
                        </li>
                        <li className="nav-item xm-1 mt-2">
                          <a
                            className="nav-link"
                            id="service_category_tab"
                            data-toggle="pill"
                            href="#service_category"
                            onClick={(e) => {
                              // setShowFilterWindow(!showFilterWindow)

                              setActiveFilter('service_category')
                              if (activeFilter === 'service_category') {
                                setShowFilterWindow(!showFilterWindow)
                              } else if (activeFilter !== 'service_category') {
                                setShowFilterWindow(true)
                              }
                              // else {
                              //   setShowFilterWindow(false)

                              // }
                            }}
                          >
                            {patient_marketplace('service_category')}
                          </a>
                          <div className="tab-content detail-list position-absolute" id="pills-tabContent1">
                            <div className="tab-pane position-absolute" style={{ zIndex: 4 }} id="service_category">
                              {showFilterWindow && activeFilter === 'service_category' ? (
                                <CardItem>
                                  <div className="p-3" style={{ width: 400 }}>
                                    <h6 className=" mt-0">{patient_marketplace('service_categories')}</h6>
                                    <StyleWrapper>
                                      <Multiselect
                                        style={{ zIndex: 3 }}
                                        options={categoryOptions}
                                        selectedValues={selectedCategoriesOptions}
                                        onSelect={(selectedList, selectedItem) => {
                                          setSelectedCategoriesOptions(selectedList)
                                          setSelectedCategories((prevState) => ({
                                            ...prevState,
                                            [selectedItem.id]: selectedItem,
                                          }))
                                        }}
                                        onRemove={(selectedList, selectedItem) => {
                                          setSelectedCategoriesOptions(selectedList)
                                          let selectedLocalCategories = { ...selectedCategories }
                                          delete selectedLocalCategories[selectedItem.id]
                                          setSelectedCategories(selectedLocalCategories)
                                        }}
                                        showCheckbox={true}
                                        displayValue="name"
                                      />
                                    </StyleWrapper>
                                  </div>
                                </CardItem>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </li>
                        <li className="nav-item xm-1 mt-2">
                          <a
                            className="nav-link"
                            id="price_range_tab"
                            data-toggle="pill"
                            href="#price_range"
                            onClick={(e) => {
                              // setShowFilterWindow(!showFilterWindow)
                              setActiveFilter('price_range')
                              if (activeFilter === 'price_range') {
                                setShowFilterWindow(!showFilterWindow)
                              } else if (activeFilter !== 'price_range') {
                                setShowFilterWindow(true)
                              }
                              // else {
                              //   setShowFilterWindow(false)

                              // }
                            }}
                          >
                            {patient_marketplace('price_range')}
                          </a>
                          <div className="tab-content detail-list position-absolute" id="pills-tabContent2">
                            <div className="tab-pane position-absolute" style={{ zIndex: 3 }} id="price_range">
                              {showFilterWindow && activeFilter === 'price_range' ? (
                                <CardItem>
                                  <div className="p-3">
                                    {/* <h6 className=" mt-0">Price Range</h6> */}
                                    <div className="m-1">
                                      <h6 className=" mb-0">{patient_marketplace('minimum')}</h6>
                                      <input
                                        ref={priceRangeRef}
                                        type="number"
                                        step={10}
                                        value={priceRangeMin}
                                        // id="range_doctors_rate"
                                        onChange={(e) => {
                                          setPriceRangeMin(e.target.value)
                                        }}
                                      />
                                    </div>
                                    <div className="m-1">
                                      <h6 className=" mb-0">{patient_marketplace('maximum')}</h6>

                                      <input
                                        ref={priceRangeRef}
                                        type="number"
                                        step={10}
                                        // id="range_doctors_rate"
                                        value={priceRangeMax}
                                        onChange={(e) => {
                                          setPriceRangeMax(e.target.value)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </CardItem>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </li>
                        <li className="nav-item xm-1 mt-2">
                          <a
                            className="nav-link"
                            id="payment_history_detail_tab"
                            data-toggle="pill"
                            href="#ratings"
                            onClick={(e) => {
                              // setShowFilterWindow(!showFilterWindow)
                              setActiveFilter('ratings')
                              if (activeFilter === 'ratings') {
                                setShowFilterWindow(!showFilterWindow)
                              } else if (activeFilter !== 'ratings') {
                                setShowFilterWindow(true)
                              }
                              // else {
                              //   setShowFilterWindow(false)

                              // }
                            }}
                          >
                            {patient_marketplace('ratings')}
                          </a>
                          <div className="tab-content detail-list position-absolute" id="pills-tabContent3">
                            <div className="tab-pane position-absolute " style={{ zIndex: 3 }} id="ratings">
                              {showFilterWindow && activeFilter === 'ratings' ? (
                                <CardItem>
                                  <div className="p-3">
                                    <h6 className="mt-0 ">{patient_marketplace('ratings')}</h6>

                                    {[5, 4, 3, 2, 1, 0].map((val, index) => (
                                      <div key={val} className="checkbox checkbox-success" style={{ width: 140 }}>
                                        <input
                                          id={`checkboxa${val}`}
                                          type="checkbox"
                                          defaultChecked={starFilter[index] === val}
                                          onChange={(e) => {
                                            var newstarfilter = starFilter
                                            var checked = false
                                            if (newstarfilter[index] === val) {
                                              newstarfilter[index] = false
                                            } else {
                                              newstarfilter[index] = parseInt(val)
                                              checked = true
                                            }
                                            setStarFilter(newstarfilter)
                                          }}
                                        />
                                        <label htmlFor={`checkboxa${val}`}>
                                          {val === 0 ? 'Unrated' : val}
                                          {Array.apply(null, { length: val }).map((e, i) => (
                                            <i key={i} className="mdi mdi-star text-warning"></i>
                                          ))}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </CardItem>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </li>
                        <li className="nav-item xm-1 mt-2 ml-1">
                          <span className="input-group-append">
                            <button type="button" className="btn btn-danger"
                              style={{ zIndex: 0 }} onClick={clearFilter}
                              alt={patient_marketplace('clear_filter')}
                              title={patient_marketplace('clear_filter')}
                            >
                              {patient_marketplace('tx_clear')}
                            </button>
                          </span>
                        </li>
                      </ul>
                    </form>
                    <div className="filter-tags-container">
                      {filterTags?.anyFilterApplied && <span>{patient_marketplace('search')}:</span>}
                      {filterTags?.search && <FilterTag text={filterTags.search} onRemove={resetSearch} />}
                      {filterTags?.categories && <FilterTag text={`Category: ${filterTags?.categories}`} onRemove={resetCategories} />}
                      {filterTags?.priceRange && <FilterTag text={`Price Range: ${filterTags?.priceRange}`} onRemove={resetPriceRange} />}
                      {filterTags?.ratings && <FilterTag text={`Ratings: ${filterTags?.ratings}`} onRemove={resetRatings} />}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="row">
                  {isLoading ? (
                    <CardLongItem>
                      <div className="d-flex justify-content-center">
                        <RingLoading size={200} />
                      </div>
                    </CardLongItem>
                  ) : searchString.length > 0 && list.length === 0 ? (
                    <div className="row" style={{ justifyContent: 'center', width: '100%' }}>
                      <CardItem>{patient_marketplace('no_results')}</CardItem>
                    </div>
                  ) : (
                    <>
                      {list.map((item, index) => (
                        <div key={index} className="col-xl-3">
                          <div className="card e-co-product" style={servBoxCss}>
                            {/* {AWS_BUCKET_SERVICES+ item.images} */}
                            <Link to={'/patient/marketplace/booking/' + item.service_id} state={{ ...item }}>
                              <img
                                src={item.images}
                                alt=""
                                //width="100%" height="100%" title="Niu Health"
                                // style={{width:'200px', height:'200px',objectFit: 'cover'}}
                                style={{
                                  // width: 'unset',
                                  maxWidth: '200px',
                                  minHeight: '200px',
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                }}
                                className="img-fluid"
                              />
                            </Link>
                            <div className="card-body product-info">
                              <Link to={'/patient/marketplace/booking/' + item.service_id} className="product-title" state={{ ...item }}>
                                <div className="text-title" style={{ marginBottom: 0 }}>
                                  <h4>{item.service_name}</h4>
                                </div>
                              </Link>
                              <br />
                              <div className="row-lg-3">
                                {/* <b>{patient_marketplace('description')} : </b>
                                <br />
                                {formatLongtxt(item.service_description, 160)}
                                <br />
                                <b>{patient_marketplace('category')} :</b>
                                <br /> {item.category}
                                <br /> */}
                              </div>
                              <br />
                              <h4 className="met-user-name">{item.provider_name}</h4>
                              <br />
                              <div className="text-muted" style={{ marginTop: -20 }}>
                                {patient_marketplace('provider')}
                              </div>

                              <div className="d-flex justify-content-between my-2 row">
                                {/* {item?.cost_price > 1 ? (
                                  <p className="product-price m-2">${item.cost_price}</p>
                                ) : item?.provider_id === '62gv3150511lx' ? (
                                  <p className="product-price m-2">{patient_marketplace('free_for_members')}</p>
                                ) : (
                                  <p className="product-price m-2">{patient_marketplace('contact_for_pricing')}</p>
                                )} */}
                                <div className="row product-review align-self-center">
                                  <div className="col-md-10 m-3">
                                    {item.average_ratings === 0 ? (
                                      <>
                                        {/* {patient_marketplace('unrated')} */}
                                      </>
                                    ) : (
                                      <Rating
                                        fillColor="#ffb822"
                                        emptyColor="white"
                                        SVGstrokeColor="#f1a545"
                                        SVGstorkeWidth={1}
                                        size={17}
                                        allowFraction={true}
                                        initialValue={item.average_ratings}
                                        readonly={true}
                                      />
                                    )}
                                    {/* ({item.average_ratings}) */}
                                  </div>
                                </div>
                              </div>
                              {showComingSoon ? (
                                <div className='xtBdr2' style={{
                                  //position: 'relative',
                                  position: 'absolute',
                                  bottom: '20px',
                                  width: '82%',
                                  textAlign: 'center',
                                }}>
                                  <button className="btn btn-info w-100 shadow-none" disabled>
                                    {patient_marketplace('coming_soon')}
                                  </button>
                                </div>
                              ) : (
                                <div className="d-flex align-items-end justify-content-between my-2 row">
                                  <button
                                    onClick={() => {
                                      navigate('/patient/marketplace/booking/' + item.service_id, { state: { ...item } });
                                    }}
                                    className="btn btn-success"
                                  >
                                    {patient_marketplace('book_appointment')}
                                  </button>
                                  <button
                                    onClick={() => {
                                      navigate('/patient/marketplace/provider/' + item?.provider_id);
                                    }}
                                    className="btn btn-outline-success"
                                  >
                                    {patient_marketplace('view_profile')}
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="row d-flex justify-content-center">
                  {servicePaginationData.defaultPage > 0 && (
                    <Pagination
                      activePage={servicePaginationData.activePage}
                      itemsCountPerPage={servicePaginationData.recordsPerPage}
                      totalItemsCount={servicePaginationData.totalRecords}
                      pageRangeDisplayed={servicePaginationData.totalPages}
                      // onPageChange={}
                      itemclassName="page-item "
                      linkClass="page-link float-center"
                      onChange={(e) => {
                        setIsLoading(true)
                        setPage(e)
                        const element = document.getElementById('main-container')
                        if (element) {
                          element.scrollIntoView({ behavior: 'smooth' })
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  )
}
