import { useEffect } from 'react'
import TableCard from '../../../../components/table/Tables'
import useGetLanguageList from '../../../../hooks/api/useGetLanguageList'
import { AWS_BUCKET_SERVICES } from '../../../../constants'
import { NavLink } from 'react-router-dom'
import useDeleteLanguage from '../../../../hooks/api/useDeleteLanguage'
import useAuth from '../../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'

const Languages = (props) => {
  const { auth } = useAuth()
  const { t: provider_languages } = useTranslation('provider_languages')

  const { data: languageList, getLanguageList, loading: languageListLoading } = useGetLanguageList()
  const { data: deleteLanguageData, deleteLanguage, loading: deleteLanguageLoading } = useDeleteLanguage()

  /*Get the list of languages*/
  useEffect(() => {
    getLanguageList({ Email: auth.email || Cookies.get('email') })
  }, [])

  /*Delete a languages*/
  const deleteLanguageHandler = (langId) => {
    Swal.fire({
      title: `${provider_languages('delete_language')}`,
      text: `${provider_languages('confirm_delete_language')}`,
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `${provider_languages('delete')}`,
      cancelButtonText: `${provider_languages('cancel')}`,
    }).then((result) => {
      if (result.value) {
        deleteLanguage({ apiParams: { Email: auth.email || Cookies.get('email') }, langId })
      }
    })
  }

  useEffect(() => {
    if (deleteLanguageData.Status) {
      getLanguageList({ Email: auth.email || Cookies.get('email') })
    }
  }, [deleteLanguageData.Status])

  return (
    <div className="container-fluid pt-5">
      <div className="tab-content detail-list" id="pills-tabContent">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist">
                  <li className="nav-item">
                    <NavLink to="/provider/translations" className="nav-link">
                      {provider_languages('translations')}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active" id="languages_tab" data-toggle="pill" href="#languages">
                      {provider_languages('languages')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-pane fade show active" id="languages">
          <div className="row">
            <div className="col-md-12 mb-3">
              <NavLink to={'add'} className="btn btn-success waves-effect waves-light float-right pl-5 pr-5">
                {provider_languages('add')}
              </NavLink>
            </div>
            <TableCard
              headers={[
                provider_languages('name'),
                provider_languages('abbreviation'),
                provider_languages('flag'),
                provider_languages('is_active'),
                provider_languages('sort_order'),
                provider_languages('action'),
              ]}
            >
              {languageListLoading && <h5>{provider_languages('loading')}</h5>}

              {languageList.Status &&
                languageList['Data'].map((language) => (
                  <tr key={language.Id}>
                    <td>{language.Name}</td>
                    <td>{language.LanguageCodeIetf}</td>
                    <td>{language.FlagIcon && <img src={AWS_BUCKET_SERVICES + 'assets/country_flags/' + language.FlagIcon} alt={language.Name} style={{ height: '25px' }} />}</td>
                    <td>{language.IsActive == 1 ? provider_languages('yes') : provider_languages('no')}</td>
                    <td>{language.SortOrder}</td>
                    <td>
                      <NavLink to={`edit/${language.Id}`} title={provider_languages('edit_language')} className="mr-5">
                        <i className="fa fa-edit"></i>
                      </NavLink>

                      <a href="#" onClick={() => deleteLanguageHandler(language.Id)} title={provider_languages('delete_language')}>
                        <i className="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                ))}

              {!languageListLoading && !languageList.Status && <h5>{provider_languages('something_went_wrong')}</h5>}
            </TableCard>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Languages
