import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Footer from "components/Footer";
import { useTranslation } from "react-i18next";
import { APP_URL } from "constants";
import { useLazyGetAppointmentDetailsQuery } from "v2/src/lib/services/nHAppointmentApi";
import { fPlayNotificationSound } from "v2/src/lib/appUtils";

export default function WaitingRoomN() {
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const myVideo = useRef("video1");
  const { t: patient_virtual_visit } = useTranslation("patient_virtual_visit");

  // const [videoLinks, setVideoLinks] = useState([APP_URL + '/videos/Braddah%202%20Braddah%20_NIU%20HEALTH_%20Theme%20Song.mp4'])
  const videoLinks = [
    APP_URL + "/videos/drt_video_default.mp4",
    APP_URL + "/videos/drt_video_1.mp4",
    APP_URL + "/videos/drt_video_2.mp4",
    APP_URL + "/videos/drt_video_3.mp4",
    APP_URL + "/videos/drt_video_4.mp4",
  ];

  const randomindex = Math.floor(Math.random() * videoLinks.length);
  const [currentVideo, setCurrentVideo] = useState(videoLinks[0]);
  const [videoIndex, setVideoIndex] = useState(0);
  const { state } = useLocation();

  var meetingNumber = state?.MeetingID;
  var appointmentId = state?.AppointmentID;
  var startTime = state?.startTime;
  var passWord = state?.Password;
  //----------------
  const [getAppointmentDetails, { data: getAppointmentStatusData }] =
    useLazyGetAppointmentDetailsQuery();

  useEffect(() => {
    // Fetch appointment status initially

    getAppointmentDetails({ appointmentId });

    const intervalId = setInterval(() => {
      getAppointmentDetails({ appointmentId });
      if (getAppointmentStatusData?.data?.status === "started") {
        clearInterval(intervalId);
        setIsReady(true);
      } else {
        setIsReady(false);
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [getAppointmentStatusData]);

  function playNextVideo() {
    setVideoIndex(randomindex);
    const next_src = videoLinks[randomindex];
    setCurrentVideo(next_src);
    myVideo.current.src = next_src;
    myVideo.current.play();
  }

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);
  useEffect(() => {
    Swal.fire({
      html: `${patient_virtual_visit("waiting_room")}`,
    }).then(() => {
      myVideo.current.play();
    });
  }, []);

  useEffect(() => {
    if (isReady) {
      Swal.fire({
        // icon: "success",
        title: `${patient_virtual_visit("doctor_ready")}`,
        html: `${patient_virtual_visit(
          "redirecting_in"
        )} <span id="timer">5</span> ${patient_virtual_visit("seconds")}`,
        confirmButtonText: `${patient_virtual_visit("virtual_visit_button")}`,
        showCancelButton: true,
        cancelButtonText: `${patient_virtual_visit("cancel_visit")}`,
        didRender: () => {
          let timer;
          const timeoutDuration = 5000; // 5 seconds in milliseconds

          const executeCommands = () => {
            navigate("../room", {
              state: {
                MeetingID: meetingNumber,
                Password: passWord,
                AppointmentID: appointmentId,
                startTime: startTime,
              },
            });
          };

          const closePopup = () => {
            Swal.close();
          };

          const updateTimerDisplay = (remainingTime) => {
            document.getElementById("timer").textContent = remainingTime;
          };

          const startTimer = () => {
            let remainingTime = timeoutDuration / 1000;
            updateTimerDisplay(remainingTime);

            timer = setInterval(() => {
              myVideo.current.pause();
              fPlayNotificationSound();
              remainingTime--;
              updateTimerDisplay(remainingTime);

              if (remainingTime <= 0) {
                clearInterval(timer);
                closePopup();
                executeCommands();
              }
            }, 1000);
          };

          const stopTimer = () => {
            clearInterval(timer);
          };

          startTimer();

          Swal.getConfirmButton().addEventListener("click", () => {
            stopTimer();
            closePopup();
            executeCommands();
          });

          // Stop the timer and close the popup if the cancel button is clicked
          Swal.getCancelButton().addEventListener("click", () => {
            stopTimer();
            closePopup();
          });
        },
      });
    }
  }, [isReady]);

  return (
    <div className="figma">
      <div className="page-wrapper">
        <div className="page-content">
          <div className="container-fluid">
            {/* <div className='row'>
              <div className='col-sm-12'>
                <div className='page-title-box'>
                  <h4 className='page-title'></h4>
                </div>
              </div>
            </div> */}

            <div className="row ">
              <div className="col-lg-6">
                {/* <iframe
                  width="100%"
                  height="500"
                  src="https://www.youtube.com/embed/oVAJZMVpL_g"
                  title="YouTube video player"
                  // frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                >

                </iframe> */}

                <>
                  <video
                    width="100%"
                    height="auto"
                    playsInline
                    className="pt-4"
                    // controls={true}
                    name={"video1"}
                    ref={myVideo}
                    // muted
                    type="video/mp4"
                    onEnded={() => {
                      playNextVideo();
                    }}
                  // playsinline={true}
                  >
                    {/* <source type="video/webm" src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4"></source> */}

                    <source type="video/mp4" src={currentVideo}></source>
                  </video>
                  {/* <div className='d-flex' style={{justifyContent: "space-around"}}>
                        <button className='btn btn-outline-success' onClick={()=>{playPreviousVideo()}}>Previous Video</button>
                        <button className='btn btn-outline-success' onClick={()=>{playNextVideo()}}>Next Video</button>
                      </div> */}
                </>

                {/* <source src="movie.mp4" type="video/mp4">
                  <source src="movie.ogg" type="video/ogg"> */}
                {/* Your browser does not support the video tag. */}
                {/* </video> */}
              </div>

              <div className="col-lg-6">
                {/* <div className="card">
                  <div className="card-body"> */}
                <h3 style={{ paddingTop: "20px" }}>
                  {patient_virtual_visit("thanks_for_your_patience")}
                  <br />
                  {patient_virtual_visit("your_provider_will_soon_be_with_you")}
                </h3>
                <div className="steps_title_sub_text">
                  {patient_virtual_visit("keep_this_window_open_msg")}
                </div>
                <div className="steps_info_text">
                  {/* <i className='dripicons-user-group green_h'></i> There are{" "}
                      <span className='green_h'>12</span> patients ahead of you */}
                </div>
                <div className="steps_info_text" style={{ fontSize: 16 }}>
                  {/* <i className='mdi mdi-av-timer green_h'></i> Estimated
                      waiting time is <span className='green_h'>5:20</span> mins */}
                </div>
                {/* <div className="steps_info_text">
                  {patient_virtual_visit('there_are')} <strong>{queueCount}</strong> {patient_virtual_visit('people_in_the_queue')}
                </div> */}
                <div className="d-flex justify-content-center m-2 p-0 w-100">
                  <div
                    className="wizard_btn"
                  // style={{ margin: '20px 0'}}
                  >
                    <div className="row">
                      <button
                        type="button"
                        className="btn btn-success btn-round waves-effect waves-light figmaBigButton m-1"
                        onClick={() =>
                          navigate("../room", {
                            state: {
                              MeetingID: meetingNumber,
                              Password: passWord,
                              AppointmentID: appointmentId,
                              startTime: startTime,
                            },
                          })
                        }
                        style={{
                          cursor: isReady ? "pointer" : "not-allowed",
                        }}
                        disabled={!isReady}
                      >
                        {isReady
                          ? patient_virtual_visit("join_virtual_visit_now")
                          : patient_virtual_visit("please_wait")}
                      </button>

                      <button
                        onClick={() => navigate("/patient")}
                        type="button"
                        className="btn btn-outline-danger btn-round waves-effect waves-light figmaBigButton m-1"
                      >
                        {patient_virtual_visit("cancel_visit")}
                      </button>
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
