import * as yup from 'yup';


export const ForgotPasswordSchema = (error_message) => {
  return yup
    .object({
      Email: yup.string().email(error_message('email_error')).required(error_message('required_error')).max(255, 'email_max_length_error').min(6, error_message('email_min_length_error')),
    })
    .required();
}
export default ForgotPasswordSchema;
