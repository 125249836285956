import { nHApi } from 'v2/src/lib/services/public/nHApi';

export const nHAppointmentApi = nHApi.injectEndpoints({
  endpoints: builder => ({
    getAppointments: builder.query({
      query: ({ current_page, start_date, end_date, status, sort_order, per_page }) => {
        const params = new URLSearchParams();
        if (current_page) params.append('page', current_page);
        if (start_date) params.append('start_date', start_date);
        if (end_date) params.append('end_date', end_date);
        if (sort_order) params.append('sort_order', sort_order);
        if (per_page) params.append('per_page', per_page);
        if (status && status.length > 0) {
          status.forEach(s => params.append('status[]', s));
        }
        return {
          url: `/appointments?${params.toString()}`,
        };
      },
      providesTags: ['Appointments'],
    }),
    getAppointmentDetails: builder.query({
      query: ({ appointmentId }) => {
        return {
          url: `/appointment/${appointmentId}`
        }
      },
    }),
    postAppointment: builder.mutation({
      query: (params) => {
        return {
          url: '/appointment',
          method: 'POST',
          body: params,
        }
      },
      invalidatesTags: ['Appointments'],
    }),
    getSignature: builder.mutation({
      query: (params) => {
        return {
          url: 'appointment/generate-zoom-signature',
          method: 'POST',
          body: params
        }
      }
    }),
    updateAppointment: builder.mutation({
      query: (params) => {
        return {
          url: `/appointment/${params.appointmentId}`,
          method: 'POST',
          body: params,
        }
      }
    }),
    deleteAppointment: builder.mutation({
      query: ({ appointmentId }) => ({
        url: `/appointment/${appointmentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Appointments'],
    }),
  }),
});

export const {
  useLazyGetAppointmentsQuery,
  useLazyGetAppointmentDetailsQuery,
  usePostAppointmentMutation,
  useGetSignatureMutation,
  useUpdateAppointmentMutation,
  useDeleteAppointmentMutation
} = nHAppointmentApi;
