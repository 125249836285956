import React, { useEffect, useRef, useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Footer from '../../../components/Footer'
import useAuth from '../../../hooks/useAuth'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { yupResolver } from '@hookform/resolvers/yup'
import SubscriptionPaymentSchema from '../../../validation/subscriptionPayment'
import { getProvinceFromCountryId } from '../../../utils'
import useGetValidateCoupon from '../../../hooks/api/useGetValidateCoupon'
import Cookies from 'js-cookie'

export default function PaymentForm() {
  const { state: selectedSubscription } = useLocation()
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const cardRef = useRef()
  const [countries, setCountries] = useState([])

  const {
    register,
    handleSubmit,
    trigger,
    setError,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    resolver: yupResolver(SubscriptionPaymentSchema),
  })
  const [Country] = watch(['Country'])

  const [countryProvinceList, setCountryProvinceList] = useState([])
  const [apiProvinceList, setApiProvinceList] = useState([])

  const [show, setShow] = useState(false)
  const [couponMsg, setCouponMsg] = useState(undefined)

  const handleClose = () => setShow(false)

  const { validateCoupon, loading: couponValidationLoading } = useGetValidateCoupon()

  const onSubmit = async (data) => {
    try {
      const ExpMonth = data?.expiry.split('/')[0].trim()
      const ExpYear = data?.expiry.split('/')[1].trim()

      const billing = {
        ...data,
        CardNumber: data.number,
        ExpMonth: ExpMonth,
        Expyear: ExpYear,
        CVC: data?.cvc,
        Amount: selectedSubscription.amount,
        Description: selectedSubscription.actionDescription,
        Email: auth.email,
        SubscriptionPlan: selectedSubscription.subscription_plan,
        PaymentType: 3,
      }

      await axiosPrivate
        .post('subscription/payment', billing)
        .then((res) => {
          const { Status, Message } = res.data
          if (Status) {
            setShow(false)
            Swal.fire({
              // icon: 'info',
              html: 'Please wait while we confirm your payment...',
              allowOutsideClick: false,
              showConfirmButton: false,
            })
            setTimeout(() => {
              Swal.close()
              Cookies.set('has_subscribed', true)
              navigate('/patient/subscription', { replace: true })
            }, 5000)
          } else {
            Swal.fire(Message)
          }
        })
        .catch((err) => {
          console.error(err)
          Swal.fire(err.message)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const hanldePayNow = async () => {
    await trigger().then(() => {
      if (isValid) setShow(true)
    })
  }

  useEffect(() => {
    if (cardRef.current) {
      new window.Card({
        form: document.querySelector('.bill-form'),
        container: '.card-wrapper',
      })
    }
  }, [])

  function dateFormat(date) {
    return moment(date).format('YYYY-MM-DD') // moment can initialize passing empty params
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    async function getProfileDetails() {
      await axiosPrivate
        .post(
          'getPatientDetails',
          { Email: auth.email },
          {
            signal: controller.signal,
          }
        )
        .then((res) => {
          const { Status, Data: data = [], Message } = res.data

          if (Status) {
            setProfile(res.data.Data[0])
          } else {
            throw new Error(Message)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
    async function getCountries() {
      await axiosPrivate
        .post(
          'getCountries',
          { Email: 'patient1@gmail.com' },
          {
            signal: controller.signal,
          }
        )
        .then((res) => {
          const { Status, Data: data = [], Message } = res.data

          if (Status) {
            setCountries(res.data.Data)
          } else {
            throw new Error(Message)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
    async function getProvince() {
      await axiosPrivate
        .post(
          'getProvince',
          { Email: auth.email },
          {
            signal: controller.signal,
          }
        )
        .then((res) => {
          const { Status, Data: data = [], Message } = res.data

          if (Status) {
            isMounted && setApiProvinceList(data)
          } else {
            throw new Error(Message)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
    getProfileDetails()
    getCountries()
    getProvince()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if (Country != '0' && apiProvinceList.length > 0) {
      const countryProvince = getProvinceFromCountryId(apiProvinceList, Country)
      setCountryProvinceList(countryProvince)
      if (countryProvince.length == 0) {
        setValue('Province', '')
      }
    }
  }, [Country, apiProvinceList])

  const applyCoupon = async (e) => {
    const couponCode = getValues('CouponCode')
    const userEmail = auth.email || Cookies.get('email')
    const validateCouponParams = {
      Email: userEmail,
      CouponCode: couponCode,
    }
    const couponValid = await validateCoupon(validateCouponParams)
    if (couponValid['Status']) {
      setCouponMsg({
        type: 'success',
        message: 'Coupon applied successfully!',
      })
    } else {
      setError('CouponCode', {
        type: 'manual',
        message: couponValid['Message'],
      })
    }
  }

  const removeCoupon = () => {
    setValue('CouponCode', '')
    setCouponMsg(undefined)
  }

  return (
    <div className="page-wrapper">
      <div className="page-content pt-4">
        <div className="container-fluid">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="header-title mt-0 mb-3">Billing Address</h4>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            First Name <small className="text-danger font-13">*</small>
                          </label>
                          <input type="text" {...register('FirstName', { onBlur: () => trigger('FirstName') })} className={`form-control ${Boolean(errors.FirstName) ? 'is-invalid' : ''}`} />
                          {errors.FirstName && <label className="text-danger">{errors.FirstName.message}</label>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Last Name <small className="text-danger font-13">*</small>
                          </label>
                          <input type="text" {...register('LastName', { onBlur: () => trigger('LastName') })} className={`form-control ${Boolean(errors.LastName) ? 'is-invalid' : ''}`} />
                          {errors.LastName && <label className="text-danger">{errors.LastName.message}</label>}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Address <small className="text-danger font-13">*</small>
                          </label>
                          <input type="text" {...register('Address', { onBlur: () => trigger('Address') })} className={`form-control ${Boolean(errors.Address) ? 'is-invalid' : ''}`} />
                          {errors.Address && <label className="text-danger">{errors.Address.message}</label>}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label pt-0 pb-1">
                            Country <small className="text-danger font-13">*</small>
                          </label>
                          <select
                            {...register('Country', { onBlur: () => trigger('Country') })}
                            className={`form-control ${Boolean(errors.Country) ? 'is-invalid' : ''}`}
                            onChange={(e) => {
                              setValue('Country', e.target.value)
                            }}
                          >
                            <option value={''}>Select</option>
                            {countries.map((country, index) => (
                              <option value={country.country_id} key={index}>
                                {country.description}
                              </option>
                            ))}
                          </select>
                          {errors.Country && <label className="text-danger">{errors.Country.message}</label>}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            City <small className="text-danger font-13">*</small>
                          </label>
                          <input type="text" {...register('City', { onBlur: () => trigger('City') })} className={`form-control ${Boolean(errors.City) ? 'is-invalid' : ''}`} />
                          {errors.City && <label className="text-danger">{errors.City.message}</label>}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Email Address <small className="text-danger font-13">*</small>
                          </label>
                          <input type="email" {...register('Email', { onBlur: () => trigger('Email') })} className={`form-control ${Boolean(errors.Email) ? 'is-invalid' : ''}`} />
                          {errors.Email && <label className="text-danger">{errors.Email.message}</label>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Mobile No</label>
                          <PhoneInput
                            name="mobile"
                            required
                            defaultCountry="US"
                            className={`form-control ${Boolean(errors.Mobile) ? 'is-invalid' : ''}`}
                            onChange={(value) => {
                              setValue('Mobile', value)
                            }}
                          />
                          {errors.Mobile && <label className="text-danger">{errors.Mobile.message}</label>}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Coupon Code</label>
                          <label className="text-success float-right">{couponMsg?.message}</label>
                          <input
                            type="text"
                            {...register('CouponCode', { onBlur: () => trigger('CouponCode') })}
                            className={`form-control ${Boolean(errors.CouponCode) ? 'is-invalid' : ''}`}
                            disabled={couponValidationLoading || (couponMsg && couponMsg?.type == 'success')}
                          />
                          {errors.CouponCode && <label className="text-danger">{errors.CouponCode.message}</label>}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label>
                            <br />
                            <br />
                            <br />
                          </label>
                          {((couponMsg && couponMsg?.type != 'success') || couponMsg == undefined) && (
                            <button type="button" className="btn btn-success px-3" onClick={applyCoupon} disabled={couponValidationLoading || (couponMsg && couponMsg?.type == 'success')}>
                              {couponValidationLoading ? 'Please wait...' : 'Apply Coupon Code'}
                            </button>
                          )}
                          {couponMsg && couponMsg?.type == 'success' && (
                            <button type="button" className="btn btn-danger px-3" onClick={removeCoupon}>
                              Remove Code
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <h4 className="header-title mt-0 mb-3">Payment Details</h4>
                    <div className="billing-nav">
                      {/* <ul
                        className='nav nav-pills justify-content-center text-center mb-3'
                        id='pills-tab'
                        role='tablist'
                      >
                        <li className='nav-item'>
                          <a
                            className='nav-link active'
                            id='pills-credit-card-tab'
                            data-toggle='pill'
                            href='#pills-credit-card'
                          >
                            <i className='mdi mdi-credit-card d-block mx-auto text-danger font-18'></i>
                            Credit-Card
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            className='nav-link'
                            id='pills-paypal-tab'
                            data-toggle='pill'
                            href='#pills-paypal'
                          >
                            <i className='mdi mdi-paypal d-block mx-auto text-secondary font-18'></i>
                            Paypal
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            className='nav-link'
                            id='pills-bitcoin-tab'
                            data-toggle='pill'
                            href='#pills-bitcoin'
                          >
                            <i className='mdi mdi-bitcoin d-block mx-auto text-warning font-18'></i>
                            Bitcoin
                          </a>
                        </li>
                      </ul> */}
                      <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-credit-card">
                          <div className="demo-container w-100 h-100">
                            <div className="card-wrapper mb-4"></div>
                            <div className="form-container">
                              <div className="bill-form" ref={cardRef}>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <input
                                        placeholder="Card number"
                                        className={`form-control ${errors.number ? 'is-invalid' : null}`}
                                        type="tel"
                                        {...register('number', {
                                          onBlur: () => trigger('number'),
                                          required: true,
                                        })}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <input
                                        placeholder="Full name"
                                        className={`form-control ${errors.fullname ? 'is-invalid' : null}`}
                                        type="text"
                                        {...register('name', {
                                          onBlur: () => trigger('name'),
                                          required: true,
                                        })}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <input
                                        placeholder="MM/YY"
                                        className={`form-control ${errors.expiry ? 'is-invalid' : null}`}
                                        type="tel"
                                        {...register('expiry', {
                                          onBlur: () => trigger('expiry'),
                                          required: true,
                                        })}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <input
                                        placeholder="CVC"
                                        className={`form-control ${errors.cvc ? 'is-invalid' : ''}`}
                                        type="number"
                                        {...register('cvc', {
                                          onBlur: () => trigger('cvc'),
                                          required: true,
                                        })}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <button type="button" className="btn btn-success px-3" onClick={hanldePayNow}>
                                  Pay Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className='tab-pane fade' id='pills-paypal'>
                          <div className='row'>
                            <div className='col-md-12 col-lg-6 offset-lg-3'>
                              <div className='card border'>
                                <div className='card-body'>
                                  <h4 className='title-text'>
                                    <i className='mdi mdi-paypal d-block mx-auto text-secondary font-18'></i>
                                    Add Paypal Form
                                  </h4>
                                  <p className='mb-0 text-muted'>
                                    There are many variations of passages of
                                    Lorem Ipsum available, but the majority have
                                    suffered alteration in some form, by
                                    injected humour, or randomised words.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='tab-pane fade' id='pills-bitcoin'>
                          <div className='row'>
                            <div className='col-md-12 col-lg-6 offset-lg-3'>
                              <div className='card border'>
                                <div className='card-body'>
                                  <h4 className='title-text'>
                                    <i className='mdi mdi-bitcoin d-block mx-auto text-warning font-18'></i>
                                    Add Bitcoin Form
                                  </h4>
                                  <p className='mb-0 text-muted'>
                                    There are many variations of passages of
                                    Lorem Ipsum available, but the majority have
                                    suffered alteration in some form, by
                                    injected humour, or randomised words.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ConfirmModal show={show} handleClose={handleClose} handleSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting} />
          </form>

          <Footer />
        </div>
      </div>
    </div>
  )
}

const ConfirmModal = ({ show, handleClose, handleSubmit, isSubmitting }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header>
      <Modal.Title>Do you want to proceed?</Modal.Title>
    </Modal.Header>
    <Modal.Body>Click Continue to confirm your subscription payment.</Modal.Body>
    <Modal.Footer>
      <button type="submit" className={`btn btn-success px-3 ${isSubmitting ? 'disabled' : ''}`} onClick={handleSubmit} disabled={isSubmitting}>
        {isSubmitting ? 'Please wait...' : 'Continue'}
      </button>
      <Button variant="outline-dark" onClick={handleClose}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>
)
