import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
//import { AWS_BUCKET_SERVICES, AWS_BUCKET_PROFILES } from 'constants'
import { FaTimes } from "react-icons/fa";
import './imageup.css';
import { Controller } from 'react-hook-form';
//---------------------------------------------------------

const ctnNoImgCss = {
  width: '180px', height: '150px',
  backgroundColor: '#edf0f5',//#dae5f4
  border: '1px solid #808080', borderRadius: '5px',
};
const upImgCss = {
  objectFit: 'cover', margin: 'unset',
  width: '180px', height: '140px',
  border: '1px solid #808080', borderRadius: '5px',
};
const ctnDSelImgCss = {
  position: 'absolute',
  zIndex: 5, //display: 'flex',
  right: '-15px', top: '-15px',
};
//---------------------------------------------------------

export function UploadOneImage({ image, setImage, disabled, inName = null, index }) {
  const imgRef = useRef();
  const { t: image_upload } = useTranslation('comp_image_upload');
  const [imagepreview, setImagePreview] = useState(false);
  const [previewImage, setPreviewImage] = useState({ path: '', file: '' });
  //const [image, setImage] = useState({});
  const inFlName = inName ? inName : 'input-file-now-custom-1';

  function fTriggerFileInput() {
    if (imgRef.current) { imgRef.current.click(); }
  }
  const fHandleImageInputChange = (e) => {
    const [file] = e.target.files;
    setImage({ ...image, file: file });
    setPreviewImage({ ...image, file: file });
    setImagePreview(true);
  }
  const fDeselectImage = () => {
    const img = { path: '' }
    setImage(img, index)
    setPreviewImage({ path: '', file: '' });
  };

  useEffect(() => {
    // let isMounted = true
    // const controller = new AbortController()
    let fileReader,
      isCancel = false
    // for (var image in formData.images) {
    if (image.file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          const tempimage = { path: result, file: image.file };
          setImage(tempimage);
          // setImages([...images, tempimage])
        }
      }
      fileReader.readAsDataURL(image.file);
    }
    // }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    }
  }, [previewImage]);
  //---------------------------------------------------------

  const ctnDSelImg = (
    <div style={ctnDSelImgCss}>
      <div className='image-close-icon xfloat-right'>
        <button type="button"
          className="btn btn-danger btn-round waves-effect waves-light image-close-icon"
          onClick={() => {
            fDeselectImage();//deleteDocumentHandler(document.Id)
          }}
        >
          <FaTimes />
        </button>
      </div>
    </div>
  );
  //---------------------------------------------------------

  return (
    <div className="upload-container d-flex flex-column justify-content-center align-items-center" style={{ marginBottom: '10px', marginLeft: '10px', marginRight: '10px', position: 'relative' }}>
      <input ref={imgRef} hidden type="file"
        //name="Image" id="input-file-now-custom-1"
        name={inFlName} id={inFlName}
        accept="image/*" // capture="user"
        // capture="filesystem"
        onChange={fHandleImageInputChange}
      />
      {image.path !== 'undefined' ? (
        <>
          {/* {!imagepreview?AWS_BUCKET_SERVICES + (image.path): (image.path)} */}
          {imagepreview && previewImage.file?.name?.length ? (
            <div>
              {ctnDSelImg}
              <div>
                <img alt="" style={upImgCss}
                  onClick={() => {
                    Swal.fire({
                      // title: 'Profile Picture',
                      customClass: 'swal-wide',
                      html: `<img height="600px" src="${!imagepreview ? image.path : image.path}"></img>`,
                      // { AWS_BUCKET_SERVICES } + profile.picture,
                    })
                  }}
                  src={!imagepreview ? image.path : image.path}
                  className="ob waves-effect waves-light"
                />
              </div>
            </div>
          ) : <div style={ctnNoImgCss}>&nbsp;</div>}
          <div className="row" style={{ marginTop: '10px' }}>
            <button type="button" disabled={disabled} className="btn btn-gradient-success waves-effect waves-light" onClick={fTriggerFileInput}>
              {image_upload('upload_button_label')}
            </button>
          </div>
        </>
      ) : null}
    </div>
  )
}
//---------------------------------------------------------

export default function UploadImage({
  control,
  name,
  setValue,
  watch,
  errors,
  clearErrors,
  prefilledImage,
}) {
  const imgRef = useRef();
  const picRef = useRef();
  const [image, setImage] = useState(prefilledImage ? { file: prefilledImage } : {});
  const [imagePreview, setImagePreview] = useState(prefilledImage || null);

  useEffect(() => {
    if (prefilledImage) {

      setImagePreview(prefilledImage);
      setValue(name, { file: prefilledImage });
    }
  }, [prefilledImage, setValue, name]);

  const fHandleImageInputChange = (e) => {
    const [file] = e.target.files;
    setImage({ ...image, file: file });
    setValue(name, { file: file });
    if (file) {
      clearErrors(name);
    }
  };

  useEffect(() => {
    if (image.file && image.file instanceof Blob) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result) {
          setImagePreview(result);
        }
      };
      fileReader.readAsDataURL(image.file);
    }
  }, [image]);

  function fTriggerFileInput() {
    if (imgRef.current) imgRef.current.click();
  }

  return (
    <div className="upload-container" style={{ marginBottom: '10px', marginLeft: '10px', marginRight: '10px' }}>
      <input
        type="file"
        ref={imgRef}
        name={name}
        hidden
        accept="image/*"
        onChange={fHandleImageInputChange}
      />

      <Controller
        name={name}
        control={control}
        rules={{
          required: !prefilledImage && 'Please upload an image',
          validate: {
            isValidImage: value => {
              if (!value || !value.file) {
                return 'Please upload an image';
              }
              const file = value.file;
              if (typeof file === "object") {
                const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
                if (!validImageTypes.includes(file.type)) {
                  return 'Only JPEG, PNG, or GIF images are allowed';
                }

                const MAX_SIZE = 5 * 1024 * 1024;
                if (file.size > MAX_SIZE) {
                  return 'Image size must be less than 5MB';
                }
              }
              return true;
            }
          }
        }}
        render={({ field }) => (
          <>
            <div className="image-preview-box" style={{ border: '2px solid #ddd', padding: '10px', width: '200px', marginBottom: '10px', textAlign: 'center' }}>
              {imagePreview ? (

                <img
                  ref={picRef}
                  alt="preview"
                  style={{
                    objectFit: 'cover',
                    width: '180px',
                    height: '150px',
                    display: 'block',
                    margin: '0 auto',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    Swal.fire({
                      html: `<img height="300px" src="${imagePreview}" />`,
                    });
                  }}
                  src={imagePreview}
                />
              ) : (

                <div style={{ width: '180px', height: '150px', backgroundColor: '#f4f4f4', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#999' }}>
                  <span>No Image</span>
                </div>
              )}
            </div>

            <div className="row" style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <button
                type="button"
                className="btn btn-gradient-success"
                onClick={fTriggerFileInput}
                style={{ textAlign: 'center' }}
              >
                {imagePreview ? 'Change Image' : 'Upload Image'}
              </button>
            </div>

            {errors[name] && (
              <div style={{ color: 'red', marginTop: '5px' }}>
                <span>{errors[name]?.message || 'Please upload an image'}</span>
              </div>
            )}
          </>
        )}
      />
    </div>
  );
}
