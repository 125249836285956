import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../../components/Footer'
import useAuth from '../../hooks/useAuth'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useTranslation } from 'react-i18next'
export default function TellUsWhy() {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const [symptom, setSymptom] = useState('')
  const [selectedSymptom, setSelectedSymptom] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [symptomCharCount, setSymptomCharCount] = useState(0)
  const { t: patient_virtual_visit } = useTranslation('patient_virtual_visit')
  const maxLength = 150

  function handleButtonClick(selected) {
    const currentSelected = new Set(selectedSymptom)

    if (currentSelected.has(selected)) return
    setSymptomCharCount(symptom.length + selected.length)
    if (maxLength - symptomCharCount > selected.length) {
      setSelectedSymptom((prev) => [...prev, selected])
      currentSelected.add(selected)
      if (symptom.trim().length <= 0) {
        setSymptom(selected)
      } else {
        setSymptom((prev) => `${prev}, ${selected}`)
      }
    }
  }

  function handleTextarea(e) {
    const val = e.target.value
    setSymptom(val)
    setSymptomCharCount(e.target.value.length)
    if (val.length <= 0) {
      setSelectedSymptom([])
    }
  }

  const handleSubmit = async () => {
    const controller = new AbortController()

    try {
      setIsSubmitting(true)
      await axiosPrivate
        .post(
          'patientSaveSymptoms',
          { Email: auth.email, Symptoms: symptom },
          {
            signal: controller.signal,
          }
        )
        .then((res) => {
          const { Status, Message, Data } = res.data
          if (Status) {
            navigate('/virtualvisit/waitingroom', { state: Data })
          } else {
            alert(Message)
          }
        })
        .catch((err) => {
          console.error(err)
          throw err
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    } catch (error) {
      setIsSubmitting(false)
      console.error(error)
    }
  }

  return (
    <div className="page-wrapper mt-0">
      {/* <!-- Page Content--> */}
      <div className="page-content">
        <div className="container-fluid">
          {/* <!-- Page-Title --> */}
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <h4 className="page-title"></h4>
              </div>
              {/* <!--end page-title-box--> */}
            </div>
            {/* <!--end col--> */}
          </div>
          {/* <!-- end page title end breadcrumb --> */}

          <div className="row ">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body">
                  <h3>{patient_virtual_visit('tell_us_why_youre_here')}</h3>
                  <textarea
                    style={{ margin: '30px 0 0 0' }}
                    className="form-control"
                    rows="5"
                    id="message"
                    placeholder={patient_virtual_visit('add_a_reason_for_your_virtual_visit')}
                    value={symptom}
                    onChange={handleTextarea}
                    maxLength={maxLength}
                  ></textarea>
                  {maxLength - symptomCharCount} {patient_virtual_visit('characters_remaining')}
                  <div className="d-flex flex-wrap justify-content-start align-items-center overflow-auto">
                    {[
                      patient_virtual_visit('cough'),
                      patient_virtual_visit('sore_throat'),
                      patient_virtual_visit('flu_symptoms'),
                      patient_virtual_visit('sinus_infection'),
                      patient_virtual_visit('ear_infection'),
                      patient_virtual_visit('skin_infection'),
                      patient_virtual_visit('upper_respiratory_infection'),
                      patient_virtual_visit('bone_fractures'),
                      patient_virtual_visit('muscles_sprain'),
                      patient_virtual_visit('urinary_issues'),
                      patient_virtual_visit('rash'),
                      patient_virtual_visit('headache_migraine'),
                      patient_virtual_visit('stomachache'),
                    ].map((e) => (
                      <button
                        key={e}
                        type="button"
                        className="btn btn-light btn-sm mr-1 text-nowrap"
                        style={{
                          boxShadow: 'unset',
                          borderRadius: '15px',
                          margin: '3px',
                        }}
                        onClick={handleButtonClick.bind(this, e)}
                      >
                        {e}
                      </button>
                    ))}
                  </div>
                  {/* <p className='text-muted mb-3'>Upload your files here</p> */}
                  {/* <input type='file' id='input-file-now' className='dropify' /> */}
                  <div className="wizard_btn  " style={{ marginBottom: '50px' }}>
                    {/* <Link to='waitingroom'> */}
                    <button
                      type="button"
                      className="mb-1 mb-md-0 btn btn-success btn-round waves-effect waves-light figmaBigButton float-left"
                      onClick={handleSubmit}
                      disabled={isSubmitting || !symptom}
                    >
                      {isSubmitting ? patient_virtual_visit('please_wait') : patient_virtual_visit('start_your_virtual_visit')}
                    </button>
                    {/* </Link> */}
                    <Link to="..">
                      <button type="button" className="btn btn-danger btn-round waves-effect waves-light figmaBigButton float-bottom">
                        {patient_virtual_visit('cancel')}
                      </button>
                    </Link>
                    <p style={{ marginTop: '40px' }}>{patient_virtual_visit('enjoy_our_video_content_while_you_wait')}</p>
                  </div>
                  {/* <!--end of row --> */}
                </div>
                {/* <!--end card-body--> */}
              </div>
              {/* <!--end card-->                                    */}
            </div>
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body">
                  <img src="../assets/images/getting-consultation-of-doctor.jpg" className="steps_img" alt="" />

                  <div className="media">
                    <div className="media-body align-self-center">
                      <h3 className="mt-0 mb-1">{patient_virtual_visit('how_to_get_the_most_out_of_your_visit')}</h3>
                    </div>
                    {/* <!--end media-body--> */}
                  </div>
                  {/* <!--end media--> */}

                  <div className="pricingTable1 text-center">
                    <ul className="list-unstyled pricing-content-2 text-left py-3 border-0 mb-0">
                      <li>{patient_virtual_visit('grant_camera_permission')}</li>
                      <li>{patient_virtual_visit('do_not_begin_a_virtual_visit_while_driving')}</li>
                      <li>{patient_virtual_visit('ensure_stable_connection')}</li>
                      <li>{patient_virtual_visit('good_lighting')}</li>
                    </ul>
                  </div>
                  <div></div>
                </div>
                {/* <!--end card-body-->  */}
              </div>
              {/* <!--end card-->*/}
            </div>
          </div>
          {/* <!--end row--> */}
        </div>
        {/* <!-- container --> */}

        {/* <footer className="footer text-center text-sm-left">
            &copy; 2022 NIU Health 
        </footer> */}
        <Footer />
        {/* <!--end footer--> */}
      </div>
      {/* <!-- end page content --> */}
    </div>
  )
}
