import { nHApi } from 'v2/src/lib/services/public/nHApi';
import { NS_API_BASE_URL } from 'v2/src/config/appConfig';
//---------------------------------------------------------

export const authApi = nHApi.injectEndpoints({
  endpoints: builder => ({
    register: builder.mutation({
      query: (params) => {
        return {
          url: '/register',
          method: 'POST',
          body: params,
          //validateStaus: (response, result) =>
          //  response.status === 200 && !result.isError,
        }
      },
    }),
    login: builder.mutation({
      query: credentials => ({
        url: '/login',
        method: 'POST',
        body: { ...credentials },
      }),
      /*transformResponse: (resp, mt) => {
        console.log('tRLogin', resp);
        if(['provider@example.com', 'drt@niuhealth.com'].
          includes(resp?.data?.user?.email)
        ) {
          if(resp?.data?.profiles?.patient) {
            resp.data.profiles['provider'] = {
              provider: {profile_id: resp.data.profiles.patient.profile_id}
            };
            delete resp.data.profiles.patient;
          }
        }console.log('trNResp', resp);
        return resp;
      },*/
    }),
    logout: builder.mutation({
      query: (params) => {
        let headers = {}
        if (params?.token) {
          headers = {
            Authorization: `Bearer ${params?.token}`,
          }
        }
        return {
          url: '/logout',
          method: 'POST',
          //body: { ...credentials },
        }
      },
    }),
    forgotPassword: builder.mutation({
      query: (params) => {
        let body = { ...params?.data }

        return {
          url: '/forgot-password',
          method: 'POST',
          body: body,
        }
      },
    }),
    resetPassword: builder.mutation({
      query: (params) => {
        //let body = { ...params?.data }
        let body = params
        console.log('resPwdBody', body);

        return {
          url: '/reset-password',
          method: 'POST',
          body: body,
        }
      },
    }),
  }),
});

export const {
  useRegisterMutation, useLoginMutation,
  useLogoutMutation, useForgotPasswordMutation, useResetPasswordMutation,
  //useGetUserQuery, useLazyGetUserQuery
} = authApi;
