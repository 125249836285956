import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import CardItem from '../../../components/cards/Card'
import PatientListData from '../../../components/provider/PatientListData'
import TableCard, { ContainerFluid, TableTitle } from '../../../components/table/Tables'
// import   from "../../../components/table/Tables"
import { AWS_BUCKET, AWS_BUCKET_SERVICES } from '../../../constants'
import useAuth from '../../../hooks/useAuth'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useDebounce from '../../../hooks/useDebounce'
import Pagination from 'react-js-pagination'
import { CardLongItem } from '../../../components/cards/Card'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import FilterTag from '../../../components/filterTag'
import { useTranslation } from 'react-i18next'
// Provider list of patients

function PatientList() {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const [errMsg, setErrMsg] = useState(null)
  const [patientList, setPatientList] = useState([])

  const [clinicList, setClinicList] = useState([])
  const [visitTarget, setVisitTarget] = useState({})
  const [search, setSearch] = useState('')
  const [searchText, setSearchText] = useState('')
  const [subscriptionStatus, setSubscriptionStatus] = useState('')
  const [subscriptionWorkflow, setSubscriptionWorkflow] = useState('')
  const [insuranceStatus, setInsuranceStatus] = useState('')
  const [insuranceWorkflow, setInsuranceWorkflow] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [pageNum, setPageNum] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [visitType, setVisitType] = useState('Virtual')
  const [clinicIDList, setClinicIDList] = useState([])
  const [serviceList, setServiceList] = useState([])
  const [service, setService] = useState('')
  const { t: provider_patient_list } = useTranslation('provider_patient_list')

  const [listSubscriptionStatus, setListSubscriptionStatus] = useState([provider_patient_list('subscribed'), provider_patient_list('not_subscribed')])
  const [listSubscriptionWorkflow, setListSubscriptionWorkflow] = useState([provider_patient_list('fully_paid'), provider_patient_list('payment_overdue'), provider_patient_list('na')])
  const [listInsuranceStatus, setListInsuranceStatus] = useState([provider_patient_list('insured'), provider_patient_list('not_insured')])
  const [listInsuranceWorkflow, setListInsuranceWorkflow] = useState([
    provider_patient_list('pending'),
    provider_patient_list('active'),
    provider_patient_list('rejected'),
    provider_patient_list('expired'),
    provider_patient_list('na'),
  ])

  const [showFilterWindow, setShowFilterWindow] = useState(false)
  const [activeFilter, setActiveFilter] = useState('')
  const [transFilter, setTransFilter] = useState([true, true, true, true, true])
  const [listOriginal, setListOriginal] = useState([])

  const [filterTags, setFilterTags] = useState()

  // itemsCountPerPage
  /*
  For Status:
  Confined -  badge-soft-purple
  Deceased - badge-soft-danger
  Follow-up Checkup - badge-soft-success
  */
  let morning_options = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7]
  function hideModal() {
    // Swal.fire("This Happened")
    $('#myModal').hide()
    $('.modal-backdrop').hide()
  }
  function hourformat(hour) {
    if (hour > 12) {
      return (hour - 12 < 10 ? '0' : '') + (hour - 12) + ':00 PM'
    } else if (hour === 12) {
      return 12 + ':00 PM'
    } else if (hour === 0) {
      return 12 + ':00 AM'
    } else {
      return (hour < 10 ? '0' : '') + hour + ':00 AM'
    }
  }
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm()
  async function createInPersonVisit(data) {
    const controller = new AbortController()
    const patient_obj = visitTarget

    await axiosPrivate
      .post(
        'providerCreateAppointment',
        {
          ...data,
          Type: visitType === 'Virtual' ? '1' : '2',
          PatientID: patient_obj.patient_id,
          Email: auth?.email || Cookies.get('email'),
          ServiceID: service.service_id,
        },
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        const { Status, Message } = res.data

        if (Status) {
          document.getElementById('create-appointment').reset()

          Swal.fire({
            title: provider_patient_list('appointment_created'),
            html: `${provider_patient_list('when')}: ${hourformat(data.Time)}`,
            // icon: 'info',
          })
          // setUpdateVisit(!updateVisit)
          hideModal()
        } else {
          Swal.fire({
            title: provider_patient_list('error'),
            html: `${Message}`,
            // icon: 'warning',
          })
          hideModal()
        }
      })
      .catch((err) => {
        console.error(err)
        hideModal()
      })
  }
  async function getServicesList() {
    const controller = new AbortController()
    await axiosPrivate
      .post(
        'getServices',
        {
          Email: auth.email || Cookies.get('email'),
          Search: search,
        },
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        const { Status, Data: data = [], Message } = res.data

        if (Status) {
          setIsLoading(false)
          const serviceList = res.data.Data
          setServiceList(serviceList)

          // setSeListOriginal(serviceList)
          // const serviceCategories=serviceList.map((item,index)=>{return item.category})
          // setCategoryOptions(serviceCategories )
        } else {
          throw new Error(Message)
        }
      })
      .catch((err) => {
        console.error(err)
        setErrMsg(err.message)
      })
  }
  async function getList(inSearchText = '', inSubscriptionStatus = '', inSubscriptionWorkflow = '', inInsuranceStatus = '', inInsuranceWorkflow = '') {
    // Variables beting set here because querystring input was not getting cast in the
    //   setVariableName functions properly. This setup ensures the query string variables
    //   can be pushed to the search function at page load
    var currentSearchText = inSearchText
    var currentSubscriptionStatus = inSubscriptionStatus
    var currentSubscriptionWorkflow = inSubscriptionWorkflow
    var currentInsuranceStatus = inInsuranceStatus
    var currentInsuranceWorkflow = inInsuranceWorkflow

    // if (searchText) {
    //   currentSearchText = searchText
    // }
    // if (subscriptionStatus) {
    //   currentSubscriptionStatus = subscriptionStatus
    // }
    // if (subscriptionWorkflow) {
    //   currentSubscriptionWorkflow = subscriptionWorkflow
    // }
    // if (insuranceStatus) {
    //   currentInsuranceStatus = insuranceStatus
    // }
    // if (insuranceWorkflow) {
    //   currentInsuranceWorkflow = insuranceWorkflow
    // }

    const controller = new AbortController()
    setIsLoading(true)
    var isUseSearch = false
    if (currentSearchText || currentSubscriptionStatus || currentSubscriptionWorkflow || currentInsuranceStatus || currentInsuranceWorkflow) {
      isUseSearch = true
    } else {
    }

    await axiosPrivate
      .post(
        isUseSearch ? 'searchPatient' : 'getPatients',
        {
          Email: auth.email,
          Search: currentSearchText,
          SubscriptionStatus: currentSubscriptionStatus,
          SubscriptionWorkflow: currentSubscriptionWorkflow,
          InsuranceStatus: currentInsuranceStatus,
          InsuranceWorkflow: currentInsuranceWorkflow,
        },
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        setIsLoading(false)

        const { Data = [] } = res.data
        if (
          currentSearchText?.length > 0 ||
          currentSubscriptionStatus?.length > 0 ||
          currentSubscriptionWorkflow?.length > 0 ||
          currentInsuranceStatus?.length > 0 ||
          currentInsuranceWorkflow?.length > 0
        ) {
          setPatientList(Data)
        } else {
          setPatientList(Data.Patients)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.error(err)
        setErrMsg(err.message)
      })
  }
  async function getClinicList() {
    const controller = new AbortController()

    //var str = JSON.stringify(auth, null, 4); // (Optional) beautiful indented output.

    await axiosPrivate
      .post(
        'getClinics',
        { Email: auth.email },
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        const { Status, Data: data = [], Message } = res.data

        if (Status) {
          setClinicList(data)
          setClinicIDList(
            data?.map((item) => {
              return { name: item.clinic_name, id: item.clinic_id }
            })
          )
        } else {
          throw new Error(Message)
        }
      })
      .catch((err) => {
        console.error(err)
        setErrMsg(err.message)
      })
  }
  async function handleSubmitSearch(event) {
    event.preventDefault()
    setPageNum(1)
    if (searchText?.length < 3 && subscriptionStatus === '' && subscriptionWorkflow === '' && insuranceStatus === '' && insuranceWorkflow === '') {
      return
    }

    await getList(searchText, subscriptionStatus, subscriptionWorkflow, insuranceStatus, insuranceWorkflow)
    setFilterTags({
      searchText,
      subscriptionStatus,
      subscriptionWorkflow,
      insuranceStatus,
      insuranceWorkflow,
    })
  }

  useEffect(() => {
    getServicesList()
    getClinicList()

    let qsSearchText = ''
    let qsSubscriptionStatus = ''
    let qsSubscriptionWorkflow = ''
    let qsInsuranceStatus = ''
    let qsInsuranceWorkflow = ''
    let params = new URLSearchParams(window.location.search)
    if (params.get('search')) {
      qsSearchText = params.get('search')
      setSearchText(qsSearchText)
    }
    if (params.get('subscription_status')) {
      qsSubscriptionStatus = params.get('subscription_status')
      setSubscriptionStatus(qsSubscriptionStatus)
    }
    if (params.get('subscription_workflow')) {
      qsSubscriptionWorkflow = params.get('subscription_workflow')
      setSubscriptionWorkflow(qsSubscriptionWorkflow)
    }
    if (params.get('insurance_status')) {
      qsInsuranceStatus = params.get('insurance_status')
      setInsuranceStatus(qsInsuranceStatus)
    }
    if (params.get('insurance_workflow')) {
      qsInsuranceWorkflow = params.get('insurance_workflow')
      setInsuranceWorkflow(qsInsuranceWorkflow)
    }

    getList(qsSearchText, qsSubscriptionStatus, qsSubscriptionWorkflow, qsInsuranceStatus, qsInsuranceWorkflow)
    setFilterTags({
      searchText: qsSearchText,
      subscriptionStatus: qsSubscriptionStatus,
      subscriptionWorkflow: qsSubscriptionWorkflow,
      insuranceStatus: qsInsuranceStatus,
      insuranceWorkflow: qsInsuranceWorkflow,
    })
  }, [])

  async function resetSearch() {
    let newSearchText = ''
    setSearchText(newSearchText)
    setFilterTags({
      ...filterTags,
      searchText: '',
    })
    await getList(newSearchText, subscriptionStatus, subscriptionWorkflow, insuranceStatus, insuranceWorkflow)
  }

  async function resetSubscriptionStatus() {
    let newSubscriptionStatus = ''
    setSubscriptionStatus(newSubscriptionStatus)
    setFilterTags({
      ...filterTags,
      subscriptionStatus: '',
    })
    await getList(searchText, newSubscriptionStatus, subscriptionWorkflow, insuranceStatus, insuranceWorkflow)
  }

  async function resetSubscriptionWorkflow() {
    let newSubscriptionWorkflow = ''
    setSubscriptionWorkflow(newSubscriptionWorkflow)
    setFilterTags({
      ...filterTags,
      subscriptionWorkflow: '',
    })
    await getList(searchText, subscriptionStatus, newSubscriptionWorkflow, insuranceStatus, insuranceWorkflow)
  }

  async function resetInsuranceStatus() {
    let newInsuranceStatus = ''
    setInsuranceStatus(newInsuranceStatus)
    setFilterTags({
      ...filterTags,
      insuranceStatus: '',
    })
    await getList(searchText, subscriptionStatus, subscriptionWorkflow, newInsuranceStatus, insuranceWorkflow)
  }

  async function resetInsuranceWorkflow() {
    let newInsuranceWorkflow = ''
    setInsuranceWorkflow(newInsuranceWorkflow)
    setFilterTags({
      ...filterTags,
      insuranceWorkflow: '',
    })
    await getList(searchText, subscriptionStatus, subscriptionWorkflow, insuranceStatus, newInsuranceWorkflow)
  }

  return (
    <ContainerFluid>
      <TableTitle title={provider_patient_list('table_title_patients')}>
        <form onSubmit={handleSubmitSearch}>
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <h5 className="mt-1 ">{provider_patient_list('filters')}</h5>
                <ul className="nav nav-pills m-2" id="pills-tab" role="tablist">
                  <li className="nav-item m-2">
                    <div className="input-group" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                      <input
                        type="text"
                        className="form-control"
                        style={{ maxWidth: '300px' }}
                        placeholder={provider_patient_list('search_patients_placeholder')}
                        aria-label={provider_patient_list('search_patients_placeholder')}
                        onChange={(e) => setSearchText(e.target.value)}
                        value={searchText || ''}
                      />
                      <span className="input-group-append">
                        <button className="btn btn-success" type="submit">
                          <i className="fas fa-search"></i>
                        </button>
                      </span>
                    </div>
                  </li>
                  <li className="nav-item m-2">
                    <a
                      className="nav-link"
                      id="subscription_status_tab"
                      data-toggle="pill"
                      href="#subscription_status"
                      onClick={(e) => {
                        setActiveFilter('subscription_status')
                        if (activeFilter === 'subscription_status') {
                          setShowFilterWindow(!showFilterWindow)
                        } else if (activeFilter !== 'subscription_status') {
                          setShowFilterWindow(true)
                        }
                      }}
                    >
                      {provider_patient_list('subscription_status')}
                    </a>
                    <div className="tab-content detail-list position-absolute" id="pills-tabContent">
                      <div className="tab-pane position-absolute " style={{ zIndex: 3 }} id="subscription_status">
                        {showFilterWindow && activeFilter === 'subscription_status' ? (
                          <CardItem>
                            <div className="p-3">
                              <h6 className="mt-0 ">{provider_patient_list('subscription_status')}</h6>
                              <div className="col-sm-4">
                                <select className="form-control" name="subscription_status" style={{ width: 185 }} onChange={(e) => setSubscriptionStatus(e.target.value)}>
                                  <option value={''}>Select</option>
                                  {listSubscriptionStatus.map((val, index) => (
                                    <option value={val} key={index} selected={val === subscriptionStatus}>
                                      {val}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="m-1">
                              <button className="btn btn-success" type="submit">
                                {provider_patient_list('apply')}
                              </button>
                            </div>
                          </CardItem>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </li>
                  <li className="nav-item m-2">
                    <a
                      className="nav-link"
                      id="subscription_workflow_tab"
                      data-toggle="pill"
                      href="#subscription_workflow"
                      onClick={(e) => {
                        setActiveFilter('subscription_workflow')
                        if (activeFilter === 'subscription_workflow') {
                          setShowFilterWindow(!showFilterWindow)
                        } else if (activeFilter !== 'subscription_workflow') {
                          setShowFilterWindow(true)
                        }
                      }}
                    >
                      {provider_patient_list('subscription_details')}
                    </a>
                    <div className="tab-content detail-list position-absolute" id="pills-tabContent">
                      <div className="tab-pane position-absolute " style={{ zIndex: 3 }} id="subscription_workflow">
                        {showFilterWindow && activeFilter === 'subscription_workflow' ? (
                          <CardItem>
                            <div className="p-3">
                              <h6 className="mt-0 ">{provider_patient_list('subscription_details')}</h6>
                              <div className="col-sm-4">
                                <select className="form-control" name="subscription_workflow" style={{ width: 185 }} onChange={(e) => setSubscriptionWorkflow(e.target.value)}>
                                  <option value={''}>Select</option>
                                  {listSubscriptionWorkflow.map((val, index) => (
                                    <option value={val} key={index} selected={val === subscriptionWorkflow}>
                                      {val}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="m-1">
                              <button className="btn btn-success" type="submit">
                                {provider_patient_list('apply')}
                              </button>
                            </div>
                          </CardItem>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </li>
                  <li className="nav-item m-2">
                    <a
                      className="nav-link"
                      id="insurance_status_tab"
                      data-toggle="pill"
                      href="#insurance_status"
                      onClick={(e) => {
                        setActiveFilter('insurance_status')
                        if (activeFilter === 'insurance_status') {
                          setShowFilterWindow(!showFilterWindow)
                        } else if (activeFilter !== 'insurance_status') {
                          setShowFilterWindow(true)
                        }
                      }}
                    >
                      {provider_patient_list('insurance_status')}
                    </a>
                    <div className="tab-content detail-list position-absolute" id="pills-tabContent">
                      <div className="tab-pane position-absolute " style={{ zIndex: 3 }} id="insurance_status">
                        {showFilterWindow && activeFilter === 'insurance_status' ? (
                          <CardItem>
                            <div className="p-3">
                              <h6 className="mt-0 ">{provider_patient_list('insurance_status')}</h6>

                              <div className="col-sm-4">
                                <select className="form-control" name="insurance_status" style={{ width: 185 }} onChange={(e) => setInsuranceStatus(e.target.value)}>
                                  <option value={''}>Select</option>
                                  {listInsuranceStatus.map((val, index) => (
                                    <option value={val} key={index} selected={val === insuranceStatus}>
                                      {val}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="m-1">
                              <button className="btn btn-success" type="submit">
                                {provider_patient_list('apply')}
                              </button>
                            </div>
                          </CardItem>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </li>
                  <li className="nav-item m-2">
                    <a
                      className="nav-link"
                      id="insurance_workflow_tab"
                      data-toggle="pill"
                      href="#insurance_workflow"
                      onClick={(e) => {
                        setActiveFilter('insurance_workflow')
                        if (activeFilter === 'insurance_workflow') {
                          setShowFilterWindow(!showFilterWindow)
                        } else if (activeFilter !== 'insurance_workflow') {
                          setShowFilterWindow(true)
                        }
                      }}
                    >
                      {provider_patient_list('insurance_details')}
                    </a>
                    <div className="tab-content detail-list position-absolute" id="pills-tabContent">
                      <div className="tab-pane position-absolute " style={{ zIndex: 3 }} id="insurance_workflow">
                        {showFilterWindow && activeFilter === 'insurance_workflow' ? (
                          <CardItem>
                            <div className="p-3">
                              <h6 className="mt-0 ">Insurance workflow</h6>

                              <div className="col-sm-4">
                                <select className="form-control" name="insurance_workflow" style={{ width: 185 }} onChange={(e) => setInsuranceWorkflow(e.target.value)}>
                                  <option value={''}>Select</option>
                                  {listInsuranceWorkflow.map((val, index) => (
                                    <option value={val} key={index} selected={val === insuranceWorkflow}>
                                      {val}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="m-1">
                              <button className="btn btn-success" type="submit">
                                {provider_patient_list('apply')}
                              </button>
                            </div>
                          </CardItem>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="filter-tags-container m-2">
                  {filterTags && Object.values(filterTags).some((tag) => tag) && <span>{provider_patient_list('search')}:</span>}
                  {filterTags?.searchText && <FilterTag text={filterTags.searchText} onRemove={resetSearch} />}
                  {filterTags?.subscriptionStatus && <FilterTag text={filterTags.subscriptionStatus} onRemove={resetSubscriptionStatus} />}
                  {filterTags?.subscriptionWorkflow && <FilterTag text={filterTags.subscriptionWorkflow} onRemove={resetSubscriptionWorkflow} />}
                  {filterTags?.insuranceStatus && <FilterTag text={filterTags.insuranceStatus} onRemove={resetInsuranceStatus} />}
                  {filterTags?.insuranceWorkflow && <FilterTag text={filterTags.insuranceWorkflow} onRemove={resetInsuranceWorkflow} />}
                </div>
              </div>
            </div>
          </div>
        </form>
      </TableTitle>
      {/* <CardLongItem> */}
      {/* {(patientList.length>pageLimit)?
      
            <div className='justify-content-center d-flex' style={{alignItems:'center',flexDirection:'column'}}>
              
              <Pagination
                activePage={pageNum}
                itemsCountPerPage={pageLimit}
                totalItemsCount={patientList.length||[]}
                pageRangeDisplayed={5}
                // onPageChange={}
                itemclassName="page-item "
                linkClass="page-link float-center"
                onChange={(e)=>{
                  setPageNum(e)}}
                        />
              <div className='row-lg-12 h-2'>Page {pageNum}</div> 
                  </div>:<></>} */}
      {/* {isLoading ? 'Loading please wait...' : null} */}
      {/* {errMsg ? <span style={{ color: 'red' }}>{errMsg}</span> : null}
        {patientList.length <= 0 && searchText.length > 0
          ? '0 record found.'
          : null} */}
      {/* </CardLongItem> */}
      {patientList?.length > 0 ? (
        <>
          <TableCard
            headers={[
              provider_patient_list('patient'),
              provider_patient_list('email'),
              provider_patient_list('phone'),
              provider_patient_list('status'),
              provider_patient_list('insurance'),
              provider_patient_list('action'),
            ]}
          >
            <PatientListData
              limit={pageLimit}
              pagenum={pageNum}
              list={patientList}
              showModalAppointment={(patient) => {
                setVisitTarget(patient)
                $('#myModal').modal()
                $('#myModal').show()
                $('.modal-backdrop').show()
              }}
              showModalSubscription={(patient) => {
                setVisitTarget(patient)
                $('#modalSubscription').modal()
                $('#modalSubscription').show()
                $('.modal-backdrop').show()
              }}
            />
          </TableCard>
          <CardLongItem>
            {patientList.length > pageLimit ? (
              <div className="justify-content-center d-flex" style={{ alignItems: 'center', flexDirection: 'column' }}>
                <Pagination
                  activePage={pageNum}
                  itemsCountPerPage={pageLimit}
                  totalItemsCount={patientList?.length || []}
                  pageRangeDisplayed={5}
                  // onPageChange={}
                  itemclassName="page-item "
                  linkClass="page-link float-center"
                  onChange={(e) => {
                    setPageNum(e)
                  }}
                />
                <div className="row-lg-12 h-2">
                  {provider_patient_list('page')} {pageNum}
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* {isLoading ? 'Loading please wait...' : null} */}
            {/* {errMsg ? <span style={{ color: 'red' }}>{errMsg}</span> : null}
        {list.length <= 0 && searchText.length > 0
          ? '0 record found.'
          : null} */}
          </CardLongItem>
        </>
      ) : (
        <CardLongItem>
          <h5>{isLoading ? provider_patient_list('loading_message') : provider_patient_list('no_results_message')}</h5>
        </CardLongItem>
      )}
      {/* <!-- Start Patient appointment modal details --> */}
      <div id="myModal" className={'modal fade show'} role="form">
        <div className="modal-dialog" style={{ maxWidth: '500px', margin: '1.75rem auto' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Book an Appointment</h4>
            </div>
            <form id="create-appointment" onSubmit={handleSubmit(createInPersonVisit)}>
              <div className="modal-body">
                <div className="nuModalCont visitRequestModal">
                  <div className="">
                    <div className="row m-2 ">
                      <img src={visitTarget.picture} alt="" className="rounded-circle mr-2 m-2" style={{ objectFit: 'cover', height: '80px', width: '80px' }} />
                      <div className="m-2 d-flex justify-content-center align-items-center">
                        <h5>
                          {visitTarget.first_name} {visitTarget.last_name}
                          <div className="text-muted">Patient</div>
                        </h5>
                      </div>
                    </div>
                    <label htmlFor="title" className="col-form-label">
                      {provider_patient_list('visit_title_label')}
                    </label>
                    <input required className="form-control" type="text" id="visitTitle" {...register('Title')} />
                    {/* <label  className="col-form-label">Patient</label>
                      <select required className="form-control" {...register("PatientID")}>
                          <option>Select Patient...</option>
                            {patientList.map((item,index)=>{
                                    return(
                                    <option key={index} value={item.patient_id}>{item.first_name} {item.last_name}</option>)
                                  })}
                      </select> */}
                    <label htmlFor="type" className="col-form-label">
                      {provider_patient_list('visit_type_label')}
                    </label>
                    <div className="row-sm-12 d-flex justify-content-center align-items-center">
                      <button
                        type="button"
                        id="inperson"
                        name="visit_type"
                        className={'m-1 col-lg-5 form-button btn waves-effect waves-light btn-' + (visitType === 'In-Person' ? '' : 'outline-') + 'purple'}
                        onClick={() => {
                          setVisitType('In-Person')
                        }}
                      >
                        {provider_patient_list('inperson_visit')}
                      </button>
                      <button
                        type="button"
                        id="virtual"
                        name="visit_type"
                        className={'m-1 col-lg-5 form-button btn waves-effect waves-light btn-' + (visitType === 'Virtual' ? '' : 'outline-') + 'purple'}
                        onClick={() => {
                          setVisitType('Virtual')
                        }}
                      >
                        {provider_patient_list('virtual_visit')}
                      </button>
                    </div>
                    <label className="col-form-label">{provider_patient_list('service_label')}</label>
                    <select
                      required
                      className="form-control"
                      onChange={(e) => {
                        const [chosenService] = serviceList.filter((item) => {
                          return e.target.value == item.service_id
                        })
                        setService(chosenService)
                      }}
                    // {...register("ServiceID")}
                    >
                      <option value="">{provider_patient_list('select_service')}</option>
                      {serviceList?.map((item) => {
                        return <option value={item.service_id}>{item.service_name} </option>
                      })}
                    </select>
                    <label className="col-form-label">{provider_patient_list('clinic_label')}</label>
                    <select required className="form-control" {...register('ClinicID')}>
                      <option value="">{provider_patient_list('select_clinic')}</option>
                      {clinicList
                        .filter((item, index) => {
                          const serviceClinics = service?.clinic_ids?.split(',')
                          return serviceClinics?.includes(item.clinic_id)
                        })
                        ?.map((item) => {
                          return <option value={item.clinic_id}>{item.clinic_name} </option>
                        })}
                    </select>

                    <label htmlFor="date" className="col-form-label">
                      {provider_patient_list('visit_date_label')}
                    </label>
                    <input
                      required
                      className="form-control"
                      min={moment().format('YYYY-MM-DD')}
                      defaultValue={moment().add(1, 'days').format('YYYY-MM-DD')}
                      type="date"
                      id="date"
                      {...register('Date')}
                    />

                    <label htmlFor="time" className="col-form-label">
                      {provider_patient_list('visit_time_label')}
                    </label>
                    {/* <input className="form-control" pattern="[0-9]{2}:[0]{2}" defaultValue={moment().format('HH:MM a')} type="time" id="time" {...register("Time")}/> */}
                    <select required {...register('Time')} className="form-control">
                      {morning_options.map((option, index) => (
                        <option key={index} value={option}>
                          {hourformat(option)}
                        </option>
                      ))}

                      <option value={null}>--:--</option>
                    </select>
                    {/* <label  className="col-form-label">Internal Notes</label>
                      <textarea className="form-control" rows="5" id="message" {...register("InternalNotes")}></textarea> */}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="nuBtnContMod">
                  <button type="submit" className="btn btn-purple waves-effect waves-light" id="create-visit">
                    {provider_patient_list('save_visit_button')}
                  </button>
                </div>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  data-target="#myModal"
                  data-dismiss="modal"
                  onClick={(e) => {
                    e.preventDefault()
                    $('#myModal').hide()
                    $('.modal-backdrop').hide()
                  }}
                >
                  {provider_patient_list('close_button')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <!-- End Patient appointment modal details --> */}
      {/* <!-- Start Subscription modal details --> */}
      <div id="modalSubscription" className={'modal fade show'} role="form">
        <div className="modal-dialog" style={{ maxWidth: '500px', margin: '1.75rem auto' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{provider_patient_list('subscription_details')}</h4>
            </div>
            <div className="modal-body">
              <div className="nuModalCont visitRequestModal">
                <div className="">
                  <div className="row m-2 ">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          <td className="payment-title">{provider_patient_list('subscription_status')}</td>
                          <td>{visitTarget.subscription_status}</td>
                        </tr>
                        <tr>
                          <td className="payment-title">Details</td>
                          <td>{visitTarget.subscription_workflow}</td>
                        </tr>
                        <tr>
                          <td className="payment-title">Plan Type</td>
                          <td>{visitTarget.subscription_plan === '' ? '' : visitTarget.subscription_plan + ' plan'}</td>
                        </tr>
                        <tr>
                          <td className="payment-title">Start Date</td>
                          <td>{visitTarget.subscription_plan === '' ? '' : visitTarget.subscription_start}</td>
                        </tr>
                        <tr>
                          <td className="payment-title">End Date</td>
                          <td>{visitTarget.subscription_plan === '' ? '' : visitTarget.subscription_end}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger"
                data-target="#modalSubscription"
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault()
                  $('#modalSubscription').hide()
                  $('.modal-backdrop').hide()
                }}
              >
                {provider_patient_list('close_button')}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Subscription modal details --> */}
    </ContainerFluid>
  )
}
export default PatientList
