import { createSlice, current } from "@reduxjs/toolkit";
//import {original } from "immer";
import { NS_DEF_ORG_ID, DEF_LANGUAGE_CODE, USER_TYPES, DEF_LANGUAGE_ID,
} from 'v2/src/config/appConfig';
import { fDetectUserType, fSetLocalSt } from 'v2/src/lib/appUtils';
//fSetLocalSt (TO_BE_REMOVED_LATER)
//---------------------------------------------------------

const iniUserDetails = {
  firstName: null, middleName: null, lastName: null, dispName: null,
  userRoleId: null, userRole: null, profilePic: null, eMail: null,
  contactNum: null, dob: null, gender: null, addressId: null,
  langId: DEF_LANGUAGE_ID, langCode: DEF_LANGUAGE_CODE, tzId: null,
  profileId: null, profiles: null, //notifications: {},
};
const iniAuthState = {
  userIdNH: null, userIdNS: null, eMail: null, orgId: null,
  userType: null, authTokenNH: null, authTokenNS: null,
  details: iniUserDetails, // awsAuthData: null}
  //nHDetails: {//<- Should not be stored in slice
    //profDetails: {
      //patient {blood_group, height, weight}
      //
      //provider {providerId, license_number}
      //
    //},
    //payments: {stripeId: null, pmType: null, pmLastFour: null, trialEndDate: null}
  //},
};

const fPopulateSignInUserDetails = (uData, profId) => {//, uRole) => {
  const uDetails = structuredClone(iniUserDetails);
  uDetails.firstName  =  uData?.user?.first_name;
  uDetails.middleName =  uData?.user?.middle_name;//||''
  uDetails.lastName   =  uData?.user?.last_name;
  uDetails.dispName   =  uData?.user?.first_name;
  uDetails.eMail      =  uData?.user?.email;
  uDetails.contactNum =  uData?.user?.phone;
  //uDetails.userRoleId =  ?;
  //uDetails.userRole   =  uRole;
  //uDetails.profilePic =  null;
  //uDetails.dob =  uData?.user?.phone;
  //uDetails.gender =  uData?.user?.phone;
  //uDetails.addressId = ;
  //uDetails.langId = ;
  //uDetails.langCode = ;
  //uDetails.tzId = ;
  //uDetails.notifications = ;
  
  uDetails.profileId =  profId;
  uDetails.profiles  =  uData?.profiles;// ?? null;
  return uDetails;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: structuredClone(iniAuthState),
  reducers: {
    rSetUserCredentials:(state, action) => {
      //return {...state, ...action.payload };
      const aData = action.payload;
      if(aData?.user?.id) {
        //const newSt = {...current(state)};
        const newSt = structuredClone({...current(state)});
        const {profileId, userType} = fDetectUserType(aData?.profiles);
        //const uDetails = fPopulateSignInUserDetails(aData, profileId, userType) || {};
        const uDetails = fPopulateSignInUserDetails(aData, profileId) || {};
        //console.log('newStUCred', {newSt, aData, profileId, userType, uDetails});
        //fSetAuthCookie2(aData);
        newSt.userIdNH = aData?.user?.id;
        newSt.userIdNS = aData?.user?.external_user_id;
        newSt.eMail = aData?.user?.email;
        newSt.orgId = NS_DEF_ORG_ID;
        newSt.userType = userType;
        newSt.authTokenNH = aData?.tokens?.health?.token;
        newSt.authTokenNS = aData?.tokens?.social?.token;
        newSt.details = uDetails;
        /*newSt.nHDetails = {
          stripeId: aData?.user?.stripe_id,
          pmType: aData?.user?.pm_type,
          pmLastFour: aData?.user?.pm_last_four,
          trialEndDate: aData?.user?.trial_ends_at,
        };*/
        //console.log('rsNewStUCred', newSt);
        fSetLocalSt('nHAUSl', newSt);
        return newSt;
      }
    },
    rSetUserDetails:(state, action) => {
      //return {...state, ...action.payload };
      const pData = action.payload;
      //console.log('rsProfData', pData);
      if(pData?.id) {
        const newSt = structuredClone({...current(state)});
        //const newSt = {...current(state)};
        //console.log('uStUPD', {newSt, pData});
        const uDetails = {};

        uDetails['userRoleId'] = pData?.role?.id;
        uDetails['userRole'] = pData?.role?.name;
        //uDetails['profilePic'] = pData?.profile_pic;
        uDetails['profilePic'] = pData?.profile_pic_url;
        uDetails['dob'] = pData?.date_of_birth;
        uDetails['gender'] = pData?.gender;
        //uDetails['addressId'] = pData?.gender;
        uDetails['langId'] = pData?.language?.id || DEF_LANGUAGE_ID;
        uDetails['langCode'] = pData?.language?.language_code || DEF_LANGUAGE_CODE;
        uDetails['tzId'] = pData?.timezone_id;
        //console.log('uDetails', uDetails);
        //console.log('uDetails1', ...newSt.details);
        newSt.details = {...newSt.details, ...uDetails};
        //console.log('newStUPD', newSt);
        fSetLocalSt('nHAUSl', newSt);
        //return {...state, ...newSt};
        return newSt;
      }
    },
    rSetProfileData:(state, action) => {
      const pData = action.payload;
      const arrK = Object.keys(pData);
      if(arrK?.length) {
        const newSt = structuredClone({...current(state)});

       arrK.forEach(k => {
          switch(k) {
            /*case 'userType':
              newSt[k] = pData[k];
              //newSt['details'][k] = pData[k];
              break;*/
            case 'profileId': case 'userType': case 'userRoleId': case 'userRole':
              //case 'gender'??
              //NotAllowed
              break;
            case 'firstName':  case 'middleName': case 'lastName':
            case 'dispName':   case 'profilePic': case 'dob':
            case 'contactNum': case 'langId':     case 'langCode':
            case 'tzId': case 'addressId':
              newSt['details'][k] = pData[k];
              break;
          }
        });
        fSetLocalSt('nHAUSl', newSt);
        return newSt;
      }
    },
    rSetProfilePic:(state, action) => {
      //OR Simply state.details.profilePic = pData.profilePic;
      const pData = action.payload;//console.log('rSetPPicPL', {pData, newSt});
      //console.log('rSetProfilePic', current(state));
      //structuredClone({...current(state)});
      //{...state};//original(state));
      if(pData?.profilePic) {
        const newSt = structuredClone({...current(state)});
        newSt['details']['profilePic'] = pData?.profilePic;
        /*const newSt = {...state,
          details: { ...state.details, profilePic: pData.profilePic },
          //nHDetails: {}
        };*/
        //console.log('newStProfPicChange', newSt);
        fSetLocalSt('nHAUSl', newSt);
        return newSt;
      }
    },
    /*rSetProfileUpdates: (state, action) => {
      const pData = action.payload;
      //if(pData?.userType === '') {} else if() {}

    },*/
    rSetLogOut: (state, action) => {
      //const newSt = {...current(state)};
      const newSt = structuredClone(iniAuthState);
      //fResetApiStat();
      //fDelLocalSt('nHAUSl', );
      return newSt;
      //return null;
    },
    rInvalidateUserCredentials:(state, action) => {
      return null;
    }
  },
});

export default authSlice.reducer;
export const { rSetUserCredentials, rSetUserDetails, rSetProfileData,
  rSetProfilePic, rSetLogOut, rInvalidateUserCredentials,
} = authSlice.actions;
export const aSGetAuthUserId = state => state.auth.userIdNH;
export const aSGetAuthUserEmail = state => state.auth.eMail;
//export const aSGetAuthTokenNH  = state => state.auth.authTokenNH;
export const aSGetAuthTokenNS  = state => state.auth.authTokenNS;
export const aSGetUserType  = state => state.auth.userType;
//export const aSGetUserRole  = state => state.auth.userType;
//export const aSGetUserOrgId  = state => state.auth.orgId;
export const aSGetAuthUserData = state => {
  //return state.auth;
  //console.log('rstAuth', state);
  return state.auth?.userIdNH ? state.auth : null;
};
//export const checkIsAdmin  = state => state.auth.email === SA_TEMP_DEF_ADMIN_EMAIL;
//export const checkIsAdmin  = state => SA_TEMP_ADMINS_FOR_FB.includes(state.auth.email);
//export const aSGetAuthUserAwsData = state => state.auth.awsAuthData;
