import { Link, useLocation, useParams } from 'react-router-dom'
import { AWS_BUCKET, AWS_BUCKET_DOCUMENTS, AWS_BUCKET_PROFILES, AWS_BUCKET_SERVICES } from '../../../constants'
import TableCard, { TableTitle } from '../../../components/table/Tables'
import { useEffect, useRef, useState } from 'react'
import useAuth from '../../../hooks/useAuth'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import CardItem from '../../../components/cards/Card'
import { useCallback } from 'react'
import ImageViewer from 'react-simple-image-viewer'

// TODO: check other UI if it has same layout
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'
import { StatusTextInsurance2, StatusTextInsurance3 } from '../../../components/status/Status'
import { useTranslation } from 'react-i18next'

export const StyleWrapper = styled.div`
  .styles-module_image__2hdkJ {
    height: auto;
    max-height: 780px;
    margin-bottom: 120px;
  }
  .styles-module_navigation__1pqAE {
    z-index: 50;
  }
  .styles-module_wrapper__1I_qj {
    margin-top: 70px;
    background-color: rgba(0 0 0 / 50%);
  }
  img {
    z-index: 120;
    object-fit: contain;
    opacity: 1 !important;
  }
`
function hourformat(hourstr) {
  const hour = parseInt(hourstr)

  if (hour > 12) {
    return (hour - 12 < 10 ? '0' : '') + (hour - 12) + ':00 PM'
  } else if (hour === 12) {
    return 12 + ':00 PM'
  } else if (hour === 0) {
    return 12 + ':00 AM'
  } else {
    return (hour < 10 ? '0' : '') + hour + ':00 AM'
  }
}
const StatusText = ({ status }) => {
  const statusColor = {
    0: 'badge-soft-warning',
    1: 'badge-soft-success',
    2: 'badge-soft-danger',
    3: 'badge-soft-danger',
    4: 'badge-soft-success',
    5: 'badge-soft-purple',
    6: 'badge-soft-primary',
  }
  const statusText = {
    0: 'For Approval',
    1: 'Completed',
    2: 'Cancelled By You',
    3: 'Cancelled By Doctor',
    4: 'Approved',
    5: 'Started',
    6: 'Created By Doctor',
  }
  return <span className={`virtualvisitbadge badge badge-md ${statusColor[status]}`}>{statusText[status]}</span>
}

const StatusIcon = ({ icontype }) => {
  const StatusColor = {
    0: 'text-purple',
    2: 'text-danger',
    3: 'text-danger',
    1: 'text-success',
    4: 'text-success',
  }
  return (
    <div className="task-priority-icon">
      <i className={`fas fa-circle ${StatusColor[icontype]}`}></i>
    </div>
  )
}

function PatientProfile() {
  const { action, id } = useParams()
  const { t: provider_profile } = useTranslation('provider_profile')
  const hashValue = window.location.hash.substring(1)
  const [profile, setProfile] = useState({})
  const [insuranceList, setInsuranceList] = useState([])
  const [profileDetails, setProfileDetails] = useState({})
  const [paymentHistory, setPaymentHistory] = useState([])
  const [validDateEnd, setValidDateEnd] = useState(moment().format('YYYY-MM-DD'))

  const [validDateStart, setValidDateStart] = useState(moment().format('YYYY-MM-DD'))
  const [appointmentslist, setAppointmentsList] = useState([])
  // setAppointmentsList
  const [isLoading, setIsLoading] = useState(true)
  const [ins_view, setIns_view] = useState(false)

  const insuranceNavRef = useRef(null)

  const [ins_index, setIns_index] = useState(0)
  // const [item,setItem]=useState({BucketName:"",BucketId:"",FrontImage:"",BackImage:""})
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const [refreshProfile, setRefreshProfile] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [images, setImages] = useState([])
  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  /*Show the documents uploaded by the patient in the popup screen*/
  const [documents, setDocuments] = useState([]) // List of documents
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0) // Index of the currently displayed document image
  const [isDocumentViewerOpen, setIsDocumentViewerOpen] = useState(false) // Flag to control the visibility of the document image viewer
  const [documentImageUrls, setDocumentImageUrls] = useState([]) // List of image URLs for the documents

  // Function to open the document image viewer and set the current image index
  const openDocumentImageViewer = useCallback((index) => {
    setCurrentDocumentIndex(index)
    setIsDocumentViewerOpen(true)
  }, [])

  // Function to close the document image viewer and reset the current image index
  const closeDocumentImageViewer = () => {
    setCurrentDocumentIndex(0)
    setIsDocumentViewerOpen(false)
  }

  useEffect(() => {
    if (documents.length > 0) {
      const tempImageUrls = documents.map((document) => {
        return document.FileName
      })
      setDocumentImageUrls(tempImageUrls)
    }
  }, [documents])

  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth()
  // let {
  //   state: { selectedUser },
  // } = useLocation()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    async function getProfileDetails() {
      await axiosPrivate
        .post(
          'providerGetPatientDetails',
          { Email: auth.email, PatientID: id },
          {
            signal: controller.signal,
          }
        )
        .then((res) => {
          const { Status, Data: data = [], Message } = res.data
          const details = data[0]

          if (Status) {
            setProfile(details)
            setIsLoading(false)
            setInsuranceList(res.data.Data.InsuranceBuckets)

            setProfileDetails(res.data.Data.Details)
            setPaymentHistory(res.data.Data.Payments)
            setAppointmentsList(res.data.Data.Appointments)
            setDocuments(res.data.Data.Documents)
          } else {
            throw new Error(Message)
          }
        })
        .catch((err) => {
          setIsLoading(false)
          console.error(err)
        })
    }
    getProfileDetails()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [refreshProfile])

  useEffect(() => {
    if (hashValue === 'insurance') {
      insuranceNavRef.current.click()
    }
  }, [])

  const dIconCss = { position: 'relative', top: '0.3rem' };

  return (
    <div className="container-fluid">
      <TableTitle title={provider_profile('patient_profile')}>
        <div className="float-right">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/provider">{provider_profile('niu_health')}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/provider/patient">{provider_profile('patients')}</Link>
            </li>
            <li className="breadcrumb-item active">
              {profileDetails.first_name} {profileDetails.middle_name} {profileDetails.last_name}
            </li>
          </ol>
        </div>
      </TableTitle>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body  met-pro-bg">
              <div className="met-profile">
                <div className="row">
                  <div className="col-lg-4 align-self-center mb-3 mb-lg-0">
                    <div className="met-profile-main">
                      <div className="met-profile-main-pic">
                        <img src={profileDetails.picture} alt="" className="rounded-circle" style={{ width: 125, height: 125, objectFit: 'cover' }} />
                        {/* <span className='fro-profile_main-pic-change'>
                          <i className='fas fa-camera'></i>
                        </span> */}
                      </div>
                      <div className="met-profile_user-detail">
                        <h5 className="met-user-name">
                          {profileDetails.first_name} {profileDetails.middle_name} {profileDetails.last_name}
                        </h5>
                        <div className="row col-md-12">
                          <div className="m-1">
                            <span className={profileDetails.subscription_plan == 0 ? 'badge badge-md badge-soft-danger' : 'badge badge-md badge-soft-success m-1'}>
                              {profileDetails.subscription_plan == false ? provider_profile('not_subscribed') : provider_profile('subscribed')}
                            </span>
                          </div>
                          <div>
                            <div className="m-1">
                              <span className={profileDetails.has_insurance == false ? 'badge badge-md badge-soft-danger' : 'badge badge-md badge-soft-success m-1'}>
                                {profileDetails.has_insurance == false ? provider_profile('not_insured') : provider_profile('insured')}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* <p className='mb-0 met-user-name-post'>
                          {selectedUser.status}
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 ml-auto">
                    <ul className="list-unstyled personal-detail">
                      <li className="">
                        <i className="dripicons-phone mr-2 text-info font-18" style={dIconCss}></i> <b> {provider_profile('phone')} </b> : {profileDetails.contact_info}
                      </li>
                      <li className="mt-2">
                        <i className="dripicons-mail text-info font-18 mt-2 mr-2" style={dIconCss}></i> <b> {provider_profile('email')} </b> : {profileDetails.email}
                      </li>
                      <li className="mt-2">
                        <i className="dripicons-location text-info font-18 mt-2 mr-2" style={dIconCss}></i> <b>{provider_profile('location')}</b> : {profileDetails.address}
                      </li>
                      <li className="mt-2">
                        <i className="dripicons-web text-info font-18 mt-2 mr-2" style={dIconCss}></i> <b>{provider_profile('language')}</b> : {profileDetails.user_language_name}
                      </li>
                      <li className="mt-2">
                        <i className="dripicons-calendar text-info font-18 mt-2 mr-2" style={dIconCss}></i> <b>{provider_profile('date_of_birth')}</b> :{' '}
                        {profileDetails.date_of_birth && moment(profileDetails.date_of_birth).format('DD MMMM YYYY')}
                      </li>
                    </ul>
                    <div className="button-list btn-social-icon">
                      {/* <button type='button' className='btn btn-blue btn-circle'>
                        <i className='fab fa-facebook-f'></i>
                      </button>

                      <button
                        type='button'
                        className='btn btn-secondary btn-circle ml-2'
                      >
                        <i className='fab fa-twitter'></i>
                      </button>

                      <button
                        type='button'
                        className='btn btn-pink btn-circle  ml-2'
                      >
                        <i className='fab fa-dribbble'></i>
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist">
                {/* <li className='nav-item'>
                  <a
                    className='nav-link active'
                    id='general_detail_tab'
                    data-toggle='pill'
                    href='#general_detail'
                  >
                    {provider_profile('general')}
                  </a>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link" id="appointments_list_detail_tab" data-toggle="pill" href="#appointments_list">
                    {provider_profile('appointments')}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="payment_history_detail_tab" data-toggle="pill" href="#payment_history">
                    {provider_profile('payment_history')}
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="insurance_detail_tab"
                    data-toggle="pill"
                    href="#insurance"
                    ref={insuranceNavRef}
                    onClick={() => {
                      if (ins_view) {
                        setIns_view(false)
                      }
                    }}
                  >
                    {provider_profile('insurance')}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="documents_tab" data-toggle="pill" href="#documents">
                    Documents
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="tab-content detail-list" id="pills-tabContent">
            <div className="tab-pane fade show" id="general2_detail">
              <div className="row">
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="mt-0 header-title">{provider_profile('patient_diagnosis')}</h4>
                      <p className="text-muted mb-4">{provider_profile('high_blood_pressure_diabetic')}</p>
                      <textarea className="form-control" rows="3" id="clipboardTextarea" defaultValue={` `}></textarea>
                      <div className="mt-3">
                        <button type="button" className="btn btn-gradient-secondary btn-clipboard m-2" data-clipboard-action="copy" data-clipboard-target="#clipboardTextarea">
                          <i className="fas fa-video"></i> {provider_profile('start_virtual_call')}
                        </button>{' '}
                        <button type="button" className="btn btn-gradient-primary btn-clipboard m-2" data-clipboard-action="cut" data-clipboard-target="#clipboardTextarea">
                          <i className="fab fa-rocketchat"></i> {provider_profile('send_message')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade show active" id="appointments_list">
              <div className="row-lg-12">
                {appointmentslist.length !== 0 ? (
                  <TableCard headers={[provider_profile('description'), provider_profile('service_description'), provider_profile('appointment_time'), provider_profile('status')]}>
                    {appointmentslist.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {/* <Link
                            to={"/provider/profiles/"+item.provider_id}
                            // state={{
                            //   selectedUser: item,
                            // }}
                          > */}
                          <div className="row">
                            <div className="col">
                              <img src={item.image} alt="" className="thumb-sm rounded-circle mr-2" style={{ objectFit: 'cover' }} />
                              {item.provider_name}
                            </div>
                          </div>
                          {/* </Link> */}
                        </td>

                        <td>{item.service_description}</td>
                        <td>{`${hourformat(item.trans_start)}, ${moment(item.trans_date_time).format('MMMM DD, YYYY')}`}</td>

                        <td>
                          <StatusText status={item.status} />
                        </td>
                      </tr>
                    ))}
                  </TableCard>
                ) : (
                  <>
                    <CardItem className={'col-lg-12'}>{isLoading ? provider_profile('loading') : provider_profile('no_appointments')}</CardItem>
                  </>
                )}
              </div>
            </div>
            <div className="tab-pane fade" id="payment_history">
              <div className="row-lg-12">
                {paymentHistory.length !== 0 ? (
                  <TableCard headers={[provider_profile('description'), provider_profile('payment_time'), provider_profile('amount')]}>
                    {paymentHistory.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <NavLink
                            onClick={async () => {
                              await axiosPrivate.post('getStripeReceipt', { Email: profileDetails.email, ChargeID: item.trans_id }).then((res) => {
                                const receipt_link = res.data.Data
                                Swal.fire({
                                  html: `${provider_profile('view_receipt')}`,
                                  title: provider_profile('payment_receipt'),
                                  showConfirmButton: true,
                                  showCancelButton: true,
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    // navigate(receipt_link,{replace:true})
                                    openInNewTab(receipt_link)
                                  } else {
                                  }
                                })
                              })
                            }}
                          >
                            {item.description}
                          </NavLink>
                        </td>
                        <td>{moment(item.payment_date_time).format('hh:mm a, MMMM DD, YYYY')}</td>
                        {/* <td>
                        <a href={item.receipt}>View<i className="fa fa-receipt"></i></a>
                        </td> */}
                        <td>$ {item.amount} USD</td>
                      </tr>
                    ))}
                  </TableCard>
                ) : (
                  <>
                    <CardItem className={'col-lg-12'}>{isLoading ? provider_profile('loading') : provider_profile('no_payment_history_results')}</CardItem>
                  </>
                )}
              </div>
            </div>
            <div className="tab-pane fade" id="insurance">
              <div className="row m-2">
                <div className="card col-lg-12">
                  <div className="card-body m-2">
                    {!ins_view ? (
                      <>
                        <div className="file-box-content">
                          {insuranceList.length === 0 ? (
                            <>
                              <CardItem>{isLoading ? `${provider_profile('loading')}` : `${provider_profile('no_insurance', { name: profileDetails?.first_name })} `}</CardItem>
                            </>
                          ) : (
                            <></>
                          )}
                          {insuranceList.map((item, index) => (
                            <Link
                              // className="btn-success waves"
                              key={index}
                              style={{ background: 'none', marginLeft: '2px' }}
                              onClick={() => {
                                setIns_view(true)
                                setIns_index(index)
                                var tempImageList = []
                                for (var i = 1; i <= 6; i++) {
                                  if (item['image' + String(i)] !== '') {
                                    tempImageList.push(`${item['image' + i]}`)
                                  }
                                }
                                setImages([...tempImageList])
                                setValidDateStart(insuranceList[index].start_date)
                                setValidDateEnd(insuranceList[index].end_date)
                              }}
                            >
                              <div className="file-box">
                                {/* <i className='dripicons-download file-download-icon'></i> */}

                                <div className="text-center">
                                  <img width={'51px'} height={'66px'} style={{ objectFit: 'cover' }} src={`${item.image1}`}></img>
                                  <i className={item.Archive == 1 ? 'far fa-folder text-gray ml-3' : 'far fa-folder text-success ml-3'}></i>

                                  <h6 className="text-truncate">{item.BucketName}</h6>
                                  {/* <span className="virtualvisitbadge badge badge-md badge-soft-purple">{((insuranceList[index].Status)==="1")?"Approved":(insuranceList[index].Status==="0")?"For Approval":(insuranceList[index].Status==="2")?"Rejected":""}</span> */}
                                  <StatusTextInsurance3 status={insuranceList[index].Archive} />
                                  <br />
                                  <StatusTextInsurance2 status={insuranceList[index].Status} />
                                  <br />
                                  <small className="text-muted">
                                    {moment(item.DateUploaded).format('MM/DD/YY')}
                                    {/* 06 March 2022 / 5MB */}
                                  </small>
                                </div>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <h4 className=" mt-0 xmb-3 col" style={{ paddingLeft: 0 }}> {provider_profile('insurance_entry')}</h4>
                        {/*<label className="col-form-label">
                          {' '}
                          <b> {provider_profile('title')}: </b>
                        </label>{' '}*/}
                        <b className='mr-2'> {provider_profile('title')}: </b>

                        {/*<h4 className="xml-2">{insuranceList[ins_index].BucketName}</h4>*/}
                        &nbsp;&nbsp;{insuranceList[ins_index].BucketName}
                        <br />
                        <b className="mr-2">{provider_profile('created')}:</b> {moment(insuranceList[ins_index].DateUploaded).format('hh:mm a MMMM DD, YYYY')}
                        <br />
                        <b className="mr-2">{provider_profile('active_status')}: </b> <StatusTextInsurance3 status={insuranceList[ins_index].Archive} /> <br />
                        <b className="mr-2">{provider_profile('approval_status')}: </b>
                        <StatusTextInsurance2 status={insuranceList[ins_index].Status} />
                        <br />
                        {insuranceList[ins_index].Status === '1' ? (
                          <>
                            <b className="mr-2">{provider_profile('approved_date')}:</b> {moment(insuranceList[ins_index].DateApproved).format('MM/DD/YY')}
                            <br />
                          </>
                        ) : (
                          <></>
                        )}
                        <br />
                        <div className="col-lg-6">
                          <div className="row text-center align-item col-lg-12">
                            <div className="col">
                              <label htmlFor="date" className="col-form-label">
                                <b> {provider_profile('date_start')}</b>
                              </label>
                              <br />

                              {moment(insuranceList[ins_index].start_date).format('MMMM DD, YYYY')}
                            </div>
                            <div className="col">
                              <label htmlFor="date" className="col-form-label">
                                <b> {provider_profile('date_end')}</b>
                              </label>
                              <br />

                              {moment(insuranceList[ins_index].end_date).format('MMMM DD, YYYY')}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="row text-center align-item col-lg-12">
                            <div className="col">
                              <label htmlFor="date" className="col-form-label">
                                {' '}
                                <b>{provider_profile('validated_date_start')}</b>
                              </label>
                              <br />
                              {insuranceList[ins_index].validated_start_date === '1900-01-01' ? 'NA' : moment(insuranceList[ins_index].validated_start_date).format('MMMM DD, YYYY')}
                            </div>
                            <div className="col">
                              <label htmlFor="date" className="col-form-label">
                                <b> {provider_profile('validated_date_end')}</b>
                              </label>
                              <br />
                              {insuranceList[ins_index].validated_end_date === '1900-01-01' ? 'NA' : moment(insuranceList[ins_index].validated_end_date).format('MMMM DD, YYYY')}
                            </div>
                          </div>
                        </div>
                        <div className="row m-4">
                          {images.map((image, index) => {
                            return (
                              <CardItem className={'m-2 col lg-4'}>
                                <div className="m-2 col lg-4" onClick={() => openImageViewer(index)}>
                                  File {index + 1}
                                  <br />
                                  <br />
                                  <img style={{ width: '250px', objectFit: 'cover' }} src={image}></img>
                                </div>
                              </CardItem>
                            )
                          })}
                        </div>
                        {insuranceList[ins_index].Status === '0' ? (
                          <>
                            <h3>
                              {' '}
                              <i className="fas fa-exclamation-triangle" style={{ color: '#f14b4b' }} /> {provider_profile('insurance_approval_required')}
                            </h3>
                            <h5 className="text-muted">{provider_profile('validated_dates_must_match')} </h5>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="float-right col text-center align-item col-lg-12">
                          {insuranceList[ins_index].Status === '0' ? (
                            <>
                              <div>
                                <div className="row">
                                  <div className="col-lg-3">
                                    <label htmlFor="date" className="col-form-label">
                                      {provider_profile('validated_date_start')}
                                    </label>
                                    <input
                                      required
                                      className="form-control"
                                      // min={moment().add(1,"days").format("YYYY-MM-DD")}
                                      // defaultValue={moment().add(1,"days").format("YYYY-MM-DD")}
                                      type="date"
                                      id="date"
                                      value={validDateStart}
                                      onChange={(e) => {
                                        setValidDateStart(e.target.value)
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-3">
                                    <label htmlFor="date" className="col-form-label">
                                      {provider_profile('validated_date_end')}
                                    </label>
                                    <input
                                      required
                                      className="form-control"
                                      min={moment().add(1, 'days').format('YYYY-MM-DD ')}
                                      // defaultValue={moment().add(1,"days").format("YYYY-MM-DD")}
                                      type="date"
                                      id="date"
                                      value={validDateEnd}
                                      onChange={(e) => {
                                        setValidDateEnd(e.target.value)
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <button
                                type="button"
                                onClick={() => {
                                  Swal.fire({
                                    title: provider_profile('approve_insurance'),
                                    html: `${provider_profile('validate_this_insurance_entry')}`,
                                    showCancelButton: true,
                                  }).then(async (res) => {
                                    if (res.isConfirmed) {
                                      await axiosPrivate
                                        .post('providerApproveInsurance', {
                                          Email: auth.email,
                                          PatientID: id,
                                          InsuranceID: insuranceList[ins_index].BucketId,
                                          ValidatedStartDate: validDateStart,
                                          ValidatedEndDate: validDateEnd,
                                        })
                                        .then((res) => {
                                          const { Status, Message } = res.data
                                          if (Status) {
                                            Swal.fire({
                                              title: provider_profile('insurance_approved'),
                                              // icon: 'success',
                                              text: provider_profile('insurance_approved_text', { date: moment().format('MMMM DD, yyyy') }),
                                            })
                                            setIns_view(false)
                                            setRefreshProfile(!refreshProfile)
                                          } else {
                                            const msg = Message
                                            Swal.fire({
                                              title: 'Error',
                                              //  icon: 'error',
                                              text: `${msg}`
                                            })
                                          }
                                        })
                                    }
                                  })
                                }}
                                className="m-1 btn btn-outline-success waves-effect waves-light mt-2"
                              >
                                Approve
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  Swal.fire({
                                    title: provider_profile('reject_insurance'),
                                    html: `${provider_profile('reject_patient_insurance_msg_1')}<br><div class=\"text-muted\">${provider_profile('reject_patient_insurance_msg_2')}<div>`,
                                    showCancelButton: true,
                                  }).then(async (res) => {
                                    if (res.isConfirmed) {
                                      await axiosPrivate
                                        .post('providerRejectInsurance', {
                                          Email: auth.email,
                                          PatientID: id,
                                          InsuranceID: insuranceList[ins_index].BucketId,
                                        })
                                        .then((res) => {
                                          const { Status, Message } = res.data
                                          if (Status) {
                                            Swal.fire({
                                              title: provider_profile('insurance_rejected'),
                                              //  icon: 'success',
                                              text: provider_profile('insurance_rejected_text')
                                            })
                                            setIns_view(false)
                                            setRefreshProfile(!refreshProfile)
                                          } else {
                                            const msg = Message
                                            Swal.fire({
                                              title: 'Error',
                                              // icon: 'error',
                                              text: `${msg}`
                                            })
                                          }
                                        })
                                    }
                                  })
                                }}
                                className="m-1 btn btn-outline-danger waves-effect waves-light mt-2"
                              >
                                {provider_profile('reject')}
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                          <button
                            type="button"
                            onClick={() => {
                              setIns_view(false)
                            }}
                            className="m-1  btn btn-outline-purple waves-effect waves-light mt-2"
                          >
                            {provider_profile('back')}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="documents">
              <div className="row m-2">
                <div className="card col-lg-12">
                  <div className="card-body m-2">
                    <div className="file-box-content">
                      {documents.length === 0 ? (
                        <>
                          <CardItem>{isLoading ? `Loading...` : `${profileDetails.first_name} has not submitted any Documents.`}</CardItem>
                        </>
                      ) : (
                        <></>
                      )}
                      <>
                        <div className="row m-4 danish">
                          {documents.map((item, index) => {
                            return (
                              <CardItem className={'m-2 col-lg-3'}>
                                <div className="m-2">
                                  Document Type: {item.DocumentTypeName}
                                  <br />
                                  <br />
                                  <Link>
                                    <img style={{ width: '250px', objectFit: 'cover' }} src={`${item.FileName}`} onClick={() => openDocumentImageViewer(index)}></img>
                                  </Link>
                                </div>
                              </CardItem>
                            )
                          })}
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isViewerOpen && (
        <div style={{ marginTop: '100px' }}>
          <StyleWrapper>
            <ImageViewer src={images} currentIndex={currentImage} disableScroll={true} closeOnClickOutside={true} onClose={closeImageViewer} />
          </StyleWrapper>
        </div>
      )}
      {isDocumentViewerOpen && (
        <div style={{ marginTop: '100px' }}>
          <StyleWrapper>
            <ImageViewer src={documentImageUrls} currentIndex={currentDocumentIndex} disableScroll={false} closeOnClickOutside={true} onClose={closeDocumentImageViewer} />
          </StyleWrapper>
        </div>
      )}
    </div>
  )
}

export default PatientProfile

function openInNewTab(url) {
  var win = window.open(url, '_blank')
  win.focus()
}
