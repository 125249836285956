import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { memo, useEffect, useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLazyGetAppointmentsQuery } from "v2/src/lib/services/nHAppointmentApi";
import { useTranslation } from "react-i18next";

function Calendar({ allowCall = true, dateList }) {
  const navigate = useNavigate();
  const { t: provider_visits } = useTranslation('provider_visits')

  const [slots, setSlots] = useState([]);
  const [appointmentList, setAppointmentList] = useState();

  const [getAppointments, { data: appointmentDetails }] = useLazyGetAppointmentsQuery();

  const handleDateSelect = (selectInfo) => { };

  const handleEventClick = (clickInfo) => {
    const selected = appointmentList.find(
      (item) => item.id == clickInfo.event.id
    );

    let timeStr = Number(selected.trans_start);
    if (timeStr < 10) {
      timeStr = "0" + timeStr;
    }

    const formatPeriod = [
      moment(selected?.start_time).format("MMM DD, YYYY"),
      moment(selected?.start_time).format("hh:mm"),
      moment(selected?.end_time).format("hh:mm a"),
    ];
    const appointmentTime = `${formatPeriod[0]}, ${formatPeriod[1]} - ${formatPeriod[2]}`;

    const appointmentPeriod = [
      moment(selected.start_time),
      moment(selected.start_time).add(1, "hours"),
    ];
    const appointmentIsOver = moment().isAfter(appointmentPeriod[1]);

    Swal.fire({
      titleText: `Appointment Details:`,
      html: `
      <div class='text-left'>
        <b class="text-center"> ${appointmentIsOver
          ? ""
          : selected?.enable_meet_button
            ? `<div class='text-success'>${provider_visits('appointment_is_now')} is Now!</div>`
            : ""
        }</b>
        Date: <strong>${appointmentTime}</strong><br/>
        Name: ${selected.patient?.profile?.user?.first_name +
        " " +
        selected.patient?.profile?.user?.last_name
        }<br/>
        Email: <a target=_blank href="mailto:${selected.patient?.profile?.user?.email
        }">${selected.patient?.profile?.user?.email}</a><br/>
        Phone: <a href="tel:${selected.patient?.profile?.user?.phone}">${selected.patient?.profile?.user?.phone
        }</a><br/>
        Service Name: ${selected.service?.name}<br/>
        </div>`,
      showConfirmButton: allowCall && selected?.enable_meet_button,
      confirmButtonText: allowCall && selected?.enable_meet_button ? provider_visits('start_zoom_meeting') : "",
      showCancelButton: allowCall,
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed && selected?.enable_meet_button) {
        navigate("/virtualvisit/room", {
          state: {
            MeetingID: selected?.zoom_meeting_id,
            Password: selected?.zoom_meeting_password,
            AppointmentID: selected?.id,
            startTime: selected?.start_time,
          },
        });
      }
    });
  };

  const INITIAL_EVENTS = (appointments = []) => {
    const schedArray = [
      appointments.map((item) => {
        const startTime = moment(item.start_time).format("YYYY-MM-DDThh:mm:ss");
        const endTime = moment(item.end_time).format("YYYY-MM-DDThh:mm:ss");
        return {
          id: item?.id,
          title: `${item.patient?.profile?.user?.first_name +
            " " +
            item.patient?.profile?.user?.last_name
            }`,
          start: startTime,
          end: endTime,
          backgroundColor: "#1eca7b",
          borderColor: "transparent",
        };
      }),
    ];

    setSlots(schedArray[0]);
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    async function getSched() {
      if (appointmentDetails) {
        isMounted && setAppointmentList(appointmentDetails.data);
        isMounted && INITIAL_EVENTS(appointmentDetails.data);
      }
    }

    getSched();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [dateList, appointmentDetails]);
  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      headerToolbar={{
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      }}
      initialView="dayGridMonth"
      events={slots}
      selectable={true}
      selectMirror={true}
      dayMaxEvents={true}
      select={handleDateSelect}
      eventClick={handleEventClick}
      datesSet={(arg) => {
        const { view } = arg;

        const startDate = new Date(view.currentStart);
        startDate.setDate(startDate.getDate() + 1);

        const formattedStartDate = startDate.toISOString().split("T")[0];
        if (view.type === "timeGridDay") {
          getAppointments({
            start_date: formattedStartDate,
            end_date: formattedStartDate,
            status: ['scheduled', 'rescheduled', 'started', 'in_progress'],
            per_page: 100,
          });
        } else if (view.type === "timeGridWeek") {
          const endDate = new Date(startDate);
          endDate.setDate(startDate.getDate() + 6);

          const formattedEndDate = endDate.toISOString().split("T")[0];
          getAppointments({
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            status: ['scheduled', 'rescheduled', 'started', 'in_progress'],
            per_page: 100,
          });
        } else if (view.type === "dayGridMonth") {
          const monthStartDate = new Date(
            view.currentStart.getFullYear(),
            view.currentStart.getMonth(),
            1
          );
          monthStartDate.setDate(monthStartDate.getDate() + 1);
          const formattedMonthStartDate = monthStartDate
            .toISOString()
            .split("T")[0];

          const monthEndDate = new Date(
            view.currentStart.getFullYear(),
            view.currentStart.getMonth() + 1,
            0
          );
          monthEndDate.setDate(monthEndDate.getDate() + 1);
          const formattedMonthEndDate = monthEndDate
            .toISOString()
            .split("T")[0];
          getAppointments({
            start_date: formattedMonthStartDate,
            end_date: formattedMonthEndDate,
            status: ['scheduled', 'rescheduled', 'started', 'in_progress'],
            per_page: 100,
          });
        }
      }}
      eventContent={function (arg) {
        const eventTitle = arg.event.title;
        return {
          html: `<div class="fc-event-title">${eventTitle}</div>`,
        };
      }}
    />
  );
}

// TODO: Refactor. put all business logic to parent component to fully memoize this component
export default memo(Calendar);
