import { AWS_BUCKET_SERVICES, AWS_BUCKET_PROFILES } from '../../constants'
import { useEffect, useRef, useState } from 'react'
import './imageup.css'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa';

export function UploadOneImage({ image, setImage, disabled, inName = null }) {
  const { t: image_upload } = useTranslation('comp_image_upload')
  const [imagepreview, setImagePreview] = useState(false)
  const [previewImage, setPreviewImage] = useState({ path: '', file: '' })
  // const [image, setImage] = useState({})
  const imgRef = useRef()
  const inFlName = inName ? inName : 'input-file-now-custom-1';

  function triggerFileInput() {
    if (imgRef.current) {
      imgRef.current.click()
    }
  }
  const handleImageInputChange = (e) => {
    const [file] = e.target.files
    setImage({ ...image, file: file })
    setPreviewImage({ ...image, file: file })
    setImagePreview(true)
  }
  const fDeselectImage = () => {
    //setImagePreview(false);
    //const newPrevImg = {...previewImage};
    setPreviewImage({ path: '', file: '' });
  };

  useEffect(() => {
    // let isMounted = true
    // const controller = new AbortController()
    let fileReader,
      isCancel = false
    // for (var image in formData.images) {
    if (image.file) {
      fileReader = new FileReader()
      fileReader.onload = (e) => {
        const { result } = e.target
        if (result && !isCancel) {
          const tempimage = { path: result, file: image.file }
          setImage(tempimage)
          // setImages([...images, tempimage])
        }
      }
      fileReader.readAsDataURL(image.file)
    }
    // }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [previewImage])

  const ctnNoImgCss = {
    width: '180px', height: '150px',
    //backgroundColor: '#dae5f4',
    backgroundColor: '#edf0f5',
    border: '1px solid #808080', borderRadius: '5px',
  };
  const upImgCss = {
    objectFit: 'cover', margin: 'unset',
    width: '180px', height: '140px',
    border: '1px solid #808080', borderRadius: '5px',
  };
  const ctnDSelImgCss = {
    position: 'absolute',
    zIndex: 5, //display: 'flex',
    right: '-15px', top: '-15px',
  };
  const ctnDSelImg = (
    <div style={ctnDSelImgCss}>
      <div className='image-close-icon xfloat-right'>
        <button
          type="button"
          className="btn btn-danger btn-round waves-effect waves-light image-close-icon"
          onClick={() => {
            fDeselectImage();//deleteDocumentHandler(document.Id)
          }}
        >
          <FaTimes />
        </button>
      </div>
    </div>
  );

  return (
    <div className="upload-container d-flex flex-column justify-content-center align-items-center" style={{ marginBottom: '10px', marginLeft: '10px', marginRight: '10px', position: 'relative' }}>
      <input
        ref={imgRef} hidden type="file"
        //name="Image" id="input-file-now-custom-1"
        name={inFlName} id={inFlName}
        accept="image/*" // capture="user"
        // capture="filesystem"
        onChange={handleImageInputChange}
      />
      {image.path !== 'undefined' ? (
        <>
          {/* {!imagepreview?AWS_BUCKET_SERVICES + (image.path): (image.path)} */}
          {imagepreview && previewImage.file?.name?.length ? (
            <div>
              {ctnDSelImg}
              <div>
                <img
                  alt=""
                  style={upImgCss}
                  onClick={() => {
                    Swal.fire({
                      // title: 'Profile Picture',
                      customClass: 'swal-wide',
                      html: `<img height="600px" src="${!imagepreview ? image.path : image.path}"></img>`,
                      // { AWS_BUCKET_SERVICES } + profile.picture,
                    })
                  }}
                  src={!imagepreview ? image.path : image.path}
                  className="ob waves-effect waves-light"
                />
              </div>
            </div>
          ) : <div style={ctnNoImgCss}>&nbsp;</div>}
          <div className="row" style={{ marginTop: '10px' }}>
            <button type="button" disabled={disabled} className="btn btn-gradient-success waves-effect waves-light" onClick={triggerFileInput}>
              {image_upload('upload_button_label')}
            </button>
          </div>
        </>
      ) : null}
    </div>
  )
}
//---------------------------------------------------------

export default function UploadImage({ id, images, setImages, previewImage, formData, setFormData, action }) {
  const [imagepreview, setImagePreview] = useState(false)
  const [image, setImage] = useState({})
  const imgRef = useRef()
  const { t: image_upload } = useTranslation('comp_image_upload')

  const picRef = useRef()
  const handleImageInputChange = (e) => {
    const [file] = e.target.files
    setImage({ ...image, file: file })
    setFormData({ ...formData, images: [...images, image] })
  }
  const removeClinicImage = (index) => {
    // setImages([
    //   ...images.slice(0, index),
    //   ...images.slice(index + 1, images.length)
    // ]);
    setImages([...images.slice(0, index), ...images.slice(index + 1, images.length)])
    const tempimage = { path: result, file: image.file }
    updateImages(id, tempimage)
  }
  function triggerFileInput() {
    if (imgRef.current) {
      imgRef.current.click()
    }
  }
  const updateImages = (i, tempimage) => {
    setImages((existingItems) => {
      return [...existingItems.slice(0, i), tempimage, ...existingItems.slice(i + 1)]
    })
  }
  useEffect(() => {
    // let isMounted = true
    // const controller = new AbortController()
    let fileReader,
      isCancel = false
    // for (var image in formData.images) {
    if (image.file) {
      fileReader = new FileReader()
      fileReader.onload = (e) => {
        const { result } = e.target
        if (result && !isCancel) {
          const tempimage = { path: result, file: image.file }
          updateImages(id, tempimage)
          // setImages([...images, tempimage])
          setImagePreview(true)
        }
      }
      fileReader.readAsDataURL(image.file)
    }
    // }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [formData])
  return (
    <div className="upload-container d-flex flex-column justify-content-center align-items-center" style={{ marginBottom: '10px', marginLeft: '10px', marginRight: '10px' }}>
      <input
        hidden
        type="file"
        id="input-file-now-custom-1"
        accept="image/*"
        // capture="user"
        name="Image"
        capture="filesystem"
        ref={imgRef}
        onChange={handleImageInputChange}
      />
      {previewImage ? (
        <>
          <img
            alt=""
            style={{ objectFit: 'cover', margin: 'unset', width: 180, height: 150 }}
            ref={picRef}
            onClick={(e) => {
              Swal.fire({
                // title: 'Profile Picture',
                html: `<img height="300px" src="${!imagepreview ? previewImage.path : previewImage.path}"></img>`,
                // { AWS_BUCKET_SERVICES } + profile.picture,
              })
            }}
            src={!imagepreview ? previewImage.path : previewImage.path}
            className="ob waves-effect waves-light"
          />
          {action === 'edit' || action === 'create' || action === 'new' || action === 'update' ? (
            <div className="row" style={{ marginTop: '10px' }}>
              <button type="button" className="btn btn-gradient-success waves-effect waves-light" onClick={triggerFileInput}>
                {image_upload('change')}
              </button>
              {/* {images.length>1&&id===images.length-1? (
                          <button
                            type="button"
                            className="btn btn-gradient-danger waves-effect waves-light"
                            onClick={()=>
                              {removeClinicImage(id)}}
                            >
                            Remove
                          </button>
                        ): null } */}
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  )
}
