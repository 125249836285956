//import { createSlice, current } from "@reduxjs/toolkit";
import { nHApi } from 'v2/src/lib/services/public/nHApi';
//---------------------------------------------------------

export const providerApi = nHApi.injectEndpoints({
  endpoints: builder => ({
    specialityList: builder.query({
      query: () => {
        let aUrl = '/specialities?per_page=100';
        return { url: aUrl, };
      },
      keepUnusedDataFor: 1200,
    }),
    getService: builder.query({
      query: ({ speciality_id = '' }) => {
        console.log("speciality_id", speciality_id)
        let aUrl = `/services?speciality_id=${speciality_id}`;
        return { url: aUrl, };
      },
    }),
    postDate: builder.mutation({
      query: ({ start_date = '', end_date = '', provider_id, practice_id, }) => {
        console.log("startDate", start_date, "endDate", end_date)
        let aUrl = `/time-slots?provider_id=${provider_id}&practice_id=${practice_id}&start_date=${start_date}&end_date=${end_date}`;
        return { url: aUrl, };
      },
    }),
    addSpeciality: builder.mutation({
      query: (params) => {
        return {
          //name, description, image
          url: '/speciality',
          method: 'POST',
          body: params,
        }
      },
    }),
    setSpeciality: builder.mutation({
      query: (params) => {
        return {
          //name, description, image
          url: '/speciality/' + params.specialityId,
          method: 'POST',
          body: params,
        }
      },
    }),
    deleteSpeciality: builder.mutation({
      query: (params) => {
        return {
          url: '/speciality/' + params.specialityId,
          method: 'DELETE',
        };
      },
    }),
    //---------------------------------------------------------

    availibilityList: builder.query({
      query: (params) => {
        let aUrl = '/availabilities?practice_id=' + params.practiceId;
        return { url: aUrl, };
      },
      //keepUnusedDataFor: 1200,
    }),

    addAvailibility: builder.mutation({
      query: (params) => {
        return {
          //practice_id, day_of_week, start_time, end_time, mode['in:clinic', 'telehealth']
          url: '/availability',
          method: 'POST',
          body: params,
        }
      },
    }),
    setAvailibility: builder.mutation({
      query: (params) => {
        return {
          //day_of_week, start_time, end_time, mode
          url: '/availability/' + params.practiceId,//dayId
          method: 'POST',
          body: params,
        }
      },
    }),
    deleteAvailibility: builder.mutation({
      query: (params) => {
        return {
          url: '/availability/' + params.specialityId,
          method: 'DELETE',
        };
      },
    }),
    //---------------------------------------------------------

    providerDetail: builder.query({
      query: (params) => {//console.log('uPDParams', params);
        let strComps = '';//provider
        const defArrComps = [
          'specialities', //'practices'
        ];
        let provId = params?.providerId;
        let arrComps = params?.arrComps || defArrComps;
        if (arrComps?.length) {
          arrComps = arrComps.map(cmp => 'relations[]=' + cmp);
          strComps = '?' + arrComps.join('&');
        }
        let aUrl = '/provider/' + provId + strComps;
        return { url: aUrl };
        //return { url: aUrl, params: params};
      },
    }),
    setProvider: builder.mutation({
      query: ({ providerId, inParams }) => {
        return {
          //license_number, speciality_ids
          url: '/provider/' + providerId,
          method: 'POST',
          body: inParams,
        }
      },
    }),
    //---------------------------------------------------------

    clinicList: builder.query({
      query: (params) => {
        let aUrl = '/clinics?per_page=100';
        return { url: aUrl, };
      },
      keepUnusedDataFor: 1200,
    }),
    //---------------------------------------------------------

    practiceList: builder.query({
      query: (params) => {
        //provider_id, relation[]=availabilities, page
        let aUrl = '/practice?per_page=100';
        return { url: aUrl, };
      },
      keepUnusedDataFor: 1200,
    }),
    practiceDetail: builder.query({
      query: ({ practiceId, inParams }) => {
        //provider_id, relation[]=availabilities, page
        let aUrl = '/practice/' + practiceId + '?per_page=100';
        return { url: aUrl, };
      },
      keepUnusedDataFor: 1200,
    }),

    addPractice: builder.mutation({
      query: (params) => {
        return {
          //practice_id, day_of_week, start_time, end_time, mode['in:clinic', 'telehealth']
          url: '/availability',
          method: 'POST',
          body: params,
        }
      },
    }),
    setPractice: builder.mutation({
      query: (params) => {
        return {
          //day_of_week, start_time, end_time, mode
          url: '/availability/' + params.practiceId,//dayId
          method: 'POST',
          body: params,
        }
      },
    }),
    deletePractice: builder.mutation({
      query: (params) => {
        return {
          url: '/availability/' + params.specialityId,
          method: 'DELETE',
        };
      },
    })
  }),
});

export const {
  useSpecialityListQuery, useLazyGetServiceQuery, useAddSpecialityMutation,
  useSetSpecialityMutation, useDeleteSpecialityMutation,
  useAvailibilityListQuery, useAddAvailibilityMutation,
  useSetAvailibilityMutation, useDeleteAvailibilityMutation,
  useProviderDetailQuery, useSetProviderMutation,
  useClinicListQuery, usePostDateMutation,
} = providerApi;
//---------------------------------------------------------
