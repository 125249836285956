import { nHApi } from 'v2/src/lib/services/public/nHApi';

export const nHPracticeApi = nHApi.injectEndpoints({
  endpoints: (builder) => ({
    getListOfClinics: builder.query({
      query: (params) => {
        const relations = ['availabilities', 'institution', 'services'];
        const relationsParam = relations
          .map((relation) => `relations[]=${encodeURIComponent(relation)}`)
          .join('&');
        return {
          url: `/practices?${relationsParam}&provider_id=${encodeURIComponent(params?.provider_id)}`,
          method: 'GET',
          params: params,
        };
      },
      providesTags: ['Practice']
    }),
    getClinicData: builder.query({
      query: ({ id }) => {
        const relations = ['availabilities', 'institution', 'services'];

        const relationsParam = relations
          .map((relation) => `relations[]=${encodeURIComponent(relation)}`)
          .join('&');

        return {
          url: `/practice/${id}?${relationsParam}`,
          method: 'GET',
        };
      },
    }),

    getListOfInstitution: builder.query({
      query: (page = 1, perPage = 100) => {
        return {
          url: '/institutions',
          method: 'GET',
          params: {
            page,
            per_page: perPage,
          },
        };
      },
      providesTags: ['Institutions']
    }),
    createInstitution: builder.mutation({
      query: (data) => ({
        url: 'institution',
        method: 'POST',
        body: data,
      }),
    }),
    createClinic: builder.mutation({
      query: (data) => {
        return {
          url: "/practice",
          method: "POST",
          body: data
        }
      },
      invalidatesTags: ['Practice']
    }),
    updateClinic: builder.mutation({
      query: ({ formData, practiceId }) => {
        return {
          url: `/practice/${practiceId}`,
          method: "POST",
          body: formData
        }
      },
      invalidatesTags: ['Practice']
    },
    ),
    postAvailability: builder.mutation({
      query: (data) => {
        return {
          url: "/availability",
          method: "POST",
          body: data
        }
      }
    }),
    updateAvailability: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/availability/${id}`,
          method: "POST",
          body: data
        }
      }
    })
  }),
});

export const {
  useGetListOfClinicsQuery,
  useGetListOfInstitutionQuery,
  useCreateInstitutionMutation,
  useCreateClinicMutation,
  usePostAvailabilityMutation,
  useUpdateClinicMutation,
  useUpdateAvailabilityMutation,
  useLazyGetClinicDataQuery,
} = nHPracticeApi;

