import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { fGetAUserInf } from "v2/src/lib/appUtils";
import { InsuranceStatusText } from "./appProfileFx";
import { LottieSpinner } from "v2/src/core";
import CardItem from "components/cards/Card";
import imgLogo from "assets/images/niuHealthLogo.svg";
import {
  useNHDocumentTypesQuery,
  useUserDocumentListQuery,
  useAddUserDocumentMutation,
  useDeleteUserDocumentMutation,
} from "v2/src/lib/services/nHDocumentApi";

export default function InsuranceDocumentList({ flgAsComponent = false }) {
  let aUData = fGetAUserInf();
  const fNavigate = useNavigate();

  const { t: patient_insurance } = useTranslation("patient_insurance");
  const [stPgMsg, setStPgMsg] = useState("");
  const [stProcessing, setStProcessing] = useState(false);
  const [stPgHasErr, setStPgHasErr] = useState(false);
  const [stHasNoInsurance, setStHasNoInsurance] = useState(0);

  const gDocType = "insurance";
  const arrValidDocTypeId = [];
  const aUserId = aUData?.userIdNH;
  const aUserProfileId = aUData?.details?.profileId;

  const [fDelUserDocument] = useDeleteUserDocumentMutation();

  let objNHDocTypesData = [];
  const {
    data: nHDocTypesApiData,
    isLoading: nHDocTypesWait,
    isFetching: nHDocTypesWait2,
    isError: nHDocTypesErr,
  } = useNHDocumentTypesQuery(
    { userType: aUData?.userType, category: gDocType }

  );
  if (nHDocTypesApiData?.data?.length) {

    objNHDocTypesData = nHDocTypesApiData?.data || [];
    objNHDocTypesData.forEach((dTI) => {
      dTI?.category === gDocType && arrValidDocTypeId.push(dTI.id);
    });
  }
  objNHDocTypesData && console.log("objNHDocTypesData", objNHDocTypesData);

  let objUserInsDocsData = [];
  const {
    data: userInsDocsApiData,
    isLoading: userInsDocsWait,
    isFetching: userInsDocsWait2,
    isError: userInsDocsErr,
    error: userInsDocsErrData,
    isSuccess: userInsDocsOK,
  } = useUserDocumentListQuery(
    { profileId: aUserProfileId, category: gDocType },
    { skip: aUserProfileId === null, refetchOnMountOrArgChange: true }
  );
  if (
    userInsDocsErrData &&
    userInsDocsErrData?.status === false &&
    userInsDocsErrData?.message?.toLowerCase() === "no documents found"
  ) {
    objUserInsDocsData = [];
  } else if (userInsDocsApiData?.data?.length) {
    objUserInsDocsData = userInsDocsApiData?.data.filter((uD) =>
      arrValidDocTypeId.includes(uD.document_type_id)
    );
  }
  console.log("userInsDocsErrData", userInsDocsErrData);
  objUserInsDocsData &&
    console.log("userInsDocsData_" + userInsDocsErr, objUserInsDocsData);

  const isPageLoading =

    userInsDocsWait || userInsDocsWait2;
  const fResetProcessing = (flgClearPgMsg = false) => {
    setStProcessing(false);

    setStPgMsg("");
    setStPgHasErr(false);

  };


  const fSetNoInsurance = (e) => {
    const chkB = e.target;
    if (chkB) {
      chkB?.checked ? setStHasNoInsurance(1) : setStHasNoInsurance(0);
    }
  };

  if (stHasNoInsurance === 1) {
    Swal.fire({
      imageUrl: imgLogo,
      imageHeight: 100,
      html: `${patient_insurance("msg_subscription_redirect")}`,
      showConfirmButton: false,
      showCancelButton: false,
    });
    setTimeout(() => {
      setStHasNoInsurance(2);
      Swal.close();
      fNavigate("/patient/subscription");
    }, 3000);
  }
  const pgCtnCss = flgAsComponent
    ? {
      width: "100%",
      position: "relative",
      padding: "0 15px 60px 15px",
    }
    : {};

  const loaderJsx = (
    <div className="d-flex justify-content-center">
      <LottieSpinner size={200} />
    </div>
  );

  return (
    <div id="ctnProffInsDoc">


      <div className="file-box-content">
        {objUserInsDocsData?.length === 0 ? (
          <>
            <CardItem>
              {isPageLoading ? (
                <div className="d-flex justify-content-center">
                  <LottieSpinner size={200} />
                </div>
              ) : (
                <>
                  {patient_insurance("no_insurance_folders")}
                  <br />
                  {patient_insurance("msg_upload_insurance")}
                </>
              )}
            </CardItem>
          </>
        ) : (
          <></>
        )}
        {objUserInsDocsData?.map((uDoc, idx) => (
          <Link
            key={idx}
            style={{ background: "none", marginLeft: "2px" }}
            to={`/patient/insurance/folders/${uDoc.id}`}
          >
            <div className="file-box">

              <div className=" d-flex flex-column align-items-center gap-3">
                <i
                  className={
                    uDoc?.status == "1"
                      ? "far fa-folder text-success mb-3"
                      : uDoc?.status == "2"
                        ? "far fa-folder text-danger "
                        : "far fa-folder app-green-color mb-3"
                  }
                />
                <InsuranceStatusText status={uDoc?.status} />
                <small className="text-muted">
                  {uDoc?.status_updated_at &&
                    "Uploaded " +
                    moment(
                      uDoc?.status_updated_at.replace(/-/g, "/").slice(0, 19)
                    ).format("hh:mm a MMM DD, YYYY")}
                </small>
              </div>
            </div>
          </Link>
        ))}
      </div>

      <div className="padS dFlexC">
        <div className="xdInFlex">
          {objUserInsDocsData?.length === 0 && (
            <span className="checkbox checkbox-success">
              <input
                id="chkNoInsurance"
                type="checkbox"
                checked={stHasNoInsurance > 0}
                onChange={(e) => fSetNoInsurance(e)}
              />
              <label htmlFor="chkNoInsurance">
                {patient_insurance("has_no_insurance")}
              </label>
            </span>
          )}
        </div>

        <div className="dxInFlex">
          <Link to={"/patient/insurance/upload"}>
            <button
              type="button"
              className="float-right btn btn-success btn-round waves-effect waves-light mt-2 m-2"
              disabled={objUserInsDocsData?.length >= 2}
            >
              {patient_insurance("new_insurance")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
