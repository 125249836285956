import { nHApi } from 'v2/src/lib/services/public/nHApi';

export const nHAddressApi = nHApi.injectEndpoints({
  endpoints: builder => ({
    postAddress: builder.mutation({
      query: ({ formData }) => {
        return {
          url: '/address',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Institutions']
    }),
  }),
});

export const {
  usePostAddressMutation,
} = nHAddressApi;