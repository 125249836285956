import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetListOfClinicsQuery } from 'v2/src/lib/services/nHPracticesApi';
import { AWS_BUCKET_SERVICES } from 'constants';

function Clinics() {
  const navigate = useNavigate();
  const { t: provider_clinic } = useTranslation('provider_clinic');

  const provider_id = localStorage.getItem("providerId");
  const { data: listOfClinics, error, isLoading } = useGetListOfClinicsQuery({
    page: 1,
    per_page: 100,
    provider_id,
  });

  const [clinicList, setClinicList] = useState([]);

  useEffect(() => {
    if (listOfClinics) {
      setClinicList(listOfClinics?.data || []);
    }
  }, [listOfClinics]);

  const handleCreateClick = () => {
    navigate('create');
  };

  return (
    <div className="container-fluid">
      <div className="page-title-box">
        <div className="d-flex justify-content-between align-items-center ">
          <h4 className="page-title">{provider_clinic('clinics')}</h4>
          <ol className="breadcrumb">
            <button
              type="button"
              className="btn btn-success waves-effect waves-light"
              onClick={handleCreateClick}
            >
              {provider_clinic('new_clinic')}
            </button>
          </ol>
        </div>
      </div>
      <div className="row">
        {clinicList.length > 0 ? (
          clinicList.map((clinic, index) => (
            <div key={index} className="col-sm-12 col-md-6">
              <Link to={`profile/${clinic.id}`} state={{ id: clinic.id }}>
                <div className="card flex-sm-col flex-md-row overflow-hidden">
                  <img
                    className="card-img-top"
                    src={clinic.image_url || `${AWS_BUCKET_SERVICES}/assets/images/default-clinic.jpg`}
                    alt={clinic.institution?.name}
                    style={{
                      width: '200px',
                      height: '150px',
                      objectFit: 'cover',
                    }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{clinic.institution?.name}</h5>
                    <p className="card-text mb-0">{clinic.position}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <div className="col-12">
            <p>{provider_clinic('no_clinics_found')}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Clinics;
