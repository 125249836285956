//import AppLogo from './AppLogo';
import AppLogo from './AppLogo';
//import Loader from './Loader/Loader';
import PageLoader from './Loader/PageLoader';
import LoadComponent from './Loader/LoadComponent';
//import Spinner from './Loader/Spinner';
//import SpinnerBox from './Loader/SpinnerBox';
//import LottieSpinner from './Loader/LottieSpinner';
import LottieSpinner from './Loader/RingLoading';
import Flasher from './MessageBox/Flasher';
import PageMessage from './MessageBox/PageMessage';
//import UploadImage from './UploadImage';
//import DatePicker from './Calendar/DatePicker';
import  LanguageDropdown from './LanguageDropdown';
import PasswordValidationList from './PasswordValidationList';
//---------------------------------------------------------

export {
  AppLogo, PageLoader, LoadComponent, //SpinnerBox, Spinner,
  LottieSpinner,
  Flasher, PageMessage, //UploadImage, DatePicker,
  LanguageDropdown, PasswordValidationList,
};
