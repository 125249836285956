import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { TableTitle } from 'components/table/Tables'
import useAuth from 'hooks/useAuth'
import useAxiosPrivate from 'hooks/useAxiosPrivate'
import ScheduleSelect from 'components/time/Hours'
import UploadImage from 'v2/src/core/UploadImage/UploadImage'
import { Autocomplete, TextField, Typography, } from '@mui/material';
import { Controller, } from "react-hook-form";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { useCreateClinicMutation, useCreateInstitutionMutation, useGetListOfInstitutionQuery, useLazyGetClinicDataQuery, usePostAvailabilityMutation, useUdateClinicMutation, useUpdateAvailabilityMutation, useUpdateClinicMutation } from 'v2/src/lib/services/nHPracticesApi'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { Button, Modal } from 'react-bootstrap'
import { usePlacesWidget } from 'react-google-autocomplete'
import {
  DEF_COUNTRY_ID,
  DEF_COUNTRY_CODE,
  DEF_LANGUAGE_CODE,
  GOOGLE_PLACES_API_KEY,
} from "v2/src/config/appConfig";
import { useLocationCompQuery } from 'v2/src/lib/services/locationApi'
import { fParseGglPlaces } from 'v2/src/lib/appUtils'
import { usePostAddressMutation } from 'v2/src/lib/services/nHAddressApi'
import PhoneInput from 'react-phone-number-input/input'

export default function ClinicScheduleN() {
  const location = useLocation();
  const id = location.state?.id;
  const { auth } = useAuth()
  const { action, clinicID } = useParams()
  const axiosPrivate = useAxiosPrivate()
  const { t: provider_clinic } = useTranslation('provider_clinic');
  const imgRef = useRef()
  const navigate = useNavigate()

  // ----------------------------------------------
  const selCountryId = DEF_COUNTRY_ID;
  const aUserLanguageCode = DEF_LANGUAGE_CODE;
  const defCountryCode = DEF_COUNTRY_CODE === "USA" ? "US" : DEF_COUNTRY_CODE;
  let addressLine1
  let addressLine2

  // ----------------------------------------------
  const [stateName, setStateName] = useState('');
  const [stateId, setStateId] = useState('');
  const [city, setCity] = useState('')
  const [zipCode, setZipCode] = useState('');
  const [address1, setAddress1] = useState()
  const [address2, setAddress2] = useState()
  const [address, setAddress] = useState('')

  const [clinicImages, setClinicImages] = useState([{ path: 'clinics/Default.png' }])
  const [prefillData, setPrefilledData] = useState(null)

  const [listOfInstitution, setListOfInstitution] = useState(null)
  const [inputValue, setInputValue] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedClinic, setSelectedClinic] = useState('');
  const [institutionId, setInstitutionId] = useState(null);

  const {
    control,
    getValues,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    reset,
    setValue,
    watch,
    clearErrors
  } = useForm()

  const [hours, setHours] = useState({
    HoursMonStart: 8,
    HoursMonEnd: 20,
    HoursTueStart: 8,
    HoursTueEnd: 20,
    HoursWedStart: 8,
    HoursWedEnd: 20,
    HoursThuStart: 8,
    HoursThuEnd: 20,
    HoursFriStart: 8,
    HoursFriEnd: 20,
    HoursSatStart: 8,
    HoursSatEnd: 20,
    HoursSunStart: 8,
    HoursSunEnd: 20,
  })

  const { data: getListOfInstitution } = useGetListOfInstitutionQuery()
  const [createInstitution] = useCreateInstitutionMutation()
  const [createClinic] = useCreateClinicMutation();
  const [postAvailability] = usePostAvailabilityMutation()
  const [updateClinic] = useUpdateClinicMutation()
  const [updateAvailability] = useUpdateAvailabilityMutation()
  const [postAddress] = usePostAddressMutation()
  const [getClinicData, { data: clinicDetail }] = useLazyGetClinicDataQuery()

  const {
    data: locApiData,
  } = useLocationCompQuery(
    { countryId: selCountryId },
    { skip: selCountryId === null }
  );

  useEffect(() => {
    if (locApiData) {
      let id = locApiData?.states?.data?.filter((obj) => obj?.code === stateName)[0]?.id
      setStateId(id);
    }
  }, [locApiData, stateName])

  const range = (start, end, step) => {
    const result = [];
    for (let i = start; i < end; i += step) {
      result.push(i);
    }
    return result;
  };

  const Datepicker = ({ selectedDate, handleDateChange, maxDate, disabled, className }) => {
    const years = range(1990, new Date().getFullYear() + 1, 1);
    const months = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December",
    ];

    return (
      <DatePicker
        selected={selectedDate}
        onChange={(date) => handleDateChange(date ? date.toISOString().split("T")[0] : null)}
        dateFormat="MM/dd/yyyy"
        maxDate={maxDate}
        className={`form-control ${className}`}
        placeholderText="MM/DD/YYYY"
        disabled={disabled}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
            <button
              onClick={(e) => { e.preventDefault(); decreaseMonth(); }}
              disabled={prevMonthButtonDisabled || disabled}
            >
              {"<"}
            </button>
            <select
              value={new Date(date).getFullYear()}
              onChange={({ target: { value } }) => changeYear(Number(value))}
              disabled={disabled}
            >
              {years.map(option => <option key={option} value={option}>{option}</option>)}
            </select>
            <select
              value={months[new Date(date).getMonth()]}
              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
              disabled={disabled}
            >
              {months.map(option => <option key={option} value={option}>{option}</option>)}
            </select>
            <button
              onClick={(e) => { e.preventDefault(); increaseMonth(); }}
              disabled={nextMonthButtonDisabled || disabled}
            >
              {">"}
            </button>
          </div>
        )}
      />
    );
  };

  const fAutoPopulateAddress = async (tEl, plInf, langCode = aUserLanguageCode) => {
    try {
      const addrComps = await fParseGglPlaces(plInf, GOOGLE_PLACES_API_KEY, langCode);

      if (addrComps) {
        const { route, streetNum } = addrComps;
        setAddress1(route?.long_name || '');
        setAddress2(streetNum?.long_name || '');
      } else {
        throw new Error('Address components not found');
      }
    } catch (error) {
      console.error('Error in fAutoPopulateAddress:', error);
    }
  };


  const sendAddress = async (id) => {
    try {
      const formData = new FormData();
      formData.append('addressable_type', 'institution');
      formData.append('stateName', stateName);
      formData.append('state_id', stateId);
      formData.append('city', city);
      formData.append('country_id', selCountryId);
      formData.append('zipcode', zipCode);
      formData.append('addressable_id', id);
      formData.append('is_primary', 1);

      if (address1) formData.append('address_line_1', address1);
      if (address2) formData.append('address_line_2', address2);

      const response = await postAddress({ formData });

      if (response.error) {
        throw new Error('Failed to submit address');
      }
    } catch (error) {
      alert('An error occurred while submitting the address. Please try again later.');
    }
  };

  const AutocompleteLoaded = ({ initialVal }) => {
    const [prefilledVal, setPrefillVal] = useState(initialVal);
    const { ref: addrRef } = usePlacesWidget({
      apiKey: GOOGLE_PLACES_API_KEY,
      onPlaceSelected: (place) => {
        try {
          if (place && addrRef.current) {
            const { name, address_components } = place;

            // Update the address field if the name is available
            if (name) {
              setPrefillVal(name);
              setInputValue(name);
            }

            // Extract and set address components
            address_components?.forEach((component) => {
              const { types, long_name, short_name } = component;
              if (types.includes('locality')) setCity(long_name);
              if (types.includes('administrative_area_level_1')) setStateName(short_name);
              if (types.includes('postal_code')) setZipCode(long_name);
            });

            fAutoPopulateAddress(addrRef.current, place, aUserLanguageCode);
          }
        } catch (error) {
          console.error('Error extracting place details:', error);
        }
      },
      options: {
        language: aUserLanguageCode,
        types: ['dentist', 'doctor', 'hospital', 'physiotherapist'],
        inputtype: 'textquery',
        componentRestrictions: { country: defCountryCode },
        fields: ["address_components", "geometry", "icon", "name"],
      },
    });

    return (
      <input
        ref={addrRef}
        type="text"
        className="form-control"
        name="Address1"
        id="elAddr1"
        value={prefilledVal}
        onChange={(e) => setPrefillVal(e.target.value)}
        style={{ width: '100%', margin: '10px 0' }}
      />
    );
  };

  useEffect(() => {
    if (id) {
      getClinicData({ id: id })
    }
  }, [id])

  useEffect(() => {
    if (clinicDetail) {
      setPrefilledData(clinicDetail?.data)
    }
  }, [clinicDetail])

  const getPrimaryAddressByHospital = (hospitals, name) => {
    const hospital = hospitals.find(hospital => hospital.name === name);

    if (!hospital) {
      return provider_clinic('no_clinic_selected');
    }

    const primaryAddress = hospital.addresses.find(addr => addr.is_primary == 1);

    if (!primaryAddress) {
      return provider_clinic('no_primary_address_found');
    }

    const { address_line_1, address_line_2, city, zipcode } = primaryAddress;
    return `${address_line_1 || ''}, ${address_line_2 || ''}, ${city || ''}, ${zipcode || ''}`;
  };

  useEffect(() => {
    if (getListOfInstitution?.data) {
      const institutionList = getListOfInstitution.data.map((obj) => obj?.name);
      const result = getPrimaryAddressByHospital(getListOfInstitution.data, inputValue);
      setAddress(result);
      setListOfInstitution(institutionList);
    }
  }, [getListOfInstitution, inputValue]);


  const createNewInstitution = async (institutionData) => {
    try {
      const result = await createInstitution(institutionData).unwrap();
      toast.success(result?.message);
      setSelectedClinic(result?.data?.name);
      if (result?.status) sendAddress(result?.data?.id);
    } catch (error) {
      console.error('Error creating institution:', error);
      toast.error('Failed to create institution');
    } finally {
      setOpenDialog(false); // Ensuring dialog is closed even if there is an error.
    }
  };

  useEffect(() => {
    if (getListOfInstitution?.data && (selectedClinic || prefillData?.institution?.name)) {
      const institution = getListOfInstitution.data.find((obj) => obj.name === (selectedClinic || prefillData?.institution?.name));
      setInstitutionId(institution?.id);
    }
  }, [getListOfInstitution, selectedClinic, prefillData]);

  const onSubmit = async (data) => {
    const providerId = localStorage.getItem('providerId');
    let formattedStartDate;
    let formattedEndDate;
    if (data.StartDate) formattedStartDate = new Date(data.StartDate).toISOString().split('T')[0]; // Format as YYYY-MM-DD
    if (data.EndDate) formattedEndDate = new Date(data.EndDate).toISOString().split('T')[0]; // Format as YYYY-MM-DD

    // Prepare working hours
    const workingHours = {
      monday: { start: convertTo12HourFormat(data?.HoursmondayStart), end: convertTo12HourFormat(data?.HoursmondayEnd) },
      tuesday: { start: convertTo12HourFormat(data?.HourstuesdayStart), end: convertTo12HourFormat(data?.HourstuesdayEnd) },
      wednesday: { start: convertTo12HourFormat(data?.HourswednesdayStart), end: convertTo12HourFormat(data?.HourswednesdayEnd) },
      thursday: { start: convertTo12HourFormat(data?.HoursthursdayStart), end: convertTo12HourFormat(data?.HoursthursdayEnd) },
      friday: { start: convertTo12HourFormat(data?.HoursfridayStart), end: convertTo12HourFormat(data?.HoursfridayEnd) },
      saturday: { start: convertTo12HourFormat(data?.HourssaturdayStart), end: convertTo12HourFormat(data?.HourssaturdayEnd) },
      sunday: { start: convertTo12HourFormat(data?.HourssundayStart), end: convertTo12HourFormat(data?.HourssundayEnd) },
    };

    function convertTo12HourFormat(hour) {
      let ampm = hour >= 12 ? 'PM' : 'AM';
      hour = hour % 12;
      hour = hour ? hour : 12;
      return `${hour < 10 ? '0' : ''}${hour}:00 ${ampm}`;
    }

    const formData = new FormData();
    formData.append('provider_id', providerId);
    formData.append('institution_id', institutionId);
    formData.append('is_current', data.checkboxValue ? 1 : 0);

    // Append additional form data
    if (formattedStartDate) formData.append('start_date', formattedStartDate);
    if (formattedEndDate) formData.append('end_date', formattedEndDate);
    if (data.Responsibilities) formData.append('responsibilities', data.Responsibilities);
    if (data.Position) formData.append('position', data.Position);
    if (data.Achievements) formData.append('achievements', data.Achievements);
    if (data.SupervisorName) formData.append('supervisor_name', data.SupervisorName);
    if (data.SupervisorContact) formData.append('supervisor_contact', data.SupervisorContact);

    if (action === 'create') {
      formData.append('image', data?.clinicImage?.file);
    } else if (action === 'edit') {
      if (typeof data?.clinicImage?.file === 'object') formData.append('image', data?.clinicImage?.file);
    }

    const updateAvailabilities = async (data, id) => {
      let result
      try {
        result = await updateAvailability({ data, id }).unwrap();
      } catch (error) {
      }
    }

    const handleWorkingHours = async (clinicId) => {
      for (const day in workingHours) {
        const { start, end } = workingHours[day];
        const workingHour = new FormData();
        workingHour.append("practice_id", clinicId);
        workingHour.append("day_of_week", day);
        workingHour.append("start_time", start);
        workingHour.append("end_time", end);
        workingHour.append("mode", "clinic");
        await postAvailability(workingHour).unwrap();
      }
    };

    try {
      let result;
      if (action === 'create') {
        result = await createClinic(formData).unwrap();
        toast.success(result?.message);
        if (result?.status) {
          await handleWorkingHours(result?.data?.id);
        }
      } else if (action === 'edit') {
        const practiceId = prefillData?.id;
        result = await updateClinic({ formData, practiceId }).unwrap();
        toast.success(result?.message);

        // Handle updated working hours
        if (result?.status) {
          const mergedSchedule = {};
          for (const day in workingHours) {
            if (prefillData?.availabilities[day]) {
              mergedSchedule[day] = {
                id: prefillData?.availabilities[day][0].id,
                start_time: workingHours[day]?.start,
                end_time: workingHours[day]?.end,
                day_of_week: day,
              };
            }
          }
          for (const day in mergedSchedule) {
            const { start_time, end_time, id } = mergedSchedule[day];
            const workingHour = new FormData();
            workingHour.append("day_of_week", day);
            workingHour.append("start_time", start_time);
            workingHour.append("end_time", end_time);
            workingHour.append("mode", "clinic");
            updateAvailabilities(workingHour, id)
          }
        }
      }

      reset();
      setSelectedClinic(null);
      setInputValue('');
    } catch (error) {
      console.error('Error during submission:', error);
      toast.error('Failed to submit. Please try again.');
    }

    navigate('/provider/clinics');
  };

  function convertTo24Hour(time) {
    const [timeStr, modifier] = time.split(" ");
    const [hours, minutes] = timeStr.split(":");

    let convertedHour = parseInt(hours);
    if (modifier === "PM" && convertedHour !== 12) {
      convertedHour += 12;
    }
    if (modifier === "AM" && convertedHour === 12) {
      convertedHour = 0;
    }

    return convertedHour;
  }

  useEffect(() => {
    if (prefillData) {
      setValue("ClinicName", prefillData?.institution?.name || '',);
      setValue("Position", prefillData?.position || '',);
      setValue("StartDate", prefillData?.start_date || '',);
      setValue("EndDate", prefillData?.end_date || '',);
      setValue("Achievements", prefillData?.achievements || '',);
      setValue("Responsibilities", prefillData.responsibilities || '',);
      setValue("SupervisorName", prefillData?.supervisor_name || '',);
      setValue("SupervisorContact", prefillData?.supervisor_contact || '',);
      setValue("checkboxValue", prefillData?.is_current);

      if (prefillData?.availabilities) {

        Object.keys(prefillData?.availabilities).forEach(day => {
          const dayData = prefillData?.availabilities[day];

          if (dayData && dayData[0]?.start_time && dayData[0]?.end_time) {
            const startHour = convertTo24Hour(dayData[0].start_time);
            const endHour = convertTo24Hour(dayData[0].end_time);

            setValue(`Hours${day}Start`, startHour);
            setValue(`Hours${day}End`, endHour);
          }
        });
      }
    }
  }, [prefillData, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container-fluid">
        <TableTitle title={'Clinic Profile'}>
          <div className="float-right">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/provider">{provider_clinic('niu_health')}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/provider/clinics">{provider_clinic('clinics')}</Link>
              </li>
              <li className="breadcrumb-item active">
                {action === 'create' ? `${provider_clinic('new_clinic')}` : action === 'edit' ? `${provider_clinic('edit_clinic')}` : `${provider_clinic('clinic')}`}
              </li>
            </ol>
          </div>
        </TableTitle>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <UploadImage
                        control={control}
                        name="clinicImage"
                        setValue={setValue}
                        watch={watch}
                        errors={errors}
                        clearErrors={clearErrors}
                        prefilledImage={prefillData?.image_url}
                        action={action}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="ClinicName" className="col-form-label text-right required">{provider_clinic('clinic_name')}</label>
                      </div>

                      <div className="col-md-12">
                        <Controller
                          name="ClinicName"
                          control={control}
                          rules={{ required: `${provider_clinic('clinic_name_is_required')}` }}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              size='small'
                              value={selectedClinic || field.value || ''}
                              onChange={(event, newValue) => {
                                field.onChange(newValue)
                                setSelectedClinic(newValue)
                              }}
                              disabled={action === 'profile'}
                              inputValue={inputValue}
                              onInputChange={(event, newInputValue) => {
                                !openDialog && setInputValue(newInputValue)
                                field.onChange(newInputValue)
                              }}
                              options={listOfInstitution}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Clinic Name"
                                  error={!!errors.ClinicName} // Set error to true if there's a validation error
                                  sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: '1px solid #e8ebf3',
                                      },
                                      '&:hover fieldset': {
                                        border: '1px solid #e8ebf3',
                                      },
                                      '&.Mui-focused fieldset': {
                                        border: '1px solid rgba(23, 97, 253, 0.5)',
                                      },
                                    },
                                  }}
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props}>
                                  {option}
                                </li>
                              )}
                              noOptionsText={
                                inputValue && listOfInstitution && !listOfInstitution.includes(inputValue) ? (
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      setOpenDialog(true);
                                    }}
                                    style={{
                                      width: '100%',
                                      textAlign: 'center',
                                      padding: '10px',
                                      marginTop: '10px',
                                      backgroundColor: "green",
                                      color: "white"
                                    }}
                                  >
                                    {provider_clinic('add')} "{inputValue}" {provider_clinic('as_an_option')}
                                  </Button>
                                ) : (
                                  `${provider_clinic('no_options')}`
                                )
                              }
                            />
                          )}
                        />

                        <Modal show={openDialog} onHide={() => setOpenDialog(false)}>
                          <Modal.Header>
                            <Modal.Title
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <b>{provider_clinic('add_new_clinic')}</b>
                              <div className="image-close-icon xfloat-right">
                                <button
                                  type="button"
                                  className="btn btn-danger btn-round waves-effect waves-light image-close-icon"
                                  onClick={() => setOpenDialog(false)}
                                >
                                  <FaTimes />
                                </button>
                              </div>
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body style={{ height: "fit-content" }}>
                            <form>
                              <Typography variant="body1" align="center">
                                {provider_clinic('do_you_want_to_add')}{" "}<strong>"{inputValue}"</strong>{" "}{provider_clinic('as_a_new_clinic')}
                              </Typography>
                              <div className="col-sm-10" style={{ maxWidth: "100%" }} >
                                {openDialog && <AutocompleteLoaded initialVal={inputValue} />}
                              </div>
                            </form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="success"
                              onClick={() => {
                                createNewInstitution({ name: inputValue })
                              }}
                            >
                              {provider_clinic('add')}
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        {errors.ClinicName && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.ClinicName.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="Address1" className="col-form-label text-right">
                          {provider_clinic('clinic_address')}
                        </label>
                      </div>
                      <div className="col-md-12">
                        <input
                          className={`form-control ${errors.Address1 ? 'is-invalid' : ''}`}
                          type="text"
                          id="Address1"
                          disabled={true}
                          name="Address1"
                          defaultValue={address}

                        />
                        {errors.Address1 && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.Address1.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {/* Position */}
                  <div className="col-lg-6">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="Position" className="col-form-label text-right required">{provider_clinic('position')}</label>
                      </div>
                      <div className="col-md-12">
                        <input
                          className={`form-control ${errors.Position ? 'is-invalid' : ''}`}
                          type="text"
                          disabled={action === 'profile'}
                          id="Position"
                          name="Position"
                          {...register('Position', { required: `${provider_clinic('position_is_required')}` })}
                        />
                        {errors.Position && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.Position.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="Achievements" className="col-form-label text-right">{provider_clinic('achievements')}</label>
                      </div>
                      <div className="col-md-12">
                        <input
                          className={`form-control ${errors.Achievements ? 'is-invalid' : ''}`}
                          type="text"
                          id="Achievements"
                          disabled={action === 'profile'}
                          name="Achievements"
                          {...register('Achievements',
                            // { required: provider_clinic('achievements_is_required') }
                          )}
                        />
                        {errors.Achievements && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.Achievements.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="SupervisorName" className="col-form-label text-right">{provider_clinic('supervisor_name')}</label>
                      </div>
                      <div className="col-md-12">
                        <input
                          className={`form-control ${errors.SupervisorName ? 'is-invalid' : ''}`}
                          type="text"
                          id="SupervisorName"
                          disabled={action === 'profile'}
                          name="SupervisorName"
                          {...register('SupervisorName',
                            // { required: provider_clinic('supervisor_name_is_required') }
                          )}
                        />
                        {errors.SupervisorName && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.SupervisorName.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="SupervisorContact" className="col-form-label text-right">
                          {provider_clinic('supervisor_contact')}
                        </label>
                      </div>
                      <div className="col-md-12">
                        <Controller
                          name="SupervisorContact"
                          control={control}
                          rules={{
                            validate: (value) => {
                              if (value) {
                                const cleanedValue = value.replace(/[^\d]/g, '');
                                if (cleanedValue.length < 8) {
                                  return provider_clinic('supervisor_contact_must_be_at_least_8_characters_long');
                                }
                                if (cleanedValue.length > 11) {
                                  return provider_clinic('supervisor_contact_must_be_no_longer_than_10_characters');
                                }
                                if (!/^\+1\d{7,10}$/.test(value)) {
                                  return provider_clinic('supervisor_contact_must_be_a_valid_phone_number');
                                }
                              }
                              return true; // If all validations pass
                            }
                          }}
                          render={({ field }) => (
                            <PhoneInput
                              {...field}
                              id="phone"
                              international
                              defaultCountry="US"
                              value={field.value}
                              onChange={(value) => field.onChange(value)} // Handle the value change
                              disabled={action === 'profile'}
                              className={"form-control"}
                              placeholder="(xxx) xxx-xxxx" // Custom placeholder
                            />
                          )}
                        />

                        {errors.SupervisorContact && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.SupervisorContact.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {/* Start Date */}
                  <div className="col-lg-6">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="StartDate" className="col-form-label text-right required">{provider_clinic('start_date')}</label>
                      </div>
                      <div className="col-md-12">
                        <Controller
                          name="StartDate"
                          control={control}
                          rules={{
                            required: `${provider_clinic('start_date_is_required')}`,
                            validate: {
                              notFutureDate: value => {
                                const today = new Date();
                                const selectedDate = new Date(value);
                                if (selectedDate > today) {
                                  return `${provider_clinic('start_date_cannot_be_a_future_date')}`;
                                }
                                return true;
                              }
                            }
                          }}
                          render={({ field }) => (
                            <Datepicker
                              selectedDate={field.value}
                              handleDateChange={field.onChange}
                              maxDate={new Date()}
                              disabled={action === 'profile'}
                              className={errors.StartDate ? 'form-control is-invalid' : 'form-control'}
                            />
                          )}
                        />
                        {errors.StartDate && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.StartDate.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Responsibilities */}
                  <div className="col-lg-6">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="Responsibilities" className="col-form-label text-right">{provider_clinic('responsibilities')}</label>
                      </div>
                      <div className="col-md-12">
                        <input
                          className={`form-control ${errors.Responsibilities ? 'is-invalid' : ''}`}
                          type="text"
                          id="Responsibilities"
                          disabled={action === 'profile'}
                          name="Responsibilities"
                          {...register('Responsibilities',
                            // { required: `${provider_clinic('responsibilities_is_required')}` }
                          )}
                        />
                        {errors.Responsibilities && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.Responsibilities.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="checkbox" className="col-form-label text-right">
                          {provider_clinic('is_current')} {/* This is the label */}
                        </label>
                      </div>
                      <div className="col-md-12">
                        <Controller
                          name="checkboxValue"
                          control={control}
                          defaultValue={false}
                          disabled={action === 'profile'}
                          render={({ field }) => (
                            <div className="custom-control custom-switch checkbox-success">
                              <input
                                type="checkbox"
                                id="toggle"
                                {...field}
                                checked={field.value}
                                onChange={(e) => field.onChange(e.target.checked)}
                                className={`custom-control-input form-control ${Boolean(errors.checkboxValue) ? 'is-invalid' : ''}`}
                              />
                              <label htmlFor="toggle" className="custom-control-label text-muted">

                              </label>
                              {errors.checkboxValue && (
                                <label className="text-danger">
                                  {error_message(errors.checkboxValue.message)}
                                </label>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  {watch('checkboxValue') === false && <div className="col-lg-6">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="EndDate" className="col-form-label text-right required">
                          {provider_clinic('end_date')}
                        </label>
                      </div>
                      <div className="col-md-12">
                        <Controller
                          name="EndDate"
                          control={control}
                          rules={{
                            required: provider_clinic('end_date_is_required'),
                            validate: {
                              isGreaterThanStartDate: (value) => {
                                const startDate = new Date(getValues('StartDate'));
                                const endDate = new Date(value);
                                if (endDate <= startDate) {
                                  return `${provider_clinic('end_date_must_be_greater_than_start_date')}`;
                                }
                                return true;
                              },
                            },
                          }}
                          render={({ field }) => (
                            <Datepicker
                              selectedDate={field.value}
                              handleDateChange={field.onChange}
                              maxDate={null}
                              disabled={action === 'profile'}
                            />
                          )}
                        />
                        {errors.EndDate && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.EndDate.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>}
                </div>
                <div className="row">
                  <div>
                    <div className="col-md-12">
                      <label htmlFor="Working_hours" className="col-form-label text-right">
                        {provider_clinic('working_hours')}
                      </label>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group row">

                        <div className="col-md-3">
                          <h5>{provider_clinic('sunday')}</h5>
                          <ScheduleSelect
                            hours={hours}
                            setHours={setHours}
                            weekday="sunday"
                            disabled={action === 'profile'}
                            register={register}
                          />
                        </div>

                        <div className="col-md-3">
                          <h5>{provider_clinic('monday')}</h5>
                          <ScheduleSelect
                            hours={hours}
                            setHours={setHours}
                            weekday="monday"
                            disabled={action === 'profile'}
                            register={register}
                          />
                        </div>

                        <div className="col-md-3">
                          <h5>{provider_clinic('tuesday')}</h5>
                          <ScheduleSelect
                            hours={hours}
                            setHours={setHours}
                            weekday="tuesday"
                            disabled={action === 'profile'}
                            register={register}
                          />
                        </div>


                        <div className="col-md-3">
                          <h5>{provider_clinic('wednesday')}</h5>
                          <ScheduleSelect
                            hours={hours}
                            setHours={setHours}
                            weekday="wednesday"
                            disabled={action === 'profile'}
                            register={register}
                          />
                        </div>


                        <div className="col-md-3">
                          <h5>{provider_clinic('thursday')}</h5>
                          <ScheduleSelect
                            hours={hours}
                            setHours={setHours}
                            weekday="thursday"
                            disabled={action === 'profile'}
                            register={register}
                          />
                        </div>


                        <div className="col-md-3">
                          <h5>{provider_clinic('friday')}</h5>
                          <ScheduleSelect
                            hours={hours}
                            setHours={setHours}
                            weekday="friday"
                            disabled={action === 'profile'}
                            register={register}
                          />
                        </div>

                        <div className="col-md-3">
                          <h5>{provider_clinic('saturday')}</h5>
                          <ScheduleSelect
                            hours={hours}
                            setHours={setHours}
                            weekday="saturday"
                            disabled={action === 'profile'}
                            register={register}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="row">
                  {action === 'profile' ? (
                    <div className="col-lg-12">
                      <button
                        type="button"
                        className="btn btn-gradient-success waves-effect waves-light"
                        onClick={(e) => {
                          navigate('/provider/clinics/edit/' + clinicID, { state: { "id": clinicID } })
                          e.preventDefault()
                        }}
                        style={{ marginRight: '10px' }}
                      >
                        {provider_clinic('edit_clinic')}
                      </button>
                      <button type="button" className="btn btn-gradient-info waves-effect waves-light" onClick={() => navigate('/provider/clinics')}>
                        {provider_clinic('back')}
                      </button>
                    </div>
                  ) : action === 'edit' ? (
                    <div className="col-lg-12">
                      <button type="submit" className="btn btn-gradient-success waves-effect waves-light" disabled={isSubmitting} style={{ marginRight: '10px' }}>
                        {provider_clinic('save')}
                      </button>{' '}

                      <button type="button" className="btn btn-gradient-info waves-effect waves-light" onClick={() => navigate(-1)}>
                        {provider_clinic('cancel')}
                      </button>
                    </div>
                  ) : action === 'create' ? (
                    <div className="col-lg-12">
                      <button type="submit" className="btn btn-gradient-success waves-effect waves-light" disabled={isSubmitting}>
                        {isSubmitting ? ` ${provider_clinic('saving...')}` : ` ${provider_clinic('save')}`}
                      </button>{' '}
                      <button type="button" className="btn btn-gradient-info waves-effect waves-light" onClick={() => navigate(-1)}>
                        {provider_clinic('cancel')}
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
