import { USER_TYPES } from 'v2/src/config/appConfig'
import { fGetLocalSt, fGetAUserInf } from 'v2/src/lib/appUtils'
import { ContainerFluid, PageWrapper, TableTitle } from 'components/table/Tables'
import ProfileForm from 'v2/src/pages/profile/ProfileForm'
import ProviderProfileForm from 'v2/src/pages/profile/ProviderProfileForm'
import IdDocumentForm from 'v2/src/pages/profile/IdDocumentForm'
import InsuranceDocumentList from 'v2/src/pages/profile/InsuranceDocumentList'
import { useUserProfileDetailQuery } from 'v2/src/lib/services/profileApi'
import { useEffect, useRef } from 'react'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'

export default function ProfileN() {
  let aUData = fGetAUserInf()
  const aUserType = aUData?.userType
  const { auth } = useAuth()
  const profileRef = useRef(null)
  const idDocumentRef = useRef(null)
  const insuranceRef = useRef(null)
  const { t: patient_common } = useTranslation('patient_common')
  const usrProfileId = aUData?.details?.profileId
  const arrComps = ['user', 'role', 'language', 'addresses', 'patient']

  const {
    data: profileApiData,
    isLoading: profWait,
    isFetching: profWait2,
    isError: profErr,
  } = useUserProfileDetailQuery({ profileId: usrProfileId, arrComps }, { skip: usrProfileId === null, refetchOnMountOrArgChange: true })

  const footerJsx = (
    <footer className="footer text-center text-sm-left" style={{ position: 'relative' }}>
      {' '}
      &copy; 2022 NIU Health
    </footer>
  )

  useEffect(() => {
    if (auth?.userType === 'patient' && profileApiData) {
      const { personal_details_completed, has_approved_identification_document, has_approved_insurance_document } = profileApiData.data
      if (!personal_details_completed) {
        profileRef.current.scrollIntoView({ behavior: 'smooth' })
      } else if (!has_approved_identification_document) {
        idDocumentRef.current.scrollIntoView({ behavior: 'smooth' })
      } else if (!has_approved_insurance_document) {
        insuranceRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [profileApiData])

  return aUserType === USER_TYPES.provider ? (
    <>
      {/* ---------------------- */}
      <PageWrapper style={{ marginTop: '1rem' }} pCStyle={{ paddingBottom: '0px!important' }}>
        <ContainerFluid>
          <TableTitle title={patient_common('my_profile')} />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <ProviderProfileForm />
                </div>
              </div>
            </div>
          </div>
        </ContainerFluid>
        {footerJsx}
      </PageWrapper>
    </>
  ) : (
    <>
      <div className="page-wrapper-custom" style={{ marginTop: '4rem' }} ref={profileRef}>
        <ContainerFluid>
          <TableTitle title={patient_common('my_profile')} />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <ProfileForm />
                </div>
              </div>
            </div>
          </div>
        </ContainerFluid>
      </div>

      <div className="page-wrapper-custom" ref={idDocumentRef}>
        <ContainerFluid>
          <TableTitle title={patient_common('identification_document')} />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <IdDocumentForm has_approved_identification_document={profileApiData?.data?.has_approved_identification_document} />
                </div>
              </div>
            </div>
          </div>
        </ContainerFluid>
      </div>

      <div className="page-wrapper-custom" ref={insuranceRef}>
        <ContainerFluid>
          <TableTitle title={patient_common('insurance')} />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <InsuranceDocumentList />
                </div>
              </div>
            </div>
          </div>
        </ContainerFluid>
        {footerJsx}
      </div>
    </>
  )
}
