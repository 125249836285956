import * as Yup from 'yup';

const serviceSchema = Yup.object({
  // Price Rate
  costPrice: Yup.number()
    .required('Price rate is required')
    .positive('Price rate must be a positive number')
    .typeError('Price rate must be a valid number'),

  // Practice Name (Category)
  practiceName: Yup.string()
    .required('Practice name is required'),

  // Clinic Availability
  clinicAvailability: Yup.string()
    .required('Clinic availability is required'),

});

export default serviceSchema;
