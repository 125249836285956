import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "components/Footer";
import { useTranslation } from "react-i18next";
import {
  useLazyGetServiceQuery,
  usePostDateMutation,
  useSpecialityListQuery,
} from "v2/src/lib/services/provider/providerApi";
import { Button, Modal } from "react-bootstrap";
import { usePostAppointmentMutation } from "v2/src/lib/services/nHAppointmentApi";
import { FaTimes } from "react-icons/fa";
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
export default function TellUsWhy() {
  const [show, setShow] = useState(false);
  const [timezone, setTimezone] = useState('');
  const [selectedDate, setSelectedDate] = useState("");
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [symptom, setSymptom] = useState("");
  const [selectedSymptom, setSelectedSymptom] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [symptomCharCount, setSymptomCharCount] = useState(0);
  const { t: patient_virtual_visit } = useTranslation("patient_virtual_visit");
  const [IsSpecialityId, setSpecialityId] = useState(false);
  const navigate = useNavigate();
  const maxLength = 150;
  const location = useLocation();
  const { timeZone } = location.state || {};
  //---------------------------->
  const handleClose = () => {
    setShow(false)
  };
  const handleShow = () => setShow(true);

  const { data: SpecialityListData } = useSpecialityListQuery();
  const [trigger, { data: serviceData = [], isFetching }] =
    useLazyGetServiceQuery();
  const [postDate] = usePostDateMutation();
  const [postAppointment] = usePostAppointmentMutation();

  useEffect(() => {
    if (IsSpecialityId) {
      trigger({ speciality_id: IsSpecialityId });
    }
  }, [IsSpecialityId]);

  const postDatefun = async (startDate, endDate) => {
    const res = await postDate({
      start_date: startDate,
      end_date: endDate,
      practice_id: serviceData?.data[0]?.practice_id,
      provider_id: serviceData?.data[0]?.provider_id,
    });
    let selectedDateKey;
    for (let dateKey in res?.data?.data) {
      selectedDateKey = dateKey;
    }
    setAvailableTimes(res?.data?.data[selectedDateKey]);
  };

  const handleDateChange = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    setSelectedDate(formattedDate);
    if (formattedDate) {
      postDatefun(formattedDate, formattedDate);
    }
  };

  useEffect(() => {
    const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimezone(systemTimeZone); // Set the system's time zone to state
  }, []);
  //---------------------------->
  function handleButtonClick(selected) {
    const currentSelected = new Set(selectedSymptom);

    if (currentSelected.has(selected)) return;
    setSymptomCharCount(symptom.length + selected.length);
    if (maxLength - symptomCharCount > selected.length) {
      setSelectedSymptom((prev) => [...prev, selected]);
      currentSelected.add(selected);
      if (symptom.trim().length <= 0) {
        setSymptom(selected);
      } else {
        setSymptom((prev) => `${prev}, ${selected}`);
      }
    }
  }

  function handleTextarea(e) {
    const val = e.target.value;
    setSymptom(val);
    setSymptomCharCount(e.target.value.length);
    if (val.length <= 0) {
      setSelectedSymptom([]);
    }
  }

  const handleSubmit = () => {
    if (!serviceData.data) {
      showServiceNotFoundAlert();
    } else {
      handleShow();
    }
  };

  const showServiceNotFoundAlert = () => {
    Swal.fire({
      title: "Service Not Found",
      text: "The requested service could not be found. Please try again.",
      // icon: "warning", // Change to 'error' if you prefer
      confirmButtonText: "Close",
      html: `
        <div class="text-center">
          <i class="bi bi-exclamation-circle" style="font-size: 3rem; color: #dc3545;"></i>
          <h5>Service Not Found!</h5>
          <p>Please check the service ID and try again.</p>
        </div>
      `,
    });
  };

  const showSuccessAlert = (message) => {
    Swal.fire({
      title: "Success!",
      text: "Your action was successful!",
      // icon: "success",
      confirmButtonText: "Close",
      html: `
        <div class="text-center">
          <i class="bi bi-check-circle" style="font-size: 3rem; color: #28a745;"></i>
          <h5>${message}</h5>
        </div>
      `,
    }).then((result) => {
      if (result?.value || result?.dismiss) {
        navigate("/patient/appointments");
      }
    });
  };

  const showAlreadyBokedAlert = (message) => {
    Swal.fire({
      title: "Error occurred!",
      text: "See Your Appointment!",
      // icon: "warning",
      confirmButtonText: "Close",
      html: `
        <div class="text-center">
          <i class="bi bi-check-circle" style="font-size: 3rem; color: #28a745;"></i>
          <h5>${message}</h5>
        </div>
      `,
    }).then((result) => {
      if (result?.value || result?.dismiss) {
        navigate("/patient/appointments");
      }
    });
  };

  return (
    <div className="page-wrapper mt-0">
      {/* <!-- Page Content--> */}
      <div className="page-content">
        <div className="container-fluid">
          {/* <!-- Page-Title --> */}
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <h4 className="page-title"></h4>
              </div>
              {/* <!--end page-title-box--> */}
            </div>
            {/* <!--end col--> */}
          </div>
          {/* <!-- end page title end breadcrumb --> */}

          <div className="row ">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body">
                  <h3>{patient_virtual_visit("tell_us_why_youre_here")}</h3>
                  <h7>
                    {patient_virtual_visit("please_select_sepeciality_below")}
                  </h7>
                  <div className="d-flex flex-wrap justify-content-start align-items-center overflow-auto">
                    {SpecialityListData?.data.map((e) => (
                      <button
                        key={e?.id}
                        type="button"
                        className={`btn  btn-sm mr-1 text-nowrap ${IsSpecialityId
                          ? e?.id === IsSpecialityId
                            ? "btn-success"
                            : "btn-light"
                          : "btn-light"
                          }`}
                        style={{
                          boxShadow: "unset",
                          borderRadius: "15px",
                          margin: "3px",
                        }}
                        onClick={() => {
                          setSpecialityId(e?.id);
                        }}
                      >
                        {e?.name}
                      </button>
                    ))}
                  </div>
                  <textarea
                    style={{ margin: "30px 0 0 0" }}
                    className="form-control"
                    rows="5"
                    id="message"
                    placeholder={patient_virtual_visit(
                      "add_a_reason_for_your_virtual_visit"
                    )}
                    value={symptom}
                    onChange={handleTextarea}
                    maxLength={maxLength}
                  ></textarea>
                  {maxLength - symptomCharCount}{" "}
                  {patient_virtual_visit("characters_remaining")}
                  <div className="d-flex flex-wrap justify-content-start align-items-center overflow-auto">
                    {[
                      patient_virtual_visit("cough"),
                      patient_virtual_visit("sore_throat"),
                      patient_virtual_visit("flu_symptoms"),
                      patient_virtual_visit("sinus_infection"),
                      patient_virtual_visit("ear_infection"),
                      patient_virtual_visit("skin_infection"),
                      patient_virtual_visit("upper_respiratory_infection"),
                      patient_virtual_visit("bone_fractures"),
                      patient_virtual_visit("muscles_sprain"),
                      patient_virtual_visit("urinary_issues"),
                      patient_virtual_visit("rash"),
                      patient_virtual_visit("headache_migraine"),
                      patient_virtual_visit("stomachache"),
                    ].map((e) => (
                      <button
                        key={e}
                        type="button"
                        className="btn btn-light btn-sm mr-1 text-nowrap"
                        style={{
                          boxShadow: "unset",
                          borderRadius: "15px",
                          margin: "3px",
                        }}
                        onClick={handleButtonClick.bind(this, e)}
                      >
                        {e}
                      </button>
                    ))}
                  </div>
                  <div className="wizard_btn">
                    <Modal show={show} onHide={handleClose} >
                      <Modal.Header>
                        <Modal.Title
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <b> {patient_virtual_visit("choose_your_date")} </b>
                          <div className="image-close-icon xfloat-right">
                            <button
                              type="button"
                              className="btn btn-danger btn-round waves-effect waves-light image-close-icon"
                              onClick={handleClose}
                            >
                              <FaTimes />
                            </button>
                          </div>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body style={{ minHeight: "380px", }}>
                        <form>
                          <div className="form-group">
                            <label htmlFor="date">
                              {patient_virtual_visit("select_date")}
                            </label>
                            <DatePicker
                              selected={selectedDate}
                              onChange={handleDateChange}
                              dateFormat="MM/dd/yyyy"
                              className="form-control"
                              minDate={new Date()}
                              placeholderText="MM/DD/YYYY"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="time">Select Time</label>
                            <select
                              className="form-control"
                              id="time"
                              // value={selectedTime}
                              onChange={(e) => {
                                console.log("-------------d", e.target.value);
                                setSelectedTime(
                                  availableTimes[e.target.value].start
                                );
                              }}
                              disabled={!availableTimes?.length}
                            >
                              <option value="">Choose a time</option>
                              {availableTimes?.map((time, index) => (
                                <option
                                  key={index}
                                  value={index}
                                  disabled={!time.is_available}
                                >
                                  {time?.start}
                                </option>
                              ))}
                            </select>
                          </div>

                          {/* Time Zone Input Box */}
                          <div className="form-group">
                            <label htmlFor="timezone">Time Zone</label>
                            <input
                              type="text"
                              className="form-control"
                              id="timezone"
                              value={timeZone || timezone} // Displays the system time zone or a loading state
                              disabled // Optional: if you want to make the time zone read-only
                              placeholder="Enter Time Zone" // Optional, as you have a default value
                            />
                          </div>
                        </form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="success"
                          disabled={!selectedDate || !selectedTime}
                          onClick={() => {
                            const params = {
                              provider_id: serviceData?.data[0]?.provider_id,
                              practice_id: serviceData?.data[0]?.practice_id,
                              service_id: serviceData?.data[0]?.id,
                              start_time: selectedDate + " " + selectedTime,
                              symptoms: symptom,
                            };
                            console.log("xxxxxxxxx", params);
                            postAppointment(params)
                              .unwrap()
                              .then((data) => {
                                console.log("---------sdf", data);
                                handleClose();
                                showSuccessAlert(data.message);
                              }).catch(error => {
                                console.log(error)
                                showAlreadyBokedAlert(error?.data?.message)
                              });
                          }}
                        >
                          Book your appointment
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <button
                      type="button"
                      className="mb-1 mb-md-0 btn btn-success btn-round waves-effect waves-light figmaBigButton float-left"
                      onClick={handleSubmit}
                      // disabled={(isSubmitting || !symptom)}
                      disabled={!IsSpecialityId || !symptom}
                    >
                      {isSubmitting
                        ? patient_virtual_visit("please_wait")
                        : patient_virtual_visit("book_your_appointment")}
                    </button>
                    {/* </Link> */}
                    <Link to="..">
                      <button
                        type="button"
                        className="btn btn-danger btn-round waves-effect waves-light figmaBigButton float-bottom"
                      >
                        {patient_virtual_visit("cancel")}
                      </button>
                    </Link>
                    <p style={{ marginTop: "30px" }}>
                      {patient_virtual_visit(
                        "enjoy_our_video_content_while_you_wait"
                      )}
                    </p>
                  </div>
                  {/* <!--end of row --> */}
                </div>
                {/* <!--end card-body--> */}
              </div>
              {/* <!--end card-->                                    */}
            </div>
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body">
                  <img
                    src="../assets/images/getting-consultation-of-doctor.jpg"
                    className="steps_img"
                    alt=""
                  />

                  <div className="media">
                    <div className="media-body align-self-center">
                      <h3 className="mt-0 mb-1">
                        {patient_virtual_visit(
                          "how_to_get_the_most_out_of_your_visit"
                        )}
                      </h3>
                    </div>
                    {/* <!--end media-body--> */}
                  </div>
                  {/* <!--end media--> */}

                  <div className="pricingTable1 text-center">
                    <ul className="list-unstyled pricing-content-2 text-left py-2 border-0 mb-0">
                      <li>
                        {patient_virtual_visit("grant_camera_permission")}
                      </li>
                      <li>
                        {patient_virtual_visit(
                          "do_not_begin_a_virtual_visit_while_driving"
                        )}
                      </li>
                      <li>
                        {patient_virtual_visit("ensure_stable_connection")}
                      </li>
                      <li>{patient_virtual_visit("good_lighting")}</li>
                    </ul>
                  </div>
                  <div></div>
                </div>
                {/* <!--end card-body-->  */}
              </div>
              {/* <!--end card-->*/}
            </div>
          </div>
          {/* <!--end row--> */}
        </div>
        {/* <!-- container --> */}

        {/* <footer className="footer text-center text-sm-left">
            &copy; 2022 NIU Health 
        </footer> */}
        <Footer />
        {/* <!--end footer--> */}
      </div>
      {/* <!-- end page content --> */}
    </div>
  );
}
