import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import styled from "@emotion/styled";
import {
  USER_TYPES,
  DEF_COUNTRY_ID,
  DEF_COUNTRY_CODE,
  DEF_PROFILE_PIC_URL,
  APP_COLORS,
  FLG_USE_SWAL,
} from "v2/src/config/appConfig";
import { fGetAUserInf } from "v2/src/lib/appUtils";
import { fGetUniqueArrayRowByKey } from "v2/src/lib/objUtils";
import { fCallNHApi, fGetUserProfileUpData } from "./appProfileFx";
import { PageMessage, Flasher, LottieSpinner } from "v2/src/core";
import ProfilePicSetter from "v2/src/core/UploadImage/ProfilePicSetter";
import ProviderProfileFormSchema from "v2/src/schema/providerProfileFormSchema";
import { rSetProfilePic } from "v2/src/lib/services/auth/authSlice";
import { useSetNSUserMutation } from "v2/src/lib/services/nSUtilApi";
import {
  useUserProfileDetailQuery,
  useLazyUserDetailsQuery,
  useSetUserMutation,
  useSetUserProfileMutation,
} from "v2/src/lib/services/profileApi";
import {
  useSpecialityListQuery,
  useSetProviderMutation,
  useProviderDetailQuery,
} from "v2/src/lib/services/provider/providerApi";
import { toast } from "react-toastify";

const StyleWrapper = styled.div`
  .optionListContainer {
    position: sticky;
}`;

export default function ProviderProfileForm() {
  const fDispatch = useDispatch();
  let aUData = fGetAUserInf();


  const { t: error_message } = useTranslation("error_message");
  const { t: comp_profile } = useTranslation("comp_profile");
  const [stPgMsg, setStPgMsg] = useState("");
  const [stProcessing, setStProcessing] = useState(false);
  const [stPgHasErr, setStPgHasErr] = useState(false);
  const [stDisableForm, setStDisableForm] = useState(false);
  const [stSelSpecialities, setStSelSpecialities] = useState([]);
  const [stSelCategories, setStSelCategories] = useState({});

  const flgEditable = !stDisableForm;
  const defCountryId = DEF_COUNTRY_ID;
  const defCountryCode = DEF_COUNTRY_CODE === "USA" ? "US" : DEF_COUNTRY_CODE;
  const aUserId = aUData?.userIdNH;
  const aUserNSId = aUData?.userIdNS;
  const aUserProfileId = aUData?.details?.profileId;
  let aUserProviderId,
    aUserPracticeId,
    aUserLicenseNum = "";

  const [fSetUserData, { reset: fResetU }] = useSetUserMutation();
  const [fSetUserProfile] = useSetUserProfileMutation();
  const [fSetProvider] = useSetProviderMutation();
  const [fSetNSUser] = useSetNSUserMutation();
  const [fGetUserProfDetails] = useLazyUserDetailsQuery({
    profileId: null,
    userType: null,
  });

  const arrComps = ["user", "role", "language", "provider"];
  const {
    data: profileApiData,
    refetch: fRefetchProfile,
    isLoading: profWait,
    isFetching: profWait2,
    isError: profErr,
  } = useUserProfileDetailQuery(
    { profileId: aUserProfileId, arrComps },
    { skip: aUserProfileId === null, refetchOnMountOrArgChange: true }
  );
  const objProfData = profileApiData?.data;
  aUserProviderId = objProfData?.provider?.id;
  aUserPracticeId = "";
  aUserLicenseNum = objProfData?.provider?.license_number;
  useEffect(() => {
    if (profErr) {
      setStPgMsg("Error fetching user profile data");
      setStPgHasErr(true);
    }
  }, [profErr]);

  const {
    data: providerApiData,
    refetch: fRefetchProvider,
    isLoading: provWait,
    isFetching: provWait2,
    isError: provErr,
  } = useProviderDetailQuery(
    { providerId: aUserProviderId, arrComps: ["specialities"] },
    { skip: !aUserProviderId, refetchOnMountOrArgChange: true }
  );
  const objProviderData = providerApiData?.data;
  useEffect(() => {
    if (providerApiData?.data?.specialities?.length) {
      let provSpecialities = structuredClone(
        providerApiData?.data?.specialities
      );
      provSpecialities = fGetUniqueArrayRowByKey(provSpecialities, "id");
      setStSelSpecialities((prevSt) => {
        const newSt = [];
        provSpecialities.forEach((pSp) =>
          newSt.push({ id: pSp.id, title: pSp.name })
        );
        return newSt;
      });
    }
  }, [providerApiData]);

  const {
    data: specialityApiData,
    isLoading: spcltWait,
    isFetching: spcltWait2,
    isError: spcltErr,
    error: specialityApiErrData,
  } = useSpecialityListQuery();
  const objSpecialityData = specialityApiData?.data;



  const isPageLoading =
    profWait || profWait2 || provWait || provWait2 || spcltWait || spcltWait2;

  const fResetProcessing = (flgClearPgMsg = false) => {
    setStProcessing(false);
    setStPgMsg("");
    setStPgHasErr(false);
  };

  const {
    register: fRegFrmProfile,
    reset: fResetFrmProfile,
    trigger: fTriggerFrmProfile,
    setValue: fSetFrmProfileVal,
    getValues: fGetFrmProfileVal,
    setError,
    clearErrors,
    formState: { errors: errFrmProfile, isValid: isValidFrmProfile },
  } = useForm({
    resolver: yupResolver(ProviderProfileFormSchema),
  });

  useEffect(() => {
    const obPData = profileApiData?.data;
    if (obPData) {
      fSetFrmProfileVal("first_name", objProfData?.user?.first_name || "");
      fSetFrmProfileVal("middle_name", objProfData?.user?.middle_name || "");
      fSetFrmProfileVal("last_name", objProfData?.user?.last_name || "");
      fSetFrmProfileVal("contactNum", objProfData?.user?.phone || "");
    }
    if (objProviderData) {
      fSetFrmProfileVal(
        "provider_description",
        objProviderData?.about_me || ""
      );
    }
  }, [fSetFrmProfileVal, profileApiData, objProviderData]);

  const fResetProviderProfileForm = () => {
    fResetFrmProfile();
  };
  const fHandleProfilePicChange = async (fl) => {
    if (typeof fl === "object") {
      setStProcessing(true);
      const formData = new FormData();
      formData.append("profile_pic", fl);
      const profPicUpResp = await fCallNHApi("profPicUp", fSetUserProfile, {
        profileId: aUserProfileId,
        inParams: formData,
      });
      if (profPicUpResp?.result === true) {
        const nSFormData = new FormData();
        nSFormData.append("profile", fl);
        const dumProfPicNSUpResp = await fSetNSUser({
          userId: aUserNSId,
          inParams: nSFormData,
        });
        fRefetchProfile();
        setTimeout(async () => {
          const uProfResp = await fGetUserProfDetails({
            profileId: aUserProfileId,
            userType: USER_TYPES.provider,
          });
          const newProfilePicUrl = uProfResp?.data?.data?.profile_pic_url;
          if (newProfilePicUrl) {
            fDispatch(rSetProfilePic({ profilePic: newProfilePicUrl }));
          }
        }, 3000);

      }
      setStProcessing(false);
    }
  };
  const fHandleProviderProfileSubmit = async () => {
    const inData = fGetFrmProfileVal();
    const isValid = await fTriggerFrmProfile();


    if (isValid) {
      setStProcessing(true);
      let profSaveResp = {
        rSaveUser: null,
        rSaveProvider: null,
      };

      try {

        let formData = new FormData();
        formData.append("first_name", inData?.first_name);
        formData.append("middle_name", inData?.middle_name);
        formData.append("last_name", inData?.last_name);
        formData.append("phone", inData?.contactNum);
        profSaveResp.rSaveUser = await fCallNHApi("userUp", fSetUserData, {
          userId: aUserId,
          inParams: formData,
        });


        formData = new FormData();
        formData.append("license_number", aUserLicenseNum);
        formData.append("about_me", inData?.provider_description);

        let arrSpcltId = [];
        if (stSelSpecialities?.length) {
          arrSpcltId = [...new Set(stSelSpecialities.map((sp) => sp.id))];
          arrSpcltId.forEach((spId) => {
            formData.append("speciality_ids[]", spId);
          });
        } else {
          formData.append("speciality_ids[]", null);
        }

        profSaveResp.rSaveProvider = await fCallNHApi("provUp", fSetProvider, {
          providerId: aUserProviderId,
          inParams: formData,
        });


        if (
          profSaveResp?.rSaveUser?.result &&
          profSaveResp?.rSaveProvider?.result
        ) {
          fRefetchProfile();
          fRefetchProvider();
          if (FLG_USE_SWAL) {
            toast.success(comp_profile("profile_update_successfull"));
          } else {
            setStPgMsg(comp_profile("profile_update_successfull"));
          }
        } else {
          const errorMessage =
            profSaveResp?.rSaveUser?.message ||
            profSaveResp?.rSaveProvider?.message ||
            comp_profile("Error saving profile data. Please try again.");
          if (FLG_USE_SWAL) {
            toast.error(errorMessage);
          } else {
            setStPgMsg(errorMessage);
            setStPgHasErr(true);
          }
        }
      } catch (error) {
        console.error("Error during profile save:", error);
        const errorMessage =
          error.message ||
          comp_profile("Error saving profile data. Please try again.");
        if (FLG_USE_SWAL) {
          toast.error(errorMessage);
        } else {
          setStPgMsg(errorMessage);
          setStPgHasErr(true);
        }
      } finally {
        setStProcessing(false);
      }
    } else {
      setStPgHasErr(true);
    }
  };

  let spcltOpts = [];
  if (objSpecialityData) {
    spcltOpts = objSpecialityData.map((spclt) => ({
      id: spclt.id,
      title: spclt.name,
    }));
    spcltOpts = spcltOpts.filter(
      (sp) => !stSelSpecialities.some((sel) => sel.id === sp.id)
    );
  }

  const [stMSelHoveredIndex, setStMSelHoveredIndex] = useState(null);
  const mSelCss = {
    zIndex: 3,

    chips: { background: APP_COLORS.dGreen },
    optionContainer: {
      backgroundColor: APP_COLORS.bG,
      "&:hover, &:focus": { backgroundColor: APP_COLORS.lGreen },
    },
    option: { "&:hover, &:focus": { backgroundColor: APP_COLORS.lGreen } },
  };
  const fGetMSelOptionStyle = (sIdx) => ({
    display: "inline-block",
    width: "100%",
    backgroundColor:
      stMSelHoveredIndex === sIdx ? APP_COLORS.lGreen : APP_COLORS.bG,
    cursor: "pointer",
  });

  let pgMsg = null,
    aUsrProfilePicUrl = objProfData?.profile_pic_url || DEF_PROFILE_PIC_URL;

  const triggerToast = () => {
    if (stPgMsg) {
      if (stPgHasErr) {
        toast.error(stPgMsg);
      } else {
        toast.success(stPgMsg);
      }
    }
  };

  useEffect(() => {
    triggerToast();
  }, [stPgMsg, stPgHasErr]);

  const loaderJsx = (
    <div className="d-flex justify-content-center">
      <LottieSpinner size={200} />
    </div>
  );

  return (
    <div id="ctnFrmProviderProf">
      <form>
        <div className="row">
          <div className="col-md-2">
            <ProfilePicSetter
              isDisabled={stDisableForm}
              imgSrc={aUsrProfilePicUrl}
              imgElId="imProfilePic"
              fileElId="iFPImg"
              fCallback={fHandleProfilePicChange}
            />
          </div>
          <div className="col">
            <div className="form-group row">
              <label
                htmlFor="first_name"
                className="col-sm-2 col-form-label text-right"
              >
                {comp_profile("first_name")}{" "}
                <small className="text-danger font-13"> *</small>
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  {...fRegFrmProfile("first_name")}
                  className={`form-control ${Boolean(errFrmProfile.first_name) ? "is-invalid" : ""
                    }`}
                />
                {errFrmProfile.first_name && (
                  <label className="text-danger">
                    {error_message(errFrmProfile.first_name.message)}
                  </label>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="last_name"
                className="col-sm-2 col-form-label text-right"
              >
                {comp_profile("last_name")}{" "}
                <small className="text-danger font-13"> *</small>
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  {...fRegFrmProfile("last_name")}
                  className={`form-control ${Boolean(errFrmProfile.last_name) ? "is-invalid" : ""
                    }`}
                />
                {errFrmProfile.last_name && (
                  <label className="text-danger">
                    {error_message(errFrmProfile.last_name.message)}
                  </label>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="email-input" className="col-sm-2 col-form-label">
                {comp_profile("email")}
              </label>
              <div className="col-sm-10">
                <input
                  type="email"
                  readOnly
                  disabled={true}
                  className="form-control"
                  name="email"
                  defaultValue={objProfData?.user?.email}
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="example-tel-input"
                className="col-sm-2 col-form-label"
              >
                {comp_profile("about_me")}
              </label>
              <div className="col-sm-10">
                <textarea
                  {...fRegFrmProfile("provider_description")}
                  className="form-control"
                  rows="4"
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="example-tel-input"
                className="col-sm-2 col-form-label"
              >
                {comp_profile("contact_info")}
              </label>
              <div className="col-sm-10">
                <PhoneInput
                  disabled={stDisableForm}
                  required
                  className={`form-control ${Boolean(errFrmProfile.contactNum) ? "is-invalid" : ""
                    }`}
                  id="contactNum"
                  name="contactNum"
                  placeholder="(xxx) xxx-xxxx"
                  value={objProfData?.user?.phone || ""}
                  defaultCountry={defCountryCode}
                  international={false}
                  onChange={(val) => {
                    if (val && typeof val === "string") {
                      const cleanedPhone = val.replace(/[^\d+]/g, '');
                      fSetFrmProfileVal("contactNum", cleanedPhone);
                      clearErrors("contactNum");
                    }
                  }}
                  onBlur={(e) => {
                    const val = e.target.value;
                    const cleanedPhone = val.replace(/[^\d+]/g, '');
                    if (cleanedPhone && typeof cleanedPhone === "string") {
                      clearErrors("contactNum");
                    } else {
                      setError("contactNum", {
                        type: "manual",
                        message: error_message("Invalid phone number"),
                      });
                    }
                  }}
                />
                {errFrmProfile.contactNum && (
                  <label className="text-danger">
                    {error_message(errFrmProfile.contactNum.message)}
                  </label>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="example-tel-input"
                className="col-sm-2 col-form-label"
              >
                {comp_profile("specialization")}
              </label>
              <div className="col-sm-10">
                <StyleWrapper>
                  <Multiselect
                    style={mSelCss}
                    avoidHighlightFirstOption={true}
                    showCheckbox={true}
                    emptyRecordMsg="No speciality data found"
                    options={spcltOpts}
                    displayValue="title"
                    selectedValues={stSelSpecialities}
                    onSelect={(selList, selItem) => {
                      setStSelSpecialities(selList);
                      setStSelCategories((prevSt) => ({
                        ...prevSt,
                        [selItem.id]: selItem,
                      }));
                    }}
                    onRemove={(selList, selItem) => {
                      setStSelSpecialities(selList);
                      let selLocalCategories = { ...stSelCategories };
                      delete selLocalCategories[selItem.id];
                      setStSelCategories(selLocalCategories);
                    }}
                  />
                </StyleWrapper>
              </div>
            </div>
          </div>
        </div>
        <div className="padS dFlexC xtBdr2">
          <div>&nbsp;</div>
          <div className="txAC xtBdr2">
            {flgEditable || !stDisableForm ? (
              <button
                type="button"
                disabled={stProcessing}
                className={"btn btn-success btn-round waves-effect waves-light"}
                style={{ marginRight: "10px" }}
                onClick={fHandleProviderProfileSubmit}
              >
                {comp_profile("submit_button")}
              </button>
            ) : null}
          </div>
        </div>
      </form>
    </div>
  );
}
