import { Suspense } from "react";
//import Loader from './Loader';
import PageLoader from './PageLoader';
//---------------------------------------------------------

const LoadComponent = (Component) => (props) => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Component {...props} />
    </Suspense>
  );
};

export default LoadComponent;
