import { configureStore } from '@reduxjs/toolkit'
import patientReducer from './slice/patient'
import { userApi } from './api/User'
//import { userApi } from 'v2/src/lib/services/auth/authApi';

export const store = configureStore({
  reducer: {
    patient: patientReducer,
    [userApi.reducerPath]: userApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(userApi.middleware),
})
