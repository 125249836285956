// import 'bootstrap/dist/css/bootstrap.min.css'
import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, Navigate, Outlet, Router, RouterProvider } from 'react-router-dom'
import AWSCallback, { loader as awsCallbackLoader } from './components/AWSCallback'
import ErrorPage45 from './components/ErrorPage45'
import LoginAuth, { loader as loginAuthLoader } from './components/LoginAuth'
import GreenLock from './components/lottie/LottieGreenLock'
import { AuthProvider } from './context/AuthProvider'
import './index.css'
import { APP_URL } from 'constants';
import ErrorPage from './pages/ErrorPage'
import About from './pages/About'
// import Appointment from './pages/patient/Appointment'
import AppointmentN from 'v2/src/pages/patient/Appointment'
import AskDrCoco from './pages/patient/AskDrCoco'
import Insurance from './pages/patient/insurance/Insurance'
import Bucket from './pages/patient/insurance/Bucket'
import Upload from './pages/patient/insurance/Upload'
//import InsuranceDocumentList from 'v2/src/pages/profile/InsuranceDocumentList';
import DefaultLayout from './pages/layout/DefaultLayout';
import PatientDashboard from './pages/patient/layout/PatientDashboard';
import ProviderDashboard from './pages/provider/layout/ProviderDashboard';
import Booking from './pages/patient/marketplace/Booking'
import Checkout from './pages/patient/marketplace/Checkout'
import Marketplace from './pages/patient/marketplace/Marketplace'
import PublicMarketplace from './pages/PublicMarketplace'
import ProvidersList from './pages/patient/marketplace/ProvidersList'
import Success from './pages/patient/marketplace/Success'
// import PatientIndexPage from './pages/patient/PatientIndexPage'
import PatientIndexPageN from 'v2/src/pages/patient/PatientIndexPage'
import Profile from './components/profile/Profile';//ProviderProf
import ProfileN from 'v2/src/pages/profile';
//import Profile2 from './components/profile/Profile2'
import Profile3 from './components/profile/Profile3'
import PaymentForm from './pages/patient/subcription/PaymentForm'
import Renew from './pages/patient/subcription/Renew'
import Subscription from './pages/patient/subcription/Subcription'
// import NewSubscription from './pages/patient/subcription/NewSubscription'
import NewSubscriptionN from 'v2/src/pages/profile/NewSubscription'
import VirtualVisit from './pages/patient/VirtualVisit'
import Clinics from './pages/provider/clinics/Clinics'
import ClinicSchedule from './components/clinics/ClinicSchedule'

import ProviderProfile from './pages/patient/marketplace/provider/Profile'
import Patients from './pages/provider/patient/PatientList'
import PatientProfile from './pages/provider/patient/Profile'
import ProviderIndex from './pages/provider/ProviderIndex'
import ProviderIndexN from 'v2/src/pages/provider/ProviderIndex'
import Ratings from './pages/provider/Ratings'
import ManageServices from './pages/provider/services/ManageServices'
import Services from './pages/provider/services/Services'
import VisitRequest from './pages/provider/VisitRequest'
import VisitRequestProfile from './pages/provider/VisitRequestProfile'
// import Visits from './pages/provider/Visits'
import VisitsN from 'v2/src/pages/provider/Visits'
import TellUsWhy from './pages/virtualvisit/TellUsWhy'
import TellUsWhyN from 'v2/src/pages/virtualvisit/TellUsWhy'
import WaitingRoom from './pages/virtualvisit/WaitingRoom'
import WaitingRoomN from 'v2/src/pages/virtualvisit/WaitingRoom'
//import Login from './pages/Login'
import Login from 'v2/src/pages/public/auth/Login';
//import AppRedirect from 'v2/src/pages/public/AppRedirect';
import Register from 'v2/src/pages/public/auth/Register';
//import Register from './pages/Register'
import ForgotPassword from 'v2/src/pages/public/auth/ForgotPassword';
import ResetPassword from 'v2/src/pages/public/auth/ResetPassword';
import useAuth from './hooks/useAuth'
import './i18n'
import "react-datepicker/dist/react-datepicker.css";
//import Home from 'v2/src/pages/profile/Home';

//import { store } from './redux/store'
import appStore from 'v2/src/lib/services/store/appStore';
import { Provider } from 'react-redux'
import Languages from './pages/provider/translations/Languages/Languages'
import Tags from './pages/provider/translations/Tags'
import AddLangauge from './pages/provider/translations/Languages/Add'
import EditLangauge from './pages/provider/translations/Languages/Edit'
import Modules from './pages/provider/translations/Modules/Modules'
import AddModule from './pages/provider/translations/Modules/Add'
import EditModule from './pages/provider/translations/Modules/Edit'
import UserLogin from 'v2/src/pages/public/auth/user/UserLogin'

//---------------------------------------------------------

//const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
//const ResetPassword = lazy(() => import('./pages/ResetPassword'))
//const ForgotPassword = lazy(() => import('v2/src/pages/public/auth/ForgotPassword'))
//const ResetPassword = lazy(() => import('v2/src/pages/public/auth/ResetPassword'))
const Verify = lazy(() => import('./pages/Verify'))
const Registration = lazy(() => import('./pages/patient/PatientRegistration'))
//const Room = lazy(() => import('./pages/virtualvisit/Room'))
const Room2 = lazy(() => import('./pages/virtualvisit/Room2'));
const RoomN = lazy(() => import('v2/src/pages/virtualvisit/Room'))
const Complete = lazy(() => import('./pages/virtualvisit/Complete'))
const CompleteN = lazy(() => import('v2/src/pages/virtualvisit/Complete'))
const AdminDashboard = lazy(() => import('./pages/admin/AdminDashboard'))
const AdminIndex = lazy(() => import('./pages/admin/AdminIndex'))
const AdminClinics = lazy(() => import('./pages/admin/clinics/Clinics'))
// AdminClinicSchedule
const AdminCompanies = lazy(() => import('./pages/admin/Companies'))
const AdminProviders = lazy(() => import('./pages/admin/Providers'))
const AdminPatients = lazy(() => import('./pages/admin/Patients'))

const AdminSettings = lazy(() => import('./pages/admin/Settings'))
// const SettingsList = lazy(() => import('./pages/admin/Settings'))

//import InsuranceDocumentForm from 'v2/src/pages/profile/InsuranceDocumentForm';
const InsuranceDocumentForm = lazy(() => import('v2/src/pages/profile/InsuranceDocumentForm'));
const InsuranceDocumentDetail = lazy(() => import('v2/src/pages/profile/InsuranceDocumentDetail'));

//---------------------------------------------------------

const App = () => (
  <Suspense>
    <Outlet />
  </Suspense>
)
// const { auth } = useAuth();

const router = createBrowserRouter(
  [
    //{ path: '/home', element: <Home />, },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/user/login',
      element: <UserLogin />,
    },
    //{ path: '/niuhealth', element: <AppRedirect />, },
    {
      path: 'waitingroom',
      element: (
        <Suspense>
          <WaitingRoom />
        </Suspense>
      ),
    },
    {
      path: '/register',
      element: (
        <Suspense>
          <Register />
        </Suspense>
      ),
    },
    {
      path: 'register_2/:email',
      errorElement: <ErrorPage />,
      element: (
        <Suspense>
          {' '}
          <Registration />
        </Suspense>
      ),
    },

    {
      path: 'verify/:email',
      errorElement: <ErrorPage />,
      element: (
        <Suspense>
          <Verify />
        </Suspense>
      ),
    },

    {
      path: 'forgot-password',
      element: (
        <Suspense>
          <ForgotPassword />
        </Suspense>
      ),
    },
    {
      path: 'reset-password',
      element: (
        <Suspense>
          <ResetPassword />
        </Suspense>
      ),
    },
    // {
    //   path: 'marketplace',
    //   element: <DefaultLayout />,
    //   errorElement: <ErrorPage />,
    //   children: [{ index: true, element: <PublicMarketplace /> }],
    // },
    {
      path: '/',
      element: <App />, // TODO: should be auth
      errorElement: <ErrorPage />,
      children: [
        { index: true, element: <LoginAuth />, loader: loginAuthLoader },
        {
          path: 'virtualvisit',
          element: <Outlet />,
          children: [
            // { index: true, element: <TellUsWhy /> },
            { index: true, element: <TellUsWhyN /> },
            { path: 'waitingroom', element: <WaitingRoomN /> },
            //{ path: 'room', element: <Room /> },
            // { path: 'room', element: <Room2 /> },
            { path: 'room', element: <RoomN /> },
            // {
            //   path: 'complete/:meetingId',
            //   element: <Complete />,
            // },
            {
              path: 'complete/:meetingId/:startTime/:appointmentId',
              element: <CompleteN />,
            },

          ],
        },
        // {
        //   path: 'login',
        //   element: <Login/>
        // },

        {
          path: 'admin',
          element: <AdminDashboard />,
          errorElement: <ErrorPage />,
          children: [
            // { index: true, element: <TellUsWhy /> },
            // { path: 'waitingroom', element: <WaitingRoom /> },
            // { path: 'room', element: <Room /> },
            { index: true, element: <AdminIndex /> },
            { path: 'companies', element: <AdminCompanies /> },
            { path: 'clinics', element: <AdminClinics /> },
            {
              path: 'clinics',
              element: <Outlet />,
              children: [
                { index: true, element: <AdminClinics /> },
                { path: 'create', element: <ClinicSchedule /> },
              ],
            },
            { path: 'providers', element: <AdminProviders /> },
            { path: 'patients', element: <AdminPatients /> },
            { path: 'settings', element: <AdminSettings /> },
          ],
        },

        {
          path: 'patient',
          element: <PatientDashboard />,
          errorElement: <ErrorPage />,
          children: [
            // { index: true, element: <PatientIndexPage /> },
            { index: true, element: <PatientIndexPageN /> },
            // { path: 'virtualvisit', element: <VirtualVisit /> },

            // { path: 'virtualvisit', element: <PatientIndexPage /> },
            { path: 'virtualvisit', element: <PatientIndexPageN /> },
            // {
            //   path: 'marketplace',
            //   element: <Outlet />,
            //   children: [
            //     { index: true, element: <Marketplace /> },
            //     { path: 'providers', element: <ProvidersList /> },
            //     { path: 'provider/:id', element: <ProviderProfile /> },
            //     { path: 'booking', element: <Booking /> },

            //     { path: 'booking/:id', element: <Booking /> },
            //     { path: 'checkout', element: <Checkout /> },
            //     { path: 'success', element: <Success /> },
            //   ],
            // },
            //{ path: 'profile', element: <Profile3 /> },
            { path: 'profile', element: <ProfileN /> },
            { path: 'about', element: <About /> },

            // { path: 'appointments', element: <Appointment /> },
            { path: 'appointments', element: <AppointmentN /> },
            { path: 'ask-dr-coco', element: <AskDrCoco /> },
            {
              path: 'subscription',
              children: [
                // { index: true, element: <NewSubscription /> },
                { index: true, element: <NewSubscriptionN /> },
                { path: 'plans', element: <Renew /> },
                { path: 'renew', element: <Renew /> },
                { path: 'renew/pay', element: <PaymentForm /> },
                { path: 'plans/pay', element: <PaymentForm /> },
              ],
            },

            {
              path: 'insurance',
              children: [
                { index: true, element: <Insurance /> },
                //{ path: 'upload', element: <Upload /> },
                { path: 'upload', element: <InsuranceDocumentForm /> },

                //{ path: 'folders/:bucketid', element: <Bucket /> },
                { path: 'folders/:theDocId', element: <InsuranceDocumentDetail /> },
              ],
            },
          ],
        },
        {
          path: 'provider',
          element: <ProviderDashboard />,
          errorElement: <ErrorPage />,
          children: [
            // { index: true, element: <ProviderIndex /> },
            { index: true, element: <ProviderIndexN /> },
            //{ path: 'profile', element: <Profile /> },
            { path: 'profile', element: <ProfileN /> },
            { path: 'service/view', element: <Booking /> },

            { path: 'service/view/:id', element: <Booking /> },
            {
              path: 'patient',
              element: <Outlet />,
              children: [
                { index: true, element: <Patients /> },
                { path: 'profile/:id', element: <PatientProfile /> },
                { path: 'profile/:id/:action', element: <PatientProfile /> },
              ],
            },
            { path: 'profiles/:id', element: <ProviderProfile /> },
            { path: 'about', element: <About /> },
            {
              path: 'ratings',
              element: <Ratings />,
            },
            {
              path: 'visits',
              // element: <Visits />,
              element: <VisitsN />,
            },

            {
              path: 'visit-requests',

              element: <Outlet />,
              children: [
                { index: true, element: <VisitRequest /> },
                { path: 'profile', element: <VisitRequestProfile /> },
              ],
            },
            {
              path: 'clinics',
              element: <Outlet />,
              children: [
                { index: true, element: <Clinics /> },
                { path: ':action', element: <ClinicSchedule /> },
                { path: ':action/:clinicID', element: <ClinicSchedule /> },
              ],
            },
            {
              path: 'services',
              element: <Outlet />,
              children: [
                { index: true, element: <Services /> },
                { path: 'manage/:action/', element: <ManageServices /> },
                { path: 'manage/:action/:id', element: <ManageServices /> },
              ],
            },
            {
              path: 'languages',
              element: <Outlet />,
              children: [
                { index: true, element: <Languages /> },
                { path: 'add', element: <AddLangauge /> },
                { path: 'edit/:id', element: <EditLangauge /> },
              ],
            },
            {
              path: 'translations',
              element: <Outlet />,
              children: [
                { index: true, element: <Modules /> },
                { path: 'add', element: <AddModule /> },
                { path: 'edit/:id', element: <EditModule /> },
              ],
            },
            { path: 'tags/:module', element: <Tags /> },
          ],
        },
        {
          path: 'cburl',
          element: <AWSCallback />,
          loader: awsCallbackLoader,
        },
        { path: 'testUi', element: <GreenLock /> },
        { path: '500', element: <ErrorPage45 statusCode={500} /> },
        { path: '404', element: <ErrorPage45 statusCode={404} /> },
      ],
    },

    { path: '*', element: <ErrorPage45 statusCode={404} /> },
  ],
  { basename: `${APP_URL}` }
)
// const routes =[
//   {
//     path: '/',
//     element: !isLoggedIn ? <MainLayout /> : <Navigate to="/app/dashboard" />,
//     children: [
//       { path: 'login', element: <Login /> },
//       { path: '/', element: <Navigate to="/login" /> },
//     ],
//   }
// ]

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <Provider store={appStore}>
    <AuthProvider>
      <Suspense fallback={null}>
        <RouterProvider router={router} />
      </Suspense>
    </AuthProvider>
  </Provider>
  // </React.StrictMode>
)
