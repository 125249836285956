//import {fGetHMFormat2, } from 'v2/src/lib/dateTimeUtils';
import { fStableSort, fGetComparator } from 'v2/src/lib/objUtils';
//---------------------------------------------------------

export default function TimeZoneSelect2({
  tzOptions, regFx, elName, setTimeZone, value, defaultValue, disabled = false, required = false,
  className, filterCountryCode=null, elStyle=null
}) {
  //console.log('tzOptions', tzOptions);
  const tzOpts = fStableSort(tzOptions, fGetComparator('utc_offset', 'desc'))
  //console.log('tzOpts', tzOpts);

  //const modifiedTimezoneOptions = fGetArrTimeZone(filterCountryCode);
  if(!elStyle) {
    elStyle = { marginLeft: '10px', marginRight: '20px', maxWidth: 400 };
  }

  return (
    <div className="row">
      <select disabled={disabled} required={required}
        className={className} style={elStyle}
        //name='selTz'
        //name='LocalTimeZone'
        id='selTz'
        //value={value}
        //defaultValue={defaultValue}
        //onChange={(e) => { setTimeZone(e.target.value) }}
        {...regFx(elName)}
      >
        <option>Select a Timezone</option>
        {tzOpts && tzOpts.map((timezone, index) => (
          <option key={'tz2_'+timezone.id}
            value={timezone.id}
            //value={fGetHMFormat2(timezone.utc_offset)}
            //value={timezone.utc_offset_hours}
          >
            {/*//UTC {fGetHMFormat2(timezone.offset)} {timezone.name}*/}
            {/*'UTC '+(timezone.utcOffsetMinute / 60)+':00  '+timezone.title*/}
            {/*UTC {fGetHMFormat2(timezone.utcOffsetMinute)} {timezone.title}*/}
            UTC {timezone.utc_offset_hours} {timezone.name}
          </option>
        ))}
      </select>
    </div>
  )
};
