//import { createSlice, current } from "@reduxjs/toolkit";
import { nHApi } from 'v2/src/lib/services/public/nHApi';
import { NH_API_BASE_URL_V2, USER_TYPES, DEF_COUNTRY_ID,  } from 'v2/src/config/appConfig';
//---------------------------------------------------------

const geoDataUpkeepTime = 18000;
export const locationApi = nHApi.injectEndpoints({ endpoints: builder => ({
  profileAddressList: builder.query({
    query: ({inParams}) => {
      ///addresses?profile_id=3
      return { url: '/addresses', params: inParams};
    },
  }),
  addressDetails: builder.query({
    query: (params) => {
      return { url: '/address/' + params?.addressId };
    },
  }),
  addProfileAddress: builder.mutation({
    query: ({inParams}) => {
      return {
        //profile_id, address_line_1, address_line_2, country_id, state_id, city,
        //zipcode, lat, long, is_primary, addressable_type, addressable_id
        url: '/address',
        method: 'POST',
        body: inParams,
      }
    },
  }),
  setAddress: builder.mutation({
    query: ({addressId, inParams}) => {
      return {
        //address_line_1, address_line_2, country, state, city,
        //zipcode, lat, long, is_primary
        url: '/address/' + addressId,
        method: 'POST',
        body: inParams,
      }
    },
  }),
  deleteAddress: builder.mutation({
    query: (params) => {
      return {
        url: '/address/'+params.addressId,
        method: 'DELETE',
      };
    },
  }),
  //---------------------------------------------------------

  languages: builder.query({
    //query: (params) => { return '/languages'; },
    query: params =>'/languages',
    keepUnusedDataFor: geoDataUpkeepTime,
    //pollingInterval: geoDataUpkeepTime
  }),
  countries: builder.query({
    query: params =>'/countries',
    keepUnusedDataFor: geoDataUpkeepTime
  }),
  timezones: builder.query({
    query: (params) => {
      return { url: '/timezones',
        params: { country_id: params?.countryId || DEF_COUNTRY_ID, per_page: 20 }
      };
    },
    keepUnusedDataFor: geoDataUpkeepTime,
  }),
  provinces: builder.query({
    query: (params) => {
      return { url: '/states',
        params: {country_id: params?.countryId || DEF_COUNTRY_ID, per_page: 100}
      };
    },
    keepUnusedDataFor: geoDataUpkeepTime,
  }),
  provincesMult: builder.query({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      let page = 1, pgGT = 0, apiMultResp;//console.log('_arg', _arg);
      const loopMax = 5;//arg?.maxPage || 5;
      const filter = _arg?.filter || null;
      const allRec = [];
      const countryId = _arg?.countryId || DEF_COUNTRY_ID;
      const _fetchData = async(page=1, aArgs=null) => {
        let sDResp, aUrl = '/states?country_id='+countryId;
        aUrl += '&page='+page;
        if(aArgs !== null) {
          //aUrl += '&filter='+JSON.stringify(argFilter);
        }
        let sResp = await fetchWithBQ(aUrl);
        //await _queryApi.dispatch(api.endpoints.states.initiate(page));
        //if(fResp?.error) throw fResp.error;
        //sResp = sResp?.data;
        if(sResp?.error) { sResp = sResp?.error; }
        else if(sResp?.data) {
          //sDResp = {};
          sDResp = {data: sResp.data};
          if(sResp?.meta) {
            sDResp['meta'] = sResp?.meta;
          }
        }//console.log('sDResp', sDResp);

        return sDResp;
      };

      const dApiResp = await _fetchData(page);//, filter);
      if(dApiResp?.data.length) {
        allRec.push(...dApiResp?.data);
        //const currPage = dApiResp?.pagination?.page?.current;
        pgGT = dApiResp?.pagination?.page?.total;

        if(page < pgGT) {
          page++; let dApiNext;
          for(let cnt=page; cnt<=loopMax && cnt<=pgGT; cnt++) {
            dApiNext = await _fetchData(cnt);//, filter);
            if(dApiNext?.data.length) {
              allRec.push(...dApiNext?.data);
            } else { break; }
          }
        }
      }

      if(dApiResp?.data) {
        apiMultResp = { data: allRec };
        if(dApiResp?.meta) {
          apiMultResp['pagination'] = dApiResp?.meta;
        }
      }//console.log('apiStatesMultResp '+pgGT, apiMultResp);
      return {data: apiMultResp};
    },
    keepUnusedDataFor: geoDataUpkeepTime,
  }),
  locationComp: builder.query({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      //console.log('locCompArgs', {_arg});
      const countryId = _arg?.countryId || DEF_COUNTRY_ID;
      let aUrl, aResp={aResp1: undefined, aResp2: undefined,
        aResp3: undefined, aResp4: undefined};

      const dEP1 = await fetchWithBQ('/languages');
      //console.log('dEP1', dEP1);
      if(dEP1?.error) { aResp.aResp1 = dEP1.error; }//throw dEP1.error;
      else if(dEP1?.data) { aResp.aResp1 = dEP1.data; }
      //if(dEP1?.error) throw dEP1.error;

      const dEP2 = await fetchWithBQ('/countries');//?per_page=300
      //console.log('dEP2', dEP2);
      if(dEP2?.error) { aResp.aResp2 = dEP2.error; }//throw dEP2.error;
      else if(dEP2?.data) { aResp.aResp2 = dEP2.data; }

      //aUrl = NH_API_BASE_URL_V2 + '/timezones?&country_id='+countryId;
      aUrl = '/timezones?per_page=20';
      aUrl += '&country_id='+countryId;
      const dEP3 = await fetchWithBQ(aUrl);
      //console.log('dEP3', dEP3);
      if(dEP3?.error) { aResp.aResp3 = dEP3.error; }//throw dEP3.error;
      else if(dEP3?.data) { aResp.aResp3 = dEP3.data; }

      aUrl = '/states?per_page=100';
      aUrl += '&country_id='+countryId;
      const dEP4 = await fetchWithBQ(aUrl);
      //console.log('dEP4', dEP4);
      if(dEP4?.error) { aResp.aResp4 = dEP4.error; }
      else if(dEP4?.data) { aResp.aResp4 = dEP4.data; }
      //console.log('locCompAResp', aResp);
      return {
        data: {
          languages: aResp.aResp1,
          countries: aResp.aResp2,
          timezones: aResp.aResp3,
          states: aResp.aResp4,
        },
        //error: {}
      };
    },
    keepUnusedDataFor: geoDataUpkeepTime,
  }),

}), });

export const {
  useProfileAddressListQuery, useAddressDetailsQuery,
  useAddProfileAddressMutation, useSetAddressMutation,
  useDeleteAddressMutation,

  useLanguagesQuery,
  useCountriesQuery, useTimezonesQuery,
  useProvincesQuery, //useProvincesMultQuery,
  useLocationCompQuery, useLazyLocationCompQuery,
} = locationApi;
//---------------------------------------------------------
