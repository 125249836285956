import { useRef, } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import './icon.css';
//---------------------------------------------------------

export default function ProfilePicSetter({isDisabled, imgSrc, imgElId, fileElId, fCallback}) {
  const { t: comp_profile } = useTranslation('comp_profile');
  //const [imagepreview, setImagePreview] = useState(false);
  const imgRef = useRef();

  const fHandleImageInputChange = (e) => {
    const [file] = e.target.files;
    const pImf = document.getElementById(imgElId);
    if(1) {
      if(fCallback) { fCallback(file); }//fSaveProfilePic(file);
      pImf.src = URL.createObjectURL(file);
      //pImf.onload = function() { URL.revokeObjectURL(pImf.src); }
    } else {
      //setProfile({ ...profile, picturefile: file, });//withUseEffect[profile]
    }
  };
  function fTriggerFileInput() {
    if(imgRef.current) { imgRef.current.click(); }
  }
  //---------------------------------------------------------
  //imgSrc = 'https://m.media-amazon.com/images/I/31RahmnlXxL._SS250_.jpg';
  //imgSrc = 'https://apia.niuhealth.com/storage/profile_pics/66ed82993e345.jpg';
  //console.log('imgSrc', imgSrc);

  return (
    <>
      {/*<div class="uploadPicContainer">
        <input type="file" id={fileElId} class="dropify"
          data-default-file="assets/images/users/user-4.jpg"/>
      </div>*/}
      <div className="d-flex flex-column justify-content-center align-items-center">
        <input type="file" accept="image/*" hidden
          id={fileElId} name={fileElId} ref={imgRef}
          // capture="user" // capture="filesystem"
          //data-default-file="assets/images/users/user-4.jpg"
          onChange={fHandleImageInputChange}
        />
        <img id={imgElId} alt="NIU-Health" className="m-4 rounded-circle profile-pic"
          src={imgSrc} style={{ objectFit: 'cover', width: 100, height: 100 }}
          onClick={() => {
            Swal.fire({
              title: comp_profile('profile_picture'),
              html: `<img style="width: 100%" height="300px" src="${imgSrc}"></img>`,
            })
          }}
        />
        {isDisabled ? null : (
          <button type="button" onClick={fTriggerFileInput}
           className="btn btn-success btn-round waves-effect waves-light mt-2 m-2"
          > {comp_profile('change')} </button>
        )}
      </div>
    </>
  );
};
