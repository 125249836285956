//import imgLogo from 'assets/images/nu-health-logo.png';
import imgLogo from 'assets/images/niuHealthLogo.svg';
import {APP_NAME} from 'v2/src/config/appConfig';
//---------------------------------------------------------

export default function AppLogo({ style }) {
  return (
    <img src={imgLogo} alt={APP_NAME} title={APP_NAME} className='logo-sm' style={style} />
  );
};
