import Lottie from "lottie-react";
import lottieData from './ring-loading.json';
//---------------------------------------------------------

//export default function RingLoading({size=160}) {
export default function LottieSpinner({size=160}) {
  return (
    <Lottie animationData={lottieData} style={{ width: size, height: size }} />
  )
};
