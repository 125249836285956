import * as yup from "yup";
import { isValidPhoneNumber, //isPossiblePhoneNumber, parsePhoneNumber,
} from "react-phone-number-input";
//---------------------------------------------------------

export const ProviderProfileSchema = yup
  .object({
    first_name: yup
      .string()
      .required('First name is required')
      .min(2, 'First name must be at least 2 characters')
      .max(50, 'First name must be less than 50 characters')
      .matches(/^[A-Za-z\s]+$/, 'First name must contain only letters'),
      
    // middle_name: yup
    //   .string()
    //   .max(50, 'Middle name must be less than 50 characters')
    //   .matches(/^[A-Za-z\s]*$/, 'Middle name must contain only letters'),
      
    last_name: yup
      .string()
      .required('Last name is required')
      .min(2, 'Last name must be at least 2 characters')
      .max(50, 'Last name must be less than 50 characters')
      .matches(/^[A-Za-z\s]+$/, 'Last name must contain only letters'),
      
    provider_description: yup
      .string()
      .nullable()
      .max(500, 'Description must be less than 500 characters'),

    contactNum: yup
      .string()
      .required('Phone number is required')
      .test('is-valid-phone-number', 'Invalid phone number', (value) => {
        if (!value) return false; // if no value, fail validation
        return isValidPhoneNumber(value); // validate phone number using react-phone-number-input
      }),
  })
  .required();

export default ProviderProfileSchema;