import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { APP_URL, USERTYPE } from '../constants'
import useAuth from '../hooks/useAuth'
import Cookies from 'js-cookie'

export async function loader({ request }) {
  const accessToken = Cookies.get('accessToken')
  const isLoggedIn = Cookies.get('isLoggedIn ')

  if (isLoggedIn === 'false' || !accessToken || accessToken === 'undefined') {
    window.location.assign(APP_URL + '/login')
  }
  return true
}

function LoginAuth() {
  const { auth, setAuth } = useAuth()

  const email = Cookies.get('email')
  const accessToken = Cookies.get('accessToken')
  const userId = Cookies.get('userId')
  const userType = Cookies.get('userType')
  const isLoggedIn = Cookies.get('isLoggedIn')
  const email_verified = true

  useEffect(() => {
    setAuth((prev) => ({
      ...prev,
      email,
      accessToken,
      userId,
      userType,
      isLoggedIn,
    }))

  }, [])

  if (userType === USERTYPE.patient) {
    return <Navigate to={'/patient'} replace={true} />
  } else if (userType === USERTYPE.provider) {
    return <Navigate to={'/provider'} replace={true} />
  } else {
    return <div>Loading...</div>
  }
}

export default LoginAuth
