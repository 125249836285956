import { useTranslation } from "react-i18next";
import {
  USER_TYPES,
} from "v2/src/config/appConfig";
import { fGetLocalSt, fSetLocalSt } from "v2/src/lib/appUtils";
//---------------------------------------------------------
export const xfSaveProfilePic = async (
  fFx,
  profId,
  actParams,
  fCallback,
  errCb
) => {
  let upProfPicResp;
  try {
    upProfPicResp = await fFx({ profileId: profId, inParams: actParams });

    if (upProfPicResp?.data?.status === true) {
      upProfPicResp = { result: true };
    } else {
      upProfPicResp = {
        result: false,
        error: { code: "profPicUpFail", desc: "" },
      };
    }
  } catch (err) {
    upProfPicResp = {
      result: false,
      error: { code: "profPicUpFail2", desc: err },
    };
  }
  return upProfPicResp;
};

export const fCallNHApi = async (aHdText, fFx, aParams, fCallback, errCb) => {
  let apiResp;
  try {
    apiResp = await fFx(aParams);

    if (apiResp?.data?.status === true) {
      apiResp = { result: true };
      if (!apiResp?.data?.hasOwnProperty("data")) {
        apiResp["data"] = apiResp;
      }
    } else {
      apiResp = { result: false, error: { code: aHdText + "Fail", desc: "" } };
    }
  } catch (err) {
    apiResp = { result: false, error: { code: aHdText + "Fail2", desc: err } };
  }
  return apiResp;
};

export const fGetUserProfileUpData = (fData, uType) => {
  let resp;
  if (uType && fData.length) {
    switch (uType) {
      case USER_TYPES.provider:
        break;
      case USER_TYPES.patient:
        resp = {
          contactNum: fData.contactNum,
          tzId: fData.LocalTimeZone,
          dob: fData.DateOfBirth,
          langId: fData,
          langCode: fData,
        };
        break;
    }
  }
  return resp;
};

export const fGetUserCurrDocImgListInf = (selPDoc, uDocsInf = []) => {
  let resp = {};
  if (uDocsInf?.length) {
    let currIdx,
      i = 0,
      arrUrls = [];
    uDocsInf.forEach((uD) => {
      if (uD.id === selPDoc.id) {
        currIdx = i;
      }
      i++;
      uD?.docApiData?.file_url && arrUrls.push(uD.docApiData.file_url);
      uD?.hasView &&
        uD?.docFormData?.docFile &&
        arrUrls.push(URL.createObjectURL(uD.docFormData.docFile));
    });
    resp = { currIdx: currIdx, arrUrls: arrUrls };
  }
  return resp;
};

export const InsuranceStatusText = ({ status = '' }) => {
  const { t: comp_profile } = useTranslation('comp_profile');
  const docStatMeta = {
    resubmission_requested: {
      css: "badge-soft-purple",
      sTxt: "Resubmission Required",
    },
    pending: { css: "badge-soft-purple", sTxt: `${comp_profile('pending')}` },
    approved: { css: "badge-soft-success", sTxt: `${comp_profile('approved')}` },
    rejected: { css: "badge-soft-danger", sTxt: `${comp_profile('rejected')}` },
    declined: { css: "badge-soft-danger", sTxt: `${comp_profile('declined')}` },
    expired: { css: "badge-soft-danger", sTxt: `${comp_profile('expired')}` },
    abandoned: { css: "badge-soft-danger", sTxt: `${comp_profile('abandoned')}` },
    review: { css: "badge-soft-purple", sTxt: `${comp_profile('reviewing')}` },
  };
  return (
    status ? (
      <span
        className={
          "virtualvisitbadge ml-0 badge badge-md " + docStatMeta[status]['css']
        }
      >
        {docStatMeta[status]['sTxt']}
      </span>
    )
      :
      null
  );
};

export const fCalcProfileIdDocsInf = (maxDocCount, savedIdDocsData = []) => {
  let loopCnt = 0,
    savedDocCnt = savedIdDocsData?.length || 0;
  let respIdDocInf = {
    maxDocCount: maxDocCount,
    savedDocCount: savedDocCnt,
    shownIdDocCount: 0,
    arrIdDocs: [],
  };

  if (savedDocCnt) {
    savedIdDocsData.forEach((uDocData) => {
      respIdDocInf.arrIdDocs[loopCnt] = {
        id: "uIdDoc_" + uDocData?.id,
        dispOrder: loopCnt,
        hasData: true,
        docApiData: uDocData,
        hasView: true,
        errCode: null,
        hasErr: false,
        arrErrMsg: [],
      };
      respIdDocInf.shownIdDocCount++;
      loopCnt++;
    });
  }

  for (; loopCnt < maxDocCount; loopCnt++) {
    const isViewable = loopCnt === 0;
    isViewable && respIdDocInf.shownIdDocCount++;

    respIdDocInf.arrIdDocs[loopCnt] = {
      id: "new_" + loopCnt,
      dispOrder: loopCnt,
      hasData: false,
      docFormData: { docTypeId: null, docFile: null },
      hasView: isViewable,
      errCode: null,
      hasErr: false,
      arrErrMsg: [],
    };
  }
  return respIdDocInf;
};
