import { useState, useCallback } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next"
import Swal from "sweetalert2";
import useAuth from 'hooks/useAuth';
import { AppLogo, PasswordValidationList } from 'v2/src/core';
import ResetPasswordSchema from 'v2/src/schema/resetPassword';
//import { fReverseStr, fBase64Decode } from 'v2/src/lib/strUtils';
import { fDecodeAppCommToken, } from 'v2/src/lib/appUtils';
import { BtnOKCls } from 'v2/src/config/uiConfig';
import {
  fGetLocalSt, fSetLocalSt, fPostAuthSetData,
} from 'v2/src/lib/appUtils';
import {
  useResetPasswordMutation, useLoginMutation,
} from 'v2/src/lib/services/auth/authApi';
import {
  rSetUserCredentials, rSetUserDetails, //aSGetAuthUserData,
} from 'v2/src/lib/services/auth/authSlice';
import { useLazyUserDetailsQuery } from 'v2/src/lib/services/profileApi';
import { toast } from "react-toastify";
//---------------------------------------------------------

export default function ResetPassword() {
  const fDispatchSignIn = useDispatch();
  const fNavigate = useNavigate();
  const location = useLocation();
  const { auth, setAuth } = useAuth();
  const queryParams = new URLSearchParams(location.search);
  const payload = queryParams.get('payload');

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const { t: error_message } = useTranslation('error_message');
  const { t: common } = useTranslation('common');
  const { t: authentication } = useTranslation('authentication');

  const [fResetPassword, { isLoading: pWaitRP, }] = useResetPasswordMutation();
  const [fLogin, { isLoading: pWaitLogin }] = useLoginMutation();
  const [fGetUserDetails] = useLazyUserDetailsQuery({ profileId: null, userType: null });

  //const decodedPayload = JSON.parse(fBase64Decode(fReverseStr(payload)));
  const decodedPayload = fDecodeAppCommToken(payload);
  let payloadEmail = decodedPayload['email'];
  //let payloadToken = decodedPayload['token'];
  const {
    register: ResetPassRegister,
    handleSubmit: ResetPassHandleSubmit,
    formState: { errors: ResetPassErrors, isSubmitting: ResetPassIsSubmitting },
    watch: ResetPassWatch,
    getValues: ResetPassGetValues,
  } = useForm({
    resolver: yupResolver(ResetPasswordSchema(error_message)),
    mode: 'onChange',
  });
  const watchFields = ResetPassWatch(['Password']);//Required

  const attemptAutoLogin = async (eMail, pwd) => {
    if (eMail && pwd) {
      let signInResp, axCode, strMsg = '';
      try {
        signInResp = await fLogin({ email: eMail, password: pwd, });
        //console.log('signInResp', signInResp);
      } catch (excp) {
        //if(excp?.status) { axCode = excp.status; }
      }

      if (signInResp?.error) { signInResp = signInResp.error; }
      else if (signInResp?.data) { signInResp = signInResp.data; }

      if (signInResp?.status === true && signInResp?.data?.user?.id) {
        fPostAuthSetData(signInResp?.data, fDispatchSignIn, rSetUserCredentials,
          fGetUserDetails, rSetUserDetails, setAuth, auth);
        /*fDispatchSignIn(rSetUserCredentials(signInResp?.data));
        fSetAuthCookie(signInResp.data, setAuth, auth);
        //strMsg = signInResp?.message;
        */

        fNavigate('/', { replace: true });
      } else {
        fNavigate('/login', { replace: true });//state: {code: 'regSuccess'}
      }
    }
    return false;
  };

  const fResetPwdHandler = useCallback(async (data) => {
    let resPwdResp, axCode, strMsg = '';
    const inParams = { payload: payload, password: data.Password, };

    try {
      resPwdResp = await fResetPassword(inParams);
    } catch (excp) { }
    if (resPwdResp?.error) { resPwdResp = resPwdResp.error; }
    else if (resPwdResp?.data) { resPwdResp = resPwdResp.data; }
    if (resPwdResp?.status === true) {
      const aLResp = await attemptAutoLogin(payloadEmail, ResetPassGetValues().Password);
    } else {
      if (!regResp?.data?.status) {
        toast.warning(authentication('reset_pass_422'));
        // Swal.fire({
        //   // icon: 'error',
        //   html: `${authentication('reset_pass_422')}`
        // })
        return;
      } else {
        toast.error(common('something_went_wrong'));
        // Swal.fire({
        //   // icon: 'error',
        //   html: common('something_went_wrong')
        // });
        return;
      }
    }
  }, []);
  //---------------------------------------------------------

  return (
    <div className="account-body accountbg">
      <div className="container">
        <div className="row vh-100 ">
          <div className="col-12 align-self-center">
            <div className="auth-page">
              <div className="card auth-card shadow-lg">
                <div className="card-body">
                  <div className="px-3">
                    <div style={{ textAlign: 'center' }}>
                      {/*<AppLogo style={{ maxHeight: '100px', height: 'auto', width: '100%', maxWidth: '170px' }} />*/}
                      <AppLogo style={{ maxHeight: '100px', height: '80px', width: 'auto', maxWidth: '100px' }} />
                    </div>

                    <div className="text-center auth-logo-text">
                      <h4 className="mt-0 mb-3 mt-5">{authentication('reset_password')}</h4>
                      <p className="text-muted mb-0">{authentication('enter_old_new_password')}</p>
                    </div>

                    <form className="form-horizontal auth-form my-4" onSubmit={ResetPassHandleSubmit(fResetPwdHandler)} noValidate>
                      <div className="form-group">
                        <label htmlFor="password">{common('new_password')}</label>
                        <div className="input-group">
                          <span
                            className="auth-form-icon password-eye-icon"
                            onClick={(e) => {
                              setShowNewPassword(!showNewPassword)
                            }}
                          >
                            <i className={`mdi ${showNewPassword ? 'mdi-eye' : 'mdi-eye-off'}`}></i>
                          </span>
                          <input
                            type={`${showNewPassword ? 'text' : 'password'}`}
                            placeholder={common('enter_new_password')}
                            {...ResetPassRegister('Password')}
                            className={`form-control ${Boolean(ResetPassErrors.Password) ? 'is-invalid' : ''}`}
                          />
                        </div>
                        {ResetPassErrors.Password && <label className="text-danger">{error_message(ResetPassErrors.Password.message)}</label>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="password">{common('confirm_password')}</label>
                        <div className="input-group">
                          <span
                            className="auth-form-icon password-eye-icon"
                            onClick={(e) => {
                              setShowNewConfirmPassword(!showNewConfirmPassword)
                            }}
                          >
                            <i className={`mdi ${showNewConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'}`}></i>
                          </span>
                          <input
                            type={`${showNewConfirmPassword ? 'text' : 'password'}`}
                            placeholder={common('enter_confirm_password')}
                            {...ResetPassRegister('ConfirmPassword')}
                            className={`form-control ${Boolean(ResetPassErrors.ConfirmPassword) ? 'is-invalid' : ''}`}
                          />
                        </div>
                        {ResetPassErrors.ConfirmPassword && <label className="text-danger">{error_message(ResetPassErrors.ConfirmPassword.message)}</label>}
                      </div>

                      <PasswordValidationList password={ResetPassGetValues().Password} errors={ResetPassErrors} />
                      <br />
                      <div className="form-group mb-0 row">
                        <div className="col-12 mt-2">
                          {/* show the error message only if it exists */}
                          {errorMessage ? <p className="text-danger text-center">{errorMessage}</p> : null}
                          <button type="submit"
                            className={BtnOKCls + (pWaitRP || pWaitLogin ?
                              ' disabled' : '')}
                          >
                            {common('submit')} <i className="fas fa-sign-in-alt ml-1"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="m-3 text-center text-muted">
                    <p className="">
                      {common('remember_it')}{' '}
                      <Link to="/login" className="text-primary ml-2">
                        {common('sigin_here')}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
