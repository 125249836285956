import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Spinner, Alert } from 'react-bootstrap';
import { usePostSubscriptionMutation } from 'v2/src/lib/services/nHSubscriptionApi';
import { useTranslation } from 'react-i18next';

const CheckoutForm = ({ handleClose, selPlanId, fCheckoutHandler }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const { t: patient_subscription } = useTranslation('patient_subscription')

  const [postSubscription, { data: postSubscriptionData, error: postSubscriptionError }] = usePostSubscriptionMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    setError(null);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    // Create payment method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    try {
      // if (paymentMethod) {
      await postSubscription({
        subscription_plan_id: selPlanId,
        payment_method: paymentMethod?.id,
      }).unwrap();
      if (postSubscriptionError) {
        console.log("error occured!")
        setError(error?.message);
        setProcessing(false);
        Swal.fire({
          // icon: "info",
          html: patient_subscription("subscription_error"),
        })
      } else {
        setSuccess(true);
        setProcessing(false);
        handleClose(); // Close the modal on successful payment
      }
      // }
    } catch (err) {
      setProcessing(false);
      Swal.fire({
        // icon: "info",
        html: patient_subscription("subscription_error"),
      })
    }
  };

  return (
    <form onSubmit={handleSubmit} className='stripe-checkout-form mt-4'>
      <CardElement className=" stripe-card-element" />

      <button
        type="button"
        className="btn btn-success btn-round waves-effect waves-light "
        style={{
          fontWeight: '700', letterSpacing: '0.32px',
          textTransform: 'uppercase',
          width: "160px",
          margin: "0 auto",
          borderRadius: "30px",
          padding: "14px 10px"
        }}
        // onClick={() => { fCheckoutHandler() }}
        disabled={!stripe || processing}
        onClick={(e) => {
          fCheckoutHandler();
          handleSubmit(e);
        }}
      >
        {patient_subscription('subscribe_now')}
      </button>
      {error && <Alert variant="danger" className="">{error}</Alert>}
      {success && <Alert variant="success" className="">{patient_subscription('payment_successful')}</Alert>}
    </form>
  );
};

export default CheckoutForm;
