import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL, NIU_SA_API_URL } from '../../constants'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      headers.set('content-type', 'application/json')
      return headers
    },
  }),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (params) => {
        let body = { ...params?.data }

        return {
          url: `${API_URL}/register`,
          method: 'POST',
          body: body,
        }
      },
    }),
    loginUser: builder.mutation({
      query: (params) => {
        let body = { ...params?.data }

        return {
          url: `${API_URL}/user/login`,
          method: 'POST',
          body: body,
        }
      },
    }),
    forgotPassword: builder.mutation({
      query: (params) => {
        let body = { ...params?.data }

        return {
          url: `${API_URL}/user/forgot-password`,
          method: 'POST',
          body: body,
        }
      },
    }),
    ResetPassword: builder.mutation({
      query: (params) => {
        let body = { ...params?.data }

        return {
          url: `${API_URL}/user/reset-password`,
          method: 'POST',
          body: body,
        }
      },
    }),
    getUser: builder.query({
      query: (params) => {
        const userId = params?.userId

        let headers = {}
        if (params?.token) {
          headers = {
            Authorization: `Bearer ${params?.token}`,
          }
        }

        return {
          url: `${NIU_SA_API_URL}/user/${userId}`,
          method: 'GET',
          headers: headers,
        }
      },
    }),
  }),
})

export const { useCreateUserMutation, useLoginUserMutation, useForgotPasswordMutation, useResetPasswordMutation, useGetUserQuery, useLazyGetUserQuery } = userApi
