import { nHApi } from 'v2/src/lib/services/public/nHApi';

export const nHMarketPlaceApi = nHApi.injectEndpoints({
  endpoints: (builder) => ({
  })
});

export const {

} = nHMarketPlaceApi;

