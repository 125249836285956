import Calendar from "./calender/Calendar";
import WelcomeCard from "components/provider/WelcomeCard";
import PatientQueue from "v2/src/components/PatientQueue";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

function hourformat(hour) {
  if (hour > 12) {
    return (hour - 12 < 10 ? "0" : "") + (hour - 12) + ":00 PM";
  } else if (hour === 12) {
    return 12 + ":00 PM";
  } else if (hour === 0) {
    return 12 + ":00 AM";
  } else {
    return (hour < 10 ? "0" : "") + hour + ":00 AM";
  }
}
function ProviderIndex() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [patientList, setPatientList] = useState([]);
  const { t: provider_dashboard } = useTranslation("provider_dashboard");

  const [clinicList, setClinicList] = useState([]);
  const myModal = useRef();
  const [updateVisit, setUpdateVisit] = useState(true);
  const navigate = useNavigate();
  const [pageNum, setPageNum] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm();
  async function createInPersonVisit(data) {
    const controller = new AbortController();

    await axiosPrivate
      .post(
        "createInPersonVisit",
        {
          ...data,
          Email: auth?.email || Cookies.get("email"),
        },
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        const { Status, Message } = res.data;
        if (Status) {
          document.getElementById("create-appointment").reset();

          Swal.fire({
            title: provider_dashboard("in_person_appointment"),
            html: ``,
            // icon: "info",
          });
          setUpdateVisit(!updateVisit);
          $("#myModal").hide();
          $(".modal-backdrop").hide();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    async function getClinicList() {
      const controller = new AbortController();

      await axiosPrivate
        .post(
          "getClinics",
          { Email: auth.email },
          {
            signal: controller.signal,
          }
        )
        .then((res) => {
          console.log(res);
          const { Status, Data: data = [], Message } = res.data;

          if (Status) {
            console.log("Clinics", data);
            setClinicList(data);
          } else {
            throw new Error(Message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    async function getPatientList() {
      const controller = new AbortController();

      await axiosPrivate
        .post(
          "getPatients",
          { Email: auth?.email || Cookies.get("email") },
          {
            signal: controller.signal,
          }
        )
        .then((res) => {
          const { Data = [] } = res.data;
          setPatientList(Data.Patients);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    getPatientList();
    getClinicList();
  }, [updateVisit]);
  let morning_options = [
    8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3, 4,
    5, 6, 7,
  ];
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box"></div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-lg-6">
          <WelcomeCard />
        </div>
        <div className="col-lg-3">
          <div className="card" style={{ height: '90%', minHeight: '300px' }}>
            <div className="card-body d-flex align-items-center justify-content-center">
              <div className="col">
                <i className="fas fa-user-nurse fa-fw fa-4x" style={{ color: '#303e67' }}></i>
                <i className="fas fa-tasks fa-fw fa-4x" style={{ color: '#303e67' }}></i>

                <h4> {provider_dashboard('administrative_updates')}</h4>
                <h6 className="text-muted">{provider_dashboard('shortcuts_to_areas')}</h6>
                <div className="align-item-center float-right p-1">
                  <button
                    className="btn btn-outline-danger btn-round "
                    onClick={() => {
                      navigate('patient?insurance_workflow=Pending')
                    }}
                  >
                    {provider_dashboard('review_patient_insurance_updates')}
                  </button>
                </div>
                <div className="align-item-center float-right p-1">
                  <button
                    className="btn btn-outline-danger btn-round "
                    onClick={() => {
                      navigate('visit-requests')
                    }}
                  >
                    {provider_dashboard('review_appointment_requests')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="card" style={{ height: '90%', minHeight: '300px' }}>
            <div className="card-body d-flex align-items-center justify-content-center">
              <div className="col">
                <i className="fas fa-user-nurse fa-fw fa-4x" style={{ color: '#303e67' }}></i>
                <i className="fas fa-comment fa-fw fa-4x" style={{ color: '#303e67' }}></i>
                <i className="fas fa-user fa-fw fa-4x" style={{ color: '#303e67' }}></i>

                <h4> {provider_dashboard('visit_with_a_patient')}</h4>
                <h6 className="text-muted">{provider_dashboard('schedule_a_virtual_inperson_visit')}</h6>
                <div className="align-item-center float-right">
                  <button
                    className="btn btn-outline-success btn-round "
                    onClick={() => {
                      navigate('patient')
                      // setShowModal(true)
                    }}
                  // data-toggle="modal"
                  // data-target="#myModal"
                  >
                    {provider_dashboard('go_to_patients_menu')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="card">
        <div className="card-body">
          <h4 className="header-title mt-0 mb-3">
            {provider_dashboard("virtual_visit_queue")}
          </h4>
          <div className="table-responsive">
            <PatientQueue limit={6} stopPolling={showModal} />
          </div>
        </div>
      </div>
      {/* <!-- Calendar --> */}
      <div className="row">
        {/* <div className='col-lg-4'>
          <TodaySchedule />
        </div> */}
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <Calendar />
              <div style={{ clear: "both" }}></div>
            </div>
          </div>
        </div>
      </div>
      {showModal === false ? null : (
        <div
          id="myModal"
          className={showModal ? "modal fade" : "modal fade show"}
          role="form"
          ref={myModal}
        >
          <div
            className="modal-dialog"
            style={{ maxWidth: "500px", margin: "1.75rem auto" }}
          >
            {/* <!-- Modal content--> */}
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {provider_dashboard("in_person_visit")}
                </h4>
              </div>
              <form
                id="create-appointment"
                onSubmit={handleSubmit(createInPersonVisit)}
              >
                <div className="modal-body">
                  <div className="nuModalCont visitRequestModal">
                    <div className="">
                      <label htmlFor="visitTitle" className="col-form-label">
                        {provider_dashboard("visit_title")}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="visitTitle"
                        {...register("VisitTitle")}
                      />
                      <label className="col-form-label">
                        {provider_dashboard("patient")}
                      </label>
                      <select
                        className="form-control"
                        {...register("PatientID")}
                      >
                        <option>{provider_dashboard("select_patient")}</option>
                        {patientList.map((item, index) => {
                          return (
                            <option key={index} value={item.patient_id}>
                              {item.first_name} {item.last_name}
                            </option>
                          );
                        })}
                      </select>

                      <label className="col-form-label">
                        {provider_dashboard("clinic")}
                      </label>
                      <select
                        className="form-control"
                        {...register("ClinicID")}
                      >
                        <option>{provider_dashboard("select_clinic")}</option>
                        {clinicList.map((item) => {
                          return (
                            <option value={item.clinic_id}>
                              {item.clinic_name}{" "}
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="date" className="col-form-label">
                        {provider_dashboard("visit_date")}
                      </label>
                      <input
                        className="form-control"
                        defaultValue={moment().format("yy-mm-dd")}
                        type="date"
                        id="date"
                        {...register("Date")}
                      />

                      <label htmlFor="time" className="col-form-label">
                        {provider_dashboard("time")}
                      </label>
                      {/* <input className="form-control" pattern="[0-9]{2}:[0]{2}" defaultValue={moment().format('HH:MM a')} type="time" id="time" {...register("Time")}/> */}
                      <select {...register("Time")} className="form-control">
                        {morning_options.map((option, index) => (
                          <option key={index} value={option}>
                            {hourformat(option)}
                          </option>
                        ))}

                        <option value={null}>--:--</option>
                      </select>
                      <label className="col-form-label">
                        {provider_dashboard("internal_notes")}
                      </label>
                      <textarea
                        className="form-control"
                        rows="5"
                        id="message"
                        {...register("InternalNotes")}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="nuBtnContMod">
                    <button
                      type="submit"
                      className="btn btn-success waves-effect waves-light"
                      id="create-visit"
                    >
                      {provider_dashboard("save_visit")}
                    </button>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    data-target="#myModal"
                    data-dismiss="modal"
                    onClick={(e) => {
                      setShowModal(false);
                      $("#myModal").hide();
                      $(".modal-backdrop").hide();
                    }}
                  >
                    {provider_dashboard("close")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProviderIndex;
