import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Footer from 'components/Footer'
import RingLoading from 'components/lottie/RingLoading'
import TableCard from 'components/table/Tables'
import { getUserSubscriptionDetailsStatusList } from 'utils'
import { AWS_BUCKET } from 'constants'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { useTranslation } from 'react-i18next'
import CardItem from 'components/cards/Card'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useGetSubscriptionsQuery, useGetUserSubscriptionHistoryQuery, useCancelSubscriptionMutation } from 'v2/src/lib/services/nHSubscriptionApi'
import CheckoutForm from 'v2/src/assets/components/CheckoutForm'
import { STRIPE_PUBLISHABLE_KEY } from 'constants'
import { toast } from 'react-toastify'
import { useUserProfileDetailQuery } from 'v2/src/lib/services/profileApi'
import { fGetAUserInf } from 'v2/src/lib/appUtils'

function NewSubscription() {
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)
  const { t: patient_subscription } = useTranslation('patient_subscription')
  const { t: patient_virtual_visit } = useTranslation('patient_virtual_visit')
  const { t: common } = useTranslation('common')
  const navigate = useNavigate()
  let aUData = fGetAUserInf()
  const aUserProfileId = aUData?.details?.profileId
  const { data: patientDetails, loading: patientDetailsLoading } = useUserProfileDetailQuery({ profileId: aUserProfileId })
  const flgShowPlansConcise = 10
  const [show, setShow] = useState(false)

  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingPayments, setIsLoadingPayments] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)

  const [subscriptionPlans, setSubscriptionPlans] = useState([])

  const [showSubPlan, setShowSubPlan] = useState(false)
  const [subIsActive, setSubIsActive] = useState(false)
  const [showCancelBtn, setShowCancelBtn] = useState(false)
  const [disableCancelBtn, setDisableCancelBtn] = useState({
    status: false,
    message: '',
  })
  const [showWaitMsg, setShowWaitMsg] = useState(false)
  const [stSelSubsPlan, setStSelSubsPlan] = useState(false)
  const [subs, setSubs] = useState({ subsStart: '', subsEnd: '' })

  const {
    data: getNHSubscriptionssData,
    isLoading: subscriptionLoading,
    refetch: refetchSubscriptions,
  } = useGetSubscriptionsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const {
    data: userSubscriptionHistoryData,
    isLoading: historyLoading,
    refetch: refetchUserSubscriptionHistory,
  } = useGetUserSubscriptionHistoryQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const userActiveSub = userSubscriptionHistoryData?.data?.filter((item) => item.subscription_status === 'active')[0]

  const [cancelSubscription] = useCancelSubscriptionMutation()

  useEffect(() => {
    ;(async function () {
      let subPlan = getNHSubscriptionssData?.data
      setSubscriptionPlans(subPlan)
      setSubs({
        subsStart: userActiveSub?.subscription_status === 'active' ? userActiveSub?.start_date : '',
        subsEnd: userActiveSub?.subscription_status === 'active' ? userActiveSub?.end_date : '',
      })
      const [showSubscriptionPlans, subscriptionIsActive, showCancelBtn, showWaitingMessage] = getUserSubscriptionDetailsStatusList(userActiveSub)
      setShowSubPlan(showSubscriptionPlans)
      setSubIsActive(subscriptionIsActive)
      setShowCancelBtn(showCancelBtn)
      setShowWaitMsg(showWaitingMessage)

      if (!userActiveSub?.renewal_date && userActiveSub?.end_date) {
        setDisableCancelBtn({
          status: true,
          message: patient_subscription('subscription_cancelled_success_msg'),
        })
      }

      if (!subscriptionLoading && !historyLoading) setIsLoading(false)
      setIsLoadingPayments(false)
      setIsSuccess(true)
    })()
  }, [getNHSubscriptionssData, userSubscriptionHistoryData, historyLoading, subscriptionLoading])

  const cancelUserSubscriptionHandler = async () => {
    const cancelUserSubscriptionApiResp = await cancelSubscription({
      subscription_plan_id: userActiveSub?.subscription_plan_id,
    })
    if (cancelUserSubscriptionApiResp?.data?.status) {
      toast.success(cancelUserSubscriptionApiResp?.message || patient_subscription('subscription_cancelled_success_msg'))
      setShowCancelBtn(false)
    } else {
      toast.error(cancelUserSubscriptionApiResp?.data?.message || 'subscription_cancel_' + cancelUserSubscriptionApiResp?.data?.status)
    }
  }

  let subsOptionsJsx = []
  const fSelSubsPlanHandler = (e) => {
    const selPlanId = parseInt(e.target.value)
    selPlanId && setStSelSubsPlan(selPlanId)
  }

  const handleClose = () => setShow(false)
  const fCheckoutHandler = async () => {
    if (stSelSubsPlan) {
      let selPlanParams = subscriptionPlans?.filter((sP) => sP.id == stSelSubsPlan)
      checkout(selPlanParams[0])
    }
  }

  useEffect(() => {
    if (patientDetails?.status) {
      if (!patientDetails.data?.personal_details_completed || !patientDetails.data?.has_approved_identification_document) {
        Swal.fire({
          html: patient_virtual_visit('complete_registration'),
          confirmButtonText: patient_virtual_visit('start_verification'),
        }).then(() => {
          navigate('/patient/profile', { replace: true })
        })
      }
    }
  }, [patientDetails])

  subscriptionPlans?.forEach((sOpt) => {
    const plId = parseInt(sOpt.id)
    if (plId !== 5) {
      const plTitle = sOpt.name.toLowerCase() === '12 months plan' ? 'Yearly Plan' : sOpt.name
      subsOptionsJsx.push(
        <div key={'subsPl_' + plId} className="txAL">
          <input
            type="radio"
            autoComplete="off"
            className={true ? 'niuHRdo3 rdo' : 'niuHRdo2 rdo'}
            name="rdoSubsPlan"
            id={'rdoSubsPlan_' + plId}
            value={plId}
            onChange={fSelSubsPlanHandler}
            checked={plId === stSelSubsPlan}
          />
          <label className="Hd2" htmlFor="rdoSubsPlan1">
            {plTitle} - ${sOpt.amount}
          </label>
        </div>
      )
    }
  })

  const checkout = async (plan) => {
    if (plan == undefined) {
      setIsLoading(false)
      toast.error(patient_subscription('subscription_error'))
    } else {
      setShow(true)
    }
  }

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <h4 className="page-title">{patient_subscription('subscription_payment')}</h4>
              </div>
            </div>
          </div>
          {isLoading ? (
            <CardItem>
              <div className="d-flex justify-content-center">
                <RingLoading size={200} />
              </div>
            </CardItem>
          ) : (
            !showSubPlan && (
              <div className="row justify-content-center">
                {flgShowPlansConcise ? (
                  <div className="col-lg-4 xtBdr2">
                    <div className="card">
                      <div className="card-body">
                        <div className="pricingTable1 text-center">
                          <ul className="list-unstyled pricing-content-2 pb-3">
                            <li className="txAL">{patient_subscription('subscription_plan_desc_1')}</li>
                            <li className="txAL">{patient_subscription('subscription_plan_desc_2')}</li>
                            <li className="txAL">{patient_subscription('subscription_plan_desc_3')}</li>
                            <li className="txAL">{patient_subscription('subscription_plan_desc_4')}</li>
                            <li className="txAL">{patient_subscription('subscription_plan_desc_5')}</li>
                          </ul>
                          <div className="text-center">
                            <h3 className="amount"></h3>
                          </div>
                          <div className="xd-flex justify-content-center xtBdr2">
                            <div className="xbtn-group xniuHRdo" role="group" aria-label="Subscription Plans">
                              {subsOptionsJsx}
                              {stSelSubsPlan && (
                                <Elements stripe={stripePromise}>
                                  <CheckoutForm handleClose={handleClose} selPlanId={stSelSubsPlan} fCheckoutHandler={fCheckoutHandler} />
                                </Elements>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  subscriptionPlans?.map((item) => (
                    <div className="col-lg-4" key={item?.id}>
                      <div className="card">
                        <div className="card-body">
                          <div className="pricingTable1 text-center">
                            <img src={`${AWS_BUCKET}/assets/images/widgets/p-5.svg`} alt="" className="" height="100" />
                            <h6 className="title1 py-3 mt-2 mb-0">
                              {item.name}
                              <small className="text-muted"></small>
                            </h6>
                            <ul className="list-unstyled pricing-content-2 pb-3">
                              <li className="txAL">{patient_subscription('subscription_plan_desc_1')}</li>
                              <li className="txAL">{patient_subscription('subscription_plan_desc_2')}</li>
                              <li className="txAL">{patient_subscription('subscription_plan_desc_3')}</li>
                              <li className="txAL">{patient_subscription('subscription_plan_desc_4')}</li>
                              <li className="txAL">{patient_subscription('subscription_plan_desc_5')}</li>
                              {item?.PromotionalText ? <li>{item?.PromotionalText}</li> : <></>}
                            </ul>
                            <div className="text-center">
                              <h3 className="amount">
                                ${item?.amount}
                                <small className="font-12 text-muted">/{item?.description}</small>
                              </h3>
                            </div>
                            <div className="d-flex justify-content-center">
                              <Link
                                className="pricingTable-signup mt-3 "
                                onClick={() => {
                                  checkout(item)
                                }}
                              >
                                {patient_subscription('subscribe_now')}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )
          )}

          {showSubPlan && (
            <>
              {!isLoading ? (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="total-payment">
                          <h5 className="m-2">{patient_subscription('subscription')}</h5>
                          <table className="table mb-0">
                            <tbody>
                              <tr>
                                <td className="payment-title">{patient_subscription('start_date')}</td>
                                <td>{subs.subsStart ? moment(subs.subsStart).format('hh:mm a MMM DD, YYYY') : '-'}</td>
                              </tr>
                              <tr>
                                <td className="payment-title">{patient_subscription('end_date')}</td>
                                <td>{subs.subsEnd ? moment(subs.subsEnd).format('hh:mm a MMM DD, YYYY') : '-'}</td>
                              </tr>
                            </tbody>
                          </table>

                          {showCancelBtn &&
                            (disableCancelBtn?.status ? (
                              <div className={`d-flex flex-column flex-md-row float-right m-4`}>
                                <OverlayTrigger placement="top" delay={{ show: 100, hide: 100 }} overlay={<Tooltip id="tooltip-disabled">{disableCancelBtn?.message}</Tooltip>}>
                                  <span className="d-inline-block">
                                    <button
                                      className={`btn btn-round btn-outline-danger waves-effect waves-light mt-1 mt-md-0 ml-0 ml-md-1 ${disableCancelBtn?.status ? 'pointer-events-none' : ''}`}
                                      onClick={() =>
                                        Swal.fire({
                                          html: `${patient_subscription('cancel_subscription_msg')}`,
                                          showConfirmButton: true,
                                          confirmButtonText: `${common('ok')}`,
                                          showCancelButton: true,
                                          cancelButtonText: `${common('cancel')}`,
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            cancelUserSubscriptionHandler()
                                          }
                                        })
                                      }
                                      disabled={disableCancelBtn?.status}
                                    >
                                      {patient_subscription('cancel_subscription')}
                                    </button>
                                  </span>
                                </OverlayTrigger>
                              </div>
                            ) : (
                              <div className={`d-flex flex-column flex-md-row float-right m-4`}>
                                <span className="d-inline-block">
                                  <button
                                    className={`btn btn-round btn-outline-danger waves-effect waves-light mt-1 mt-md-0 ml-0 ml-md-1 ${disableCancelBtn?.status ? 'pointer-events-none' : ''}`}
                                    onClick={() =>
                                      Swal.fire({
                                        html: `${patient_subscription('cancel_subscription_msg')}`,
                                        showConfirmButton: true,
                                        confirmButtonText: `${common('ok')}`,
                                        showCancelButton: true,
                                        cancelButtonText: `${common('cancel')}`,
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          cancelUserSubscriptionHandler()
                                        }
                                      })
                                    }
                                    disabled={disableCancelBtn?.status}
                                  >
                                    {patient_subscription('cancel_subscription')}
                                  </button>
                                </span>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <CardItem>
                  <div className="d-flex justify-content-center">
                    <RingLoading size={200} />
                  </div>
                </CardItem>
              )}
            </>
          )}
          {userSubscriptionHistoryData && userSubscriptionHistoryData?.data?.length > 0 ? (
            <div className="row">
              <TableCard headers={[patient_subscription('subscription_name'), patient_subscription('payment_date'), patient_subscription('amount')]}>
                {userSubscriptionHistoryData?.data?.map((item) => (
                  <tr>
                    <td>{item?.subscription_name}</td>
                    <td>{moment(item?.payment_details?.payment_date).format('hh:mm a MMM DD, YYYY')}</td>
                    <td>{item?.payment_details?.amount}</td>
                  </tr>
                ))}
              </TableCard>
            </div>
          ) : (
            <>
              <CardItem className={'col-lg-12'}>
                {isLoadingPayments ? (
                  <div className="d-flex justify-content-center">
                    <RingLoading size={200} />
                  </div>
                ) : (
                  <h5 className="m-2">{patient_subscription('no_payment_history')}</h5>
                )}
              </CardItem>
            </>
          )}
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default NewSubscription
