import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

const dummyDOB = '1920-01-01';

export const ProfileFormSchema = yup.object({
  FirstName: yup
    .string()
    .required('required_error')
    .min(2, 'name_min_length_error')
    .max(50, 'name_max_length_error')
    .matches(/^[A-Za-z\s]+$/, 'name_format_error'),

  LastName: yup
    .string()
    .required('required_error')
    .min(2, 'name_min_length_error')
    .max(50, 'name_max_length_error')
    .matches(/^[A-Za-z\s]+$/, 'name_format_error'),

  contactNum: yup.string()
    .required('Contact number is required')
    .test('isValidPhoneNumber', 'Invalid phone number', value => {
      return isValidPhoneNumber(value || '');
    }),

  Address1: yup.string().required('required_error').max(255, 'address_max_length_error'),
  CountryID: yup.string().required('required_error'),
  Province: yup.string().required('required_error'),
  City: yup.string().required('required_error'),
  ZIPCode: yup
    .string()
    .required('required_error')
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'zipcode_error'),

  LocalTimeZone: yup.string().required('required_error'),

  DateOfBirth: yup
    .string()
    .required('required_error')
    .test('is-not-dummy-date', 'required_error', value => {
      return value !== dummyDOB;
    })
    .test('is-not-in-future', 'no_future_dob', function (value) {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      selectedDate.setHours(0, 0, 0, 0);
      return selectedDate <= currentDate;
    })
    .test('is-at-least-11-years-old', 'min_11_years_old', function (value) {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      let age = currentDate.getFullYear() - selectedDate.getFullYear();
      const m = currentDate.getMonth() - selectedDate.getMonth();
      if (m < 0 || (m === 0 && currentDate.getDate() < selectedDate.getDate())) {
        age--;
      }
      return age >= 11;
    }),

})
  .required();

export default ProfileFormSchema;

